unit EndOfYearForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TEOYForm = class(TJobBase)
    Statements: TXDataWebDataSet;
    StatementsDateAdded: TDateTimeField;
    StatementsLastUpdatedBy: TIntegerField;
    StatementsDateSent: TDateField;
    StatementsLastUpdated: TDateTimeField;
    StatementsNotNeeded: TIntegerField;
    StatementsId: TIntegerField;
    StatementsThreeYear: TIntegerField;
    StatementsOneYear: TIntegerField;
    StatementsAddedBy: TIntegerField;
    StatementsJobId: TIntegerField;
    StatementsTaxYearKey: TIntegerField;
    StatementsTwoYear: TIntegerField;
    procedure StatementsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    FMostRecentTaxYear: Word;
    FLastStatements: Word;
    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function RecentJobsTitle: string; override;
    function NoRecentJobsMessage: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;


implementation

{$R *.dfm}

uses MainDataModule, AppUtils;

procedure TEOYForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  JobRunType.Visible := True;
  jOBrUNtyPE.ItemIndex := 1;
  FMostRecentTaxYear := TSysHelper.LastTaxYearKey;

  Statements.Load;
end;

{ TEOYForm }

function TEOYForm.CanPlaceJob: Boolean;
begin
  Result := FLastStatements < FMostRecentTaxYear;
end;

function TEOYForm.JobClass: string;
begin
  Result := 'TGiftAidStatements';
end;

function TEOYForm.JobDescription: string;
begin
  Result := 'End of Year Letters';
end;

function TEOYForm.JobDisplayName: string;
begin
  Result := 'End of Year Letters';
end;

procedure TEOYForm.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
   AJobParams.Properties['TaxYear'] := FMostRecentTaxYear;
end;

function TEOYForm.MinimumProcessLevel: string;
begin
    result := 'AnyAdministrator';
end;

function TEOYForm.NoRecentJobsMessage: string;
begin
 Result := 'There have been no recent ' + JobDisplayName + ' run';
end;

function TEOYForm.RecentJobsTitle: string;
begin
Result := 'Recent ' + JobDisplayName;
end;

procedure TEOYForm.StatementsAfterOpen(DataSet: TDataSet);
begin
 if Statements.IsEmpty then
    FLastStatements := 0
 else
 begin
   Statements.First;
   FLastStatements := StatementsTaxYearKey.Value;
 end;

  if FMostRecentTaxYear > FLastStatements then
     JobMessage.HTML.Text := 'Statements for tax year ' + TSysHelper.TaxYearDescriptor(FMostRecentTaxYear) + ' can be created'
  else
    JobMessage.HTML.Text := 'Statements for tax year ' + TSysHelper.TaxYearDescriptor(FMostRecentTaxYear) + 'have already been created';
end;

procedure TEOYForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Statements := TXDataWebDataSet.Create(Self);
  StatementsDateAdded := TDateTimeField.Create(Self);
  StatementsLastUpdatedBy := TIntegerField.Create(Self);
  StatementsDateSent := TDateField.Create(Self);
  StatementsLastUpdated := TDateTimeField.Create(Self);
  StatementsNotNeeded := TIntegerField.Create(Self);
  StatementsId := TIntegerField.Create(Self);
  StatementsThreeYear := TIntegerField.Create(Self);
  StatementsOneYear := TIntegerField.Create(Self);
  StatementsAddedBy := TIntegerField.Create(Self);
  StatementsJobId := TIntegerField.Create(Self);
  StatementsTaxYearKey := TIntegerField.Create(Self);
  StatementsTwoYear := TIntegerField.Create(Self);

  Statements.BeforeLoadDFMValues;
  StatementsDateAdded.BeforeLoadDFMValues;
  StatementsLastUpdatedBy.BeforeLoadDFMValues;
  StatementsDateSent.BeforeLoadDFMValues;
  StatementsLastUpdated.BeforeLoadDFMValues;
  StatementsNotNeeded.BeforeLoadDFMValues;
  StatementsId.BeforeLoadDFMValues;
  StatementsThreeYear.BeforeLoadDFMValues;
  StatementsOneYear.BeforeLoadDFMValues;
  StatementsAddedBy.BeforeLoadDFMValues;
  StatementsJobId.BeforeLoadDFMValues;
  StatementsTaxYearKey.BeforeLoadDFMValues;
  StatementsTwoYear.BeforeLoadDFMValues;
  try
    Statements.SetParentComponent(Self);
    Statements.Name := 'Statements';
    Statements.AfterOpen := StatementsAfterOpen;
    Statements.EntitySetName := 'GiftAidStatement';
    Statements.Connection := MainData.DataConnection;
    Statements.QueryString := '$OrderBy=Id desc';
    Statements.Left := 632;
    Statements.Top := 56;
    StatementsDateAdded.SetParentComponent(Statements);
    StatementsDateAdded.Name := 'StatementsDateAdded';
    StatementsDateAdded.FieldName := 'DateAdded';
    StatementsDateAdded.Required := True;
    StatementsLastUpdatedBy.SetParentComponent(Statements);
    StatementsLastUpdatedBy.Name := 'StatementsLastUpdatedBy';
    StatementsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    StatementsDateSent.SetParentComponent(Statements);
    StatementsDateSent.Name := 'StatementsDateSent';
    StatementsDateSent.FieldName := 'DateSent';
    StatementsDateSent.Required := True;
    StatementsLastUpdated.SetParentComponent(Statements);
    StatementsLastUpdated.Name := 'StatementsLastUpdated';
    StatementsLastUpdated.FieldName := 'LastUpdated';
    StatementsNotNeeded.SetParentComponent(Statements);
    StatementsNotNeeded.Name := 'StatementsNotNeeded';
    StatementsNotNeeded.FieldName := 'NotNeeded';
    StatementsNotNeeded.Required := True;
    StatementsId.SetParentComponent(Statements);
    StatementsId.Name := 'StatementsId';
    StatementsId.FieldName := 'Id';
    StatementsId.Required := True;
    StatementsThreeYear.SetParentComponent(Statements);
    StatementsThreeYear.Name := 'StatementsThreeYear';
    StatementsThreeYear.FieldName := 'ThreeYear';
    StatementsThreeYear.Required := True;
    StatementsOneYear.SetParentComponent(Statements);
    StatementsOneYear.Name := 'StatementsOneYear';
    StatementsOneYear.FieldName := 'OneYear';
    StatementsOneYear.Required := True;
    StatementsAddedBy.SetParentComponent(Statements);
    StatementsAddedBy.Name := 'StatementsAddedBy';
    StatementsAddedBy.FieldName := 'AddedBy';
    StatementsAddedBy.Required := True;
    StatementsJobId.SetParentComponent(Statements);
    StatementsJobId.Name := 'StatementsJobId';
    StatementsJobId.FieldName := 'JobId';
    StatementsJobId.Required := True;
    StatementsTaxYearKey.SetParentComponent(Statements);
    StatementsTaxYearKey.Name := 'StatementsTaxYearKey';
    StatementsTaxYearKey.FieldName := 'TaxYearKey';
    StatementsTaxYearKey.Required := True;
    StatementsTwoYear.SetParentComponent(Statements);
    StatementsTwoYear.Name := 'StatementsTwoYear';
    StatementsTwoYear.FieldName := 'TwoYear';
    StatementsTwoYear.Required := True;
  finally
    Statements.AfterLoadDFMValues;
    StatementsDateAdded.AfterLoadDFMValues;
    StatementsLastUpdatedBy.AfterLoadDFMValues;
    StatementsDateSent.AfterLoadDFMValues;
    StatementsLastUpdated.AfterLoadDFMValues;
    StatementsNotNeeded.AfterLoadDFMValues;
    StatementsId.AfterLoadDFMValues;
    StatementsThreeYear.AfterLoadDFMValues;
    StatementsOneYear.AfterLoadDFMValues;
    StatementsAddedBy.AfterLoadDFMValues;
    StatementsJobId.AfterLoadDFMValues;
    StatementsTaxYearKey.AfterLoadDFMValues;
    StatementsTwoYear.AfterLoadDFMValues;
  end;
end;

end.
