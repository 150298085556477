unit SelectShopForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls;

type
  TSelectShop = class(TCoreWebForm)
    ShopSelectCancelButton: TButton;
    ShopSelectOKButton: TButton;
    TitleSpan: THTMLSpan;
    ShopSelect: TLookupComboBox;
    procedure ShopSelectCancelButtonClick(Sender: TObject);
    procedure ShopSelectOKButtonClick(Sender: TObject);
    procedure ShopSelectChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FSelectedShop: string;
    FShopsloaded: Boolean;
    [async]
    procedure LoadShopsList; async;
    function GetShopRef: String;
    procedure SetShopRef(const Value: String);
  public
    { Public declarations }
    property ShopRef: String read GetShopRef write SetShopRef;
  protected procedure LoadDFMValues; override; end;

var
  SelectShop: TSelectShop;

implementation

{$R *.dfm}

uses
  MainDataModule,
  WEBLib.REST;

procedure TSelectShop.SetShopRef(const Value: String);
begin
  FSelectedShop := Value;
  if FShopsLoaded then
     ShopSelect.Value := Value;
end;

procedure TSelectShop.WebFormCreate(Sender: TObject);
begin
  inherited;
  FShopsloaded := False;
  FSelectedShop := '';
  LoadShopsList;
end;

{ TSelectShop }

function TSelectShop.GetShopRef: String;
begin
  Result := ShopSelect.Value;
end;

procedure TSelectShop.ShopSelectCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TSelectShop.ShopSelectOKButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TSelectShop.LoadShopsList;
var
  lShops: TStrings;
  I: Integer;
begin
  lShops := await(TStrings, MainData.ShopLookUps);
  for I := 0 to lShops.Count - 1 do
    ShopSelect.LookUpValues.AddPair(lShops.ValueFromIndex[I], lShops.Names[I]);
  FShopsloaded := True;
  if FSelectedShop <> '' then
    ShopSelect.Value := FSelectedShop;
end;

procedure TSelectShop.ShopSelectChange(Sender: TObject);
begin
  ShopSelectOKButton.Enabled := ShopSelect.Value <> FSelectedShop;
end;

procedure TSelectShop.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopSelectCancelButton := TButton.Create('ShopSelectCancelButton');
  ShopSelectOKButton := TButton.Create('ShopSelectOKButton');
  TitleSpan := THTMLSpan.Create('Title-Span');
  ShopSelect := TLookupComboBox.Create('ShopSelect');

  ShopSelectCancelButton.BeforeLoadDFMValues;
  ShopSelectOKButton.BeforeLoadDFMValues;
  TitleSpan.BeforeLoadDFMValues;
  ShopSelect.BeforeLoadDFMValues;
  try
    Width := 602;
    Height := 325;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    ShopSelectCancelButton.SetParentComponent(Self);
    ShopSelectCancelButton.Name := 'ShopSelectCancelButton';
    ShopSelectCancelButton.AlignWithMargins := True;
    ShopSelectCancelButton.Left := 375;
    ShopSelectCancelButton.Top := 268;
    ShopSelectCancelButton.Width := 96;
    ShopSelectCancelButton.Height := 33;
    ShopSelectCancelButton.Caption := 'Cancel';
    ShopSelectCancelButton.ChildOrder := 1;
    ShopSelectCancelButton.ElementClassName := 'btn btn-primary';
    ShopSelectCancelButton.ElementFont := efCSS;
    ShopSelectCancelButton.ElementPosition := epIgnore;
    ShopSelectCancelButton.HeightStyle := ssAuto;
    ShopSelectCancelButton.HeightPercent := 100.000000000000000000;
    ShopSelectCancelButton.TabOrder := 2;
    ShopSelectCancelButton.WidthStyle := ssAuto;
    ShopSelectCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(ShopSelectCancelButton, Self, 'OnClick', 'ShopSelectCancelButtonClick');
    ShopSelectOKButton.SetParentComponent(Self);
    ShopSelectOKButton.Name := 'ShopSelectOKButton';
    ShopSelectOKButton.AlignWithMargins := True;
    ShopSelectOKButton.Left := 485;
    ShopSelectOKButton.Top := 268;
    ShopSelectOKButton.Width := 96;
    ShopSelectOKButton.Height := 33;
    ShopSelectOKButton.Caption := 'Save';
    ShopSelectOKButton.ChildOrder := 6;
    ShopSelectOKButton.ElementClassName := 'btn btn-primary';
    ShopSelectOKButton.ElementFont := efCSS;
    ShopSelectOKButton.ElementPosition := epIgnore;
    ShopSelectOKButton.Enabled := False;
    ShopSelectOKButton.HeightStyle := ssAuto;
    ShopSelectOKButton.HeightPercent := 100.000000000000000000;
    ShopSelectOKButton.TabOrder := 1;
    ShopSelectOKButton.WidthStyle := ssAuto;
    ShopSelectOKButton.WidthPercent := 100.000000000000000000;
    SetEvent(ShopSelectOKButton, Self, 'OnClick', 'ShopSelectOKButtonClick');
    TitleSpan.SetParentComponent(Self);
    TitleSpan.Name := 'TitleSpan';
    TitleSpan.Left := 56;
    TitleSpan.Top := 32;
    TitleSpan.Width := 100;
    TitleSpan.Height := 40;
    TitleSpan.HeightStyle := ssAuto;
    TitleSpan.WidthStyle := ssAuto;
    TitleSpan.ChildOrder := 3;
    TitleSpan.ElementPosition := epIgnore;
    TitleSpan.ElementFont := efCSS;
    TitleSpan.Role := '';
    ShopSelect.SetParentComponent(Self);
    ShopSelect.Name := 'ShopSelect';
    ShopSelect.Left := 192;
    ShopSelect.Top := 96;
    ShopSelect.Width := 145;
    ShopSelect.Height := 22;
    ShopSelect.ElementClassName := 'form-select form-control';
    ShopSelect.ElementFont := efCSS;
    ShopSelect.ElementPosition := epIgnore;
    ShopSelect.HeightStyle := ssAuto;
    ShopSelect.HeightPercent := 100.000000000000000000;
    ShopSelect.WidthStyle := ssAuto;
    ShopSelect.WidthPercent := 100.000000000000000000;
    SetEvent(ShopSelect, Self, 'OnChange', 'ShopSelectChange');
    ShopSelect.ItemIndex := -1;
  finally
    ShopSelectCancelButton.AfterLoadDFMValues;
    ShopSelectOKButton.AfterLoadDFMValues;
    TitleSpan.AfterLoadDFMValues;
    ShopSelect.AfterLoadDFMValues;
  end;
end;

end.
