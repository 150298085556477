unit UserList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  AppUtils;

type
  TUserListForm = class(TBaseListForm)
    ShopsLookUp: TXDataWebDataSet;
    ShopsLookUpRef: TStringField;
    ShopsLookUpName: TStringField;
    ShopsLookUpStatus: TStringField;
    ShopsLookUpLocationType: TStringField;
    MainDatasetId: TIntegerField;
    MainDatasetShopRef: TStringField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetUserName: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetPhone: TStringField;
    MainDatasetJobTitle: TStringField;
    MainDatasetStatus: TStringField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetUserLevel: TStringField;
    MainDatasetShopName: TStringField;
    ShopFilter: TLookupComboBox;
    MainDatasetPostCode: TStringField;
    ActiveUsersOnly: TCheckBox;
    MainDatasetHMRCAuthorised: TStringField;
    procedure ActiveUsersOnlyClick(Sender: TObject);
    procedure MainDatasetNewRecord(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure ShopFilterChange(Sender: TObject);
    procedure ShopsLookUpAfterOpen(Dataset: TDataSet);
  private
    { Private declarations }
    FBuildingQuery: Boolean;
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SearchData(const Value: string); override;
    procedure SetEditFormLookUps(AForm: TForm); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule,
  UserForm,
  Auth.Service,
  Grid.Settings;

{$R *.dfm}

procedure TUserListForm.ActiveUsersOnlyClick(Sender: TObject);
begin
//  if ActiveUsersOnly.Checked then
//     FGridPlugin.SetPreparedFilter('(Status eq ''Active'')', True)
//  else
//    FGridPlugin.SetPreparedFilter('', True);
end;

procedure TUserListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FBuildingQuery := False;
end;

{ TForm3 }

function TUserListForm.GetEditFormClass: TFormClass;
begin
  Result := TUserEditForm;
end;

procedure TUserListForm.LoadData;
begin
  ShopsLookUp.Load;
end;

procedure TUserListForm.MainDatasetNewRecord(DataSet: TDataSet);
begin
  MainDatasetHMRCAuthorised.Value := 'No';
end;

procedure TUserListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TUserListForm.RecordViewOption: TViewOption;
begin
  if AuthService.IsAdministrator then
    Result := TViewOption.voCreateAndEdit
  else
    Result := TViewOption.voReadOnly;
end;

procedure TUserListForm.SearchData(const Value: string);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  ShopFilter.ItemIndex := 0;

  if TSysHelper.IsRef(Value) then
    FGridPlugin.SetDefinedSearch(UpperCase(Value), [TSearchField.Create('ShopRef', soEQ, 5)])
  else if TSysHelper.IsInteger(Value) then
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('Id', soEQ, 0, False, True)])
  else if Pos('@', Value) > 0 then
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('Email', soStarts, 0, True)])
  else
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('FirstName', soStarts, 0, True),
      TSearchField.Create('LastName', soStarts, 0, True)]);

  FBuildingQuery := False;

end;

procedure TUserListForm.SetEditFormLookUps(AForm: TForm);
begin
  inherited;
  TUserEditForm(AForm).SetLookUpValues('shops', ShopFilter.LookUpValues);
end;

procedure TUserListForm.ShopFilterChange(Sender: TObject);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  edSearch.Text := '';

  if ShopFilter.Value = 'All' then
    FGridPlugin.SetFilterText('', True)
  else
    FGridPlugin.SetDefinedSearch(ShopFilter.Value, [TSearchField.Create('ShopRef')]);

  FBuildingQuery := False;
end;

procedure TUserListForm.ShopsLookUpAfterOpen(Dataset: TDataSet);
var
  ts: TStringList;
  I: Integer;
begin
  ShopFilter.LookUpValues.AddPair('All', 'All Shops');
  ts := TStringList.Create;
  try
    while not ShopsLookUp.eof do
    begin

      if (AuthService.LocationType = 'System') and (ShopsLookUpLocationType.Value = 'System') then
        ts.AddPair(ShopsLookUpName.Value, ShopsLookUpRef.Value)
      else if ShopsLookUpLocationType.AsString = 'HeadOffice' then
        ts.AddPair(ShopsLookUpName.Value, ShopsLookUpRef.Value);
      ShopsLookUp.Next;
    end;

    ts.Sort;

    for I := 0 to ts.Count - 1 do
      ShopFilter.LookUpValues.AddPair(ts.ValueFromIndex[I], ts.Names[I]);
  finally
    ts.Free;
  end;

//  if ActiveUsersOnly.Checked then
//     FGridPlugin.SetPreparedFilter('(Status eq ''Active'')');
  FGridPlugin.Load;

  { TODO : Do This on server }
  // if AuthService.UserLevel = 'SuperUser' then
  // UsersDataset.Load
  // else
  // begin
  // UsersDataset.QueryString := '$filter=ShopRef ne ' + QuotedStr(SysLocationRef);
  // UsersDataset.Load;
  // end;

end;

procedure TUserListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopFilter := TLookupComboBox.Create('ShopFilter');
  ActiveUsersOnly := TCheckBox.Create('ActiveUsersOnly');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetShopRef := TStringField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetUserName := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetPhone := TStringField.Create(Self);
  MainDatasetJobTitle := TStringField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetUserLevel := TStringField.Create(Self);
  MainDatasetShopName := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetHMRCAuthorised := TStringField.Create(Self);
  ShopsLookUp := TXDataWebDataSet.Create(Self);
  ShopsLookUpRef := TStringField.Create(Self);
  ShopsLookUpName := TStringField.Create(Self);
  ShopsLookUpStatus := TStringField.Create(Self);
  ShopsLookUpLocationType := TStringField.Create(Self);

  ShopFilter.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  ActiveUsersOnly.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetShopRef.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetUserName.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetPhone.BeforeLoadDFMValues;
  MainDatasetJobTitle.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetUserLevel.BeforeLoadDFMValues;
  MainDatasetShopName.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetHMRCAuthorised.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  ShopsLookUp.BeforeLoadDFMValues;
  ShopsLookUpRef.BeforeLoadDFMValues;
  ShopsLookUpName.BeforeLoadDFMValues;
  ShopsLookUpStatus.BeforeLoadDFMValues;
  ShopsLookUpLocationType.BeforeLoadDFMValues;
  try
    ShopFilter.SetParentComponent(Self);
    ShopFilter.Name := 'ShopFilter';
    ShopFilter.Left := 176;
    ShopFilter.Top := 67;
    ShopFilter.Width := 145;
    ShopFilter.Height := 22;
    ShopFilter.ElementClassName := 'form-select';
    ShopFilter.ElementFont := efCSS;
    ShopFilter.HeightPercent := 100.000000000000000000;
    ShopFilter.WidthPercent := 100.000000000000000000;
    SetEvent(ShopFilter, Self, 'OnChange', 'ShopFilterChange');
    ShopFilter.ItemIndex := -1;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'First Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Last Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ShopName';
      Title := 'Shop';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'JobTitle';
      Title := 'Job Title';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'UserLevel';
      Title := 'Role';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'HMRCAuthorised';
      Title := 'HMRC Authorised';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ActiveUsersOnly.SetParentComponent(Self);
    ActiveUsersOnly.Name := 'ActiveUsersOnly';
    ActiveUsersOnly.Left := 120;
    ActiveUsersOnly.Top := 67;
    ActiveUsersOnly.Width := 113;
    ActiveUsersOnly.Height := 22;
    ActiveUsersOnly.Caption := 'Only Active Users';
    ActiveUsersOnly.Checked := True;
    ActiveUsersOnly.ChildOrder := 10;
    ActiveUsersOnly.Color := clNone;
    ActiveUsersOnly.ElementClassName := 'form-control custom-checkbox';
    ActiveUsersOnly.ElementButtonClassName := 'form-control-input';
    ActiveUsersOnly.ElementLabelClassName := 'custom-control-label';
    ActiveUsersOnly.ElementFont := efCSS;
    ActiveUsersOnly.ElementPosition := epIgnore;
    ActiveUsersOnly.HeightStyle := ssAuto;
    ActiveUsersOnly.HeightPercent := 100.000000000000000000;
    ActiveUsersOnly.State := cbChecked;
    ActiveUsersOnly.Visible := False;
    ActiveUsersOnly.WidthStyle := ssAuto;
    ActiveUsersOnly.WidthPercent := 100.000000000000000000;
    SetEvent(ActiveUsersOnly, Self, 'OnClick', 'ActiveUsersOnlyClick');
    SetEvent(MainDataset, Self, 'OnNewRecord', 'MainDatasetNewRecord');
    MainDataset.EntitySetName := 'User';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetShopRef.SetParentComponent(MainDataset);
    MainDatasetShopRef.Name := 'MainDatasetShopRef';
    MainDatasetShopRef.FieldName := 'ShopRef';
    MainDatasetShopRef.Size := 5;
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetUserName.SetParentComponent(MainDataset);
    MainDatasetUserName.Name := 'MainDatasetUserName';
    MainDatasetUserName.FieldName := 'UserName';
    MainDatasetUserName.Size := 25;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 100;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetPhone.SetParentComponent(MainDataset);
    MainDatasetPhone.Name := 'MainDatasetPhone';
    MainDatasetPhone.FieldName := 'Phone';
    MainDatasetPhone.Size := 25;
    MainDatasetJobTitle.SetParentComponent(MainDataset);
    MainDatasetJobTitle.Name := 'MainDatasetJobTitle';
    MainDatasetJobTitle.FieldName := 'JobTitle';
    MainDatasetJobTitle.Size := 100;
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Size := 9;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetUserLevel.SetParentComponent(MainDataset);
    MainDatasetUserLevel.Name := 'MainDatasetUserLevel';
    MainDatasetUserLevel.FieldName := 'UserLevel';
    MainDatasetUserLevel.Size := 12;
    MainDatasetShopName.SetParentComponent(MainDataset);
    MainDatasetShopName.Name := 'MainDatasetShopName';
    MainDatasetShopName.FieldKind := fkLookup;
    MainDatasetShopName.FieldName := 'ShopName';
    MainDatasetShopName.LookupDataSet := ShopsLookUp;
    MainDatasetShopName.LookupKeyFields := 'Ref';
    MainDatasetShopName.LookupResultField := 'Name';
    MainDatasetShopName.KeyFields := 'ShopRef';
    MainDatasetShopName.Size := 50;
    MainDatasetShopName.Lookup := True;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 12;
    MainDatasetHMRCAuthorised.SetParentComponent(MainDataset);
    MainDatasetHMRCAuthorised.Name := 'MainDatasetHMRCAuthorised';
    MainDatasetHMRCAuthorised.FieldName := 'HMRCAuthorised';
    MainDatasetHMRCAuthorised.Size := 3;
    MainDataSource.Left := 136;
    ShopsLookUp.SetParentComponent(Self);
    ShopsLookUp.Name := 'ShopsLookUp';
    ShopsLookUp.AfterOpen := ShopsLookUpAfterOpen;
    ShopsLookUp.EntitySetName := 'Shop';
    ShopsLookUp.Connection := MainData.DataConnection;
    ShopsLookUp.Left := 360;
    ShopsLookUp.Top := 16;
    ShopsLookUpRef.SetParentComponent(ShopsLookUp);
    ShopsLookUpRef.Name := 'ShopsLookUpRef';
    ShopsLookUpRef.FieldName := 'Ref';
    ShopsLookUpRef.Size := 5;
    ShopsLookUpName.SetParentComponent(ShopsLookUp);
    ShopsLookUpName.Name := 'ShopsLookUpName';
    ShopsLookUpName.FieldName := 'Name';
    ShopsLookUpName.Size := 250;
    ShopsLookUpStatus.SetParentComponent(ShopsLookUp);
    ShopsLookUpStatus.Name := 'ShopsLookUpStatus';
    ShopsLookUpStatus.FieldName := 'Status';
    ShopsLookUpStatus.Size := 6;
    ShopsLookUpLocationType.SetParentComponent(ShopsLookUp);
    ShopsLookUpLocationType.Name := 'ShopsLookUpLocationType';
    ShopsLookUpLocationType.FieldName := 'LocationType';
    ShopsLookUpLocationType.Size := 10;
  finally
    ShopFilter.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    ActiveUsersOnly.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetShopRef.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetUserName.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetPhone.AfterLoadDFMValues;
    MainDatasetJobTitle.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetUserLevel.AfterLoadDFMValues;
    MainDatasetShopName.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetHMRCAuthorised.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    ShopsLookUp.AfterLoadDFMValues;
    ShopsLookUpRef.AfterLoadDFMValues;
    ShopsLookUpName.AfterLoadDFMValues;
    ShopsLookUpStatus.AfterLoadDFMValues;
    ShopsLookUpLocationType.AfterLoadDFMValues;
  end;
end;

end.
