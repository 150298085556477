/// <summary>
/// This unit can be shared between the server and the webcore app
/// </summary>
unit smx.Reports.Types;

interface

type

  TReportIdType = (ridReport, ridMyDashboard);

  TReportProc = (rpStandard, rpCrosstab, rpScript);

  TParamValueType = (Single, Range, List);

  TParamOperator = (Equals, GreatThan, GreatThanOrEqualTo, LessThan, LessThanOrEqualTo, NotEqualTo, StartsWith);

  /// <summary>
  ///   Primarily used for teh dashboard this is the default value to use for
  ///   date paramaters
  /// </summary>
  TParamDefault = (pdNoDefault, pdStartOfThisYear, pdStartOfThisMonth, pdStartOfThisWeek, pdToday, pdNow, pdYesterday,
    pdEndOfYesterday, pdStartOfLastYear, pdEndofLastYear, pdStartOfLastMonth, pdEndOfLastMonth, pdStartOfLastWeek,
    pdEndOfLastWeek, pdStartOfThisQuarter, pdStartOfLastQuarter, pdEndOfLastQuarter, pdStartOfTaxYear,
    pdStartOfLastTaxYear, pdEndOfLastTaxYear, pdPreviousWorkingDay, pdPreviousWorkingDayEnd, pdIncDay, pdIncWeek, pdIncMonth,
    pdStartOfCompanyYear, pdEndOfCompanyYear);

  /// <summary>
  ///   this is used to modify the default date parameters where the value is
  ///   just the year or month of the date. This calls
  ///   System.DateUtils.YearOf/MonthOf
  /// </summary>
  TParamModifier = (pmNoModifier, pmYearOf, pmMonthOf);

  /// <summary>
  /// This maps directly to TDashboardDisplayType in smx.Reports.Entities
  /// </summary>
  TDashboardDisplay = (ddNone, ddText, ddGraph, ddTable, ddHTMLFile);
  /// <summary>
  /// This maps directly to TRefreshFrequency in smx.Reports.Entities
  /// </summary>
  TRefreshFreq = (rfManual, rfAlways, rfHourly, rfDaily, rfWeekly, rfMonthly, rfAnnually);

  TDashboardItemStatus = (disOK, disExpired, disNoRights, disError);

  THorizontalAlign = (halLeft, halCenter, halRight);

  TColProcess = (cpNone, cpIgnore, cpWeekNumber);

  TCompanyYearEnd = class
    Month: Word;
    Day: Word;
    function CurrentYearStart: TDate;
    function CurrentYearEnd: TDate;
    function YearStart(const ADate: TDate): TDate;
    function YearEnd(const ADate: TDate): TDate;
  end;

const
  DASHBOARD_CLASS: array [TDashboardDisplay] of string = ('None', 'Text', 'Graph', 'Table', 'HTML');
  HALIGN_CLASS: array [THorizontalAlign] of string = ('left-align', 'center-align', 'right-align');
  TABLE_CLASS: array[TReportProc] of string =('tableStandard', 'tableCrossTab', 'tableScript');

implementation

uses system.SysUtils, System.DateUtils;

{ TCompanyEndDate }

function TCompanyYearEnd.CurrentYearEnd: TDate;
var
  currentYear, currentMonth, currentDay: Word;
begin
  DecodeDate(Today, currentYear, currentMonth, currentDay);
  if (currentMonth < Month) then
     Result := EncodeDate(currentYear, Month, Day)
 else if currentMonth = Month then
 begin
   if currentDay <= Day then
      Result := EncodeDate(currentYear, Month, Day)
   else
      Result := EncodeDate(currentYear + 1, Month, Day)
 end
 else
    Result := EncodeDate(currentYear + 1, Month, Day);

end;

function TCompanyYearEnd.CurrentYearStart: TDate;
begin
  Result := IncYear(IncDay(CurrentYearEnd), -1);
end;

function TCompanyYearEnd.YearEnd(const ADate: TDate): TDate;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(ADate, lYear, lMonth, lDay);

  if (lMonth < Month) then
     Result := EncodeDate(lYear, Month, Day)
 else if lMonth = Month then
 begin
   if lDay <= Day then
      Result := EncodeDate(lYear, Month, Day)
   else
      Result := EncodeDate(lYear + 1, Month, Day)
 end
 else
    Result := EncodeDate(lYear + 1, Month, Day);
end;

function TCompanyYearEnd.YearStart(const ADate: TDate): TDate;
begin
  Result := IncYear(IncDay(YearEnd(ADate)), -1);
end;

end.
