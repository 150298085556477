unit ShopList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.Toast,
  AppUtils;

type
  TShopListForm = class(TBaseListForm)
    RegionLookUp: TXDataWebDataSet;
    RegionLookUpRef: TStringField;
    RegionLookUpName: TStringField;
    RegionLookUpStatus: TStringField;
    RegionFilter: TLookupComboBox;
    MainDatasetRef: TStringField;
    MainDatasetRegionRef: TStringField;
    MainDatasetName: TStringField;
    MainDatasetPhone: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetCounty: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetCountry: TStringField;
    MainDatasetLocationType: TStringField;
    MainDatasetStatus: TStringField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetRegionName: TStringField;
    procedure WebFormCreate(Sender: TObject);
    procedure MainDatasetNewRecord(DataSet: TDataSet);
    procedure RegionFilterChange(Sender: TObject);
    procedure RegionLookUpAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
    FBuildingQuery: Boolean;
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SetEditFormLookUps(AForm: TForm); override;
    procedure SearchData(const Value: string); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule,
  ShopForm,
  Grid.Settings,
  Auth.Service;

{$R *.dfm}

procedure TShopListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FBuildingQuery := False;
end;

{ TForm2 }

function TShopListForm.GetEditFormClass: TFormClass;
begin
  Result := TShopEditForm;
end;

procedure TShopListForm.LoadData;
begin
  RegionLookUp.Load;
end;

procedure TShopListForm.MainDatasetNewRecord(DataSet: TDataSet);
begin
  MainDatasetLocationType.Value := 'Shop';
  MainDatasetStatus.Value := 'Open';
  if RegionFilter.Value <> 'All' then
    MainDatasetRegionRef.Value := RegionFilter.Value;
end;

procedure TShopListForm.PrepareForm;
begin
  FIdField := 'Ref';

end;

function TShopListForm.RecordViewOption: TViewOption;
begin
  if AuthService.IsAdministrator then
    Result := TViewOption.voCreateAndEdit
  else
    Result := TViewOption.voReadOnly;
end;

procedure TShopListForm.RegionFilterChange(Sender: TObject);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  edSearch.Text := '';

  if RegionFilter.Value = 'All' then
    FGridPlugin.SetFilterText('', True)
  else
    FGridPlugin.SetDefinedSearch(RegionFilter.Value, [TSearchField.Create('RegionRef')]);

  FBuildingQuery := False;
end;

procedure TShopListForm.RegionLookUpAfterOpen(DataSet: TDataSet);
begin
  RegionFilter.LookupValues.AddPair('All', 'All Regions');
  while not RegionLookUp.Eof do
  begin
    RegionFilter.LookupValues.AddPair(RegionLookUp.FieldByName('Ref').AsString, RegionLookUp.FieldByName('Name').AsString);
    RegionLookUp.Next;
  end;
  RegionLookUp.First;
  RegionFilter.ItemIndex := 0;

  FGridPlugin.Load;
end;

procedure TShopListForm.SearchData(const Value: string);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  RegionFilter.ItemIndex := 0;

  if TSysHelper.IsRef(Value) then
  begin
    FGridPlugin.SetDefinedSearch(UpperCase(Value), [TSearchField.Create('Ref', soEQ, 5), TSearchField.Create('RegionRef',
      soEQ, 5)]);
  end
  else
  begin
    FGridPlugin.SetDefinedSearch(Value, [TSearchField.Create('Name', soStarts, 0, True), TSearchField.Create('City',
      soStarts, 0, True)]);
  end;
  FBuildingQuery := False;
end;

procedure TShopListForm.SetEditFormLookUps(AForm: TForm);
begin
  inherited;
  TShopEditForm(AForm).SetLookUpValues('Regions', RegionFilter.LookupValues);
end;

procedure TShopListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RegionFilter := TLookupComboBox.Create('RegionFilter');
  MainDatasetRef := TStringField.Create(Self);
  MainDatasetRegionRef := TStringField.Create(Self);
  MainDatasetName := TStringField.Create(Self);
  MainDatasetPhone := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetCounty := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetCountry := TStringField.Create(Self);
  MainDatasetLocationType := TStringField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetRegionName := TStringField.Create(Self);
  RegionLookUp := TXDataWebDataSet.Create(Self);
  RegionLookUpRef := TStringField.Create(Self);
  RegionLookUpName := TStringField.Create(Self);
  RegionLookUpStatus := TStringField.Create(Self);

  DataTable.BeforeLoadDFMValues;
  RegionFilter.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetRef.BeforeLoadDFMValues;
  MainDatasetRegionRef.BeforeLoadDFMValues;
  MainDatasetName.BeforeLoadDFMValues;
  MainDatasetPhone.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetCounty.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetCountry.BeforeLoadDFMValues;
  MainDatasetLocationType.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetRegionName.BeforeLoadDFMValues;
  RegionLookUp.BeforeLoadDFMValues;
  RegionLookUpRef.BeforeLoadDFMValues;
  RegionLookUpName.BeforeLoadDFMValues;
  RegionLookUpStatus.BeforeLoadDFMValues;
  try
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Ref';
      Title := 'Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'RegionName';
      Title := 'Region';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Name';
      Title := 'Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Phone';
      Title := 'Phone';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'City';
      Title := 'City/Town';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LocationType';
      Title := 'Classification';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    RegionFilter.SetParentComponent(Self);
    RegionFilter.Name := 'RegionFilter';
    RegionFilter.Left := 240;
    RegionFilter.Top := 67;
    RegionFilter.Width := 145;
    RegionFilter.Height := 22;
    RegionFilter.ElementClassName := 'form-select';
    RegionFilter.ElementFont := efCSS;
    RegionFilter.ElementPosition := epIgnore;
    RegionFilter.HeightStyle := ssAuto;
    RegionFilter.HeightPercent := 100.000000000000000000;
    RegionFilter.WidthStyle := ssAuto;
    RegionFilter.WidthPercent := 100.000000000000000000;
    SetEvent(RegionFilter, Self, 'OnChange', 'RegionFilterChange');
    RegionFilter.ItemIndex := -1;
    SetEvent(MainDataset, Self, 'OnNewRecord', 'MainDatasetNewRecord');
    MainDataset.EntitySetName := 'Shop';
    MainDatasetRef.SetParentComponent(MainDataset);
    MainDatasetRef.Name := 'MainDatasetRef';
    MainDatasetRef.FieldName := 'Ref';
    MainDatasetRef.Size := 5;
    MainDatasetRegionRef.SetParentComponent(MainDataset);
    MainDatasetRegionRef.Name := 'MainDatasetRegionRef';
    MainDatasetRegionRef.FieldName := 'RegionRef';
    MainDatasetRegionRef.Size := 5;
    MainDatasetName.SetParentComponent(MainDataset);
    MainDatasetName.Name := 'MainDatasetName';
    MainDatasetName.FieldName := 'Name';
    MainDatasetName.Size := 250;
    MainDatasetPhone.SetParentComponent(MainDataset);
    MainDatasetPhone.Name := 'MainDatasetPhone';
    MainDatasetPhone.FieldName := 'Phone';
    MainDatasetPhone.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 100;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 100;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 100;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 50;
    MainDatasetCounty.SetParentComponent(MainDataset);
    MainDatasetCounty.Name := 'MainDatasetCounty';
    MainDatasetCounty.FieldName := 'County';
    MainDatasetCounty.Size := 50;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 25;
    MainDatasetCountry.SetParentComponent(MainDataset);
    MainDatasetCountry.Name := 'MainDatasetCountry';
    MainDatasetCountry.FieldName := 'Country';
    MainDatasetCountry.Size := 3;
    MainDatasetLocationType.SetParentComponent(MainDataset);
    MainDatasetLocationType.Name := 'MainDatasetLocationType';
    MainDatasetLocationType.FieldName := 'LocationType';
    MainDatasetLocationType.Size := 10;
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Size := 6;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetRegionName.SetParentComponent(MainDataset);
    MainDatasetRegionName.Name := 'MainDatasetRegionName';
    MainDatasetRegionName.FieldKind := fkLookup;
    MainDatasetRegionName.FieldName := 'RegionName';
    MainDatasetRegionName.LookupDataSet := RegionLookUp;
    MainDatasetRegionName.LookupKeyFields := 'Ref';
    MainDatasetRegionName.LookupResultField := 'Name';
    MainDatasetRegionName.KeyFields := 'RegionRef';
    MainDatasetRegionName.Size := 50;
    MainDatasetRegionName.Lookup := True;
    RegionLookUp.SetParentComponent(Self);
    RegionLookUp.Name := 'RegionLookUp';
    RegionLookUp.AfterOpen := RegionLookUpAfterOpen;
    RegionLookUp.EntitySetName := 'Region';
    RegionLookUp.Connection := MainData.DataConnection;
    RegionLookUp.Left := 192;
    RegionLookUp.Top := 16;
    RegionLookUpRef.SetParentComponent(RegionLookUp);
    RegionLookUpRef.Name := 'RegionLookUpRef';
    RegionLookUpRef.FieldName := 'Ref';
    RegionLookUpRef.Size := 5;
    RegionLookUpName.SetParentComponent(RegionLookUp);
    RegionLookUpName.Name := 'RegionLookUpName';
    RegionLookUpName.FieldName := 'Name';
    RegionLookUpName.Size := 250;
    RegionLookUpStatus.SetParentComponent(RegionLookUp);
    RegionLookUpStatus.Name := 'RegionLookUpStatus';
    RegionLookUpStatus.FieldName := 'Status';
    RegionLookUpStatus.Size := 7;
  finally
    DataTable.AfterLoadDFMValues;
    RegionFilter.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetRef.AfterLoadDFMValues;
    MainDatasetRegionRef.AfterLoadDFMValues;
    MainDatasetName.AfterLoadDFMValues;
    MainDatasetPhone.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetCounty.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetCountry.AfterLoadDFMValues;
    MainDatasetLocationType.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetRegionName.AfterLoadDFMValues;
    RegionLookUp.AfterLoadDFMValues;
    RegionLookUpRef.AfterLoadDFMValues;
    RegionLookUpName.AfterLoadDFMValues;
    RegionLookUpStatus.AfterLoadDFMValues;
  end;
end;

end.

