unit ShopForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  AppUtils,
  xdata.Web.client,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast;

type
  TShopEditForm = class(TBaseEditForm)
    ShopRef: TDBEdit;
    edName: TDBEdit;
    RegionRef: TDBLookupComboBox;
    edPhone: TDBEdit;
    edAdd1: TDBEdit;
    edAdd2: TDBEdit;
    edAdd3: TDBEdit;
    edCity: TDBEdit;
    edCounty: TDBEdit;
    edPostCode: TDBEdit;
    LocationType: TDBComboBox;
    LocationStatus: TDBComboBox;
    ShopRefWarningLabel: THTMLSpan;
    procedure WebFormCreate(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure edPostCodeExit(Sender: TObject);
    procedure ShopRefChange(Sender: TObject);
    procedure ShopRefExit(Sender: TObject);
  private
    { Private declarations }
    FRefStatus: TRefStatus;
    FValidatedRef: string;
    [async]
    procedure CheckShopRef; async;
    procedure ValidatePostCode;
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); override;
  protected
    function CanSaveThis: Boolean; override;
    function GetRequiredEditLevel: string; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ShopEditForm: TShopEditForm;

implementation

uses
  GA.Shop.Shared;

{$R *.dfm}

procedure TShopEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  TrapEnterPressSetUp;
end;

{ TForm1 }

function TShopEditForm.CanSaveThis: Boolean;
begin
  inherited;

  case FRefStatus of
    RefEmpty:
      ErrorList.Add('you need to provide a Shop Ref');
    // RefNotChecked: ErrorList.Add();
    RefChecking:
      ErrorList.Add('The Shop Ref is being checked, please wait a second and try again');
    // RefUnique: ;
    RefInUse:
      ErrorList.Add('The Shop Ref is not unique, please choose another one');
  end;

  if Trim(DataSource.Dataset.FieldByName('Name').AsString) = '' then
    ErrorList.Add('Please give this shop a name');

  if RegionRef.Value = '' then
    ErrorList.Add('Please select a region');

  Result := (not IsErrorList) or (ErrorList.Count = 0);
end;

procedure TShopEditForm.CheckShopRef;
var
  lRetval: TXDataClientResponse;
  lOutcome: Boolean;
  AShopRef: string;
begin
  AShopRef := ShopRef.Text;
  if AShopRef = FValidatedRef then
    Exit;
  FValidatedRef := AShopRef;
  lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(IUTILSVC_CHECK_SHOPREF, [AShopRef]));
  lOutcome := Boolean(TJSObject(lRetval.Result)['value']);
  if lOutcome then
  begin
    FRefStatus := RefUnique;
    ShopRefWarningLabel.HTML.Text := '<i class="fad fa-check"></i> OK';
    ShopRefWarningLabel.ElementClassName := 'text-success';
    ShopRefWarningLabel.Hint := '';
  end
  else
  begin
    FRefStatus := RefInUse;
    ShopRefWarningLabel.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> In Use';
    ShopRefWarningLabel.ElementClassName := 'text-warning';
    ShopRefWarningLabel.Hint := 'This shop reference is already in use';
  end;

end;

procedure TShopEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;
  if Field = nil then
  begin
    ShopRef.Enabled := DataSource.State = TDatasetState.dsInsert;
    if not ShopRef.Enabled then
    begin
      FormCaption.Caption := DataSource.Dataset.FieldByName('Name').AsString;
      FRefStatus := RefUnique;
      ShopRefWarningLabel.HTML.Text := '<i class="fad fa-check"></i> OK';
      ShopRefWarningLabel.ElementClassName := 'text-success';
      ShopRefWarningLabel.Hint := '';
      edName.SetFocus;
    end
    else
    begin
      FormCaption.Caption := 'Adding a New Shop';
      if DataSource.Dataset.FieldByName('Ref').AsString = '' then
      begin
        FRefStatus := RefEmpty;
        ShopRefWarningLabel.HTML.Text := '<i class="fad fa-plus"></i> Ref Needed';
        ShopRefWarningLabel.ElementClassName := 'text-info';
      end
      else
        FRefStatus := RefNotChecked;
      ShopRef.SetFocus;
    end;
  end;
end;

procedure TShopEditForm.edPostCodeExit(Sender: TObject);
begin
  ValidatePostCode;
end;

function TShopEditForm.GetRequiredEditLevel: string;
begin
  Result := SCOPE_ADMIN;
end;

procedure TShopEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
var
  I: Integer;
begin
  for I := 1 to Value.Count - 1 do
  begin
    RegionRef.LookUpValues.AddPair(TLookupValueItem(Value.Items[I]).Value, TLookupValueItem(Value.Items[I])
      .DisplayText);
  end;
end;

procedure TShopEditForm.ShopRefChange(Sender: TObject);
begin
  ShopRef.Text := Uppercase(ShopRef.Text);
end;

procedure TShopEditForm.ShopRefExit(Sender: TObject);
begin
  if (DataSource.State = dsInsert) and (ShopRef.Text <> FValidatedRef) then
  begin
    CheckShopRef;
  end;
end;

procedure TShopEditForm.ValidatePostCode;
begin
  if (Trim(edPostCode.Text) = '') then
  begin
    ControlNoValidity('PostCode');
    Exit;
  end;

  edPostCode.Text := TSysHelper.FormatPostCode(edPostCode.Text);
  if TSysHelper.IsPostCode(edPostCode.Text) <> pcFull then
  begin
    ControlInvalid('PostCode');
  end
  else
  begin
    ControlValid('PostCode', True);
  end;
end;

procedure TShopEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edName := TDBEdit.Create('ShopName');
  RegionRef := TDBLookupComboBox.Create('RegionRef');
  edPhone := TDBEdit.Create('ShopPhone');
  edAdd1 := TDBEdit.Create('Add1');
  edAdd2 := TDBEdit.Create('Add2');
  edAdd3 := TDBEdit.Create('Add3');
  edCity := TDBEdit.Create('City');
  edCounty := TDBEdit.Create('County');
  edPostCode := TDBEdit.Create('PostCode');
  LocationType := TDBComboBox.Create('LocationType');
  LocationStatus := TDBComboBox.Create('ShopStatus');
  ShopRef := TDBEdit.Create('ShopRef');
  ShopRefWarningLabel := THTMLSpan.Create('ShopRefWarning');

  FormCaption.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  edName.BeforeLoadDFMValues;
  RegionRef.BeforeLoadDFMValues;
  edPhone.BeforeLoadDFMValues;
  edAdd1.BeforeLoadDFMValues;
  edAdd2.BeforeLoadDFMValues;
  edAdd3.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edCounty.BeforeLoadDFMValues;
  edPostCode.BeforeLoadDFMValues;
  LocationType.BeforeLoadDFMValues;
  LocationStatus.BeforeLoadDFMValues;
  ShopRef.BeforeLoadDFMValues;
  ShopRefWarningLabel.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  AuditInfo.BeforeLoadDFMValues;
  UpdatedByLabel.BeforeLoadDFMValues;
  AddedByLabel.BeforeLoadDFMValues;
  AddedByDate.BeforeLoadDFMValues;
  UpdatedByDate.BeforeLoadDFMValues;
  try
    FormCaption.Width := 73;
    FormCaption.Height := 15;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    CancelButton.Left := 264;
    CancelButton.Top := 320;
    CancelButton.TabOrder := 13;
    edName.SetParentComponent(Self);
    edName.Name := 'edName';
    edName.Left := 57;
    edName.Top := 67;
    edName.Width := 200;
    edName.Height := 21;
    edName.ChildOrder := 12;
    edName.ElementClassName := 'form-control';
    edName.ElementPosition := epIgnore;
    edName.HeightStyle := ssAuto;
    edName.HeightPercent := 100.000000000000000000;
    edName.Required := True;
    edName.TabOrder := 1;
    edName.Text := 'edName';
    edName.WidthStyle := ssAuto;
    edName.WidthPercent := 100.000000000000000000;
    edName.DataField := 'Name';
    edName.DataSource := DataSource;
    RegionRef.SetParentComponent(Self);
    RegionRef.Name := 'RegionRef';
    RegionRef.Left := 57;
    RegionRef.Top := 94;
    RegionRef.Width := 145;
    RegionRef.Height := 22;
    RegionRef.ElementClassName := 'form-select';
    RegionRef.ElementPosition := epIgnore;
    RegionRef.HeightStyle := ssAuto;
    RegionRef.HeightPercent := 100.000000000000000000;
    RegionRef.TabOrder := 3;
    RegionRef.WidthStyle := ssAuto;
    RegionRef.WidthPercent := 100.000000000000000000;
    RegionRef.DataField := 'RegionRef';
    RegionRef.DataSource := DataSource;
    edPhone.SetParentComponent(Self);
    edPhone.Name := 'edPhone';
    edPhone.Left := 57;
    edPhone.Top := 118;
    edPhone.Width := 200;
    edPhone.Height := 21;
    edPhone.ChildOrder := 11;
    edPhone.ElementClassName := 'form-control';
    edPhone.ElementPosition := epIgnore;
    edPhone.HeightStyle := ssAuto;
    edPhone.HeightPercent := 100.000000000000000000;
    edPhone.TabOrder := 2;
    edPhone.Text := 'edPhone';
    edPhone.WidthStyle := ssAuto;
    edPhone.WidthPercent := 100.000000000000000000;
    edPhone.DataField := 'Phone';
    edPhone.DataSource := DataSource;
    edAdd1.SetParentComponent(Self);
    edAdd1.Name := 'edAdd1';
    edAdd1.Left := 57;
    edAdd1.Top := 145;
    edAdd1.Width := 200;
    edAdd1.Height := 21;
    edAdd1.ChildOrder := 10;
    edAdd1.ElementClassName := 'form-control';
    edAdd1.ElementPosition := epIgnore;
    edAdd1.HeightStyle := ssAuto;
    edAdd1.HeightPercent := 100.000000000000000000;
    edAdd1.TabOrder := 6;
    edAdd1.Text := 'edAdd1';
    edAdd1.WidthStyle := ssAuto;
    edAdd1.WidthPercent := 100.000000000000000000;
    edAdd1.DataField := 'Add1';
    edAdd1.DataSource := DataSource;
    edAdd2.SetParentComponent(Self);
    edAdd2.Name := 'edAdd2';
    edAdd2.Left := 57;
    edAdd2.Top := 172;
    edAdd2.Width := 200;
    edAdd2.Height := 21;
    edAdd2.ChildOrder := 9;
    edAdd2.ElementClassName := 'form-control';
    edAdd2.ElementPosition := epIgnore;
    edAdd2.HeightStyle := ssAuto;
    edAdd2.HeightPercent := 100.000000000000000000;
    edAdd2.TabOrder := 7;
    edAdd2.Text := 'edAdd2';
    edAdd2.WidthStyle := ssAuto;
    edAdd2.WidthPercent := 100.000000000000000000;
    edAdd2.DataField := 'Add2';
    edAdd2.DataSource := DataSource;
    edAdd3.SetParentComponent(Self);
    edAdd3.Name := 'edAdd3';
    edAdd3.Left := 57;
    edAdd3.Top := 199;
    edAdd3.Width := 200;
    edAdd3.Height := 21;
    edAdd3.ChildOrder := 8;
    edAdd3.ElementClassName := 'form-control';
    edAdd3.ElementPosition := epIgnore;
    edAdd3.HeightStyle := ssAuto;
    edAdd3.HeightPercent := 100.000000000000000000;
    edAdd3.TabOrder := 8;
    edAdd3.Text := 'edAdd3';
    edAdd3.WidthStyle := ssAuto;
    edAdd3.WidthPercent := 100.000000000000000000;
    edAdd3.DataField := 'Add3';
    edAdd3.DataSource := DataSource;
    edCity.SetParentComponent(Self);
    edCity.Name := 'edCity';
    edCity.Left := 57;
    edCity.Top := 226;
    edCity.Width := 200;
    edCity.Height := 21;
    edCity.ChildOrder := 2;
    edCity.ElementClassName := 'form-control';
    edCity.ElementPosition := epIgnore;
    edCity.HeightStyle := ssAuto;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 9;
    edCity.Text := 'edCity';
    edCity.WidthStyle := ssAuto;
    edCity.WidthPercent := 100.000000000000000000;
    edCity.DataField := 'City';
    edCity.DataSource := DataSource;
    edCounty.SetParentComponent(Self);
    edCounty.Name := 'edCounty';
    edCounty.Left := 57;
    edCounty.Top := 253;
    edCounty.Width := 200;
    edCounty.Height := 21;
    edCounty.ChildOrder := 7;
    edCounty.ElementClassName := 'form-control';
    edCounty.ElementPosition := epIgnore;
    edCounty.HeightStyle := ssAuto;
    edCounty.HeightPercent := 100.000000000000000000;
    edCounty.TabOrder := 10;
    edCounty.Text := 'edCounty';
    edCounty.WidthStyle := ssAuto;
    edCounty.WidthPercent := 100.000000000000000000;
    edCounty.DataField := 'County';
    edCounty.DataSource := DataSource;
    edPostCode.SetParentComponent(Self);
    edPostCode.Name := 'edPostCode';
    edPostCode.Left := 57;
    edPostCode.Top := 280;
    edPostCode.Width := 200;
    edPostCode.Height := 21;
    edPostCode.CharCase := wecUpperCase;
    edPostCode.ChildOrder := 6;
    edPostCode.ElementClassName := 'form-control';
    edPostCode.ElementPosition := epIgnore;
    edPostCode.HeightStyle := ssAuto;
    edPostCode.HeightPercent := 100.000000000000000000;
    edPostCode.TabOrder := 11;
    edPostCode.Text := 'edPostCode';
    edPostCode.WidthStyle := ssAuto;
    edPostCode.WidthPercent := 100.000000000000000000;
    SetEvent(edPostCode, Self, 'OnExit', 'edPostCodeExit');
    edPostCode.DataField := 'PostCode';
    edPostCode.DataSource := DataSource;
    LocationType.SetParentComponent(Self);
    LocationType.Name := 'LocationType';
    LocationType.Left := 252;
    LocationType.Top := 79;
    LocationType.Width := 145;
    LocationType.Height := 23;
    LocationType.ElementClassName := 'form-select';
    LocationType.ElementPosition := epIgnore;
    LocationType.HeightStyle := ssAuto;
    LocationType.HeightPercent := 100.000000000000000000;
    LocationType.TabOrder := 4;
    LocationType.Text := 'LocationType';
    LocationType.WidthStyle := ssAuto;
    LocationType.WidthPercent := 100.000000000000000000;
    LocationType.ItemIndex := -1;
    LocationType.Items.BeginUpdate;
    try
      LocationType.Items.Clear;
      LocationType.Items.Add('Shop');
      LocationType.Items.Add('HeadOffice');
      LocationType.Items.Add('Warehouse');
      LocationType.Items.Add('System');
    finally
      LocationType.Items.EndUpdate;
    end;
    LocationType.DataField := 'LocationType';
    LocationType.DataSource := DataSource;
    LocationStatus.SetParentComponent(Self);
    LocationStatus.Name := 'LocationStatus';
    LocationStatus.Left := 252;
    LocationStatus.Top := 106;
    LocationStatus.Width := 145;
    LocationStatus.Height := 23;
    LocationStatus.ElementClassName := 'form-select';
    LocationStatus.ElementPosition := epIgnore;
    LocationStatus.HeightStyle := ssAuto;
    LocationStatus.HeightPercent := 100.000000000000000000;
    LocationStatus.TabOrder := 5;
    LocationStatus.Text := 'Status';
    LocationStatus.WidthStyle := ssAuto;
    LocationStatus.WidthPercent := 100.000000000000000000;
    LocationStatus.ItemIndex := -1;
    LocationStatus.Items.BeginUpdate;
    try
      LocationStatus.Items.Clear;
      LocationStatus.Items.Add('Open');
      LocationStatus.Items.Add('Closed');
    finally
      LocationStatus.Items.EndUpdate;
    end;
    LocationStatus.DataField := 'Status';
    LocationStatus.DataSource := DataSource;
    ShopRef.SetParentComponent(Self);
    ShopRef.Name := 'ShopRef';
    ShopRef.Left := 57;
    ShopRef.Top := 39;
    ShopRef.Width := 121;
    ShopRef.Height := 22;
    ShopRef.AutoCompletion := acNone;
    ShopRef.CharCase := wecUpperCase;
    ShopRef.ChildOrder := 3;
    ShopRef.ElementClassName := 'form-control';
    ShopRef.ElementPosition := epIgnore;
    ShopRef.HeightStyle := ssAuto;
    ShopRef.HeightPercent := 100.000000000000000000;
    ShopRef.Required := True;
    ShopRef.Text := 'ShopRef';
    ShopRef.WidthStyle := ssAuto;
    ShopRef.WidthPercent := 100.000000000000000000;
    SetEvent(ShopRef, Self, 'OnChange', 'ShopRefChange');
    SetEvent(ShopRef, Self, 'OnExit', 'ShopRefExit');
    ShopRef.DataField := 'Ref';
    ShopRef.DataSource := DataSource;
    ShopRefWarningLabel.SetParentComponent(Self);
    ShopRefWarningLabel.Name := 'ShopRefWarningLabel';
    ShopRefWarningLabel.Left := 184;
    ShopRefWarningLabel.Top := 36;
    ShopRefWarningLabel.Width := 100;
    ShopRefWarningLabel.Height := 25;
    ShopRefWarningLabel.HeightStyle := ssAuto;
    ShopRefWarningLabel.WidthStyle := ssAuto;
    ShopRefWarningLabel.HeightPercent := 100.000000000000000000;
    ShopRefWarningLabel.WidthPercent := 100.000000000000000000;
    ShopRefWarningLabel.ChildOrder := 17;
    ShopRefWarningLabel.ElementPosition := epIgnore;
    ShopRefWarningLabel.ElementFont := efCSS;
    ShopRefWarningLabel.Role := '';
    SaveButton.Top := 320;
    SaveButton.TabOrder := 12;
    UpdatedByLabel.Width := 86;
    UpdatedByLabel.Height := 15;
    AddedByLabel.Width := 76;
    AddedByLabel.Height := 15;
    AddedByDate.Width := 72;
    AddedByDate.Height := 15;
    UpdatedByDate.Width := 82;
    UpdatedByDate.Height := 15;
  finally
    FormCaption.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    edName.AfterLoadDFMValues;
    RegionRef.AfterLoadDFMValues;
    edPhone.AfterLoadDFMValues;
    edAdd1.AfterLoadDFMValues;
    edAdd2.AfterLoadDFMValues;
    edAdd3.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edCounty.AfterLoadDFMValues;
    edPostCode.AfterLoadDFMValues;
    LocationType.AfterLoadDFMValues;
    LocationStatus.AfterLoadDFMValues;
    ShopRef.AfterLoadDFMValues;
    ShopRefWarningLabel.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    AuditInfo.AfterLoadDFMValues;
    UpdatedByLabel.AfterLoadDFMValues;
    AddedByLabel.AfterLoadDFMValues;
    AddedByDate.AfterLoadDFMValues;
    UpdatedByDate.AfterLoadDFMValues;
  end;
end;

end.
