program GAShopApp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  MainForm in 'MainForm.pas'{*.html},
  MainDataModule in 'MainDataModule.pas',
  AppConfig in 'AppConfig.pas',
  DashboardForm in 'DashboardForm.pas'{*.html},
  AppUtils in 'AppUtils.pas',
  Auth.Service in 'Core\Auth.Service.pas',
  App.Types in 'Core\App.Types.pas',
  LoginForm in 'LoginForm.pas'{*.html},
  ErrorForm in 'ErrorForm.pas'{*.html},
  GA.Shop.Shared in '..\Shared\GA.Shop.Shared.pas',
  BaseList in 'BaseList.pas'{*.html},
  Dataset.Plugins in 'Core\Dataset.Plugins.pas',
  Grid.Plugins in 'Core\Grid.Plugins.pas',
  Grid.Settings in 'Core\Grid.Settings.pas',
  Paginator.Plugins in 'Core\Paginator.Plugins.pas',
  RegionList in 'RegionList.pas'{*.html},
  BaseForm in 'BaseForm.pas'{*.html},
  RegionForm in 'RegionForm.pas'{*.html},
  ShopForm in 'ShopForm.pas'{*.html},
  UserForm in 'UserForm.pas'{*.html},
  UserList in 'UserList.pas'{*.html},
  ShopList in 'ShopList.pas'{*.html},
  DonorList in 'DonorList.pas'{*.html},
  DonorForm in 'DonorForm.pas'{*.html},
  App.Helper in 'Core\App.Helper.pas',
  DonorIdRequest in 'DonorIdRequest.pas'{*.html},
  NewDonorsForm in 'NewDonorsForm.pas'{*.html},
  JobDetailForm in 'JobDetailForm.pas'{*.html},
  DonorBaseViewForm in 'DonorBaseViewForm.pas'{*.html},
  DupesForm in 'DupesForm.pas'{*.html},
  SelfUpdateForm in 'SelfUpdateForm.pas'{*.html},
  DailySalesForm in 'DailySalesForm.pas'{*.html},
  BaseCoreForm in 'BaseCoreForm.pas'{*.html},
  SalesList in 'SalesList.pas'{*.html},
  SalesDetailForm in 'SalesDetailForm.pas'{*.html},
  DonorSalesForm in 'DonorSalesForm.pas'{*.html},
  AdvancedSearchForm in 'AdvancedSearchForm.pas'{*.html},
  ForgottenPasswordForm in 'ForgottenPasswordForm.pas'{*.html},
  JobBaseForm in 'JobBaseForm.pas'{*.html},
  ExportDonorsForm in 'ExportDonorsForm.pas'{*.html},
  ExportSalesForm in 'ExportSalesForm.pas'{*.html},
  ImportDonorsForm in 'ImportDonorsForm.pas'{*.html},
  JobErrorForm in 'JobErrorForm.pas'{*.html},
  GiftAidClaimsList in 'GiftAidClaimsList.pas'{*.html},
  GiftAidDetailsForm in 'GiftAidDetailsForm.pas'{*.html},
  NotificationsJobForm in 'NotificationsJobForm.pas'{*.html},
  NotificationsList in 'NotificationsList.pas'{*.html},
  NotificationsForm in 'NotificationsForm.pas'{*.html},
  smx.webcore.types in 'Core\smx.webcore.types.pas',
  DonorRefund in 'DonorRefund.pas'{*.html},
  DonorRefundForm in 'DonorRefundForm.pas'{*.html},
  SysConfigForm in 'SysConfigForm.pas'{*.html},
  GAConfigForm in 'GAConfigForm.pas'{*.html},
  SupportForm in 'SupportForm.pas'{*.html},
  InputQueryModule in 'InputQueryModule.pas'{*.html},
  DailySales.Support in 'DailySales.Support.pas',
  DonorIdEditForm in 'DonorIdEditForm.pas'{*.html},
  ConsIdEditForm in 'ConsIdEditForm.pas'{*.html},
  SalesDataEditForm in 'SalesDataEditForm.pas'{*.html},
  GiftAidClaimForm in 'GiftAidClaimForm.pas'{*.html},
  DirectoryRequest in 'DirectoryRequest.pas'{*.html},
  EndOfYearForm in 'EndOfYearForm.pas'{*.html},
  SalesLabelJob in 'SalesLabelJob.pas'{*.html},
  ReportMaster in 'ReportMaster.pas'{*.html},
  ReportParamsForm in 'ReportParamsForm.pas'{*.html},
  ReportResult in 'ReportResult.pas'{*.html},
  App.Support in 'Core\App.Support.pas',
  smx.Reports.Types in '..\Server\Reporting\smx.Reports.Types.pas',
  smx.Reports.Support in '..\Server\Reporting\smx.Reports.Support.pas',
  Dashboard.ReturnTypes in '..\Server\Reporting\Dashboard.ReturnTypes.pas',
  smx.Dashboard.store in 'smx.Dashboard.store.pas',
  DashboardManager in 'DashboardManager.pas',
  SalesBankingForm in 'SalesBankingForm.pas'{*.html},
  NotificationsRedoJobForm in 'NotificationsRedoJobForm.pas'{*.html},
  SelectShopForm in 'SelectShopForm.pas'{*.html},
  ExportDonorStatusesForm in 'ExportDonorStatusesForm.pas'{*.html},
  ImportDonorStatusesForm in 'ImportDonorStatusesForm.pas'{*.html};

{$R *.res}

procedure DisplayLoginPage(AMessage: string = ''; const LoggingOut: Boolean = False); forward;

procedure DisplayMainView;

  procedure ConnectProc;
  begin
    if Assigned(LoginPage) then
    begin
      LoginPage.Free;
      LoginPage := Nil;
    end;

    TMainPage.Display('publishhere', @DisplayLoginPage);
  end;

begin
  if not MainData.DataConnection.Connected then
    MainData.DataConnection.Open(@ConnectProc)
  else
    ConnectProc;
end;

procedure DisplayLoginPage(AMessage: string; const LoggingOut: Boolean);
begin
  if Assigned(LoginPage) then
     Exit;
  if LoggingOut then
  begin
    if Assigned(MainPage) then
    begin
      MainPage.Free;
      MainPage := Nil;
    end;
  end;
  AuthService.Logout(LoggingOut);
  MainData.DataConnection.Connected := False;
  TLoginPage.Display('publishhere', @DisplayMainView, AMessage);
end;

procedure UnauthorizedAccessProc(AMessage: string);
begin
  MainPage.ReLogin(AMessage);
//  DisplayLoginPage(AMessage);
end;

procedure StartApplication;
begin
  if (not AuthService.Authenticated) or AuthService.TokenExpired then
    DisplayLoginPage
  else
  begin
    AuthService.StartAuth;
    DisplayMainView;
  end;
end;

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainData, MainData);
  Application.Run;
  MainData.InitApp(@StartApplication, @UnauthorizedAccessProc)
end.
