unit AppConfig;

interface
Uses
  JS,
  XData.Web.Connection,
  XData.Web.Request,
  XData.Web.Response;

Type
  TAppConfig = Class
  Private
    FBaseUrl: String;
    FAuthURL: String;
  Public
    Constructor Create;
    Property BaseUrl: String Read FBaseUrl Write FBaseUrl;
    /// <summary>
    ///   AuthURL is provided as an option as authentication may move outside
    ///   the main app to an authentication server or support single sign on
    /// </summary>
    Property AuthURL: String read FAuthURL write FAuthURL;
  End;

  TConfigLoadedProc = reference To Procedure(Config: TAppConfig);

Procedure LoadConfig(LoadProc: TConfigLoadedProc);

Implementation

Procedure LoadConfig(LoadProc: TConfigLoadedProc);

  Procedure OnSuccess(Response: IHttpResponse);
  Var
    Obj: TJSObject;
    Config: TAppConfig;
    lResponse: String;
  Begin
    Config := TAppConfig.Create;
    Try
      If Response.StatusCode = 200 Then
      Begin
        lResponse := Response.ContentAsText;
        Obj := TJSObject(TJSJSON.parse(lResponse));

        If JS.toString(Obj['BaseUrl']) <> '' Then
          Config.BaseUrl := JS.toString(Obj['BaseUrl']);
        If JS.toString(Obj['AuthUrl']) <> '' Then
          Config.AuthUrl := JS.toString(Obj['AuthUrl']);

      End;
    Finally
      LoadProc(Config);
      Config.Free;
    End;
  End;

  Procedure OnError;
  Var
    Config: TAppConfig;
  Begin
    Config := TAppConfig.Create;
    Try
      LoadProc(Config);
    Finally
      Config.Free;
    End;
  End;

Var
  Conn: TXDataWebConnection;
Begin
  Conn := TXDataWebConnection.Create(Nil);
  Try
    Conn.SendRequest(THttpRequest.Create('config/config.json'), @OnSuccess, @OnError);
  Finally
    Conn.Free;
  End;
End;

{ TAppConfig }

Constructor TAppConfig.Create;
Begin
  FBaseUrl := '';
End;

end.
