unit SalesBankingForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.DB,
  WEBLib.WebCtrls,
  Data.DB,
  WEBLib.ExtCtrls;

type
  TSalesBanking = class(TForm)
    CreditCards: TDBEdit;
    CashAmount: TDBEdit;
    BankingDataSource: TDataSource;
    BankingSaveButton: TButton;
    BankingCancelButton: TButton;
    BankingEditTitle: THTMLSpan;
    ShopCombo: TDBLookupComboBox;
    SaleDate: TDBDateTimePicker;
    procedure WebFormCreate(Sender: TObject);
    procedure BankingCancelButtonClick(Sender: TObject);
    procedure BankingDataSourceDataChange(Sender: TObject; Field: TField);
    procedure BankingSaveButtonClick(Sender: TObject);
    procedure SaleDateExit(Sender: TObject);
  private
    FOriginalCash: Double;
    FOriginalCards: Double;
    FOriginalShop: string;
    FOriginalDate: TDate;
    FLoaded: Boolean;
    procedure SetDataset(const Value: TDataset);
    procedure BankingButtonsStatus;
    procedure SetShopLookups(const Value: TLookupValues);
    { Private declarations }
  public
    { Public declarations }
    property Dataset: TDataset write SetDataset;
    property ShopLookups: TLookupValues write SetShopLookups;
  protected procedure LoadDFMValues; override; end;

var
  SalesBanking: TSalesBanking;

implementation

{$R *.dfm}

uses
  System.Variants,
  System.DateUtils;

resourcestring
  Title_Template = '<span id="%s" class="col-6"><label class="form-label">%s</label> %s </span>';

procedure TSalesBanking.WebFormCreate(Sender: TObject);
begin
  FLoaded := False;
end;

procedure TSalesBanking.BankingButtonsStatus;
var
//  lState: TDatasetState;
  lSameShop, lSameCash, lSameCards, lSameDate: Boolean;
  lDate: TDate;
begin

  lSameCash := (FOriginalCash = BankingDataSource.Dataset.FieldByName('CashAndCheques').AsFloat);
  lSameCards := (FOriginalCards = BankingDataSource.Dataset.FieldByName('CreditCards').AsFloat);
  lSameShop := (FOriginalShop = BankingDataSource.Dataset.FieldByName('ShopRef').AsString);
  lDate := BankingDataSource.Dataset.FieldByName('SaleDate').AsDateTime;
  lSameDate := (FOriginalDate = DateOf(SaleDate.Date));
  BankingSaveButton.Enabled := (not lSameCash) or (not lSameCards) or (not lSameShop) or (not lSameDate);

end;

procedure TSalesBanking.BankingCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TSalesBanking.BankingDataSourceDataChange(Sender: TObject; Field: TField);
begin
  if FLoaded then
    BankingButtonsStatus;
end;

procedure TSalesBanking.BankingSaveButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TSalesBanking.SaleDateExit(Sender: TObject);
begin
  if FLoaded then
    BankingButtonsStatus;
end;

{ TSalesBanking }

procedure TSalesBanking.SetDataset(const Value: TDataset);
begin
  BankingDataSource.Dataset := Value;
  FOriginalCash := BankingDataSource.Dataset.FieldByName('CashAndCheques').AsFloat;
  FOriginalCards := BankingDataSource.Dataset.FieldByName('CreditCards').AsFloat;
  FOriginalShop := BankingDataSource.Dataset.FieldByName('ShopRef').AsString;
  FOriginalDate := DateOf(BankingDataSource.Dataset.FieldByName('SaleDate').AsDateTime);

  // Title_Template = '<span id="%s" class="col-6"><label class="form-label">%s</label> %s </span>';

  BankingEditTitle.HTML.Add(Format(Title_Template, ['SalesBatchId', 'Batch',
    BankingDataSource.Dataset.FieldByName('BatchId').AsString]));
  BankingEditTitle.HTML.Add(Format(Title_Template, ['SalesSheetDate', 'Sale Date', FormatDateTime('dd/mm/yyyy',
    BankingDataSource.Dataset.FieldByName('SaleDate').AsDateTime)]));
  BankingEditTitle.HTML.Add(Format(Title_Template, ['SalesSheetShop', 'Shop',
    BankingDataSource.Dataset.FieldByName('ShopName').AsString]));
  BankingEditTitle.HTML.Add(Format(Title_Template, ['SalesSheetAdded', 'Date Added', FormatDateTime('dd/mm/yyyy',
    BankingDataSource.Dataset.FieldByName('DateAdded').AsDateTime) + ' (' + BankingDataSource.Dataset.FieldByName
    ('AddedByName').AsString + ')']));
  BankingDataSource.Dataset.Edit;
  FLoaded := True;
end;

procedure TSalesBanking.SetShopLookups(const Value: TLookupValues);
var
  I: Integer;
begin
  for I := 1 to Value.Count - 1 do
  begin
    ShopCombo.LookUpValues.AddPair(TLookupValueItem(Value.Items[I]).Value, TLookupValueItem(Value.Items[I])
      .DisplayText);
  end;
end;

procedure TSalesBanking.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CreditCards := TDBEdit.Create('Cards-Amount');
  CashAmount := TDBEdit.Create('Cash-Amount');
  BankingSaveButton := TButton.Create('BankingSaveButton');
  BankingCancelButton := TButton.Create('BankingCancelButton');
  BankingEditTitle := THTMLSpan.Create('BankingEditTitle');
  ShopCombo := TDBLookupComboBox.Create('Shop-Ref');
  SaleDate := TDBDateTimePicker.Create('Sales-Date');
  BankingDataSource := TDataSource.Create(Self);

  CreditCards.BeforeLoadDFMValues;
  CashAmount.BeforeLoadDFMValues;
  BankingSaveButton.BeforeLoadDFMValues;
  BankingCancelButton.BeforeLoadDFMValues;
  BankingEditTitle.BeforeLoadDFMValues;
  ShopCombo.BeforeLoadDFMValues;
  SaleDate.BeforeLoadDFMValues;
  BankingDataSource.BeforeLoadDFMValues;
  try
    Name := 'SalesBanking';
    Width := 640;
    Height := 321;
    Caption := 'ShopRef';
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    CreditCards.SetParentComponent(Self);
    CreditCards.Name := 'CreditCards';
    CreditCards.Left := 181;
    CreditCards.Top := 128;
    CreditCards.Width := 121;
    CreditCards.Height := 22;
    CreditCards.ChildOrder := 3;
    CreditCards.EditType := weFloat;
    CreditCards.ElementClassName := 'form-control';
    CreditCards.ElementFont := efCSS;
    CreditCards.ElementPosition := epIgnore;
    CreditCards.HeightStyle := ssAuto;
    CreditCards.HeightPercent := 100.000000000000000000;
    CreditCards.Text := 'CreditCards';
    CreditCards.WidthStyle := ssAuto;
    CreditCards.WidthPercent := 100.000000000000000000;
    CreditCards.DataField := 'CreditCards';
    CreditCards.DataSource := BankingDataSource;
    CashAmount.SetParentComponent(Self);
    CashAmount.Name := 'CashAmount';
    CashAmount.Left := 181;
    CashAmount.Top := 100;
    CashAmount.Width := 121;
    CashAmount.Height := 22;
    CashAmount.ChildOrder := 2;
    CashAmount.EditType := weFloat;
    CashAmount.ElementClassName := 'form-control';
    CashAmount.ElementFont := efCSS;
    CashAmount.ElementPosition := epIgnore;
    CashAmount.HeightStyle := ssAuto;
    CashAmount.HeightPercent := 100.000000000000000000;
    CashAmount.Text := 'CashAmount';
    CashAmount.WidthStyle := ssAuto;
    CashAmount.WidthPercent := 100.000000000000000000;
    CashAmount.DataField := 'CashAndCheques';
    CashAmount.DataSource := BankingDataSource;
    BankingSaveButton.SetParentComponent(Self);
    BankingSaveButton.Name := 'BankingSaveButton';
    BankingSaveButton.Left := 229;
    BankingSaveButton.Top := 200;
    BankingSaveButton.Width := 73;
    BankingSaveButton.Height := 25;
    BankingSaveButton.Caption := 'Save';
    BankingSaveButton.ChildOrder := 2;
    BankingSaveButton.ElementClassName := 'btn btn-primary';
    BankingSaveButton.ElementFont := efCSS;
    BankingSaveButton.Enabled := False;
    BankingSaveButton.HeightStyle := ssAuto;
    BankingSaveButton.HeightPercent := 100.000000000000000000;
    BankingSaveButton.TabOrder := 3;
    BankingSaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(BankingSaveButton, Self, 'OnClick', 'BankingSaveButtonClick');
    BankingCancelButton.SetParentComponent(Self);
    BankingCancelButton.Name := 'BankingCancelButton';
    BankingCancelButton.Left := 335;
    BankingCancelButton.Top := 200;
    BankingCancelButton.Width := 73;
    BankingCancelButton.Height := 25;
    BankingCancelButton.Caption := 'Cancel';
    BankingCancelButton.ChildOrder := 2;
    BankingCancelButton.ElementClassName := 'btn btn-secondary';
    BankingCancelButton.ElementFont := efCSS;
    BankingCancelButton.HeightStyle := ssAuto;
    BankingCancelButton.HeightPercent := 100.000000000000000000;
    BankingCancelButton.TabOrder := 4;
    BankingCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(BankingCancelButton, Self, 'OnClick', 'BankingCancelButtonClick');
    BankingEditTitle.SetParentComponent(Self);
    BankingEditTitle.Name := 'BankingEditTitle';
    BankingEditTitle.Left := 48;
    BankingEditTitle.Top := 8;
    BankingEditTitle.Width := 100;
    BankingEditTitle.Height := 40;
    BankingEditTitle.HeightStyle := ssAuto;
    BankingEditTitle.WidthStyle := ssAuto;
    BankingEditTitle.ChildOrder := 4;
    BankingEditTitle.ElementPosition := epIgnore;
    BankingEditTitle.ElementFont := efCSS;
    BankingEditTitle.Role := '';
    ShopCombo.SetParentComponent(Self);
    ShopCombo.Name := 'ShopCombo';
    ShopCombo.Left := 181;
    ShopCombo.Top := 72;
    ShopCombo.Width := 209;
    ShopCombo.Height := 22;
    ShopCombo.ElementClassName := 'form-select';
    ShopCombo.HeightPercent := 100.000000000000000000;
    ShopCombo.WidthPercent := 100.000000000000000000;
    ShopCombo.DataField := 'ShopRef';
    ShopCombo.DataSource := BankingDataSource;
    SaleDate.SetParentComponent(Self);
    SaleDate.Name := 'SaleDate';
    SaleDate.Left := 181;
    SaleDate.Top := 44;
    SaleDate.Width := 170;
    SaleDate.Height := 22;
    SaleDate.ElementClassName := 'form-control';
    SaleDate.HeightStyle := ssAuto;
    SaleDate.WidthStyle := ssAuto;
    SaleDate.BorderStyle := bsSingle;
    SaleDate.ChildOrder := 1;
    SaleDate.Color := clWhite;
    SaleDate.Date := 44483.612014027780000000;
    SaleDate.ElementFont := efCSS;
    SaleDate.ElementPosition := epIgnore;
    SaleDate.Role := '';
    SaleDate.TabOrder := 3;
    SaleDate.Text := '';
    SetEvent(SaleDate, Self, 'OnExit', 'SaleDateExit');
    SaleDate.DataField := 'SaleDate';
    SaleDate.DataSource := BankingDataSource;
    BankingDataSource.SetParentComponent(Self);
    BankingDataSource.Name := 'BankingDataSource';
    SetEvent(BankingDataSource, Self, 'OnDataChange', 'BankingDataSourceDataChange');
    BankingDataSource.Left := 544;
    BankingDataSource.Top := 24;
  finally
    CreditCards.AfterLoadDFMValues;
    CashAmount.AfterLoadDFMValues;
    BankingSaveButton.AfterLoadDFMValues;
    BankingCancelButton.AfterLoadDFMValues;
    BankingEditTitle.AfterLoadDFMValues;
    ShopCombo.AfterLoadDFMValues;
    SaleDate.AfterLoadDFMValues;
    BankingDataSource.AfterLoadDFMValues;
  end;
end;

end.
