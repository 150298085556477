unit DirectoryRequest;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TDonorDirectoryJob = class(TJobBase)
  private
    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;
    function RecentJobsTitle: string; override;
    function NoRecentJobsMessage: string; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DonorDirectoryJob: TDonorDirectoryJob;

implementation

{$R *.dfm}
{ TDonorDirectoryJob }

function TDonorDirectoryJob.CanPlaceJob: Boolean;
begin
  result := inherited CanPlaceJob;
end;

function TDonorDirectoryJob.JobClass: string;
begin
  result := 'TDonorDirectory';
end;

function TDonorDirectoryJob.JobDescription: string;
begin
  result := 'Create Donor Directories';
end;

function TDonorDirectoryJob.JobDisplayName: string;
begin
  result := 'Donor Directories';
end;

procedure TDonorDirectoryJob.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;

end;

function TDonorDirectoryJob.MinimumProcessLevel: string;
begin
  result := 'AnyAdministrator';
end;

function TDonorDirectoryJob.NoRecentJobsMessage: string;
begin
  Result := 'There have been no recent Directory Requests';
end;

function TDonorDirectoryJob.RecentJobsTitle: string;
begin
  Result := 'Recent Directory Requests';
end;

procedure TDonorDirectoryJob.LoadDFMValues;
begin
  inherited LoadDFMValues;


  OutputOption.BeforeLoadDFMValues;
  try
    OutputOption.Visible := False;
  finally
    OutputOption.AfterLoadDFMValues;
  end;
end;

end.
