unit SalesLabelJob;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TAdditionalSalesLabels = class(TJobBase)
    ShopSelect: TLookupComboBox;
    DonorIds: TEdit;
    LabelsOptions: TComboBox;
    procedure ShopSelectChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    [async]
    procedure FetchShopList; async;
    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function RecentJobsTitle: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;

  public
    { Public declarations }

  protected procedure LoadDFMValues; override; end;

var
  AdditionalSalesLabels: TAdditionalSalesLabels;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainDataModule,
  AppUtils;

procedure TAdditionalSalesLabels.WebFormCreate(Sender: TObject);
begin
  inherited;
  FetchShopList;
  PreviewAvailable := False;
  LabelsOptions.ItemIndex := 1;
end;

{ TAdditionalSalesLabels }

function TAdditionalSalesLabels.CanPlaceJob: Boolean;
begin
  Result := inherited;
  Result := Result and (ShopSelect.Value <> '') and (DonorIds.Text <> '');
end;

procedure TAdditionalSalesLabels.FetchShopList;
var
  lShopList: JS.TJSArray;
  lRetval: TXDataClientResponse;
  I: Integer;
  lShop: TJSObject;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSVC_SHOPLIST, []));
  lShopList := JS.ToArray(lRetval.ResultAsObject['value']);

  for I := 0 to lShopList.Length - 1 do
  begin
    lShop := TJSObject(lShopList[I]);
    if JS.toString(lShop['Status']) = 'Open' then
      ShopSelect.LookupValues.AddPair(JS.toString(lShop['Ref']), JS.toString(lShop['Name']));
  end;
  ShopSelect.SetFocus;
end;

function TAdditionalSalesLabels.JobClass: string;
begin
  Result := 'TPricingLabels';
end;

function TAdditionalSalesLabels.JobDescription: string;
begin
  Result := 'Additional Pricing Labels';
end;

function TAdditionalSalesLabels.JobDisplayName: string;
begin
  Result := 'Additional Pricing Labels';
end;

procedure TAdditionalSalesLabels.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['ShopRef'] := ShopSelect.Value;
  AJobParams.Properties['DonorIds'] := DonorIds.Text;
  AJobParams.Properties['LabelsOption'] := LabelsOptions.ItemIndex;
end;

function TAdditionalSalesLabels.MinimumProcessLevel: string;
begin
  Result := 'AnyAdministrator';
end;

function TAdditionalSalesLabels.RecentJobsTitle: string;
begin
  Result := 'Recent price label requests';
end;

procedure TAdditionalSalesLabels.ShopSelectChange(Sender: TObject);
begin
  ValidateJob;
end;

procedure TAdditionalSalesLabels.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopSelect := TLookupComboBox.Create('ShopSelect');
  DonorIds := TEdit.Create('DonorIds');
  LabelsOptions := TComboBox.Create('LabelsOptions');

  ShopSelect.BeforeLoadDFMValues;
  DonorIds.BeforeLoadDFMValues;
  LabelsOptions.BeforeLoadDFMValues;
  try
    ShopSelect.SetParentComponent(Self);
    ShopSelect.Name := 'ShopSelect';
    ShopSelect.Left := 80;
    ShopSelect.Top := 552;
    ShopSelect.Width := 241;
    ShopSelect.Height := 22;
    ShopSelect.ElementClassName := 'form-select';
    ShopSelect.ElementFont := efCSS;
    ShopSelect.ElementPosition := epIgnore;
    ShopSelect.HeightStyle := ssAuto;
    ShopSelect.HeightPercent := 100.000000000000000000;
    ShopSelect.WidthStyle := ssAuto;
    ShopSelect.WidthPercent := 100.000000000000000000;
    SetEvent(ShopSelect, Self, 'OnChange', 'ShopSelectChange');
    ShopSelect.ItemIndex := -1;
    DonorIds.SetParentComponent(Self);
    DonorIds.Name := 'DonorIds';
    DonorIds.Left := 80;
    DonorIds.Top := 592;
    DonorIds.Width := 481;
    DonorIds.Height := 22;
    DonorIds.ChildOrder := 13;
    DonorIds.ElementClassName := 'form-control';
    DonorIds.ElementFont := efCSS;
    DonorIds.ElementPosition := epIgnore;
    DonorIds.HeightStyle := ssAuto;
    DonorIds.HeightPercent := 100.000000000000000000;
    DonorIds.WidthStyle := ssAuto;
    DonorIds.WidthPercent := 100.000000000000000000;
    SetEvent(DonorIds, Self, 'OnChange', 'ShopSelectChange');
    LabelsOptions.SetParentComponent(Self);
    LabelsOptions.Name := 'LabelsOptions';
    LabelsOptions.Left := 80;
    LabelsOptions.Top := 632;
    LabelsOptions.Width := 145;
    LabelsOptions.Height := 23;
    LabelsOptions.ElementClassName := 'form-select';
    LabelsOptions.ElementFont := efCSS;
    LabelsOptions.HeightStyle := ssAuto;
    LabelsOptions.HeightPercent := 100.000000000000000000;
    LabelsOptions.Text := 'Use Template';
    LabelsOptions.WidthStyle := ssAuto;
    LabelsOptions.WidthPercent := 100.000000000000000000;
    SetEvent(LabelsOptions, Self, 'OnChange', 'ShopSelectChange');
    LabelsOptions.ItemIndex := -1;
    LabelsOptions.Items.BeginUpdate;
    try
      LabelsOptions.Items.Clear;
      LabelsOptions.Items.Add('Use label template');
      LabelsOptions.Items.Add('Use pre printed template');
    finally
      LabelsOptions.Items.EndUpdate;
    end;
  finally
    ShopSelect.AfterLoadDFMValues;
    DonorIds.AfterLoadDFMValues;
    LabelsOptions.AfterLoadDFMValues;
  end;
end;

end.
