unit GA.Shop.Shared;

interface

type
TUserScope = (usNone, usRead, usShopuser, usAdminuser, usAdministrator, usSuperuser, usSysUser);

const
  CLAIM_FIRSTNAME = 'firstname';
  CLAIM_FULLNAME = 'fullname';
  CLAIM_USERID = 'userid';
  CLAIM_SHOP = 'shop';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';

  SCOPE_SUPERUSER = 'superuser';
  SCOPE_ADMIN = 'administrator';
  SCOPE_ADMIN_USER = 'adminuser';
  SCOPE_SHOPUSER = 'shopuser';
  SCOPE_NONE = 'none';
  SCOPE_READONLY = 'read';

  SCOPE_ADMIN_AND_ABOVE = 'superuser,administrator';
  SCOPE_ANY_ADMIN = 'superuser,administrator,adminuser';
  SCOPE_USERS = 'superuser,adminuser,shopuser';

  SCOPE_ALL_USERS = 'superuser,administrator,adminuser,shopuser';

  User_Scopes: array[TUserScope] of string = ('none','read','shopuser','adminuser','administrator','superuser','sysuser');

  LABELS_TEMPLATE = 0;
  LABELS_PLAIN_PAPER = 1;
  LABELS_NONE = 2;

  JOBS_UPLOADED_FILE = 'UploadedFile';


  rRegExp_CharityRef = '^[A-Z]{1,2}[0-9]{1,5}$';
  rRegExp_Name = '^[A-Za-z][A-Za-z''\-]*$';
  rRegExp_Surname = '^[A-Za-z0-9 ,\.\(\)/&\-'']+$';
  rRegExp_Postcode = '^(GIR 0AA|(([A-Z][0-9][0-9]?' + '|[A-Z][A-HJ-Y][0-9][0-9]?' + '|[A-Z][0-9][A-Z]' +
    '|[A-Z][A-HJ-Y][0-9][A-Z])' + ' [0-9][A-Z]{2}))$';
  rRegExp_Amount = '-?(([1-9][0-9]*)|0)\.[0-9]{2}';

  GA_MaxSubmitRecCount = 500000;
  GA_CountryNamesForUK = 'UK,THE UK,UNITED KINGDOM,THE UNITED KINGDOM,GB,GREAT BRITAIN,' +
    'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND,ENGLAND,' + 'SCOTLAND,WALES,NORTHERN IRELAND';

function CreateSearchHash(const AFirstName, ALastName, APostCode, Add1: string): string;

implementation

function CreateSearchHash(const AFirstName, ALastName, APostCode, Add1: string): string;
var
  HashSource: string;
begin
  if Length(AFirstName) > 0 then
    HashSource := Copy(AFirstName, 1, 1)
  else
    HashSource := '-';

  Result := HashSource;

end;

end.

