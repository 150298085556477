unit DonorBaseViewForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.WebCtrls,
  BaseCoreForm, WEBLib.Toast;

type
  TDonorBaseView = class(TCoreWebForm)
    DataSource: TDataSource;
    dpFullName: TDBLabel;
    dpConsIdLabel: TDBLabel;
    dpOldRefLabel: TDBLabel;
    dpDonorIdLabel: TDBLabel;
    dpFullAddress: TDBLabel;
    dpEmail: TDBLabel;
    dpTelephone: TDBLabel;
    dpDateSigned: TDBLabel;
    dpClaimStatus: TDBLabel;
    dpComments: TDBLabel;
    dpGiftAidScheme: TDBLabel;
    CloseButton: TButton;
    FormCaption: TLabel;
    SubCaption: THTMLSpan;
    procedure WebFormCreate(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
  private

    procedure SetSubCaptionText(const Value: string);
    function GetDonorId: Integer;
    { Private declarations }
  protected
    FDatasetApplied: Boolean;
    procedure SetDataset(const Value: TDataset);
    procedure AfterSetDataset; virtual;
    procedure DisplaySubCaption(const Value: String); virtual;
    function GetTheModalResult: TModalResult; virtual;
    property DonorId: Integer read GetDonorId;
  public
    { Public declarations }
    property Dataset: TDataset write SetDataset;
    property SubCaptionText: string write SetSubCaptionText;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TDonorBaseView.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDatasetApplied := False;
end;

procedure TDonorBaseView.AfterSetDataset;
begin
  DataSource.DataSet.EnableControls;
end;

procedure TDonorBaseView.CloseButtonClick(Sender: TObject);
begin
  ModalResult := GetTheModalResult;
end;

procedure TDonorBaseView.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  if Field = Nil then
  begin
    FormCaption.HTML := DataSource.Dataset.FieldByName('FirstName').AsString + ' ' +
       DataSource.Dataset.FieldByName('LastName').AsString;
  end;
end;

procedure TDonorBaseView.DisplaySubCaption(const Value: String);
begin
  SubCaption.HTML.Text := Value;
end;

function TDonorBaseView.GetDonorId: Integer;
begin
  Result := Datasource.DataSet.FieldByName('Id').AsInteger;
end;

function TDonorBaseView.GetTheModalResult: TModalResult;
begin
  result := mrClose;
end;

procedure TDonorBaseView.SetDataset(const Value: TDataset);
begin
  DataSource.DataSet := Value;
  FDatasetApplied := True;
  AfterSetDataset;
end;

procedure TDonorBaseView.SetSubCaptionText(const Value: string);
begin
  DisplaySubCaption(Value);
end;

procedure TDonorBaseView.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dpFullName := TDBLabel.Create('dpFullName');
  dpConsIdLabel := TDBLabel.Create('dpConsId');
  dpOldRefLabel := TDBLabel.Create('dpOldRef');
  dpDonorIdLabel := TDBLabel.Create('dpDonorId');
  dpFullAddress := TDBLabel.Create('dpFullAddress');
  dpEmail := TDBLabel.Create('dpEmail');
  dpTelephone := TDBLabel.Create('dpPhone');
  dpDateSigned := TDBLabel.Create('dpDateSigned');
  dpClaimStatus := TDBLabel.Create('dpClaimStatus');
  dpComments := TDBLabel.Create('dpComments');
  dpGiftAidScheme := TDBLabel.Create('dpGiftAidScheme');
  FormCaption := TLabel.Create('FormCaption');
  CloseButton := TButton.Create('CloseButton');
  SubCaption := THTMLSpan.Create('SubCaption');
  DataSource := TDataSource.Create(Self);

  dpFullName.BeforeLoadDFMValues;
  dpConsIdLabel.BeforeLoadDFMValues;
  dpOldRefLabel.BeforeLoadDFMValues;
  dpDonorIdLabel.BeforeLoadDFMValues;
  dpFullAddress.BeforeLoadDFMValues;
  dpEmail.BeforeLoadDFMValues;
  dpTelephone.BeforeLoadDFMValues;
  dpDateSigned.BeforeLoadDFMValues;
  dpClaimStatus.BeforeLoadDFMValues;
  dpComments.BeforeLoadDFMValues;
  dpGiftAidScheme.BeforeLoadDFMValues;
  FormCaption.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SubCaption.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    Width := 900;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    dpFullName.SetParentComponent(Self);
    dpFullName.Name := 'dpFullName';
    dpFullName.Left := 46;
    dpFullName.Top := 104;
    dpFullName.Width := 65;
    dpFullName.Height := 15;
    dpFullName.Caption := 'dpFullName';
    dpFullName.ElementFont := efCSS;
    dpFullName.ElementPosition := epIgnore;
    dpFullName.HeightStyle := ssAuto;
    dpFullName.HeightPercent := 100.000000000000000000;
    dpFullName.HTMLType := tSPAN;
    dpFullName.WidthStyle := ssAuto;
    dpFullName.WidthPercent := 100.000000000000000000;
    dpFullName.DataField := 'FullName';
    dpFullName.DataSource := DataSource;
    dpConsIdLabel.SetParentComponent(Self);
    dpConsIdLabel.Name := 'dpConsIdLabel';
    dpConsIdLabel.Left := 48;
    dpConsIdLabel.Top := 67;
    dpConsIdLabel.Width := 79;
    dpConsIdLabel.Height := 15;
    dpConsIdLabel.Caption := 'dpConsIdLabel';
    dpConsIdLabel.ElementFont := efCSS;
    dpConsIdLabel.HeightStyle := ssAuto;
    dpConsIdLabel.HeightPercent := 100.000000000000000000;
    dpConsIdLabel.HTMLType := tSPAN;
    dpConsIdLabel.WidthPercent := 100.000000000000000000;
    dpConsIdLabel.DataField := 'ConsId';
    dpConsIdLabel.DataSource := DataSource;
    dpOldRefLabel.SetParentComponent(Self);
    dpOldRefLabel.Name := 'dpOldRefLabel';
    dpOldRefLabel.Left := 48;
    dpOldRefLabel.Top := 48;
    dpOldRefLabel.Width := 78;
    dpOldRefLabel.Height := 15;
    dpOldRefLabel.Caption := 'dpOldRefLabel';
    dpOldRefLabel.ElementFont := efCSS;
    dpOldRefLabel.HeightStyle := ssAuto;
    dpOldRefLabel.HeightPercent := 100.000000000000000000;
    dpOldRefLabel.HTMLType := tSPAN;
    dpOldRefLabel.WidthPercent := 100.000000000000000000;
    dpOldRefLabel.DataField := 'OldRef';
    dpOldRefLabel.DataSource := DataSource;
    dpDonorIdLabel.SetParentComponent(Self);
    dpDonorIdLabel.Name := 'dpDonorIdLabel';
    dpDonorIdLabel.Left := 48;
    dpDonorIdLabel.Top := 27;
    dpDonorIdLabel.Width := 85;
    dpDonorIdLabel.Height := 15;
    dpDonorIdLabel.Caption := 'dpDonorIdLabel';
    dpDonorIdLabel.ElementFont := efCSS;
    dpDonorIdLabel.ElementPosition := epIgnore;
    dpDonorIdLabel.HeightStyle := ssAuto;
    dpDonorIdLabel.HeightPercent := 100.000000000000000000;
    dpDonorIdLabel.HTMLType := tSPAN;
    dpDonorIdLabel.WidthStyle := ssAuto;
    dpDonorIdLabel.WidthPercent := 100.000000000000000000;
    dpDonorIdLabel.DataField := 'Id';
    dpDonorIdLabel.DataSource := DataSource;
    dpFullAddress.SetParentComponent(Self);
    dpFullAddress.Name := 'dpFullAddress';
    dpFullAddress.Left := 46;
    dpFullAddress.Top := 123;
    dpFullAddress.Width := 75;
    dpFullAddress.Height := 15;
    dpFullAddress.Caption := 'dpFullAddress';
    dpFullAddress.ElementFont := efCSS;
    dpFullAddress.ElementPosition := epIgnore;
    dpFullAddress.HeightStyle := ssAuto;
    dpFullAddress.HeightPercent := 100.000000000000000000;
    dpFullAddress.HTMLType := tSPAN;
    dpFullAddress.WidthStyle := ssAuto;
    dpFullAddress.WidthPercent := 100.000000000000000000;
    dpFullAddress.DataField := 'FullAddress';
    dpFullAddress.DataSource := DataSource;
    dpEmail.SetParentComponent(Self);
    dpEmail.Name := 'dpEmail';
    dpEmail.Left := 46;
    dpEmail.Top := 283;
    dpEmail.Width := 43;
    dpEmail.Height := 15;
    dpEmail.Caption := 'dpEmail';
    dpEmail.ElementFont := efCSS;
    dpEmail.ElementPosition := epIgnore;
    dpEmail.HeightStyle := ssAuto;
    dpEmail.HeightPercent := 100.000000000000000000;
    dpEmail.HTMLType := tSPAN;
    dpEmail.WidthStyle := ssAuto;
    dpEmail.WidthPercent := 100.000000000000000000;
    dpEmail.DataField := 'Email';
    dpEmail.DataSource := DataSource;
    dpTelephone.SetParentComponent(Self);
    dpTelephone.Name := 'dpTelephone';
    dpTelephone.Left := 46;
    dpTelephone.Top := 307;
    dpTelephone.Width := 68;
    dpTelephone.Height := 15;
    dpTelephone.Caption := 'dpTelephone';
    dpTelephone.ElementFont := efCSS;
    dpTelephone.ElementPosition := epIgnore;
    dpTelephone.HeightStyle := ssAuto;
    dpTelephone.HeightPercent := 100.000000000000000000;
    dpTelephone.HTMLType := tSPAN;
    dpTelephone.WidthStyle := ssAuto;
    dpTelephone.WidthPercent := 100.000000000000000000;
    dpTelephone.DataField := 'Telephone';
    dpTelephone.DataSource := DataSource;
    dpDateSigned.SetParentComponent(Self);
    dpDateSigned.Name := 'dpDateSigned';
    dpDateSigned.Left := 46;
    dpDateSigned.Top := 341;
    dpDateSigned.Width := 74;
    dpDateSigned.Height := 15;
    dpDateSigned.Caption := 'dpDateSigned';
    dpDateSigned.ElementFont := efCSS;
    dpDateSigned.ElementPosition := epIgnore;
    dpDateSigned.HeightStyle := ssAuto;
    dpDateSigned.HeightPercent := 100.000000000000000000;
    dpDateSigned.HTMLType := tSPAN;
    dpDateSigned.WidthStyle := ssAuto;
    dpDateSigned.WidthPercent := 100.000000000000000000;
    dpDateSigned.DataField := 'DateSigned';
    dpDateSigned.DataSource := DataSource;
    dpClaimStatus.SetParentComponent(Self);
    dpClaimStatus.Name := 'dpClaimStatus';
    dpClaimStatus.Left := 46;
    dpClaimStatus.Top := 354;
    dpClaimStatus.Width := 77;
    dpClaimStatus.Height := 15;
    dpClaimStatus.Caption := 'dpClaimStatus';
    dpClaimStatus.ElementFont := efCSS;
    dpClaimStatus.ElementPosition := epIgnore;
    dpClaimStatus.HeightStyle := ssAuto;
    dpClaimStatus.HeightPercent := 100.000000000000000000;
    dpClaimStatus.HTMLType := tSPAN;
    dpClaimStatus.WidthStyle := ssAuto;
    dpClaimStatus.WidthPercent := 100.000000000000000000;
    dpClaimStatus.DataField := 'ClaimStatus';
    dpClaimStatus.DataSource := DataSource;
    dpComments.SetParentComponent(Self);
    dpComments.Name := 'dpComments';
    dpComments.Left := 46;
    dpComments.Top := 368;
    dpComments.Width := 73;
    dpComments.Height := 15;
    dpComments.Caption := 'dpComments';
    dpComments.ElementFont := efCSS;
    dpComments.ElementPosition := epIgnore;
    dpComments.HeightStyle := ssAuto;
    dpComments.HeightPercent := 100.000000000000000000;
    dpComments.HTMLType := tSPAN;
    dpComments.WidthStyle := ssAuto;
    dpComments.WidthPercent := 100.000000000000000000;
    dpComments.DataField := 'Comments';
    dpComments.DataSource := DataSource;
    dpGiftAidScheme.SetParentComponent(Self);
    dpGiftAidScheme.Name := 'dpGiftAidScheme';
    dpGiftAidScheme.Left := 46;
    dpGiftAidScheme.Top := 382;
    dpGiftAidScheme.Width := 93;
    dpGiftAidScheme.Height := 15;
    dpGiftAidScheme.Caption := 'dpGiftAidScheme';
    dpGiftAidScheme.ElementFont := efCSS;
    dpGiftAidScheme.ElementPosition := epIgnore;
    dpGiftAidScheme.HeightStyle := ssAuto;
    dpGiftAidScheme.HeightPercent := 100.000000000000000000;
    dpGiftAidScheme.HTMLType := tSPAN;
    dpGiftAidScheme.WidthStyle := ssAuto;
    dpGiftAidScheme.WidthPercent := 100.000000000000000000;
    dpGiftAidScheme.DataField := 'GiftAidScheme';
    dpGiftAidScheme.DataSource := DataSource;
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 152;
    FormCaption.Top := 8;
    FormCaption.Width := 73;
    FormCaption.Height := 15;
    FormCaption.Caption := 'Form Caption';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 432;
    CloseButton.Top := 416;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 17;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementFont := efCSS;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SubCaption.SetParentComponent(Self);
    SubCaption.Name := 'SubCaption';
    SubCaption.Left := 168;
    SubCaption.Top := 27;
    SubCaption.Width := 100;
    SubCaption.Height := 40;
    SubCaption.HeightStyle := ssAuto;
    SubCaption.HeightPercent := 100.000000000000000000;
    SubCaption.WidthPercent := 100.000000000000000000;
    SubCaption.ChildOrder := 20;
    SubCaption.ElementPosition := epIgnore;
    SubCaption.ElementFont := efCSS;
    SubCaption.Role := '';
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.AutoEdit := False;
    SetEvent(DataSource, Self, 'OnDataChange', 'DataSourceDataChange');
    DataSource.Left := 288;
    DataSource.Top := 32;
  finally
    dpFullName.AfterLoadDFMValues;
    dpConsIdLabel.AfterLoadDFMValues;
    dpOldRefLabel.AfterLoadDFMValues;
    dpDonorIdLabel.AfterLoadDFMValues;
    dpFullAddress.AfterLoadDFMValues;
    dpEmail.AfterLoadDFMValues;
    dpTelephone.AfterLoadDFMValues;
    dpDateSigned.AfterLoadDFMValues;
    dpClaimStatus.AfterLoadDFMValues;
    dpComments.AfterLoadDFMValues;
    dpGiftAidScheme.AfterLoadDFMValues;
    FormCaption.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SubCaption.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.

