unit smx.webcore.types;

interface

uses System.Generics.Collections;

type

  TSortDirection = (sdAsc, sdDesc);
  TSortPair = TPair<integer, TSortDirection>;

implementation

end.
