unit GiftAidDetailsForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  WEBLib.Toast,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls;

type
  TGiftAidDetails = class(TBaseEditForm)
    GiftAidId: TDBLabel;
    TransactionId: TDBLabel;
    ClaimRef: TDBLabel;
    ClaimStatus: TDBLabel;
    SubmissionDate: TDBLabel;
    IRMark: TDBLabel;
    R68Link: TLabel;
    IRMarkReceipt: THTMLSpan;
    IRMarkReceiptLink: TLabel;
    procedure IRMarkReceiptLinkClick(Sender: TObject);
    procedure R68LinkClick(Sender: TObject);
  private
    { Private declarations }
  protected
    procedure RecordChanged; override;
    [async]
    procedure CheckForR68; async;
    [async]
    procedure GetIRReceipt; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  GiftAidDetails: TGiftAidDetails;

implementation

uses
  xdata.Web.client,
  XData.Web.Dataset,
  AppUtils,
  MainDataModule;

{$R *.dfm}

procedure TGiftAidDetails.CheckForR68;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSSVC_FILEEXISTS,
    ['Store', 'GiftAidSubmissions\' + DataSource.Dataset.FieldByName('Id').AsString, 'R68.pdf']));
  R68Link.Visible := Boolean(TJSObject(lRetval.Result)['value']);
end;

procedure TGiftAidDetails.GetIRReceipt;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSSVC_IRRECEIPT,
    [Datasource.DataSet.FieldByName('Id').AsInteger]));
  IRMarkReceipt.HTML.Text := JS.ToString(TJSObject(lRetval.Result)['value']);
end;

procedure TGiftAidDetails.IRMarkReceiptLinkClick(Sender: TObject);
begin
  GetIRReceipt;
end;

procedure TGiftAidDetails.R68LinkClick(Sender: TObject);
var
  lURL: string;
begin
  inherited;
  lURL := 'store/GiftAidSubmissions/' + DataSource.Dataset.FieldByName('Id').AsString + '/R68.pdf';
  Application.Navigate(lURL);
end;

{ TGiftAidDetails }

procedure TGiftAidDetails.RecordChanged;
begin
  inherited;
  FormCaption.Caption := DataSource.Dataset.FieldByName('ClaimRef').AsString;
  IRMarkReceipt.HTML.Text := '';
  R68Link.Visible := False;
  CheckForR68;
end;

procedure TGiftAidDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GiftAidId := TDBLabel.Create('GiftAidId');
  TransactionId := TDBLabel.Create('TransactionId');
  ClaimRef := TDBLabel.Create('ClaimRef');
  ClaimStatus := TDBLabel.Create('ClaimStatus');
  SubmissionDate := TDBLabel.Create('SubmissionDate');
  IRMark := TDBLabel.Create('IRMark');
  R68Link := TLabel.Create('R68Link');
  IRMarkReceiptLink := TLabel.Create('IRMarkReceiptLink');
  IRMarkReceipt := THTMLSpan.Create('IRMarkReceipt');

  GiftAidId.BeforeLoadDFMValues;
  TransactionId.BeforeLoadDFMValues;
  ClaimRef.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  SubmissionDate.BeforeLoadDFMValues;
  IRMark.BeforeLoadDFMValues;
  R68Link.BeforeLoadDFMValues;
  IRMarkReceiptLink.BeforeLoadDFMValues;
  IRMarkReceipt.BeforeLoadDFMValues;
  try
    GiftAidId.SetParentComponent(Self);
    GiftAidId.Name := 'GiftAidId';
    GiftAidId.Left := 48;
    GiftAidId.Top := 104;
    GiftAidId.Width := 47;
    GiftAidId.Height := 15;
    GiftAidId.Caption := 'GiftAidId';
    GiftAidId.ElementFont := efCSS;
    GiftAidId.HeightStyle := ssAuto;
    GiftAidId.HeightPercent := 100.000000000000000000;
    GiftAidId.WidthPercent := 100.000000000000000000;
    GiftAidId.DataField := 'Id';
    GiftAidId.DataSource := DataSource;
    TransactionId.SetParentComponent(Self);
    TransactionId.Name := 'TransactionId';
    TransactionId.Left := 48;
    TransactionId.Top := 136;
    TransactionId.Width := 70;
    TransactionId.Height := 15;
    TransactionId.Caption := 'TransactionId';
    TransactionId.ElementFont := efCSS;
    TransactionId.HeightStyle := ssAuto;
    TransactionId.HeightPercent := 100.000000000000000000;
    TransactionId.WidthPercent := 100.000000000000000000;
    TransactionId.DataField := 'TransactionId';
    TransactionId.DataSource := DataSource;
    ClaimRef.SetParentComponent(Self);
    ClaimRef.Name := 'ClaimRef';
    ClaimRef.Left := 48;
    ClaimRef.Top := 150;
    ClaimRef.Width := 48;
    ClaimRef.Height := 15;
    ClaimRef.Caption := 'ClaimRef';
    ClaimRef.ElementFont := efCSS;
    ClaimRef.HeightStyle := ssAuto;
    ClaimRef.HeightPercent := 100.000000000000000000;
    ClaimRef.WidthPercent := 100.000000000000000000;
    ClaimRef.DataField := 'ClaimRef';
    ClaimRef.DataSource := DataSource;
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 48;
    ClaimStatus.Top := 165;
    ClaimStatus.Width := 63;
    ClaimStatus.Height := 15;
    ClaimStatus.Caption := 'ClaimStatus';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.HeightStyle := ssAuto;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    ClaimStatus.DataField := 'ClaimStatus';
    ClaimStatus.DataSource := DataSource;
    SubmissionDate.SetParentComponent(Self);
    SubmissionDate.Name := 'SubmissionDate';
    SubmissionDate.Left := 48;
    SubmissionDate.Top := 180;
    SubmissionDate.Width := 85;
    SubmissionDate.Height := 15;
    SubmissionDate.Caption := 'SubmissionDate';
    SubmissionDate.ElementFont := efCSS;
    SubmissionDate.HeightStyle := ssAuto;
    SubmissionDate.HeightPercent := 100.000000000000000000;
    SubmissionDate.WidthPercent := 100.000000000000000000;
    SubmissionDate.DataField := 'SubmissionDate';
    SubmissionDate.DataSource := DataSource;
    IRMark.SetParentComponent(Self);
    IRMark.Name := 'IRMark';
    IRMark.Left := 48;
    IRMark.Top := 195;
    IRMark.Width := 37;
    IRMark.Height := 15;
    IRMark.Caption := 'IRMark';
    IRMark.ElementFont := efCSS;
    IRMark.HeightStyle := ssAuto;
    IRMark.HeightPercent := 100.000000000000000000;
    IRMark.WidthPercent := 100.000000000000000000;
    IRMark.DataField := 'IRMark';
    IRMark.DataSource := DataSource;
    R68Link.SetParentComponent(Self);
    R68Link.Name := 'R68Link';
    R68Link.Left := 48;
    R68Link.Top := 240;
    R68Link.Width := 57;
    R68Link.Height := 15;
    R68Link.Cursor := crHandPoint;
    R68Link.Caption := 'R68 Report';
    R68Link.ElementFont := efCSS;
    R68Link.HeightStyle := ssAuto;
    R68Link.HeightPercent := 100.000000000000000000;
    R68Link.Visible := False;
    R68Link.WidthPercent := 100.000000000000000000;
    SetEvent(R68Link, Self, 'OnClick', 'R68LinkClick');
    IRMarkReceiptLink.SetParentComponent(Self);
    IRMarkReceiptLink.Name := 'IRMarkReceiptLink';
    IRMarkReceiptLink.Left := 48;
    IRMarkReceiptLink.Top := 224;
    IRMarkReceiptLink.Width := 82;
    IRMarkReceiptLink.Height := 15;
    IRMarkReceiptLink.Cursor := crHandPoint;
    IRMarkReceiptLink.Caption := 'IR Mark Receipt';
    IRMarkReceiptLink.ElementFont := efCSS;
    IRMarkReceiptLink.HeightStyle := ssAuto;
    IRMarkReceiptLink.HeightPercent := 100.000000000000000000;
    IRMarkReceiptLink.WidthPercent := 100.000000000000000000;
    SetEvent(IRMarkReceiptLink, Self, 'OnClick', 'IRMarkReceiptLinkClick');
    IRMarkReceipt.SetParentComponent(Self);
    IRMarkReceipt.Name := 'IRMarkReceipt';
    IRMarkReceipt.Left := 216;
    IRMarkReceipt.Top := 150;
    IRMarkReceipt.Width := 100;
    IRMarkReceipt.Height := 41;
    IRMarkReceipt.HeightStyle := ssAuto;
    IRMarkReceipt.WidthStyle := ssAuto;
    IRMarkReceipt.ChildOrder := 17;
    IRMarkReceipt.ElementPosition := epIgnore;
    IRMarkReceipt.ElementFont := efCSS;
    IRMarkReceipt.Role := '';
  finally
    GiftAidId.AfterLoadDFMValues;
    TransactionId.AfterLoadDFMValues;
    ClaimRef.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    SubmissionDate.AfterLoadDFMValues;
    IRMark.AfterLoadDFMValues;
    R68Link.AfterLoadDFMValues;
    IRMarkReceiptLink.AfterLoadDFMValues;
    IRMarkReceipt.AfterLoadDFMValues;
  end;
end;

end.
