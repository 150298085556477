unit GAConfigForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseCoreForm, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, WEBLib.DB, WEBLib.ExtCtrls, WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TGAConfig = class(TCoreWebForm)
    CancelButton: TButton;
    SaveButton: TButton;
    ConfigDataset: TXDataWebDataSet;
    ConfigDatasetId: TIntegerField;
    ConfigDatasetHMRCOfficial: TIntegerField;
    ConfigDatasetCharityRef: TStringField;
    ConfigDatasetCharityType: TStringField;
    ConfigDatasetGatewayId: TStringField;
    ConfigDatasetRegulatorNumber: TStringField;
    ConfigDatasetEndOfYear: TStringField;
    ConfigDatasetGatewayPassword: TStringField;
    ConfigDatasetOrganisationType: TStringField;
    ConfigDatasetRegulator: TStringField;
    ConfigDatasetOrganisationName: TStringField;
    ConfigDatasetSubmissionMethod: TStringField;
    HMRCOfficial: TDBLookupComboBox;
    ConfigSource: TDataSource;
    StaffLookup: TXDataWebDataSet;
    StaffLookupId: TIntegerField;
    StaffLookupFirstName: TStringField;
    StaffLookupLastName: TStringField;
    StaffLookupHMRCAuthorised: TBooleanField;
    StaffLookupFullName: TStringField;
    StaffSource: TDataSource;
    StaffLookupStatus: TStringField;
    CharityRef: TDBEdit;
    GatewayId: TDBEdit;
    GatewayPassword: TDBEdit;
    OrganisationName: TDBEdit;
    RegulatorNumber: TDBEdit;
    CharityType: TDBLookupComboBox;
    Regulator: TDBLookupComboBox;
    OrganisationType: TDBLookupComboBox;
    SubmissionMethod: TDBLookupComboBox;
    procedure WebFormCreate(Sender: TObject);
    procedure CancelButtonClick(Sender: TObject);
    procedure ConfigDatasetAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure ConfigDatasetAfterOpen(DataSet: TDataSet);
    procedure ConfigSourceStateChange(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure StaffLookupAfterOpen(DataSet: TDataSet);
    procedure StaffLookupCalcFields(DataSet: TDataSet);
  private
    { Private declarations }
    procedure CanSave;

  public
    { Public declarations }
    function CanClose: boolean; override;
  protected procedure LoadDFMValues; override; end;

var
  GAConfig: TGAConfig;

implementation

{$R *.dfm}

uses MainDataModule;

procedure TGAConfig.WebFormCreate(Sender: TObject);
const
  _Filter = '$filter=(ShopRef eq ''H00'') and (Status eq ''Active'') and (HMRCAuthorised eq ''Yes'')';
begin
  inherited;

  CharityType.LookupValues.AddPair('CharitableTrust', 'Charitable Trust');
  CharityType.LookupValues.AddPair('Other', 'Other');

  Regulator.LookupValues.AddPair('None', 'Not Registered');
  Regulator.LookupValues.AddPair('CCEW', 'Charity Commission for England and Wales');
  Regulator.LookupValues.AddPair('CCNI',  'Charity Commission for Northern Ireland');
  Regulator.LookupValues.AddPair('OSCR',  'Office of the Scottish Charity Regulator');
  Regulator.LookupValues.AddPair('Other', 'Other');

  OrganisationType.LookupValues.AddPair('Individual', 'Individual');
  OrganisationType.LookupValues.AddPair('Company', 'Company');
  OrganisationType.LookupValues.AddPair('Agent', 'Agent');
  OrganisationType.LookupValues.AddPair('Bureau', 'Bureau');
  OrganisationType.LookupValues.AddPair('Partnership', 'Partnership');
  OrganisationType.LookupValues.AddPair('Trust', 'Trust');
  OrganisationType.LookupValues.AddPair('Employer', 'Employer');
  OrganisationType.LookupValues.AddPair('Government', 'Government');
  OrganisationType.LookupValues.AddPair('ActingInCapacity', 'Acting in Capacity');
  OrganisationType.LookupValues.AddPair('Other', 'Other');

  SubmissionMethod.LookupValues.AddPair('Live', 'Live');
  SubmissionMethod.LookupValues.AddPair('LTS', 'LTS');
  SubmissionMethod.LookupValues.AddPair('ISV', 'ISV');

  StaffLookup.QueryString := _Filter;
  StaffLookup.Load;


end;

procedure TGAConfig.CancelButtonClick(Sender: TObject);
begin
  if ConfigDataset.Active and (ConfigDataset.State in dsEditModes) then
     ConfigDataset.Cancel;
  ModalResult := mrCancel;
end;

function TGAConfig.CanClose: boolean;
begin
  result := (not SaveButton.Enabled);
end;

procedure TGAConfig.CanSave;
begin
  SaveButton.Enabled := ConfigDataset.Active and (ConfigDataset.State in dsEditModes);
end;

procedure TGAConfig.ConfigDatasetAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
begin
  ModalResult := mrOK;
end;

procedure TGAConfig.ConfigDatasetAfterOpen(DataSet: TDataSet);
begin
   if ConfigDataset.IsEmpty then
     ConfigDataset.Insert;
end;

procedure TGAConfig.ConfigSourceStateChange(Sender: TObject);
begin
  CanSave;
end;

procedure TGAConfig.SaveButtonClick(Sender: TObject);
begin
  if ConfigDataset.State in dsEditModes then
  begin
     ConfigDataset.Post;
     ConfigDataset.ApplyUpdates;
  end
  else
     ModalResult := mrOK;
end;

procedure TGAConfig.StaffLookupAfterOpen(DataSet: TDataSet);
begin
  ConfigDataset.Load;
end;

procedure TGAConfig.StaffLookupCalcFields(DataSet: TDataSet);
begin
  StaffLookUpFullName.Value := StaffLookUpFirstName.Value + ' ' + StaffLookUPLastName.Value;
end;

procedure TGAConfig.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CancelButton := TButton.Create('CancelButton');
  SaveButton := TButton.Create('SaveButton');
  HMRCOfficial := TDBLookupComboBox.Create('HMRCOfficial');
  CharityRef := TDBEdit.Create('CharityRef');
  GatewayId := TDBEdit.Create('GatewayId');
  GatewayPassword := TDBEdit.Create('GatewayPassword');
  OrganisationName := TDBEdit.Create('OrganisationName');
  RegulatorNumber := TDBEdit.Create('RegulatorNumber');
  CharityType := TDBLookupComboBox.Create('CharityType');
  Regulator := TDBLookupComboBox.Create('Regulator');
  OrganisationType := TDBLookupComboBox.Create('OrganisationType');
  SubmissionMethod := TDBLookupComboBox.Create('SubmissionMethod');
  ConfigDataset := TXDataWebDataSet.Create(Self);
  ConfigDatasetId := TIntegerField.Create(Self);
  ConfigDatasetHMRCOfficial := TIntegerField.Create(Self);
  ConfigDatasetCharityRef := TStringField.Create(Self);
  ConfigDatasetCharityType := TStringField.Create(Self);
  ConfigDatasetGatewayId := TStringField.Create(Self);
  ConfigDatasetGatewayPassword := TStringField.Create(Self);
  ConfigDatasetRegulator := TStringField.Create(Self);
  ConfigDatasetRegulatorNumber := TStringField.Create(Self);
  ConfigDatasetEndOfYear := TStringField.Create(Self);
  ConfigDatasetOrganisationType := TStringField.Create(Self);
  ConfigDatasetOrganisationName := TStringField.Create(Self);
  ConfigDatasetSubmissionMethod := TStringField.Create(Self);
  ConfigSource := TDataSource.Create(Self);
  StaffLookup := TXDataWebDataSet.Create(Self);
  StaffLookupId := TIntegerField.Create(Self);
  StaffLookupFirstName := TStringField.Create(Self);
  StaffLookupLastName := TStringField.Create(Self);
  StaffLookupHMRCAuthorised := TBooleanField.Create(Self);
  StaffLookupFullName := TStringField.Create(Self);
  StaffLookupStatus := TStringField.Create(Self);
  StaffSource := TDataSource.Create(Self);

  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  HMRCOfficial.BeforeLoadDFMValues;
  CharityRef.BeforeLoadDFMValues;
  GatewayId.BeforeLoadDFMValues;
  GatewayPassword.BeforeLoadDFMValues;
  OrganisationName.BeforeLoadDFMValues;
  RegulatorNumber.BeforeLoadDFMValues;
  CharityType.BeforeLoadDFMValues;
  Regulator.BeforeLoadDFMValues;
  OrganisationType.BeforeLoadDFMValues;
  SubmissionMethod.BeforeLoadDFMValues;
  ConfigDataset.BeforeLoadDFMValues;
  ConfigDatasetId.BeforeLoadDFMValues;
  ConfigDatasetHMRCOfficial.BeforeLoadDFMValues;
  ConfigDatasetCharityRef.BeforeLoadDFMValues;
  ConfigDatasetCharityType.BeforeLoadDFMValues;
  ConfigDatasetGatewayId.BeforeLoadDFMValues;
  ConfigDatasetGatewayPassword.BeforeLoadDFMValues;
  ConfigDatasetRegulator.BeforeLoadDFMValues;
  ConfigDatasetRegulatorNumber.BeforeLoadDFMValues;
  ConfigDatasetEndOfYear.BeforeLoadDFMValues;
  ConfigDatasetOrganisationType.BeforeLoadDFMValues;
  ConfigDatasetOrganisationName.BeforeLoadDFMValues;
  ConfigDatasetSubmissionMethod.BeforeLoadDFMValues;
  ConfigSource.BeforeLoadDFMValues;
  StaffLookup.BeforeLoadDFMValues;
  StaffLookupId.BeforeLoadDFMValues;
  StaffLookupFirstName.BeforeLoadDFMValues;
  StaffLookupLastName.BeforeLoadDFMValues;
  StaffLookupHMRCAuthorised.BeforeLoadDFMValues;
  StaffLookupFullName.BeforeLoadDFMValues;
  StaffLookupStatus.BeforeLoadDFMValues;
  StaffSource.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 520;
    CancelButton.Top := 424;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 400;
    SaveButton.Top := 424;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    HMRCOfficial.SetParentComponent(Self);
    HMRCOfficial.Name := 'HMRCOfficial';
    HMRCOfficial.Left := 128;
    HMRCOfficial.Top := 24;
    HMRCOfficial.Width := 257;
    HMRCOfficial.Height := 22;
    HMRCOfficial.ElementClassName := 'form-select';
    HMRCOfficial.ElementFont := efCSS;
    HMRCOfficial.ElementPosition := epIgnore;
    HMRCOfficial.HeightStyle := ssAuto;
    HMRCOfficial.HeightPercent := 100.000000000000000000;
    HMRCOfficial.WidthStyle := ssAuto;
    HMRCOfficial.WidthPercent := 100.000000000000000000;
    HMRCOfficial.DataField := 'HMRCOfficial';
    HMRCOfficial.DataSource := ConfigSource;
    HMRCOfficial.KeyField := 'Id';
    HMRCOfficial.ListField := 'FullName';
    HMRCOfficial.ListSource := StaffSource;
    CharityRef.SetParentComponent(Self);
    CharityRef.Name := 'CharityRef';
    CharityRef.Left := 136;
    CharityRef.Top := 144;
    CharityRef.Width := 121;
    CharityRef.Height := 22;
    CharityRef.ChildOrder := 4;
    CharityRef.ElementClassName := 'form-control';
    CharityRef.ElementFont := efCSS;
    CharityRef.ElementPosition := epIgnore;
    CharityRef.HeightStyle := ssAuto;
    CharityRef.HeightPercent := 100.000000000000000000;
    CharityRef.Text := 'CharityRef';
    CharityRef.WidthStyle := ssAuto;
    CharityRef.WidthPercent := 100.000000000000000000;
    CharityRef.DataField := 'CharityRef';
    CharityRef.DataSource := ConfigSource;
    GatewayId.SetParentComponent(Self);
    GatewayId.Name := 'GatewayId';
    GatewayId.Left := 136;
    GatewayId.Top := 280;
    GatewayId.Width := 121;
    GatewayId.Height := 22;
    GatewayId.ChildOrder := 4;
    GatewayId.ElementClassName := 'form-control';
    GatewayId.ElementFont := efCSS;
    GatewayId.ElementPosition := epIgnore;
    GatewayId.HeightStyle := ssAuto;
    GatewayId.HeightPercent := 100.000000000000000000;
    GatewayId.Text := 'GatewayId';
    GatewayId.WidthStyle := ssAuto;
    GatewayId.WidthPercent := 100.000000000000000000;
    GatewayId.DataField := 'GatewayId';
    GatewayId.DataSource := ConfigSource;
    GatewayPassword.SetParentComponent(Self);
    GatewayPassword.Name := 'GatewayPassword';
    GatewayPassword.Left := 136;
    GatewayPassword.Top := 308;
    GatewayPassword.Width := 121;
    GatewayPassword.Height := 22;
    GatewayPassword.ChildOrder := 4;
    GatewayPassword.ElementClassName := 'form-control';
    GatewayPassword.ElementFont := efCSS;
    GatewayPassword.ElementPosition := epIgnore;
    GatewayPassword.HeightStyle := ssAuto;
    GatewayPassword.HeightPercent := 100.000000000000000000;
    GatewayPassword.PasswordChar := '*';
    GatewayPassword.Text := 'GatewayPassword';
    GatewayPassword.WidthStyle := ssAuto;
    GatewayPassword.WidthPercent := 100.000000000000000000;
    GatewayPassword.DataField := 'GatewayPassword';
    GatewayPassword.DataSource := ConfigSource;
    OrganisationName.SetParentComponent(Self);
    OrganisationName.Name := 'OrganisationName';
    OrganisationName.Left := 136;
    OrganisationName.Top := 88;
    OrganisationName.Width := 249;
    OrganisationName.Height := 22;
    OrganisationName.ChildOrder := 4;
    OrganisationName.ElementClassName := 'form-control';
    OrganisationName.ElementFont := efCSS;
    OrganisationName.ElementPosition := epIgnore;
    OrganisationName.HeightStyle := ssAuto;
    OrganisationName.HeightPercent := 100.000000000000000000;
    OrganisationName.Text := 'OrganisationName';
    OrganisationName.WidthStyle := ssAuto;
    OrganisationName.WidthPercent := 100.000000000000000000;
    OrganisationName.DataField := 'OrganisationName';
    OrganisationName.DataSource := ConfigSource;
    RegulatorNumber.SetParentComponent(Self);
    RegulatorNumber.Name := 'RegulatorNumber';
    RegulatorNumber.Left := 136;
    RegulatorNumber.Top := 216;
    RegulatorNumber.Width := 121;
    RegulatorNumber.Height := 22;
    RegulatorNumber.ChildOrder := 4;
    RegulatorNumber.ElementClassName := 'form-control';
    RegulatorNumber.ElementFont := efCSS;
    RegulatorNumber.ElementPosition := epIgnore;
    RegulatorNumber.HeightStyle := ssAuto;
    RegulatorNumber.HeightPercent := 100.000000000000000000;
    RegulatorNumber.Text := 'RegulatorNumber';
    RegulatorNumber.WidthStyle := ssAuto;
    RegulatorNumber.WidthPercent := 100.000000000000000000;
    RegulatorNumber.DataField := 'RegulatorNumber';
    RegulatorNumber.DataSource := ConfigSource;
    CharityType.SetParentComponent(Self);
    CharityType.Name := 'CharityType';
    CharityType.Left := 136;
    CharityType.Top := 116;
    CharityType.Width := 249;
    CharityType.Height := 22;
    CharityType.ElementClassName := 'form-select';
    CharityType.ElementFont := efCSS;
    CharityType.HeightPercent := 100.000000000000000000;
    CharityType.WidthPercent := 100.000000000000000000;
    CharityType.DataField := 'CharityType';
    CharityType.DataSource := ConfigSource;
    Regulator.SetParentComponent(Self);
    Regulator.Name := 'Regulator';
    Regulator.Left := 136;
    Regulator.Top := 188;
    Regulator.Width := 249;
    Regulator.Height := 22;
    Regulator.ElementClassName := 'form-select';
    Regulator.ElementFont := efCSS;
    Regulator.HeightPercent := 100.000000000000000000;
    Regulator.WidthPercent := 100.000000000000000000;
    Regulator.DataField := 'Regulator';
    Regulator.DataSource := ConfigSource;
    OrganisationType.SetParentComponent(Self);
    OrganisationType.Name := 'OrganisationType';
    OrganisationType.Left := 136;
    OrganisationType.Top := 60;
    OrganisationType.Width := 249;
    OrganisationType.Height := 22;
    OrganisationType.ElementClassName := 'form-select';
    OrganisationType.ElementFont := efCSS;
    OrganisationType.HeightStyle := ssAuto;
    OrganisationType.HeightPercent := 100.000000000000000000;
    OrganisationType.WidthStyle := ssAuto;
    OrganisationType.WidthPercent := 100.000000000000000000;
    OrganisationType.DataField := 'OrganisationType';
    OrganisationType.DataSource := ConfigSource;
    SubmissionMethod.SetParentComponent(Self);
    SubmissionMethod.Name := 'SubmissionMethod';
    SubmissionMethod.Left := 136;
    SubmissionMethod.Top := 360;
    SubmissionMethod.Width := 249;
    SubmissionMethod.Height := 22;
    SubmissionMethod.ElementClassName := 'form-select';
    SubmissionMethod.ElementFont := efCSS;
    SubmissionMethod.HeightPercent := 100.000000000000000000;
    SubmissionMethod.WidthPercent := 100.000000000000000000;
    SubmissionMethod.DataField := 'SubmissionMethod';
    SubmissionMethod.DataSource := ConfigSource;
    ConfigDataset.SetParentComponent(Self);
    ConfigDataset.Name := 'ConfigDataset';
    ConfigDataset.AfterApplyUpdates := ConfigDatasetAfterApplyUpdates;
    ConfigDataset.AfterOpen := ConfigDatasetAfterOpen;
    ConfigDataset.EntitySetName := 'GiftAidParams';
    ConfigDataset.Connection := MainData.DataConnection;
    ConfigDataset.Left := 472;
    ConfigDataset.Top := 96;
    ConfigDatasetId.SetParentComponent(ConfigDataset);
    ConfigDatasetId.Name := 'ConfigDatasetId';
    ConfigDatasetId.FieldName := 'Id';
    ConfigDatasetId.Required := True;
    ConfigDatasetHMRCOfficial.SetParentComponent(ConfigDataset);
    ConfigDatasetHMRCOfficial.Name := 'ConfigDatasetHMRCOfficial';
    ConfigDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    ConfigDatasetHMRCOfficial.Required := True;
    ConfigDatasetCharityRef.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityRef.Name := 'ConfigDatasetCharityRef';
    ConfigDatasetCharityRef.FieldName := 'CharityRef';
    ConfigDatasetCharityRef.Size := 50;
    ConfigDatasetCharityType.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityType.Name := 'ConfigDatasetCharityType';
    ConfigDatasetCharityType.FieldName := 'CharityType';
    ConfigDatasetCharityType.Required := True;
    ConfigDatasetCharityType.Size := 15;
    ConfigDatasetGatewayId.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayId.Name := 'ConfigDatasetGatewayId';
    ConfigDatasetGatewayId.FieldName := 'GatewayId';
    ConfigDatasetGatewayId.Size := 50;
    ConfigDatasetGatewayPassword.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayPassword.Name := 'ConfigDatasetGatewayPassword';
    ConfigDatasetGatewayPassword.FieldName := 'GatewayPassword';
    ConfigDatasetGatewayPassword.Size := 64;
    ConfigDatasetRegulator.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulator.Name := 'ConfigDatasetRegulator';
    ConfigDatasetRegulator.FieldName := 'Regulator';
    ConfigDatasetRegulator.Required := True;
    ConfigDatasetRegulator.Size := 5;
    ConfigDatasetRegulatorNumber.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulatorNumber.Name := 'ConfigDatasetRegulatorNumber';
    ConfigDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    ConfigDatasetRegulatorNumber.Size := 50;
    ConfigDatasetEndOfYear.SetParentComponent(ConfigDataset);
    ConfigDatasetEndOfYear.Name := 'ConfigDatasetEndOfYear';
    ConfigDatasetEndOfYear.FieldName := 'EndOfYear';
    ConfigDatasetEndOfYear.Size := 5;
    ConfigDatasetOrganisationType.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationType.Name := 'ConfigDatasetOrganisationType';
    ConfigDatasetOrganisationType.FieldName := 'OrganisationType';
    ConfigDatasetOrganisationType.Required := True;
    ConfigDatasetOrganisationType.Size := 16;
    ConfigDatasetOrganisationName.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationName.Name := 'ConfigDatasetOrganisationName';
    ConfigDatasetOrganisationName.FieldName := 'OrganisationName';
    ConfigDatasetOrganisationName.Size := 250;
    ConfigDatasetSubmissionMethod.SetParentComponent(ConfigDataset);
    ConfigDatasetSubmissionMethod.Name := 'ConfigDatasetSubmissionMethod';
    ConfigDatasetSubmissionMethod.FieldName := 'SubmissionMethod';
    ConfigDatasetSubmissionMethod.Required := True;
    ConfigDatasetSubmissionMethod.Size := 4;
    ConfigSource.SetParentComponent(Self);
    ConfigSource.Name := 'ConfigSource';
    ConfigSource.DataSet := ConfigDataset;
    SetEvent(ConfigSource, Self, 'OnStateChange', 'ConfigSourceStateChange');
    ConfigSource.Left := 560;
    ConfigSource.Top := 96;
    StaffLookup.SetParentComponent(Self);
    StaffLookup.Name := 'StaffLookup';
    StaffLookup.AfterOpen := StaffLookupAfterOpen;
    SetEvent(StaffLookup, Self, 'OnCalcFields', 'StaffLookupCalcFields');
    StaffLookup.EntitySetName := 'User';
    StaffLookup.Connection := MainData.DataConnection;
    StaffLookup.Left := 472;
    StaffLookup.Top := 216;
    StaffLookupId.SetParentComponent(StaffLookup);
    StaffLookupId.Name := 'StaffLookupId';
    StaffLookupId.FieldName := 'Id';
    StaffLookupFirstName.SetParentComponent(StaffLookup);
    StaffLookupFirstName.Name := 'StaffLookupFirstName';
    StaffLookupFirstName.FieldName := 'FirstName';
    StaffLookupFirstName.Size := 50;
    StaffLookupLastName.SetParentComponent(StaffLookup);
    StaffLookupLastName.Name := 'StaffLookupLastName';
    StaffLookupLastName.FieldName := 'LastName';
    StaffLookupLastName.Size := 50;
    StaffLookupHMRCAuthorised.SetParentComponent(StaffLookup);
    StaffLookupHMRCAuthorised.Name := 'StaffLookupHMRCAuthorised';
    StaffLookupHMRCAuthorised.FieldName := 'HMRCAuthorised';
    StaffLookupFullName.SetParentComponent(StaffLookup);
    StaffLookupFullName.Name := 'StaffLookupFullName';
    StaffLookupFullName.FieldKind := fkCalculated;
    StaffLookupFullName.FieldName := 'FullName';
    StaffLookupFullName.Size := 50;
    StaffLookupFullName.Calculated := True;
    StaffLookupStatus.SetParentComponent(StaffLookup);
    StaffLookupStatus.Name := 'StaffLookupStatus';
    StaffLookupStatus.FieldName := 'Status';
    StaffLookupStatus.Size := 9;
    StaffSource.SetParentComponent(Self);
    StaffSource.Name := 'StaffSource';
    StaffSource.AutoEdit := False;
    StaffSource.DataSet := StaffLookup;
    StaffSource.Left := 552;
    StaffSource.Top := 216;
  finally
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    HMRCOfficial.AfterLoadDFMValues;
    CharityRef.AfterLoadDFMValues;
    GatewayId.AfterLoadDFMValues;
    GatewayPassword.AfterLoadDFMValues;
    OrganisationName.AfterLoadDFMValues;
    RegulatorNumber.AfterLoadDFMValues;
    CharityType.AfterLoadDFMValues;
    Regulator.AfterLoadDFMValues;
    OrganisationType.AfterLoadDFMValues;
    SubmissionMethod.AfterLoadDFMValues;
    ConfigDataset.AfterLoadDFMValues;
    ConfigDatasetId.AfterLoadDFMValues;
    ConfigDatasetHMRCOfficial.AfterLoadDFMValues;
    ConfigDatasetCharityRef.AfterLoadDFMValues;
    ConfigDatasetCharityType.AfterLoadDFMValues;
    ConfigDatasetGatewayId.AfterLoadDFMValues;
    ConfigDatasetGatewayPassword.AfterLoadDFMValues;
    ConfigDatasetRegulator.AfterLoadDFMValues;
    ConfigDatasetRegulatorNumber.AfterLoadDFMValues;
    ConfigDatasetEndOfYear.AfterLoadDFMValues;
    ConfigDatasetOrganisationType.AfterLoadDFMValues;
    ConfigDatasetOrganisationName.AfterLoadDFMValues;
    ConfigDatasetSubmissionMethod.AfterLoadDFMValues;
    ConfigSource.AfterLoadDFMValues;
    StaffLookup.AfterLoadDFMValues;
    StaffLookupId.AfterLoadDFMValues;
    StaffLookupFirstName.AfterLoadDFMValues;
    StaffLookupLastName.AfterLoadDFMValues;
    StaffLookupHMRCAuthorised.AfterLoadDFMValues;
    StaffLookupFullName.AfterLoadDFMValues;
    StaffLookupStatus.AfterLoadDFMValues;
    StaffSource.AfterLoadDFMValues;
  end;
end;

end.