unit ConsIdEditForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.WebCtrls,
  Data.DB,
  WEBLib.DB,
  AppUtils, WEBLib.Lists;

type
  TConsIdEditDialog = class(TCoreWebForm)
    IdEditCancelButton: TButton;
    DonorIdDataSource: TDataSource;
    DonorIdStatusLabel: THTMLSpan;
    IdEditOKButton: TButton;
    DonorIdEdit: TDBEdit;
    OtherConsDonors: THTMLDiv;
    IdEditValidate: TButton;
    OtherConsList: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure DonorIdEditEnter(Sender: TObject);
    procedure DonorIdEditExit(Sender: TObject);
    procedure IdEditCancelButtonClick(Sender: TObject);
    procedure IdEditOKButtonClick(Sender: TObject);
  private
    { Private declarations }
    FOriginalId: Integer;
    FLastValidatedId: Integer;
    FIdState: TValueChangeState;
    function GetDataset: TDataset;
    procedure SetDataset(const Value: TDataset);
    procedure ValidateEnteredId;
    [async]
    procedure ValidateId(const ADonorId: Integer); async;
    procedure ValidateForm;

    { Private declarations }
  public
    { Public declarations }
    property Dataset: TDataset read GetDataset write SetDataset;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainDataModule;

procedure TConsIdEditDialog.WebFormCreate(Sender: TObject);
begin
  inherited;
  OtherConsList.Items.Clear;
end;

procedure TConsIdEditDialog.DonorIdEditEnter(Sender: TObject);
begin
  DonorIdStatusLabel.HTML.Text := '';
end;

procedure TConsIdEditDialog.DonorIdEditExit(Sender: TObject);
begin
  ValidateEnteredId;
end;

function TConsIdEditDialog.GetDataset: TDataset;
begin
  Result := DonorIdDataSource.Dataset;
end;

procedure TConsIdEditDialog.IdEditCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TConsIdEditDialog.IdEditOKButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TConsIdEditDialog.SetDataset(const Value: TDataset);
begin
  FOriginalId := Value.FieldByName('ConsId').AsInteger;
  DonorIdDataSource.Dataset := Value;
end;

procedure TConsIdEditDialog.ValidateEnteredId;
var
  lDonorId: Integer;
begin
  lDonorId := StrToIntDef(Trim(DonorIdEdit.Text), 0);

  if lDonorId = 0 then
    Exit;

  if lDonorId = FOriginalId then
  begin
    ControlNoValidity(DonorIdEdit.ElementID);
    DonorIdStatusLabel.HTML.Clear;
    FIdState := vcsNoChange;
    ValidateForm;
  end
  else if lDonorId <> FLastValidatedId then
    ValidateId(lDonorId);

end;

procedure TConsIdEditDialog.ValidateForm;
begin
  IdEditOKButton.Enabled := (FIdState in ChangeStateOk) and (FIdState = vcsValid);
end;

procedure TConsIdEditDialog.ValidateId(const ADonorId: Integer);
var
  lRetval: TXDataClientResponse;
  lResult, lDonor: TJSObject;
  lList: TJSArray;
  lDonorCount: Integer;
  I: Integer;
  S: string;
  li: TListItem;
begin

  FLastValidatedId := ADonorId;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_DONORS_BY_CONSID, [ADonorId]));

  lResult := lRetval.ResultAsObject;

  lDonorCount := JS.ToInteger(lResult['DonorCount']);
  // Status
  // StatusMessage

  OtherConsList.Items.Clear;

  if lDonorCount > 0 then
  begin
    lList := JS.toArray(lResult['Donors']);
    for I := 0 to lDonorCount - 1 do
    begin
      lDonor := JS.toObject(lList[I]);

      S := JS.toString(lDonor['OldRef']);
      if S = '' then
         S := JS.toInteger(lDonor['DonorId']).ToString;
      S := S + ': ' + JS.ToString(lDonor['DonorName']) + ' - ' +  JS.toString(lDonor['Address']);
      li := OtherConsList.Items.Add;
      li.Text := S;
    end;

    OtherConsDonors.Visible := True;
  end
  else
    OtherConsDonors.Visible := False;


  if lDonorCount = 1 then
  begin
    DonorIdStatusLabel.HTML.Text :=
      '<i class="fad fa-person"></i> There is already a Donor using this ConsId. If this is the same person, you can still change it.';
    FIdState := vcsValid;
  end
  else if lDonorCount > 1 then
  begin
    DonorIdStatusLabel.HTML.Text :=
      '<i class="fad fa-people-group"></i> There are already a Donors using this ConsId. If this is the same person, you can still change it.';
    FIdState := vcsValid;
  end
  else
  begin
    DonorIdStatusLabel.HTML.Text := '<i class="fad fa-check"></i> OK, this ConsId is not in use.';
    FIdState := vcsValid;
  end;

  ValidateForm;
end;

procedure TConsIdEditDialog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  IdEditCancelButton := TButton.Create('IdEditCancelButton');
  DonorIdStatusLabel := THTMLSpan.Create('DonorIdStatusLabel');
  IdEditOKButton := TButton.Create('IdEditOKButton');
  DonorIdEdit := TDBEdit.Create('DonorIdEdit');
  OtherConsDonors := THTMLDiv.Create('OtherConsDonors');
  OtherConsList := TListControl.Create('OtherConsList');
  IdEditValidate := TButton.Create('IdEditValidate');
  DonorIdDataSource := TDataSource.Create(Self);

  IdEditCancelButton.BeforeLoadDFMValues;
  DonorIdStatusLabel.BeforeLoadDFMValues;
  IdEditOKButton.BeforeLoadDFMValues;
  DonorIdEdit.BeforeLoadDFMValues;
  OtherConsDonors.BeforeLoadDFMValues;
  OtherConsList.BeforeLoadDFMValues;
  IdEditValidate.BeforeLoadDFMValues;
  DonorIdDataSource.BeforeLoadDFMValues;
  try
    Width := 563;
    Height := 362;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    IdEditCancelButton.SetParentComponent(Self);
    IdEditCancelButton.Name := 'IdEditCancelButton';
    IdEditCancelButton.AlignWithMargins := True;
    IdEditCancelButton.Left := 335;
    IdEditCancelButton.Top := 307;
    IdEditCancelButton.Width := 96;
    IdEditCancelButton.Height := 33;
    IdEditCancelButton.Caption := 'Cancel';
    IdEditCancelButton.ChildOrder := 1;
    IdEditCancelButton.ElementClassName := 'btn btn-primary';
    IdEditCancelButton.ElementFont := efCSS;
    IdEditCancelButton.ElementPosition := epIgnore;
    IdEditCancelButton.HeightStyle := ssAuto;
    IdEditCancelButton.HeightPercent := 100.000000000000000000;
    IdEditCancelButton.TabOrder := 2;
    IdEditCancelButton.WidthStyle := ssAuto;
    IdEditCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(IdEditCancelButton, Self, 'OnClick', 'IdEditCancelButtonClick');
    DonorIdStatusLabel.SetParentComponent(Self);
    DonorIdStatusLabel.Name := 'DonorIdStatusLabel';
    DonorIdStatusLabel.Left := 48;
    DonorIdStatusLabel.Top := 121;
    DonorIdStatusLabel.Width := 250;
    DonorIdStatusLabel.Height := 26;
    DonorIdStatusLabel.HeightStyle := ssAuto;
    DonorIdStatusLabel.WidthStyle := ssAuto;
    DonorIdStatusLabel.ChildOrder := 7;
    DonorIdStatusLabel.ElementPosition := epIgnore;
    DonorIdStatusLabel.ElementFont := efCSS;
    DonorIdStatusLabel.Role := '';
    IdEditOKButton.SetParentComponent(Self);
    IdEditOKButton.Name := 'IdEditOKButton';
    IdEditOKButton.AlignWithMargins := True;
    IdEditOKButton.Left := 445;
    IdEditOKButton.Top := 307;
    IdEditOKButton.Width := 96;
    IdEditOKButton.Height := 33;
    IdEditOKButton.Caption := 'Save';
    IdEditOKButton.ChildOrder := 6;
    IdEditOKButton.ElementClassName := 'btn btn-primary';
    IdEditOKButton.ElementFont := efCSS;
    IdEditOKButton.ElementPosition := epIgnore;
    IdEditOKButton.Enabled := False;
    IdEditOKButton.HeightStyle := ssAuto;
    IdEditOKButton.HeightPercent := 100.000000000000000000;
    IdEditOKButton.TabOrder := 1;
    IdEditOKButton.WidthStyle := ssAuto;
    IdEditOKButton.WidthPercent := 100.000000000000000000;
    SetEvent(IdEditOKButton, Self, 'OnClick', 'IdEditOKButtonClick');
    DonorIdEdit.SetParentComponent(Self);
    DonorIdEdit.Name := 'DonorIdEdit';
    DonorIdEdit.Left := 145;
    DonorIdEdit.Top := 93;
    DonorIdEdit.Width := 153;
    DonorIdEdit.Height := 22;
    DonorIdEdit.ChildOrder := 2;
    DonorIdEdit.EditType := weNumeric;
    DonorIdEdit.ElementClassName := 'form-control';
    DonorIdEdit.ElementFont := efCSS;
    DonorIdEdit.ElementPosition := epIgnore;
    DonorIdEdit.HeightStyle := ssAuto;
    DonorIdEdit.HeightPercent := 100.000000000000000000;
    DonorIdEdit.Text := 'DonorIdEdit';
    DonorIdEdit.WidthStyle := ssAuto;
    DonorIdEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DonorIdEdit, Self, 'OnEnter', 'DonorIdEditEnter');
    SetEvent(DonorIdEdit, Self, 'OnExit', 'DonorIdEditExit');
    DonorIdEdit.DataField := 'ConsId';
    DonorIdEdit.DataSource := DonorIdDataSource;
    OtherConsDonors.SetParentComponent(Self);
    OtherConsDonors.Name := 'OtherConsDonors';
    OtherConsDonors.Left := 48;
    OtherConsDonors.Top := 176;
    OtherConsDonors.Width := 267;
    OtherConsDonors.Height := 153;
    OtherConsDonors.HeightStyle := ssAuto;
    OtherConsDonors.WidthStyle := ssAuto;
    OtherConsDonors.ChildOrder := 6;
    OtherConsDonors.ElementPosition := epIgnore;
    OtherConsDonors.ElementFont := efCSS;
    OtherConsDonors.Role := '';
    OtherConsDonors.Visible := False;
    OtherConsList.SetParentComponent(OtherConsDonors);
    OtherConsList.Name := 'OtherConsList';
    OtherConsList.Left := 7;
    OtherConsList.Top := 3;
    OtherConsList.Width := 257;
    OtherConsList.Height := 60;
    OtherConsList.HeightStyle := ssAuto;
    OtherConsList.WidthStyle := ssAuto;
    OtherConsList.HeightPercent := 100.000000000000000000;
    OtherConsList.WidthPercent := 100.000000000000000000;
    OtherConsList.DefaultItemClassName := 'list-group-item';
    OtherConsList.DefaultItemLinkClassName := 'list-group-link';
    OtherConsList.ElementFont := efCSS;
    OtherConsList.ElementPosition := epIgnore;
    OtherConsList.ElementListClassName := 'list-group';
    OtherConsList.Items.Clear;
    with OtherConsList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 0';
    end;
    with OtherConsList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 1';
    end;
    with OtherConsList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 2';
    end;
    OtherConsList.Style := lsListGroup;
    IdEditValidate.SetParentComponent(Self);
    IdEditValidate.Name := 'IdEditValidate';
    IdEditValidate.Left := 304;
    IdEditValidate.Top := 92;
    IdEditValidate.Width := 96;
    IdEditValidate.Height := 25;
    IdEditValidate.Caption := 'Validate';
    IdEditValidate.ChildOrder := 6;
    IdEditValidate.ElementClassName := 'btn btn-primary';
    IdEditValidate.ElementFont := efCSS;
    IdEditValidate.ElementPosition := epIgnore;
    IdEditValidate.HeightStyle := ssAuto;
    IdEditValidate.HeightPercent := 100.000000000000000000;
    IdEditValidate.WidthStyle := ssAuto;
    IdEditValidate.WidthPercent := 100.000000000000000000;
    DonorIdDataSource.SetParentComponent(Self);
    DonorIdDataSource.Name := 'DonorIdDataSource';
    DonorIdDataSource.Left := 328;
    DonorIdDataSource.Top := 32;
  finally
    IdEditCancelButton.AfterLoadDFMValues;
    DonorIdStatusLabel.AfterLoadDFMValues;
    IdEditOKButton.AfterLoadDFMValues;
    DonorIdEdit.AfterLoadDFMValues;
    OtherConsDonors.AfterLoadDFMValues;
    OtherConsList.AfterLoadDFMValues;
    IdEditValidate.AfterLoadDFMValues;
    DonorIdDataSource.AfterLoadDFMValues;
  end;
end;

end.
