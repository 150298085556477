unit DonorIdEditForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.ExtCtrls,
  WEBLib.DB,
  WEBLib.DBCtrls,
  Data.DB,
  BaseCoreForm,
  AppUtils,
  WEBLib.WebCtrls;

type
  TDonorIdEditDialog = class(TCoreWebForm)
    DonorIdDataSource: TDataSource;
    DonorIdEditLabel: TLabel;
    DonorIdEdit: TDBEdit;
    IdEditCancelButton: TButton;
    IdEditOKButton: TButton;
    DonorIdStatusLabel: THTMLSpan;
    IdEditValidate: TButton;
    procedure DonorIdEditEnter(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure DonorIdEditExit(Sender: TObject);
    procedure DonorIdEditKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure IdEditCancelButtonClick(Sender: TObject);
    procedure IdEditOKButtonClick(Sender: TObject);
  private
    FOriginalId: string;
    FLastValidatedId: String;
    FIdState: TValueChangeState;
    FIdType: TDonorIdType;
    function GetDataset: TDataset;
    procedure SetDataset(const Value: TDataset);

    procedure ValidateEnteredId;
    [async]
    procedure ValidateId(const ADonorId: String); async;

    procedure ValidateForm;
    procedure SetIdType(const Value: TDonorIdType);

    { Private declarations }
  public
    { Public declarations }
    property IdType: TDonorIdType write SetIdType;
    property Dataset: TDataset read GetDataset write SetDataset;
  protected procedure LoadDFMValues; override; end;

var
  DonorIdEditDialog: TDonorIdEditDialog;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainDataModule;

procedure TDonorIdEditDialog.DonorIdEditEnter(Sender: TObject);
begin
  DonorIdStatusLabel.HTML.Text := '';
   ControlNoValidity(DonorIdEdit.ElementID);
   DonorIdEdit.SelectAll;
end;

procedure TDonorIdEditDialog.WebFormCreate(Sender: TObject);
begin
  inherited;
  DonorIdStatusLabel.HTML.Clear;
end;

procedure TDonorIdEditDialog.DonorIdEditExit(Sender: TObject);
begin
  ValidateEnteredId;
end;

procedure TDonorIdEditDialog.DonorIdEditKeyUp(Sender: TObject; var Key: Word;
    Shift: TShiftState);
begin
 if (FIdType = ditOldRef) and (Length(DonorIdEdit.Text) = 7)then
    ValidateEnteredId;
end;

function TDonorIdEditDialog.GetDataset: TDataset;
begin
  Result := DonorIdDataSource.Dataset;
end;

procedure TDonorIdEditDialog.IdEditCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TDonorIdEditDialog.IdEditOKButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TDonorIdEditDialog.SetDataset(const Value: TDataset);
begin

  FIdState := vcsNoChange;

  case FIdType of
    ditNotSpecified:
      raise Exception.Create('Must specify an id type');
    ditId:
      begin
        DonorIdEditLabel.Caption := 'Donor Id';
        DonorIdEdit.DataField := 'Id';
        DonorIdEdit.EditType := TEditType.weNumeric;
        FOriginalId := Value.FieldByName('Id').AsString;
      end;
    ditOldRef:
      begin
        DonorIdEditLabel.Caption := 'Donor Ref';
        DonorIdEdit.DataField := 'OldRef';
        DonorIdEdit.CharCase := wecUpperCase;
        DonorIdEdit.EditType := TEditType.weString;
        FOriginalId := Value.FieldByName('OldRef').AsString;
      end;
    ditConsId:
      raise Exception.Create('Wrong Form for Cons Id');
  end;

  DonorIdDataSource.Dataset := Value;

end;

procedure TDonorIdEditDialog.SetIdType(const Value: TDonorIdType);
begin
  FIdType := Value;
end;

procedure TDonorIdEditDialog.ValidateEnteredId;
var
  lDonorId: string;
begin
  lDonorId := Trim(DonorIdEdit.Text);
  if lDonorId.Contains('O') then
  begin
    lDonorId := lDonorId.Replace('O', '0');
    DonorIdEdit.Text := lDonorId;
  end;

  if lDonorId = FOriginalId then
  begin
    ControlNoValidity(DonorIdEdit.ElementID);
    DonorIdStatusLabel.HTML.Clear;
    FIdState := vcsNoChange;
    ValidateForm;
  end
  else if lDonorId <> FLastValidatedId then
    ValidateId(lDonorId);

end;

procedure TDonorIdEditDialog.ValidateForm;
begin
  IdEditOKButton.Enabled := (FIdState in ChangeStateOk) and (FIdState = vcsValid);
end;

procedure TDonorIdEditDialog.ValidateId(const ADonorId: String);
var
  lStatus: string;
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
begin
  FLastValidatedId := ADonorId;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_DONORID_AVAILABILITY,
    [ADonorId]));
  lResult := lRetval.ResultAsObject;

  lStatus := JS.ToString(lResult['Status']);
  if lStatus = 'Exception' then
  begin
    ControlInvalid(DonorIdEdit.ElementID);
    DonorIdStatusLabel.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> ' +
      JS.ToString(lResult['StatusMessage']);
    FIdState := vcsInvalid;
  end
  else
  begin
    ControlValid(DonorIdEdit.ElementID);
    DonorIdStatusLabel.HTML.Text := '<i class="fad fa-check"></i> OK';
    FIdState := vcsValid;
  end;

  ValidateForm;
end;

procedure TDonorIdEditDialog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonorIdEditLabel := TLabel.Create('DonorIdEditLabel');
  DonorIdEdit := TDBEdit.Create('DonorIdEdit');
  IdEditCancelButton := TButton.Create('IdEditCancelButton');
  IdEditOKButton := TButton.Create('IdEditOKButton');
  DonorIdStatusLabel := THTMLSpan.Create('DonorIdStatusLabel');
  IdEditValidate := TButton.Create('IdEditValidate');
  DonorIdDataSource := TDataSource.Create(Self);

  DonorIdEditLabel.BeforeLoadDFMValues;
  DonorIdEdit.BeforeLoadDFMValues;
  IdEditCancelButton.BeforeLoadDFMValues;
  IdEditOKButton.BeforeLoadDFMValues;
  DonorIdStatusLabel.BeforeLoadDFMValues;
  IdEditValidate.BeforeLoadDFMValues;
  DonorIdDataSource.BeforeLoadDFMValues;
  try
    Width := 521;
    Height := 335;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    DonorIdEditLabel.SetParentComponent(Self);
    DonorIdEditLabel.Name := 'DonorIdEditLabel';
    DonorIdEditLabel.Left := 48;
    DonorIdEditLabel.Top := 96;
    DonorIdEditLabel.Width := 91;
    DonorIdEditLabel.Height := 15;
    DonorIdEditLabel.Caption := 'DonorIdEditLabel';
    DonorIdEditLabel.ElementFont := efCSS;
    DonorIdEditLabel.HeightStyle := ssAuto;
    DonorIdEditLabel.HeightPercent := 100.000000000000000000;
    DonorIdEditLabel.WidthPercent := 100.000000000000000000;
    DonorIdEdit.SetParentComponent(Self);
    DonorIdEdit.Name := 'DonorIdEdit';
    DonorIdEdit.Left := 145;
    DonorIdEdit.Top := 93;
    DonorIdEdit.Width := 153;
    DonorIdEdit.Height := 22;
    DonorIdEdit.ChildOrder := 2;
    DonorIdEdit.ElementClassName := 'form-control';
    DonorIdEdit.ElementFont := efCSS;
    DonorIdEdit.HeightStyle := ssAuto;
    DonorIdEdit.HeightPercent := 100.000000000000000000;
    DonorIdEdit.Text := 'DonorIdEdit';
    DonorIdEdit.WidthPercent := 100.000000000000000000;
    SetEvent(DonorIdEdit, Self, 'OnEnter', 'DonorIdEditEnter');
    SetEvent(DonorIdEdit, Self, 'OnExit', 'DonorIdEditExit');
    SetEvent(DonorIdEdit, Self, 'OnKeyUp', 'DonorIdEditKeyUp');
    DonorIdEdit.DataSource := DonorIdDataSource;
    IdEditCancelButton.SetParentComponent(Self);
    IdEditCancelButton.Name := 'IdEditCancelButton';
    IdEditCancelButton.AlignWithMargins := True;
    IdEditCancelButton.Left := 287;
    IdEditCancelButton.Top := 259;
    IdEditCancelButton.Width := 96;
    IdEditCancelButton.Height := 33;
    IdEditCancelButton.Caption := 'Cancel';
    IdEditCancelButton.ChildOrder := 1;
    IdEditCancelButton.ElementClassName := 'btn btn-primary';
    IdEditCancelButton.ElementFont := efCSS;
    IdEditCancelButton.ElementPosition := epIgnore;
    IdEditCancelButton.HeightStyle := ssAuto;
    IdEditCancelButton.HeightPercent := 100.000000000000000000;
    IdEditCancelButton.TabOrder := 2;
    IdEditCancelButton.WidthStyle := ssAuto;
    IdEditCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(IdEditCancelButton, Self, 'OnClick', 'IdEditCancelButtonClick');
    IdEditOKButton.SetParentComponent(Self);
    IdEditOKButton.Name := 'IdEditOKButton';
    IdEditOKButton.AlignWithMargins := True;
    IdEditOKButton.Left := 389;
    IdEditOKButton.Top := 259;
    IdEditOKButton.Width := 96;
    IdEditOKButton.Height := 33;
    IdEditOKButton.Caption := 'Save';
    IdEditOKButton.ChildOrder := 6;
    IdEditOKButton.ElementClassName := 'btn btn-primary';
    IdEditOKButton.ElementFont := efCSS;
    IdEditOKButton.ElementPosition := epIgnore;
    IdEditOKButton.Enabled := False;
    IdEditOKButton.HeightStyle := ssAuto;
    IdEditOKButton.HeightPercent := 100.000000000000000000;
    IdEditOKButton.TabOrder := 1;
    IdEditOKButton.WidthStyle := ssAuto;
    IdEditOKButton.WidthPercent := 100.000000000000000000;
    SetEvent(IdEditOKButton, Self, 'OnClick', 'IdEditOKButtonClick');
    DonorIdStatusLabel.SetParentComponent(Self);
    DonorIdStatusLabel.Name := 'DonorIdStatusLabel';
    DonorIdStatusLabel.Left := 48;
    DonorIdStatusLabel.Top := 121;
    DonorIdStatusLabel.Width := 250;
    DonorIdStatusLabel.Height := 26;
    DonorIdStatusLabel.HeightStyle := ssAuto;
    DonorIdStatusLabel.WidthStyle := ssAuto;
    DonorIdStatusLabel.ChildOrder := 7;
    DonorIdStatusLabel.ElementPosition := epIgnore;
    DonorIdStatusLabel.ElementFont := efCSS;
    DonorIdStatusLabel.Role := '';
    IdEditValidate.SetParentComponent(Self);
    IdEditValidate.Name := 'IdEditValidate';
    IdEditValidate.Left := 304;
    IdEditValidate.Top := 92;
    IdEditValidate.Width := 96;
    IdEditValidate.Height := 25;
    IdEditValidate.Caption := 'Validate';
    IdEditValidate.ChildOrder := 6;
    IdEditValidate.ElementClassName := 'btn btn-primary';
    IdEditValidate.ElementFont := efCSS;
    IdEditValidate.ElementPosition := epIgnore;
    IdEditValidate.HeightStyle := ssAuto;
    IdEditValidate.HeightPercent := 100.000000000000000000;
    IdEditValidate.WidthStyle := ssAuto;
    IdEditValidate.WidthPercent := 100.000000000000000000;
    DonorIdDataSource.SetParentComponent(Self);
    DonorIdDataSource.Name := 'DonorIdDataSource';
    DonorIdDataSource.Left := 328;
    DonorIdDataSource.Top := 32;
  finally
    DonorIdEditLabel.AfterLoadDFMValues;
    DonorIdEdit.AfterLoadDFMValues;
    IdEditCancelButton.AfterLoadDFMValues;
    IdEditOKButton.AfterLoadDFMValues;
    DonorIdStatusLabel.AfterLoadDFMValues;
    IdEditValidate.AfterLoadDFMValues;
    DonorIdDataSource.AfterLoadDFMValues;
  end;
end;

end.
