unit SalesDetailForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Toast,
  Vcl.Grids,
  Vcl.Menus,
  WEBLib.Menus,
  WEBLib.Buttons;

type
  TSalesSheetAction = (ssaNone, ssaUpdated, ssaDeleted, ssaSheetData);

  TMasterSheetRefreshEvent = procedure(const ASelectedId: Integer) of object;

  TSalesDetail = class(TBaseEditForm)
    SalesData: TXDataWebDataSet;
    SalesSource: TDataSource;
    SalesDataId: TIntegerField;
    SalesDataSheetId: TIntegerField;
    SalesDataPageNum: TIntegerField;
    SalesDataDonorId: TIntegerField;
    SalesDataShopRef: TStringField;
    SalesDataSaleDate: TDateField;
    SalesDataAmount: TFloatField;
    SalesDataCommission: TFloatField;
    SalesDataNetAmount: TFloatField;
    SalesDataClaimStatus: TStringField;
    SalesDataClaimId: TIntegerField;
    SalesDataClaimRef: TStringField;
    SalesDataClaimDate: TDateField;
    SalesDataClaimStatusChanged: TDateField;
    SalesDataNotificationId: TIntegerField;
    SalesDataRefundDate: TDateField;
    SalesDataRefundId: TIntegerField;
    SalesDataAddedBy: TIntegerField;
    SalesDataDateAdded: TDateTimeField;
    SalesDataLastUpdatedBy: TIntegerField;
    SalesDataLastUpdated: TDateTimeField;
    CashAmount: TDBEdit;
    CreditCards: TDBEdit;
    SaleDate: TDBDateTimePicker;
    CompletedBy: TDBEdit;
    TotalBanked: TDBEdit;
    SalesDataNotificationDate: TDateField;
    SalesDataNotificationSent: TBooleanField;
    SalesDataNotifcationSentText: TStringField;
    SalesDataTaxYear: TIntegerField;
    SalesSheets: TXDataWebDataSet;
    SalesSheetSource: TDataSource;
    SalesSheetsId: TIntegerField;
    SalesSheetsShopRef: TStringField;
    SalesSheetsSaleDate: TDateField;
    SalesSheetsTotalSales: TFloatField;
    SalesSheetsCreditCards: TFloatField;
    SalesSheetsCashAndCheques: TFloatField;
    SalesSheetsTotalBanked: TFloatField;
    SalesSheetsCompletedBy: TStringField;
    SalesSheetsBatchId: TIntegerField;
    SalesSheetsSheetNumber: TIntegerField;
    SalesSheetsSheetCount: TIntegerField;
    SalesSheetsAddedBy: TIntegerField;
    SalesSheetsDateAdded: TDateTimeField;
    SalesSheetsLastUpdatedBy: TIntegerField;
    SalesSheetsLastUpdated: TDateTimeField;
    SalesDataOldRef: TStringField;
    SalesDataEnteredId: TStringField;
    SalesDataDisplayId: TStringField;
    SalesDataPopUp: TPopupMenu;
    Edit1: TMenuItem;
    SalesDataApplyButton: TButton;
    SalesDataResetButton: TButton;
    SalesDetailMessageDlg: TMessageDlg;
    SalesDataAddButton: TButton;
    SalesDetailDlg: TMessageDlg;
    SheetList: TDBTableControl;
    SalesList: TDBTableControl;
    ShopRef: TDBEdit;
    SalesDataSalesTaxYear: TIntegerField;
    SalesDataSalesYear: TIntegerField;
    SalesDataGiftAidValue: TFloatField;
    SalesDataCompanyYear: TIntegerField;
    procedure WebFormCreate(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure SalesDataAddButtonClick(Sender: TObject);
    procedure SalesDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure SalesDataAfterDelete(Dataset: TDataSet);
    procedure SalesDataAfterOpen(Dataset: TDataSet);
    procedure SalesDataAfterPost(Dataset: TDataSet);
    procedure SalesDataApplyButtonClick(Sender: TObject);
    procedure SalesDataCalcFields(Dataset: TDataSet);
    procedure SalesSheetsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure SalesDataResetButtonClick(Sender: TObject);
    procedure SalesListGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure SalesSheetsAfterOpen(Dataset: TDataSet);
    procedure SalesSheetsBeforeScroll(Dataset: TDataSet);
    procedure SalesSourceStateChange(Sender: TObject);
    procedure SheetListClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure SheetListGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
  private

    FBatchId: Integer;
    FLastLoaded: Integer;
    FSavingSales: Boolean;
    { Private declarations }
    FShopsList: TStringList;
    FEditingMaster: Boolean;
    FSalesModified: Boolean;
    FLastSalesId: Integer;
    FSelectedSheetId: Integer;
    FLastSheetAction: TSalesSheetAction;
    FDeletedSheetNumber: Integer;
    FNewSheet1Amount: Double;
    FApplySheetUpdate: Boolean;
    FSaleDeleted: Boolean;
    FRefreshMaster: TMasterSheetRefreshEvent;
    procedure AfterSetDataset; override;
    procedure LoadSalesSheet;
    [async]
    procedure ProcessUpdatedSales; async;
    [async]
    procedure AfterSalesUpdated; async;
    [async]
    procedure DeleteSheetRecord(ARecordId: Integer); async;

    [async]
    procedure NewSalesRecord; async;
    [async]
    procedure DeleteSalesRec(ARecordId: Integer); async;
    [async]
    procedure EditSalesRec(ARecordId: Integer); async;

    [async]
    procedure RefreshSalesSheetForm(const ASelectedId: Integer); async;
    procedure SetSalesEditButtonStates;
    function SalesDataNeedsAttention: Boolean;
  protected
    function CanSaveThis: Boolean; override;
    procedure RecordChanged; override;
    procedure BeforePostRecord; override;
    function CloseOnSave: Boolean; override;
    procedure LoadSales;
  public
    { Public declarations }
    property RefreshMaster: TMasterSheetRefreshEvent read FRefreshMaster write FRefreshMaster;
  protected procedure LoadDFMValues; override; end;

var
  SalesDetail: TSalesDetail;

implementation

uses
  XData.Web.Client,
  MainDataModule,
  AppUtils,
  System.DateUtils,
  SalesDataEditForm,
  Auth.Service;

{$R *.dfm}

procedure TSalesDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FSavingSales := False;
  SalesDataAddButton.Caption := '<i class="fas fa-plus fa-lg fa-fw"></i>';
  SalesDataApplyButton.Caption := '<i class="fas fa-save fa-lg fa-fw"></i>';
  SalesDataResetButton.Caption := '<i class="fas fa-undo fa-lg fa-fw"></i>';
  FLastSheetAction := ssaNone;
end;

{ TSalesDetail }

procedure TSalesDetail.AfterSalesUpdated;
var
  lMessage: string;
  mr: TModalResult;
begin
  FSaleDeleted := False;

  if SalesData.IsEmpty then
  begin
    lMessage :=
      Format('As there are no sales left for this sheet, sales sheet with the id %d can be deleted. Shall we do this?',
      [SalesSheetsId.Value]);
    mr := await(TModalResult, SalesDetailDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));
    if mr = mrYes then
    begin
      SalesSheets.Delete;
      SalesSheets.ApplyUpdates;
      Exit;
    end;
  end
  else
  begin
    if FLastSalesId > 0 then
      SalesData.Locate('Id', FLastSalesId, []);
    FLastSalesId := 0;
  end;

  if FApplySheetUpdate then
  begin
    SalesSheets.ApplyUpdates;
    FApplySheetUpdate := False;
  end;

  FSavingSales := False;
  FSalesModified := False;

  SetSalesEditButtonStates;
  ShowAnAwesomeToast('Sales', 'Your changes have been saved', 'fas fa-check-circle');

end;

procedure TSalesDetail.AfterSetDataset;
begin
  FBatchId := Dataset.FieldByName('BatchId').AsInteger;
  inherited;
end;

procedure TSalesDetail.BeforePostRecord;
begin
  inherited;

end;

function TSalesDetail.CanSaveThis: Boolean;
begin
  if SalesDataNeedsAttention then
  begin
    Result := False;
    SalesDetailMessageDlg.ShowDialog('Please complete/roll back your changes to the sales data before closing',
      mtWarning, [mbOK]);
    FSaveMessagesHandled := True;
  end
  else
    Result := inherited CanSaveThis;
end;

function TSalesDetail.CloseOnSave: Boolean;
begin
  Result := False;
end;

procedure TSalesDetail.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;

  if (Field <> nil) and (Field.Dataset.State in dsEditModes) and
    ((Field.FieldName = 'CashAndCheques') or (Field.FieldName = 'CreditCards')) then
    Dataset.FieldByName('TotalBanked').AsFloat := Dataset.FieldByName('CreditCards').AsFloat +
      Dataset.FieldByName('CashAndCheques').AsFloat;
end;

procedure TSalesDetail.DeleteSalesRec(ARecordId: Integer);
var
  lMessage: string;
  mr: TModalResult;
begin

  if not SalesData.Locate('Id', ARecordId, []) then
    Exit;

  if (TSysHelper.StringToClaimStatus(SalesDataClaimStatus.Value) in Stop_Sales_Delete) then
  begin
    MessageDlg('You cannot delete this sales record because of it''s current status.', mtWarning, [mbOK]);
    Exit;
  end;

  lMessage := 'Are you sure you want to delete this sale for ' + FormatFloat('#,##0.00', SalesDataAmount.Value) + '?';
  mr := await(TModalResult, SalesDetailDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));
  if mr = mrYes then
  begin
    SalesData.Delete;
  end;
end;

procedure TSalesDetail.DeleteSheetRecord(ARecordId: Integer);
var
  lCanDelete: Boolean;
  lMessage: string;
  mr: TModalResult;
begin
  if SalesSheets.IsEmpty then
    Exit;

  if ARecordId <> FSelectedSheetId then
    // if not SalesSheets.Locate('Id', ARecordId, []) then
    Exit;

  lCanDelete := True;
  SalesData.DisableControls;
  SalesData.First;
  while not SalesData.Eof do
  begin
    if (TSysHelper.StringToClaimStatus(SalesDataClaimStatus.Value) in Stop_Sales_Delete) then
    begin
      lCanDelete := False;
      Break;
    end;
    SalesData.Next;
  end;

  if not lCanDelete then
  begin
    MessageDlg('You cannot delete this sheet as one or more sales records status does not allow it.',
      mtWarning, [mbOK]);
    SalesData.EnableControls;
    Exit;
  end;

  lMessage := Format('Are you sure you want to delete sales sheet with the id %d?', [SalesSheetsId.Value]);
  mr := await(TModalResult, SalesDetailDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));
  if mr = mrYes then
  begin
    SalesSheets.Delete;
    FLastSheetAction := ssaDeleted;
    SalesSheets.ApplyUpdates;
  end;

end;

procedure TSalesDetail.EditSalesRec(ARecordId: Integer);
var
  AForm: TEditSalesData;
  lResult: TModalResult;
  lUpdateId: Boolean;
begin
  if not SalesData.Locate('Id', ARecordId, []) then
    Exit;

  if (TSysHelper.StringToClaimStatus(SalesDataClaimStatus.Value) in Stop_Sales_Delete) then
  begin
    MessageDlg('You cannot edit this sales record because of it''s current status.', mtWarning, [mbOK]);
    Exit;
  end;

  if ((SalesDataClaimStatus.Value = 'Scheduled') and (not AuthService.IsAdministrator)) then
  begin
    MessageDlg('Only Administrators can edit Scheduled sales.', mtWarning, [mbOK]);
    Exit;
  end;

  AForm := TEditSalesData.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TEditSalesData, AForm.Load());


    AForm.EditId := SalesDataDisplayId.Value;
    AForm.EditAmount := SalesDataAmount.Value;
     AForm.EditClaimStatus := SalesDataClaimStatus.Value;

    lResult := await(TModalResult, AForm.Execute);
    if lResult = mrOK then
    begin
      lUpdateId := AForm.EditId <> SalesDataDisplayId.Value;
      SalesData.Edit;
      SalesDataAmount.Value := AForm.EditAmount;
      SalesDataClaimStatus.Value := AForm.EditClaimStatus;

      if lUpdateId then
      begin
        SalesDataDonorId.Value := AForm.DonorId;
        SalesDataOldRef.Value := AForm.DonorRef;
        SalesDataEnteredId.Value := AForm.EditId;
      end;

      SalesData.Post;
    end;

  finally
    AForm.Free;
  end;
end;

procedure TSalesDetail.NewSalesRecord;
var
  AForm: TEditSalesData;
  lResult: TModalResult;
begin
  AForm := TEditSalesData.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TEditSalesData, AForm.Load());

    AForm.EditClaimStatus := 'Unclaimed';

    lResult := await(TModalResult, AForm.Execute);
    if lResult = mrOK then
    begin
      SalesData.Insert;
      Self.SalesDataSheetId.Value := SalesSheetsId.Value;
      SalesDataShopRef.Value := SalesSheetsShopRef.Value;
      SalesDataSaleDate.Value := SalesSheetsSaleDate.Value;
      SalesDataPageNum.Value := SalesSheetsSheetNumber.Value;
      SalesDataAmount.Value := AForm.EditAmount;
      SalesDataDonorId.Value := AForm.DonorId;
      SalesDataOldRef.Value := AForm.DonorRef;
      SalesDataEnteredId.Value := AForm.EditId;
      SalesDataClaimStatus.Value := AForm.EditClaimStatus;

      SalesData.Post;

    end;

  finally
    AForm.Free;
    AForm := nil;
    SetSalesEditButtonStates;
  end;
end;

procedure TSalesDetail.LoadSales;
begin
  if FLastLoaded = FSelectedSheetId then
  begin
    SalesData.EnableControls;
    Exit;
  end;

  FLastSalesId := 0;
  SalesData.DisableControls;
//  SalesList.BeginUpdate;
  FLastLoaded := FSelectedSheetId;
  if SalesData.Active then
    SalesData.Close;
  if FLastLoaded = -1 then
    SalesData.EnableControls
  else
  begin
    SalesData.QueryString := '$filter=SheetId eq ' + FLastLoaded.ToString + '&$orderby=id';
    SalesData.Load;
  end;
end;

procedure TSalesDetail.LoadSalesSheet;
begin
  SalesSheets.DisableControls;
  if SalesSheets.Active then
     SalesSheets.Close;
  SalesSheets.QueryString := Format('$filter=BatchId eq %d', [FBatchId]);
  SalesSheets.Load;
end;

procedure TSalesDetail.ProcessUpdatedSales;
var
  lRetval: TXDataClientResponse;
begin
  FApplySheetUpdate := False;
  { TODO : Should move this to server }
  FSavingSales := True;
  FLastSalesId := SalesDataId.AsInteger;
  SalesData.DisableControls;
  SalesData.First;
  FNewSheet1Amount := 0;
  while not SalesData.Eof do
  begin
    FNewSheet1Amount := FNewSheet1Amount + SalesDataAmount.Value;
    SalesData.Next;
  end;

  if FNewSheet1Amount <> SalesSheetsTotalSales.Value then
  begin
    SalesSheets.Edit;
    SalesSheetsTotalSales.Value := FNewSheet1Amount;
    SalesSheets.Post;
    FApplySheetUpdate := True;
    FLastSheetAction := ssaUpdated;
  end;
  SalesData.ApplyUpdates;

end;

procedure TSalesDetail.RecordChanged;
begin
  inherited;

  FormCaption.Caption := 'Sales at ' + Dataset.FieldByName('ShopName').AsString + ' on ' +
    FormatDateTime(SYS_DATE_FORMAT, Dataset.FieldByName('SaleDate').AsDateTime);

{$IFDEF DEBUG}
  FormCaption.Caption := FormCaption.Caption + ' [sheets ' + Dataset.FieldByName('SheetCount').AsString + '] (Id:' +
    Dataset.FieldByName('Id').AsString + '; BatchId: ' + Dataset.FieldByName('BatchId').AsString + ')';
{$ENDIF}
  LoadSalesSheet;
end;

procedure TSalesDetail.RefreshSalesSheetForm(const ASelectedId: Integer);
begin
  if Assigned(FRefreshMaster) then
    FRefreshMaster(ASelectedId);
end;

procedure TSalesDetail.SalesDataAddButtonClick(Sender: TObject);
begin
  NewSalesRecord;
end;

procedure TSalesDetail.SalesDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  AfterSalesUpdated;
end;

procedure TSalesDetail.SalesDataAfterDelete(Dataset: TDataSet);
begin
  FSalesModified := True;
  FSaleDeleted := True;
  SetSalesEditButtonStates;
end;

procedure TSalesDetail.SalesDataAfterOpen(Dataset: TDataSet);
begin
  FSalesModified := False;
  SalesData.EnableControls;
//  SalesList.EndUpdate;
  SetSalesEditButtonStates;
end;

procedure TSalesDetail.SalesDataAfterPost(Dataset: TDataSet);
begin
  FSalesModified := FSalesModified or Dataset.Modified;
  SetSalesEditButtonStates;
end;

procedure TSalesDetail.SalesDataApplyButtonClick(Sender: TObject);
begin
  ProcessUpdatedSales;
end;

procedure TSalesDetail.SalesDataCalcFields(Dataset: TDataSet);
begin
  if SalesDataNotificationSent.Value then
    SalesDataNotifcationSentText.Value := '<i class="fad fa-check-square fa-fw fa-lg"></i>';

  if SalesDataEnteredId.Value > '' then
  begin
    if SalesDataClaimStatus.Value = 'Void' then
      SalesDataDisplayId.Value := '[' + SalesDataEnteredId.Value + ']'
    else
      SalesDataDisplayId.Value := SalesDataEnteredId.Value;
  end
  else if SalesDataOldRef.Value > '' then
    SalesDataDisplayId.Value := SalesDataOldRef.Value
  else if SalesDataId.Value > 0 then
    SalesDataDisplayId.Value := SalesDataId.AsString
  else
    SalesDataDisplayId.Value := '?????';

end;

function TSalesDetail.SalesDataNeedsAttention: Boolean;
begin
  if FSavingSales or FEditingMaster then
    Result := False
  else
    Result := FSalesModified or (SalesData.State in dsEditModes);
end;

procedure TSalesDetail.SalesSheetsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin

  if SalesSheets.IsEmpty then
  begin
    ModalResult := mrOK;
    FSelectedSheetId := -1;
    Exit;
  end
  else
  begin
    case FLastSheetAction of
      ssaNone:
        ;
      ssaUpdated:
        begin
          if SalesSheetsId.Value <> FSelectedSheetId then
            SalesSheets.Locate('Id', FSelectedSheetId, []);
          FLastSheetAction := ssaNone;
          SalesSheets.EnableControls;
          if SalesSheetsSheetNumber.Value = 1 then
          begin
            DataSource.DataSet.Edit;
            DataSource.DataSet.FieldByName('TotalSales').AsFloat := FNewSheet1Amount;
            DataSource.DataSet.Post;
          end;
          LoadSales;
        end;
      ssaDeleted:
      begin
        LoadSalesSheet;
      end;
    end;
    FEditingMaster := False;
  end;
end;

procedure TSalesDetail.SetSalesEditButtonStates;
var
  lIsEditing, lIsData: Boolean;
begin

  lIsData := SalesData.Active and (not SalesData.IsEmpty);
  lIsEditing := (SalesData.State in dsEditModes);

  SalesDataAddButton.Enabled := (not SalesSheets.IsEmpty) and SalesData.Active and (not lIsEditing);

  SalesDataApplyButton.Enabled := (lIsData or FSaleDeleted) and (not lIsEditing) and FSalesModified;
  SalesDataResetButton.Enabled := SalesDataApplyButton.Enabled;
end;

procedure TSalesDetail.SalesDataResetButtonClick(Sender: TObject);
begin
  if FSalesModified then
  begin
    SalesData.DisableControls;
    SalesData.Close;
    SalesData.Load;
  end
  else
    SetSalesEditButtonStates;
  ShowAnAwesomeToast('Sales', 'Your changes have been rolled back', 'fas fa-undo-alt');
end;

procedure TSalesDetail.SalesListGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: Integer;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteSalesRec(RecordId);
  end;

  procedure EditClick(Sender: TObject);
  begin
    EditSalesRec(RecordId);
  end;

var
  Span: THTMLSpan;
  lClaimStatus: TLocalClaimStatus;
begin
  if ARow = 0 then
    Exit;

  lClaimStatus := TSysHelper.StringToClaimStatus(SalesDataClaimStatus.Value);

  if (lClaimStatus in Stop_Sales_Delete) then
     Exit;

  if SalesList.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := SalesData.FieldByName('Id').AsInteger;

    Span := TSysHelper.RowActionSpan(AElement.Element, 'fad fa-edit', 'edit');
    Span.OnClick := @EditClick;

    Span := TSysHelper.RowActionSpan(AElement.Element, 'fad fa-trash', 'delete');
    Span.OnClick := @DeleteClick;

  end;

end;

procedure TSalesDetail.SalesSheetsAfterOpen(Dataset: TDataSet);
begin
  SalesSheets.EnableControls;
  FSelectedSheetId := SalesSheetsId.Value;
  LoadSales;
end;

procedure TSalesDetail.SalesSheetsBeforeScroll(Dataset: TDataSet);
begin
  if SalesDataNeedsAttention then
  begin
    SalesDetailMessageDlg.ShowDialog
      ('Please complete/roll back your changes to the sales data before changing Sales Sheet', mtWarning, [mbOK]);
    abort;
  end;
end;

procedure TSalesDetail.SalesSourceStateChange(Sender: TObject);
begin
  SetSalesEditButtonStates;
end;

procedure TSalesDetail.SheetListClickCell(Sender: TObject; ACol, ARow: Integer);
var
  SheetId: Integer;
begin
  if ARow = 0 then
    Exit;

  SheetId := SheetList.Cells[0, ARow].ToInteger;
  if SheetId <> FSelectedSheetId then
  begin
    SalesSheets.Locate('Id', SheetId, []);
    FSelectedSheetId := SheetId;
    LoadSales;
  end;

end;

procedure TSalesDetail.SheetListGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);

var
  RecordId: Integer;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteSheetRecord(RecordId);
  end;

var
  Span: THTMLSpan;
begin
  if ARow = 0 then
    Exit;

  if SheetList.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := SalesSheets.FieldByName('Id').AsInteger;
    Span := TSysHelper.RowActionSpan(AElement.Element, 'fad fa-trash', 'delete');
    Span.OnClick := @DeleteClick;
  end;
end;

procedure TSalesDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CashAmount := TDBEdit.Create('ActualCashAmount');
  CreditCards := TDBEdit.Create('CardsAmount');
  SaleDate := TDBDateTimePicker.Create('Date');
  CompletedBy := TDBEdit.Create('CompletedBy');
  TotalBanked := TDBEdit.Create('TotalBanked');
  SalesDataApplyButton := TButton.Create('SalesDataApplyButton');
  SalesDataResetButton := TButton.Create('SalesDataResetButton');
  SalesDetailMessageDlg := TMessageDlg.Create(Self);
  SalesDataAddButton := TButton.Create('SalesDataAddButton');
  SalesDetailDlg := TMessageDlg.Create(Self);
  SheetList := TDBTableControl.Create('SheetList');
  SalesList := TDBTableControl.Create('SalesList');
  ShopRef := TDBEdit.Create('ShopRef');
  SalesData := TXDataWebDataSet.Create(Self);
  SalesDataId := TIntegerField.Create(Self);
  SalesDataSheetId := TIntegerField.Create(Self);
  SalesDataPageNum := TIntegerField.Create(Self);
  SalesDataDonorId := TIntegerField.Create(Self);
  SalesDataShopRef := TStringField.Create(Self);
  SalesDataSaleDate := TDateField.Create(Self);
  SalesDataAmount := TFloatField.Create(Self);
  SalesDataCommission := TFloatField.Create(Self);
  SalesDataNetAmount := TFloatField.Create(Self);
  SalesDataClaimStatus := TStringField.Create(Self);
  SalesDataClaimId := TIntegerField.Create(Self);
  SalesDataClaimRef := TStringField.Create(Self);
  SalesDataClaimDate := TDateField.Create(Self);
  SalesDataClaimStatusChanged := TDateField.Create(Self);
  SalesDataNotificationId := TIntegerField.Create(Self);
  SalesDataRefundDate := TDateField.Create(Self);
  SalesDataRefundId := TIntegerField.Create(Self);
  SalesDataAddedBy := TIntegerField.Create(Self);
  SalesDataDateAdded := TDateTimeField.Create(Self);
  SalesDataLastUpdatedBy := TIntegerField.Create(Self);
  SalesDataLastUpdated := TDateTimeField.Create(Self);
  SalesDataNotificationDate := TDateField.Create(Self);
  SalesDataNotificationSent := TBooleanField.Create(Self);
  SalesDataNotifcationSentText := TStringField.Create(Self);
  SalesDataTaxYear := TIntegerField.Create(Self);
  SalesDataOldRef := TStringField.Create(Self);
  SalesDataEnteredId := TStringField.Create(Self);
  SalesDataDisplayId := TStringField.Create(Self);
  SalesDataSalesTaxYear := TIntegerField.Create(Self);
  SalesDataSalesYear := TIntegerField.Create(Self);
  SalesDataGiftAidValue := TFloatField.Create(Self);
  SalesDataCompanyYear := TIntegerField.Create(Self);
  SalesSource := TDataSource.Create(Self);
  SalesSheets := TXDataWebDataSet.Create(Self);
  SalesSheetsId := TIntegerField.Create(Self);
  SalesSheetsShopRef := TStringField.Create(Self);
  SalesSheetsSaleDate := TDateField.Create(Self);
  SalesSheetsTotalSales := TFloatField.Create(Self);
  SalesSheetsCreditCards := TFloatField.Create(Self);
  SalesSheetsCashAndCheques := TFloatField.Create(Self);
  SalesSheetsTotalBanked := TFloatField.Create(Self);
  SalesSheetsCompletedBy := TStringField.Create(Self);
  SalesSheetsBatchId := TIntegerField.Create(Self);
  SalesSheetsSheetNumber := TIntegerField.Create(Self);
  SalesSheetsSheetCount := TIntegerField.Create(Self);
  SalesSheetsAddedBy := TIntegerField.Create(Self);
  SalesSheetsDateAdded := TDateTimeField.Create(Self);
  SalesSheetsLastUpdatedBy := TIntegerField.Create(Self);
  SalesSheetsLastUpdated := TDateTimeField.Create(Self);
  SalesSheetSource := TDataSource.Create(Self);
  SalesDataPopUp := TPopupMenu.Create(Self);
  Edit1 := TMenuItem.Create(Self);

  MessageLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CashAmount.BeforeLoadDFMValues;
  CreditCards.BeforeLoadDFMValues;
  SaleDate.BeforeLoadDFMValues;
  CompletedBy.BeforeLoadDFMValues;
  TotalBanked.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  PriorButton.BeforeLoadDFMValues;
  NextButton.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  AuditInfo.BeforeLoadDFMValues;
  AddedByDate.BeforeLoadDFMValues;
  UpdatedByDate.BeforeLoadDFMValues;
  SalesDataApplyButton.BeforeLoadDFMValues;
  SalesDataResetButton.BeforeLoadDFMValues;
  SalesDetailMessageDlg.BeforeLoadDFMValues;
  SalesDataAddButton.BeforeLoadDFMValues;
  SalesDetailDlg.BeforeLoadDFMValues;
  SheetList.BeforeLoadDFMValues;
  SalesList.BeforeLoadDFMValues;
  ShopRef.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  SalesData.BeforeLoadDFMValues;
  SalesDataId.BeforeLoadDFMValues;
  SalesDataSheetId.BeforeLoadDFMValues;
  SalesDataPageNum.BeforeLoadDFMValues;
  SalesDataDonorId.BeforeLoadDFMValues;
  SalesDataShopRef.BeforeLoadDFMValues;
  SalesDataSaleDate.BeforeLoadDFMValues;
  SalesDataAmount.BeforeLoadDFMValues;
  SalesDataCommission.BeforeLoadDFMValues;
  SalesDataNetAmount.BeforeLoadDFMValues;
  SalesDataClaimStatus.BeforeLoadDFMValues;
  SalesDataClaimId.BeforeLoadDFMValues;
  SalesDataClaimRef.BeforeLoadDFMValues;
  SalesDataClaimDate.BeforeLoadDFMValues;
  SalesDataClaimStatusChanged.BeforeLoadDFMValues;
  SalesDataNotificationId.BeforeLoadDFMValues;
  SalesDataRefundDate.BeforeLoadDFMValues;
  SalesDataRefundId.BeforeLoadDFMValues;
  SalesDataAddedBy.BeforeLoadDFMValues;
  SalesDataDateAdded.BeforeLoadDFMValues;
  SalesDataLastUpdatedBy.BeforeLoadDFMValues;
  SalesDataLastUpdated.BeforeLoadDFMValues;
  SalesDataNotificationDate.BeforeLoadDFMValues;
  SalesDataNotificationSent.BeforeLoadDFMValues;
  SalesDataNotifcationSentText.BeforeLoadDFMValues;
  SalesDataTaxYear.BeforeLoadDFMValues;
  SalesDataOldRef.BeforeLoadDFMValues;
  SalesDataEnteredId.BeforeLoadDFMValues;
  SalesDataDisplayId.BeforeLoadDFMValues;
  SalesDataSalesTaxYear.BeforeLoadDFMValues;
  SalesDataSalesYear.BeforeLoadDFMValues;
  SalesDataGiftAidValue.BeforeLoadDFMValues;
  SalesDataCompanyYear.BeforeLoadDFMValues;
  SalesSource.BeforeLoadDFMValues;
  SalesSheets.BeforeLoadDFMValues;
  SalesSheetsId.BeforeLoadDFMValues;
  SalesSheetsShopRef.BeforeLoadDFMValues;
  SalesSheetsSaleDate.BeforeLoadDFMValues;
  SalesSheetsTotalSales.BeforeLoadDFMValues;
  SalesSheetsCreditCards.BeforeLoadDFMValues;
  SalesSheetsCashAndCheques.BeforeLoadDFMValues;
  SalesSheetsTotalBanked.BeforeLoadDFMValues;
  SalesSheetsCompletedBy.BeforeLoadDFMValues;
  SalesSheetsBatchId.BeforeLoadDFMValues;
  SalesSheetsSheetNumber.BeforeLoadDFMValues;
  SalesSheetsSheetCount.BeforeLoadDFMValues;
  SalesSheetsAddedBy.BeforeLoadDFMValues;
  SalesSheetsDateAdded.BeforeLoadDFMValues;
  SalesSheetsLastUpdatedBy.BeforeLoadDFMValues;
  SalesSheetsLastUpdated.BeforeLoadDFMValues;
  SalesSheetSource.BeforeLoadDFMValues;
  SalesDataPopUp.BeforeLoadDFMValues;
  Edit1.BeforeLoadDFMValues;
  try
    Width := 1254;
    Height := 566;
    MessageLabel.Left := 61;
    MessageLabel.Top := 35;
    CancelButton.Top := 456;
    SaveButton.Left := 32;
    SaveButton.Top := 456;
    CashAmount.SetParentComponent(Self);
    CashAmount.Name := 'CashAmount';
    CashAmount.Left := 373;
    CashAmount.Top := 100;
    CashAmount.Width := 121;
    CashAmount.Height := 22;
    CashAmount.ChildOrder := 2;
    CashAmount.EditType := weFloat;
    CashAmount.ElementClassName := 'form-control';
    CashAmount.ElementFont := efCSS;
    CashAmount.ElementPosition := epIgnore;
    CashAmount.HeightStyle := ssAuto;
    CashAmount.HeightPercent := 100.000000000000000000;
    CashAmount.ReadOnly := True;
    CashAmount.Text := 'CashAmount';
    CashAmount.WidthStyle := ssAuto;
    CashAmount.WidthPercent := 100.000000000000000000;
    CashAmount.DataField := 'CashAndCheques';
    CashAmount.DataSource := DataSource;
    CreditCards.SetParentComponent(Self);
    CreditCards.Name := 'CreditCards';
    CreditCards.Left := 511;
    CreditCards.Top := 100;
    CreditCards.Width := 121;
    CreditCards.Height := 22;
    CreditCards.ChildOrder := 3;
    CreditCards.EditType := weFloat;
    CreditCards.ElementClassName := 'form-control';
    CreditCards.ElementFont := efCSS;
    CreditCards.ElementPosition := epIgnore;
    CreditCards.HeightStyle := ssAuto;
    CreditCards.HeightPercent := 100.000000000000000000;
    CreditCards.ReadOnly := True;
    CreditCards.Text := 'CreditCards';
    CreditCards.WidthStyle := ssAuto;
    CreditCards.WidthPercent := 100.000000000000000000;
    CreditCards.DataField := 'CreditCards';
    CreditCards.DataSource := DataSource;
    SaleDate.SetParentComponent(Self);
    SaleDate.Name := 'SaleDate';
    SaleDate.Left := 168;
    SaleDate.Top := 100;
    SaleDate.Width := 170;
    SaleDate.Height := 22;
    SaleDate.ElementClassName := 'form-control';
    SaleDate.HeightStyle := ssAuto;
    SaleDate.WidthStyle := ssAuto;
    SaleDate.BorderStyle := bsSingle;
    SaleDate.ChildOrder := 1;
    SaleDate.Color := clWhite;
    SaleDate.Date := 44483.612014027780000000;
    SaleDate.ElementFont := efCSS;
    SaleDate.ElementPosition := epIgnore;
    SaleDate.ReadOnly := True;
    SaleDate.Role := '';
    SaleDate.TabOrder := 3;
    SaleDate.Text := '';
    SaleDate.DataField := 'SaleDate';
    SaleDate.DataSource := DataSource;
    CompletedBy.SetParentComponent(Self);
    CompletedBy.Name := 'CompletedBy';
    CompletedBy.Left := 246;
    CompletedBy.Top := 144;
    CompletedBy.Width := 248;
    CompletedBy.Height := 22;
    CompletedBy.ChildOrder := 7;
    CompletedBy.ElementClassName := 'form-control';
    CompletedBy.ElementFont := efCSS;
    CompletedBy.ElementPosition := epIgnore;
    CompletedBy.HeightStyle := ssAuto;
    CompletedBy.HeightPercent := 100.000000000000000000;
    CompletedBy.ReadOnly := True;
    CompletedBy.Text := 'CompletedBy';
    CompletedBy.WidthStyle := ssAuto;
    CompletedBy.WidthPercent := 100.000000000000000000;
    CompletedBy.DataField := 'CompletedBy';
    CompletedBy.DataSource := DataSource;
    TotalBanked.SetParentComponent(Self);
    TotalBanked.Name := 'TotalBanked';
    TotalBanked.Left := 655;
    TotalBanked.Top := 100;
    TotalBanked.Width := 121;
    TotalBanked.Height := 22;
    TotalBanked.ChildOrder := 3;
    TotalBanked.EditType := weFloat;
    TotalBanked.ElementClassName := 'form-control';
    TotalBanked.ElementFont := efCSS;
    TotalBanked.ElementPosition := epIgnore;
    TotalBanked.Enabled := False;
    TotalBanked.HeightStyle := ssAuto;
    TotalBanked.HeightPercent := 100.000000000000000000;
    TotalBanked.ReadOnly := True;
    TotalBanked.Text := 'TotalBanked';
    TotalBanked.WidthStyle := ssAuto;
    TotalBanked.WidthPercent := 100.000000000000000000;
    TotalBanked.DataField := 'TotalBanked';
    TotalBanked.DataSource := DataSource;
    DataState.Left := 52;
    DataState.Top := 54;
    PriorButton.Left := 168;
    PriorButton.Top := 54;
    NextButton.Left := 207;
    NextButton.Top := 54;
    WaitMessage.Left := 160;
    WaitMessage.Top := 496;
    AuditInfo.Left := 976;
    AuditInfo.Top := 487;
    AddedByDate.Left := 18;
    AddedByDate.Top := 24;
    UpdatedByDate.Top := 24;
    SalesDataApplyButton.SetParentComponent(Self);
    SalesDataApplyButton.Name := 'SalesDataApplyButton';
    SalesDataApplyButton.Left := 490;
    SalesDataApplyButton.Top := 424;
    SalesDataApplyButton.Width := 50;
    SalesDataApplyButton.Height := 25;
    SalesDataApplyButton.Hint := 'save changes to sales to database ';
    SalesDataApplyButton.Caption := 'Apply';
    SalesDataApplyButton.ChildOrder := 18;
    SalesDataApplyButton.ElementClassName := 'btn btn-outline-primary btn-small';
    SalesDataApplyButton.ElementFont := efCSS;
    SalesDataApplyButton.ElementPosition := epIgnore;
    SalesDataApplyButton.Enabled := False;
    SalesDataApplyButton.HeightStyle := ssAuto;
    SalesDataApplyButton.HeightPercent := 100.000000000000000000;
    SalesDataApplyButton.ShowHint := True;
    SalesDataApplyButton.WidthStyle := ssAuto;
    SalesDataApplyButton.WidthPercent := 100.000000000000000000;
    SetEvent(SalesDataApplyButton, Self, 'OnClick', 'SalesDataApplyButtonClick');
    SalesDataResetButton.SetParentComponent(Self);
    SalesDataResetButton.Name := 'SalesDataResetButton';
    SalesDataResetButton.Left := 575;
    SalesDataResetButton.Top := 424;
    SalesDataResetButton.Width := 50;
    SalesDataResetButton.Height := 25;
    SalesDataResetButton.Hint := 'undo all changes not saved to the database';
    SalesDataResetButton.Caption := 'Reset';
    SalesDataResetButton.ChildOrder := 18;
    SalesDataResetButton.ElementClassName := 'btn btn-outline-primary btn-small';
    SalesDataResetButton.ElementFont := efCSS;
    SalesDataResetButton.ElementPosition := epIgnore;
    SalesDataResetButton.Enabled := False;
    SalesDataResetButton.HeightStyle := ssAuto;
    SalesDataResetButton.HeightPercent := 100.000000000000000000;
    SalesDataResetButton.ShowHint := True;
    SalesDataResetButton.WidthStyle := ssAuto;
    SalesDataResetButton.WidthPercent := 100.000000000000000000;
    SetEvent(SalesDataResetButton, Self, 'OnClick', 'SalesDataResetButtonClick');
    SalesDetailMessageDlg.SetParentComponent(Self);
    SalesDetailMessageDlg.Name := 'SalesDetailMessageDlg';
    SalesDetailMessageDlg.Left := 768;
    SalesDetailMessageDlg.Top := 232;
    SalesDetailMessageDlg.Width := 24;
    SalesDetailMessageDlg.Height := 24;
    SalesDetailMessageDlg.HeightStyle := ssAuto;
    SalesDetailMessageDlg.WidthStyle := ssAuto;
    SalesDetailMessageDlg.Buttons := [];
    SalesDetailMessageDlg.Opacity := 0.200000000000000000;
    SalesDetailMessageDlg.ElementButtonClassName := 'btn';
    SalesDetailMessageDlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    SalesDetailMessageDlg.ElementTitleClassName := 'text-body';
    SalesDetailMessageDlg.ElementContentClassName := 'text-body';
    SalesDataAddButton.SetParentComponent(Self);
    SalesDataAddButton.Name := 'SalesDataAddButton';
    SalesDataAddButton.Left := 412;
    SalesDataAddButton.Top := 424;
    SalesDataAddButton.Width := 33;
    SalesDataAddButton.Height := 25;
    SalesDataAddButton.Hint := 'add a new sales record';
    SalesDataAddButton.Caption := 'Add';
    SalesDataAddButton.ChildOrder := 18;
    SalesDataAddButton.ElementClassName := 'btn btn-outline-primary btn-small';
    SalesDataAddButton.ElementFont := efCSS;
    SalesDataAddButton.ElementPosition := epIgnore;
    SalesDataAddButton.HeightStyle := ssAuto;
    SalesDataAddButton.HeightPercent := 100.000000000000000000;
    SalesDataAddButton.ShowHint := True;
    SalesDataAddButton.WidthStyle := ssAuto;
    SalesDataAddButton.WidthPercent := 100.000000000000000000;
    SetEvent(SalesDataAddButton, Self, 'OnClick', 'SalesDataAddButtonClick');
    SalesDetailDlg.SetParentComponent(Self);
    SalesDetailDlg.Name := 'SalesDetailDlg';
    SalesDetailDlg.Left := 688;
    SalesDetailDlg.Top := 82;
    SalesDetailDlg.Width := 24;
    SalesDetailDlg.Height := 24;
    SalesDetailDlg.HeightStyle := ssAuto;
    SalesDetailDlg.WidthStyle := ssAuto;
    SalesDetailDlg.Buttons := [];
    SalesDetailDlg.DialogType := mtConfirmation;
    SalesDetailDlg.Opacity := 0.200000000000000000;
    SalesDetailDlg.ElementButtonClassName := 'btn btn-primary';
    SalesDetailDlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    SalesDetailDlg.ElementTitleClassName := 'text-body';
    SalesDetailDlg.ElementContentClassName := 'text-body';
    SheetList.SetParentComponent(Self);
    SheetList.Name := 'SheetList';
    SheetList.Left := 16;
    SheetList.Top := 184;
    SheetList.Width := 300;
    SheetList.Height := 200;
    SheetList.HeightStyle := ssAuto;
    SheetList.WidthStyle := ssAuto;
    SheetList.BorderColor := clSilver;
    SheetList.ChildOrder := 26;
    SheetList.ElementFont := efCSS;
    SheetList.ElementHeaderClassName := 'thead-light';
    SheetList.ElementPosition := epIgnore;
    SheetList.ElementRowSelectClassName := 'BrandHighlight';
    SheetList.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(SheetList, Self, 'OnClickCell', 'SheetListClickCell');
    SetEvent(SheetList, Self, 'OnGetCellChildren', 'SheetListGetCellChildren');
    SheetList.Columns.Clear;
    with SheetList.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with SheetList.Columns.Add do
    begin
      DataField := 'BatchId';
      Title := 'Batch';
    end;
    with SheetList.Columns.Add do
    begin
      DataField := 'SheetNumber';
      Title := 'Sheet Number';
    end;
    with SheetList.Columns.Add do
    begin
      DataField := 'TotalSales';
      Title := 'Sales';
    end;
    with SheetList.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SheetList.DataSource := SalesSheetSource;
    SalesList.SetParentComponent(Self);
    SalesList.Name := 'SalesList';
    SalesList.Left := 337;
    SalesList.Top := 172;
    SalesList.Width := 425;
    SalesList.Height := 232;
    SalesList.HeightStyle := ssAuto;
    SalesList.WidthStyle := ssAuto;
    SalesList.BorderColor := clSilver;
    SalesList.ChildOrder := 26;
    SalesList.ElementFont := efCSS;
    SalesList.ElementHeaderClassName := 'thead-light';
    SalesList.ElementPosition := epIgnore;
    SalesList.ElementRowSelectClassName := 'BrandHighlight';
    SalesList.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(SalesList, Self, 'OnGetCellChildren', 'SalesListGetCellChildren');
    SalesList.Columns.Clear;
    with SalesList.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Sales Id';
    end;
    with SalesList.Columns.Add do
    begin
      DataField := 'DisplayId';
      Title := 'Donor Id';
    end;
    with SalesList.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with SalesList.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Claim Status';
    end;
    with SalesList.Columns.Add do
    begin
      DataField := 'SheetId';
      Title := 'Sheet Id';
    end;
    with SalesList.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SalesList.DataSource := SalesSource;
    ShopRef.SetParentComponent(Self);
    ShopRef.Name := 'ShopRef';
    ShopRef.Left := 500;
    ShopRef.Top := 144;
    ShopRef.Width := 248;
    ShopRef.Height := 22;
    ShopRef.ChildOrder := 7;
    ShopRef.ElementClassName := 'form-control';
    ShopRef.ElementFont := efCSS;
    ShopRef.ElementPosition := epIgnore;
    ShopRef.HeightStyle := ssAuto;
    ShopRef.HeightPercent := 100.000000000000000000;
    ShopRef.ReadOnly := True;
    ShopRef.Text := 'ShopName';
    ShopRef.WidthStyle := ssAuto;
    ShopRef.WidthPercent := 100.000000000000000000;
    ShopRef.DataField := 'ShopName';
    ShopRef.DataSource := DataSource;
    DataSource.AutoEdit := False;
    DataSource.Left := 360;
    DataSource.Top := 24;
    EditElements.Top := 16;
    CloseTimer.Left := 80;
    CloseTimer.Top := 488;
    SalesData.SetParentComponent(Self);
    SalesData.Name := 'SalesData';
    SalesData.AfterApplyUpdates := SalesDataAfterApplyUpdates;
    SalesData.AfterOpen := SalesDataAfterOpen;
    SalesData.AfterPost := SalesDataAfterPost;
    SalesData.AfterDelete := SalesDataAfterDelete;
    SetEvent(SalesData, Self, 'OnCalcFields', 'SalesDataCalcFields');
    SalesData.EntitySetName := 'Sale';
    SalesData.Connection := MainData.DataConnection;
    SalesData.Left := 504;
    SalesData.Top := 40;
    SalesDataId.SetParentComponent(SalesData);
    SalesDataId.Name := 'SalesDataId';
    SalesDataId.FieldName := 'Id';
    SalesDataSheetId.SetParentComponent(SalesData);
    SalesDataSheetId.Name := 'SalesDataSheetId';
    SalesDataSheetId.FieldName := 'SheetId';
    SalesDataPageNum.SetParentComponent(SalesData);
    SalesDataPageNum.Name := 'SalesDataPageNum';
    SalesDataPageNum.FieldName := 'PageNum';
    SalesDataDonorId.SetParentComponent(SalesData);
    SalesDataDonorId.Name := 'SalesDataDonorId';
    SalesDataDonorId.FieldName := 'DonorId';
    SalesDataDonorId.ReadOnly := True;
    SalesDataShopRef.SetParentComponent(SalesData);
    SalesDataShopRef.Name := 'SalesDataShopRef';
    SalesDataShopRef.FieldName := 'ShopRef';
    SalesDataShopRef.Size := 10;
    SalesDataSaleDate.SetParentComponent(SalesData);
    SalesDataSaleDate.Name := 'SalesDataSaleDate';
    SalesDataSaleDate.FieldName := 'SaleDate';
    SalesDataAmount.SetParentComponent(SalesData);
    SalesDataAmount.Name := 'SalesDataAmount';
    SalesDataAmount.FieldName := 'Amount';
    SalesDataAmount.ReadOnly := True;
    SalesDataAmount.DisplayFormat := '#,##0.00';
    SalesDataAmount.EditFormat := '#0.00';
    SalesDataCommission.SetParentComponent(SalesData);
    SalesDataCommission.Name := 'SalesDataCommission';
    SalesDataCommission.FieldName := 'Commission';
    SalesDataNetAmount.SetParentComponent(SalesData);
    SalesDataNetAmount.Name := 'SalesDataNetAmount';
    SalesDataNetAmount.FieldName := 'NetAmount';
    SalesDataClaimStatus.SetParentComponent(SalesData);
    SalesDataClaimStatus.Name := 'SalesDataClaimStatus';
    SalesDataClaimStatus.FieldName := 'ClaimStatus';
    SalesDataClaimStatus.ReadOnly := True;
    SalesDataClaimStatus.Size := 18;
    SalesDataClaimId.SetParentComponent(SalesData);
    SalesDataClaimId.Name := 'SalesDataClaimId';
    SalesDataClaimId.FieldName := 'ClaimId';
    SalesDataClaimRef.SetParentComponent(SalesData);
    SalesDataClaimRef.Name := 'SalesDataClaimRef';
    SalesDataClaimRef.FieldName := 'ClaimRef';
    SalesDataClaimRef.Size := 255;
    SalesDataClaimDate.SetParentComponent(SalesData);
    SalesDataClaimDate.Name := 'SalesDataClaimDate';
    SalesDataClaimDate.FieldName := 'ClaimDate';
    SalesDataClaimStatusChanged.SetParentComponent(SalesData);
    SalesDataClaimStatusChanged.Name := 'SalesDataClaimStatusChanged';
    SalesDataClaimStatusChanged.FieldName := 'ClaimStatusChanged';
    SalesDataNotificationId.SetParentComponent(SalesData);
    SalesDataNotificationId.Name := 'SalesDataNotificationId';
    SalesDataNotificationId.FieldName := 'NotificationId';
    SalesDataRefundDate.SetParentComponent(SalesData);
    SalesDataRefundDate.Name := 'SalesDataRefundDate';
    SalesDataRefundDate.FieldName := 'RefundDate';
    SalesDataRefundId.SetParentComponent(SalesData);
    SalesDataRefundId.Name := 'SalesDataRefundId';
    SalesDataRefundId.FieldName := 'RefundId';
    SalesDataAddedBy.SetParentComponent(SalesData);
    SalesDataAddedBy.Name := 'SalesDataAddedBy';
    SalesDataAddedBy.FieldName := 'AddedBy';
    SalesDataDateAdded.SetParentComponent(SalesData);
    SalesDataDateAdded.Name := 'SalesDataDateAdded';
    SalesDataDateAdded.FieldName := 'DateAdded';
    SalesDataLastUpdatedBy.SetParentComponent(SalesData);
    SalesDataLastUpdatedBy.Name := 'SalesDataLastUpdatedBy';
    SalesDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesDataLastUpdated.SetParentComponent(SalesData);
    SalesDataLastUpdated.Name := 'SalesDataLastUpdated';
    SalesDataLastUpdated.FieldName := 'LastUpdated';
    SalesDataNotificationDate.SetParentComponent(SalesData);
    SalesDataNotificationDate.Name := 'SalesDataNotificationDate';
    SalesDataNotificationDate.FieldName := 'NotificationDate';
    SalesDataNotificationSent.SetParentComponent(SalesData);
    SalesDataNotificationSent.Name := 'SalesDataNotificationSent';
    SalesDataNotificationSent.FieldName := 'NotificationSent';
    SalesDataNotifcationSentText.SetParentComponent(SalesData);
    SalesDataNotifcationSentText.Name := 'SalesDataNotifcationSentText';
    SalesDataNotifcationSentText.FieldKind := fkCalculated;
    SalesDataNotifcationSentText.FieldName := 'NotifcationSentText';
    SalesDataNotifcationSentText.Size := 25;
    SalesDataNotifcationSentText.Calculated := True;
    SalesDataTaxYear.SetParentComponent(SalesData);
    SalesDataTaxYear.Name := 'SalesDataTaxYear';
    SalesDataTaxYear.FieldName := 'TaxYear';
    SalesDataTaxYear.ReadOnly := True;
    SalesDataOldRef.SetParentComponent(SalesData);
    SalesDataOldRef.Name := 'SalesDataOldRef';
    SalesDataOldRef.FieldName := 'OldRef';
    SalesDataOldRef.Size := 10;
    SalesDataEnteredId.SetParentComponent(SalesData);
    SalesDataEnteredId.Name := 'SalesDataEnteredId';
    SalesDataEnteredId.FieldName := 'EnteredId';
    SalesDataEnteredId.Size := 255;
    SalesDataDisplayId.SetParentComponent(SalesData);
    SalesDataDisplayId.Name := 'SalesDataDisplayId';
    SalesDataDisplayId.FieldKind := fkCalculated;
    SalesDataDisplayId.FieldName := 'DisplayId';
    SalesDataDisplayId.ReadOnly := True;
    SalesDataDisplayId.Size := 25;
    SalesDataDisplayId.Calculated := True;
    SalesDataSalesTaxYear.SetParentComponent(SalesData);
    SalesDataSalesTaxYear.Name := 'SalesDataSalesTaxYear';
    SalesDataSalesTaxYear.FieldName := 'SalesTaxYear';
    SalesDataSalesYear.SetParentComponent(SalesData);
    SalesDataSalesYear.Name := 'SalesDataSalesYear';
    SalesDataSalesYear.FieldName := 'SalesYear';
    SalesDataGiftAidValue.SetParentComponent(SalesData);
    SalesDataGiftAidValue.Name := 'SalesDataGiftAidValue';
    SalesDataGiftAidValue.FieldName := 'GiftAidValue';
    SalesDataCompanyYear.SetParentComponent(SalesData);
    SalesDataCompanyYear.Name := 'SalesDataCompanyYear';
    SalesDataCompanyYear.FieldName := 'CompanyYear';
    SalesSource.SetParentComponent(Self);
    SalesSource.Name := 'SalesSource';
    SalesSource.AutoEdit := False;
    SalesSource.DataSet := SalesData;
    SetEvent(SalesSource, Self, 'OnStateChange', 'SalesSourceStateChange');
    SalesSource.Left := 576;
    SalesSource.Top := 40;
    SalesSheets.SetParentComponent(Self);
    SalesSheets.Name := 'SalesSheets';
    SalesSheets.AfterApplyUpdates := SalesSheetsAfterApplyUpdates;
    SalesSheets.AfterOpen := SalesSheetsAfterOpen;
    SalesSheets.BeforeScroll := SalesSheetsBeforeScroll;
    SalesSheets.EntitySetName := 'SalesSheet';
    SalesSheets.Connection := MainData.DataConnection;
    SalesSheets.Left := 24;
    SalesSheets.Top := 120;
    SalesSheetsId.SetParentComponent(SalesSheets);
    SalesSheetsId.Name := 'SalesSheetsId';
    SalesSheetsId.FieldName := 'Id';
    SalesSheetsShopRef.SetParentComponent(SalesSheets);
    SalesSheetsShopRef.Name := 'SalesSheetsShopRef';
    SalesSheetsShopRef.FieldName := 'ShopRef';
    SalesSheetsShopRef.Size := 10;
    SalesSheetsSaleDate.SetParentComponent(SalesSheets);
    SalesSheetsSaleDate.Name := 'SalesSheetsSaleDate';
    SalesSheetsSaleDate.FieldName := 'SaleDate';
    SalesSheetsSaleDate.DisplayFormat := 'dd/mm/yyyy';
    SalesSheetsTotalSales.SetParentComponent(SalesSheets);
    SalesSheetsTotalSales.Name := 'SalesSheetsTotalSales';
    SalesSheetsTotalSales.FieldName := 'TotalSales';
    SalesSheetsTotalSales.DisplayFormat := '#,##0.00';
    SalesSheetsCreditCards.SetParentComponent(SalesSheets);
    SalesSheetsCreditCards.Name := 'SalesSheetsCreditCards';
    SalesSheetsCreditCards.FieldName := 'CreditCards';
    SalesSheetsCreditCards.DisplayFormat := '#,##0.00';
    SalesSheetsCashAndCheques.SetParentComponent(SalesSheets);
    SalesSheetsCashAndCheques.Name := 'SalesSheetsCashAndCheques';
    SalesSheetsCashAndCheques.FieldName := 'CashAndCheques';
    SalesSheetsCashAndCheques.DisplayFormat := '#,##0.00';
    SalesSheetsTotalBanked.SetParentComponent(SalesSheets);
    SalesSheetsTotalBanked.Name := 'SalesSheetsTotalBanked';
    SalesSheetsTotalBanked.FieldName := 'TotalBanked';
    SalesSheetsTotalBanked.DisplayFormat := '#,##0.00';
    SalesSheetsCompletedBy.SetParentComponent(SalesSheets);
    SalesSheetsCompletedBy.Name := 'SalesSheetsCompletedBy';
    SalesSheetsCompletedBy.FieldName := 'CompletedBy';
    SalesSheetsCompletedBy.Size := 50;
    SalesSheetsBatchId.SetParentComponent(SalesSheets);
    SalesSheetsBatchId.Name := 'SalesSheetsBatchId';
    SalesSheetsBatchId.FieldName := 'BatchId';
    SalesSheetsSheetNumber.SetParentComponent(SalesSheets);
    SalesSheetsSheetNumber.Name := 'SalesSheetsSheetNumber';
    SalesSheetsSheetNumber.FieldName := 'SheetNumber';
    SalesSheetsSheetCount.SetParentComponent(SalesSheets);
    SalesSheetsSheetCount.Name := 'SalesSheetsSheetCount';
    SalesSheetsSheetCount.FieldName := 'SheetCount';
    SalesSheetsAddedBy.SetParentComponent(SalesSheets);
    SalesSheetsAddedBy.Name := 'SalesSheetsAddedBy';
    SalesSheetsAddedBy.FieldName := 'AddedBy';
    SalesSheetsDateAdded.SetParentComponent(SalesSheets);
    SalesSheetsDateAdded.Name := 'SalesSheetsDateAdded';
    SalesSheetsDateAdded.FieldName := 'DateAdded';
    SalesSheetsLastUpdatedBy.SetParentComponent(SalesSheets);
    SalesSheetsLastUpdatedBy.Name := 'SalesSheetsLastUpdatedBy';
    SalesSheetsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesSheetsLastUpdated.SetParentComponent(SalesSheets);
    SalesSheetsLastUpdated.Name := 'SalesSheetsLastUpdated';
    SalesSheetsLastUpdated.FieldName := 'LastUpdated';
    SalesSheetSource.SetParentComponent(Self);
    SalesSheetSource.Name := 'SalesSheetSource';
    SalesSheetSource.DataSet := SalesSheets;
    SalesSheetSource.Left := 104;
    SalesSheetSource.Top := 120;
    SalesDataPopUp.SetParentComponent(Self);
    SalesDataPopUp.Name := 'SalesDataPopUp';
    SalesDataPopUp.Appearance.HamburgerMenu.Caption := 'Menu';
    SalesDataPopUp.Appearance.SubmenuIndicator := '&#9658;';
    SalesDataPopUp.Font.Charset := DEFAULT_CHARSET;
    SalesDataPopUp.Font.Color := clWindowText;
    SalesDataPopUp.Font.Height := -12;
    SalesDataPopUp.Font.Name := 'Segoe UI';
    SalesDataPopUp.Font.Style := [];
    SalesDataPopUp.Left := 896;
    SalesDataPopUp.Top := 136;
    Edit1.SetParentComponent(SalesDataPopUp);
    Edit1.Name := 'Edit1';
    Edit1.Caption := 'Edit';
  finally
    MessageLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CashAmount.AfterLoadDFMValues;
    CreditCards.AfterLoadDFMValues;
    SaleDate.AfterLoadDFMValues;
    CompletedBy.AfterLoadDFMValues;
    TotalBanked.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    PriorButton.AfterLoadDFMValues;
    NextButton.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    AuditInfo.AfterLoadDFMValues;
    AddedByDate.AfterLoadDFMValues;
    UpdatedByDate.AfterLoadDFMValues;
    SalesDataApplyButton.AfterLoadDFMValues;
    SalesDataResetButton.AfterLoadDFMValues;
    SalesDetailMessageDlg.AfterLoadDFMValues;
    SalesDataAddButton.AfterLoadDFMValues;
    SalesDetailDlg.AfterLoadDFMValues;
    SheetList.AfterLoadDFMValues;
    SalesList.AfterLoadDFMValues;
    ShopRef.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
    SalesData.AfterLoadDFMValues;
    SalesDataId.AfterLoadDFMValues;
    SalesDataSheetId.AfterLoadDFMValues;
    SalesDataPageNum.AfterLoadDFMValues;
    SalesDataDonorId.AfterLoadDFMValues;
    SalesDataShopRef.AfterLoadDFMValues;
    SalesDataSaleDate.AfterLoadDFMValues;
    SalesDataAmount.AfterLoadDFMValues;
    SalesDataCommission.AfterLoadDFMValues;
    SalesDataNetAmount.AfterLoadDFMValues;
    SalesDataClaimStatus.AfterLoadDFMValues;
    SalesDataClaimId.AfterLoadDFMValues;
    SalesDataClaimRef.AfterLoadDFMValues;
    SalesDataClaimDate.AfterLoadDFMValues;
    SalesDataClaimStatusChanged.AfterLoadDFMValues;
    SalesDataNotificationId.AfterLoadDFMValues;
    SalesDataRefundDate.AfterLoadDFMValues;
    SalesDataRefundId.AfterLoadDFMValues;
    SalesDataAddedBy.AfterLoadDFMValues;
    SalesDataDateAdded.AfterLoadDFMValues;
    SalesDataLastUpdatedBy.AfterLoadDFMValues;
    SalesDataLastUpdated.AfterLoadDFMValues;
    SalesDataNotificationDate.AfterLoadDFMValues;
    SalesDataNotificationSent.AfterLoadDFMValues;
    SalesDataNotifcationSentText.AfterLoadDFMValues;
    SalesDataTaxYear.AfterLoadDFMValues;
    SalesDataOldRef.AfterLoadDFMValues;
    SalesDataEnteredId.AfterLoadDFMValues;
    SalesDataDisplayId.AfterLoadDFMValues;
    SalesDataSalesTaxYear.AfterLoadDFMValues;
    SalesDataSalesYear.AfterLoadDFMValues;
    SalesDataGiftAidValue.AfterLoadDFMValues;
    SalesDataCompanyYear.AfterLoadDFMValues;
    SalesSource.AfterLoadDFMValues;
    SalesSheets.AfterLoadDFMValues;
    SalesSheetsId.AfterLoadDFMValues;
    SalesSheetsShopRef.AfterLoadDFMValues;
    SalesSheetsSaleDate.AfterLoadDFMValues;
    SalesSheetsTotalSales.AfterLoadDFMValues;
    SalesSheetsCreditCards.AfterLoadDFMValues;
    SalesSheetsCashAndCheques.AfterLoadDFMValues;
    SalesSheetsTotalBanked.AfterLoadDFMValues;
    SalesSheetsCompletedBy.AfterLoadDFMValues;
    SalesSheetsBatchId.AfterLoadDFMValues;
    SalesSheetsSheetNumber.AfterLoadDFMValues;
    SalesSheetsSheetCount.AfterLoadDFMValues;
    SalesSheetsAddedBy.AfterLoadDFMValues;
    SalesSheetsDateAdded.AfterLoadDFMValues;
    SalesSheetsLastUpdatedBy.AfterLoadDFMValues;
    SalesSheetsLastUpdated.AfterLoadDFMValues;
    SalesSheetSource.AfterLoadDFMValues;
    SalesDataPopUp.AfterLoadDFMValues;
    Edit1.AfterLoadDFMValues;
  end;
end;

end.
