unit Dashboard.ReturnTypes;

interface

uses smx.Reports.Types;

type

TDashboardBase = class
  ReportId: Integer;
  MyId: Integer;
  DisplayType: TDashboardDisplay;
  Title: String;
  SubTitle: String;
  Data: String;
  Config: String;
  Refresh: TRefreshFreq;
  LastRefresh: TDatetime;
  Status: TDashboardItemStatus;
  TableProcess: TReportProc;
end;




implementation

end.
