unit DonorForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg,
  AppUtils;

type
  TDonorEditForm = class(TBaseEditForm)
    edFirstName: TDBEdit;
    edLastName: TDBEdit;
    edAdd1: TDBEdit;
    edAdd2: TDBEdit;
    edAdd3: TDBEdit;
    edCity: TDBEdit;
    edPostCode: TDBEdit;
    edEmail: TDBEdit;
    edMobile: TDBEdit;
    edTelephone: TDBEdit;
    DonorIdLabel: TDBLabel;
    OldRefLabel: TDBLabel;
    ScannedDocsExist: TDBCheckBox;
    ClaimStatus: TDBLookupComboBox;
    GiftAidScheme: TDBLabel;
    NotifyAllSales: TCheckBox;
    Comments: TDBEdit;
    DateSigned: TDBDateTimePicker;
    ContactByPhone: TDBCheckBox;
    ContactByEmail: TDBCheckBox;
    ContactByText: TDBCheckBox;
    ContactByPost: TDBCheckBox;
    ShopNameLabel: TDBLabel;
    ConsIdLabel: TDBLabel;
    Title: TDBComboBox;
    LastStatement: TDBLabel;
    procedure ClaimStatusChange(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure edPostCodeExit(Sender: TObject);
    procedure NotifyAllSalesClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FNeedsFullRefresh: Boolean;
    { Private declarations }

    FNewId: Integer;
    FOldId: Integer;

    FRefreshMessage: string;

    [async]
    procedure EditId(const AWhichId: TDonorIdType); async;
    [async]
    procedure EditConsId; async;
    [async]
    procedure EditShopRef; async;
    [async]
    function UpdateDonorId(const AOldId, ANewId: Integer): Boolean; async;
    [async]
    function AllowedToChangeId(const AOldId: Integer): Boolean; async;
    procedure AfterSetDataset; override;
    procedure ValidatePostCode;
    function AnyContactAllowed: Boolean;
    procedure ConsIdClick(Sender: TObject);
    procedure DonorIdClick(Sender: TObject);
    procedure OldRefClick(Sender: TObject);
    procedure ShopRefClick(Sender: TObject);

  protected
    function DisplayName: string;
    function SaveTitle(const AStatus: TUpdateStatus): string; override;
    function SaveDescription(const AStatus: TUpdateStatus): string; override;
    function GetRequiredEditLevel: string; override;
    procedure BeforePostRecord; override;

  public
    { Public declarations }
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); override;
    property NeedsFullRefresh: Boolean read FNeedsFullRefresh write FNeedsFullRefresh;
    property RefreshMessage: string read FRefreshMessage write FRefreshMessage;
    property NewId: Integer read FNewId write FNewId;
    property OldId: Integer read FOldId write FOldId;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  MainDataModule,
  DonorList,
  GA.Shop.Shared,
  Auth.Service,
  DonorIdEditForm,
  ConsIdEditForm,
  SelectShopForm;

{$R *.dfm}

procedure TDonorEditForm.AfterSetDataset;
var
  lCStatus: string;
begin
  inherited;
  // Not sure why we need to do this
  lCStatus := Dataset.FieldByName('ClaimStatus').AsString;
  ClaimStatus.Value := lCStatus;
  Comments.Visible := (ClaimStatus.Value = 'Other');

  if AuthService.IsAdministrator then
  begin

    if Dataset.FieldByName('OldRef').AsString <> '' then
    begin
      OldRefLabel.OnClick := OldRefClick;
      OldRefLabel.ElementClassName := 'clickable';
      OldRefLabel.Cursor := crHandPoint;
    end
    else
    begin
      DonorIdLabel.OnClick := DonorIdClick;
      DonorIdLabel.ElementClassName := 'clickable';
      DonorIdLabel.Cursor := crHandPoint;
    end;

    ConsIdLabel.OnClick := ConsIdClick;
    ConsIdLabel.ElementClassName := 'clickable';
    ConsIdLabel.Cursor := crHandPoint;

    ShopNameLabel.OnClick := ShopRefClick;
    ShopNameLabel.ElementClassName := 'clickable';
    ShopNameLabel.Cursor := crHandPoint;
  end;
end;

function TDonorEditForm.AllowedToChangeId(const AOldId: Integer): Boolean;
var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lValue: Integer;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_CLAIMEDSALES_COUNT, [AOldId]));
  lResult := lRetval.ResultAsObject;
  lValue := JS.ToInteger(lResult['value']);
  Result := (lValue = 0);
  if not Result then
  begin
    MessageDlg('You cannot change the id for a donor that has claimed or processed sales', mtWarning, [mbOk]);
  end;
end;

function TDonorEditForm.AnyContactAllowed: Boolean;
begin
  result := ContactByEmail.Checked or ContactByPhone.Checked or ContactByPost.Checked or ContactByText.Checked;
end;

procedure TDonorEditForm.BeforePostRecord;
var
  lTitle: string;
  lDateSigned: TDate;
begin
  inherited;

  lTitle := Title.Text;
  lDateSigned := DateSigned.Date;

  Dataset.FieldByName('Title').AsString := lTitle;
  Dataset.FieldByName('DateSigned').AsDateTime := lDateSigned;

  Dataset.FieldByName('GiftAidScheme').AsString := GiftAid_Scheme[NotifyAllSales.Checked];
  Dataset.FieldByName('DateSigned').AsDateTime := lDateSigned;

  Dataset.FieldByName('ScannedDocsExist').AsBoolean := ScannedDocsExist.Checked;

  Dataset.FieldByName('ContactByPhone').AsBoolean := ContactByPhone.Checked;
  Dataset.FieldByName('ContactByPost').AsBoolean := ContactByPost.Checked;
  Dataset.FieldByName('ContactByText').AsBoolean := ContactByText.Checked;
  Dataset.FieldByName('ContactByEmail').AsBoolean := ContactByEmail.Checked;
  Dataset.FieldByName('FurtherContact').AsBoolean := AnyContactAllowed;

end;

procedure TDonorEditForm.ClaimStatusChange(Sender: TObject);
begin
  Comments.Visible := (ClaimStatus.Value = 'Other');
end;

procedure TDonorEditForm.ConsIdClick(Sender: TObject);
begin
  EditConsId;
end;

procedure TDonorEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;
  if Field = nil then
  begin
    NotifyAllSales.Checked := Datasource.Dataset.FieldByName('GiftAidScheme').AsString = GiftAid_Scheme[True];
    FormCaption.Caption := DisplayName;
  end;
end;

function TDonorEditForm.DisplayName: string;
begin
  result := Datasource.Dataset.FieldByName('FirstName').AsString + ' ' + Datasource.Dataset.FieldByName
    ('LastName').AsString;
end;

procedure TDonorEditForm.DonorIdClick(Sender: TObject);
begin
  EditId(ditId);
end;

procedure TDonorEditForm.EditConsId;
var
  lDialog: TConsIdEditDialog;
  lResult: TModalResult;
begin
  lDialog := TConsIdEditDialog.Create(Self);
  try
    FPopUpShowing := True;
    lDialog.Popup := True;
    lDialog.PopupOpacity := 1;
    await(TForm, lDialog.Load());
    lDialog.Dataset := Dataset;

    lResult := await(TModalResult, lDialog.Execute);

    if (lResult = mrCancel) then
    begin
      if (Dataset.State in dsEditModes) then
        Dataset.Cancel;
    end
    else
    begin
      if (Dataset.State in dsEditModes) then
        Dataset.Post;
    end;
  finally
    FPopUpShowing := False;
    lDialog.Free;
    lDialog := nil;
  end;
end;

procedure TDonorEditForm.EditId(const AWhichId: TDonorIdType);
var
  lDialog: TDonorIdEditDialog;
  lResult: TModalResult;
  lOutcome, lCanChange: Boolean;
  lId: string;
begin

  FOldId := Dataset.FieldByName('Id').AsInteger;

  if AWhichId = TDonorIdType.ditId then
  begin
    lCanChange := await(AllowedToChangeId(FOldId));
    if not lCanChange then
       Exit;
  end;

  lDialog := TDonorIdEditDialog.Create(Self);
  try
    FPopUpShowing := True;
    lDialog.Popup := True;
    lDialog.PopupOpacity := 1;
    await(TForm, lDialog.Load());
    lDialog.IdType := AWhichId;
    lDialog.Dataset := Dataset;

    lResult := await(TModalResult, lDialog.Execute);

  finally
    FPopUpShowing := False;
    lDialog.Free;
    lDialog := nil;
  end;

  if (lResult = mrCancel) then
  begin
    if (Dataset.State in dsEditModes) then
      Dataset.Cancel;
  end
  else
  begin
    if AWhichId = TDonorIdType.ditId then
    begin
      FNewId := Dataset.FieldByName('Id').AsInteger;
      lOutcome := await(UpdateDonorId(FOldId, FNewId));
      if lOutcome then
      begin
        if (Dataset.State in dsEditModes) then
          Dataset.Cancel;
        FNeedsFullRefresh := True;
        FRefreshMessage := Format('Donor Id changed from %d to %d successfully', [FOldId, FNewId]);
        ModalResult := mrOk;
      end
      else
      begin
        FNewId := 0;
        if (Dataset.State in dsEditModes) then
          Dataset.Cancel;
      end;
    end
    else if (Dataset.State in dsEditModes) then
      Dataset.Post;
  end;

end;

procedure TDonorEditForm.EditShopRef;
var
  lDialog: TSelectShop;
  lResult: TModalResult;
begin
  lDialog := TSelectShop.Create(Self);
  try
    FPopUpShowing := True;
    lDialog.Popup := True;
    lDialog.PopupOpacity := 1;
    await(TForm, lDialog.Load());
    lDialog.ShopRef := Dataset.FieldByName('ShopRef').AsString;

    lResult := await(TModalResult, lDialog.Execute);

    if (lResult = mrCancel) then
    begin
      if (Dataset.State in dsEditModes) then
        Dataset.Cancel;
    end
    else
    begin
      if not(Dataset.State in dsEditModes) then
        Dataset.Edit;
      Dataset.FieldByName('ShopRef').AsString := lDialog.ShopRef;
      Dataset.Post;
    end;
  finally
    FPopUpShowing := False;
    lDialog.Free;
    lDialog := nil;
  end;

end;

procedure TDonorEditForm.edPostCodeExit(Sender: TObject);
begin
  ValidatePostCode;
end;

function TDonorEditForm.GetRequiredEditLevel: string;
begin
  result := SCOPE_ANY_ADMIN;
end;

procedure TDonorEditForm.NotifyAllSalesClick(Sender: TObject);
var
  lNotify: Boolean;
begin
  if not(Datasource.State in dsEditModes) then
  begin
    lNotify := NotifyAllSales.Checked;
    Datasource.Dataset.Edit;
    NotifyAllSales.Checked := lNotify;
    Datasource.Dataset.FieldByName('GiftAidScheme').AsString := GiftAid_Scheme[NotifyAllSales.Checked];
  end
  else
    Datasource.Dataset.FieldByName('GiftAidScheme').AsString := GiftAid_Scheme[NotifyAllSales.Checked];
end;

procedure TDonorEditForm.OldRefClick(Sender: TObject);
begin
  EditId(ditOldRef);
end;

function TDonorEditForm.SaveDescription(const AStatus: TUpdateStatus): string;
begin

  case AStatus of
    TUpdateStatus.usModified:
      result := 'Your changes for ' + DisplayName + ' have been saved';
    TUpdateStatus.usInserted:
      result := DisplayName + ' has been added.';
  else
    result := inherited;
  end;

end;

function TDonorEditForm.SaveTitle(const AStatus: TUpdateStatus): string;
begin
  case AStatus of
    TUpdateStatus.usModified:
      result := 'Changes for ' + DisplayName;
    TUpdateStatus.usInserted:
      result := DisplayName + ' added.';
  else
    result := inherited;
  end;
end;

procedure TDonorEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
// var
// I: Integer;
begin
  // for I := 1 to Value.Count - 1 do
  // begin
  // ShopRef.LookUpValues.AddPair(TLookupValueItem(Value.Items[I]).Value, TLookupValueItem(Value.Items[I]).DisplayText);
  // end;
end;

procedure TDonorEditForm.ShopRefClick(Sender: TObject);
begin
  EditShopRef;
end;

function TDonorEditForm.UpdateDonorId(const AOldId, ANewId: Integer): Boolean;
var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lValue: string;
begin
  result := False;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_DONORID_CHANGE, [AOldId, ANewId]));

  lResult := lRetval.ResultAsObject;
  lValue := JS.ToString(lResult['value']);

  if lValue = 'OK' then
  begin
    FNewId := ANewId;
    result := True;
  end
  else
  begin
    FNewId := 0;
    MessageDlg('Unable to change the Donor Id because ' + lValue, mtError, [mbOk]);
  end;

end;

procedure TDonorEditForm.ValidatePostCode;
begin
  if (Trim(edPostCode.Text) = '') then
  begin
    ControlInvalid('PostCode');
    Exit;
  end;

  edPostCode.Text := TSysHelper.FormatPostCode(edPostCode.Text);
  if TSysHelper.IsPostCode(edPostCode.Text) <> pcFull then
  begin
    ControlInvalid('PostCode');
  end
  else
  begin
    ControlValid('PostCode', True);
  end;
end;

procedure TDonorEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FNeedsFullRefresh := False;
  ClaimStatus.LookupValues.AddPair('OK', 'OK');
  ClaimStatus.LookupValues.AddPair('AwaitingPaperwork', 'Awaiting Paperwork');
  ClaimStatus.LookupValues.AddPair('Deceased', 'Deceased');
  ClaimStatus.LookupValues.AddPair('DonorDetailsIncomplete', 'Donor Details Incomplete');
  ClaimStatus.LookupValues.AddPair('NoLongerPaysTax', 'No Longer Pays Tax');
  ClaimStatus.LookupValues.AddPair('NoValidAddressGiven', 'No ValidAddress Given');
  ClaimStatus.LookupValues.AddPair('RequestedRefund', 'Requested Refund');
  ClaimStatus.LookupValues.AddPair('Other', 'Other - please add comment');
  Title.Items.CommaText := NAME_TITLES;
  TrapEnterPressSetUp;

end;

procedure TDonorEditForm.WebFormShow(Sender: TObject);
begin
  Title.SetFocus;
end;

procedure TDonorEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonorIdLabel := TDBLabel.Create('DonorIdLabel');
  OldRefLabel := TDBLabel.Create('OldRefLabel');
  GiftAidScheme := TDBLabel.Create('GiftAidScheme');
  ShopNameLabel := TDBLabel.Create('ShopNameLabel');
  ConsIdLabel := TDBLabel.Create('ConsIdLabel');
  LastStatement := TDBLabel.Create('LastStatement');
  edFirstName := TDBEdit.Create('FirstName');
  edLastName := TDBEdit.Create('LastName');
  edAdd1 := TDBEdit.Create('Add1');
  edAdd2 := TDBEdit.Create('Add2');
  edAdd3 := TDBEdit.Create('Add3');
  edCity := TDBEdit.Create('City');
  edPostCode := TDBEdit.Create('PostCode');
  edEmail := TDBEdit.Create('Email');
  edMobile := TDBEdit.Create('Mobile');
  edTelephone := TDBEdit.Create('Phone');
  ScannedDocsExist := TDBCheckBox.Create('ScannedDocsExist');
  ClaimStatus := TDBLookupComboBox.Create('ClaimStatus');
  NotifyAllSales := TCheckBox.Create('NotifyAllSales');
  Comments := TDBEdit.Create('Comments');
  DateSigned := TDBDateTimePicker.Create('DateSigned');
  ContactByPhone := TDBCheckBox.Create('ContactByPhone');
  ContactByEmail := TDBCheckBox.Create('ContactByEmail');
  ContactByText := TDBCheckBox.Create('ContactByText');
  ContactByPost := TDBCheckBox.Create('ContactByPost');
  Title := TDBComboBox.Create('Title');

  DonorIdLabel.BeforeLoadDFMValues;
  OldRefLabel.BeforeLoadDFMValues;
  GiftAidScheme.BeforeLoadDFMValues;
  ShopNameLabel.BeforeLoadDFMValues;
  ConsIdLabel.BeforeLoadDFMValues;
  LastStatement.BeforeLoadDFMValues;
  CloseThisButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  edFirstName.BeforeLoadDFMValues;
  edLastName.BeforeLoadDFMValues;
  edAdd1.BeforeLoadDFMValues;
  edAdd2.BeforeLoadDFMValues;
  edAdd3.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edPostCode.BeforeLoadDFMValues;
  edEmail.BeforeLoadDFMValues;
  edMobile.BeforeLoadDFMValues;
  edTelephone.BeforeLoadDFMValues;
  ScannedDocsExist.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  NotifyAllSales.BeforeLoadDFMValues;
  Comments.BeforeLoadDFMValues;
  DateSigned.BeforeLoadDFMValues;
  ContactByPhone.BeforeLoadDFMValues;
  ContactByEmail.BeforeLoadDFMValues;
  ContactByText.BeforeLoadDFMValues;
  ContactByPost.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  PriorButton.BeforeLoadDFMValues;
  NextButton.BeforeLoadDFMValues;
  Title.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    Width := 900;
    Height := 508;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DonorIdLabel.SetParentComponent(Self);
    DonorIdLabel.Name := 'DonorIdLabel';
    DonorIdLabel.Left := 232;
    DonorIdLabel.Top := 27;
    DonorIdLabel.Width := 71;
    DonorIdLabel.Height := 15;
    DonorIdLabel.Caption := 'DonorIdLabel';
    DonorIdLabel.ElementFont := efCSS;
    DonorIdLabel.HeightStyle := ssAuto;
    DonorIdLabel.HeightPercent := 100.000000000000000000;
    DonorIdLabel.WidthPercent := 100.000000000000000000;
    DonorIdLabel.DataField := 'Id';
    DonorIdLabel.DataSource := DataSource;
    OldRefLabel.SetParentComponent(Self);
    OldRefLabel.Name := 'OldRefLabel';
    OldRefLabel.Left := 232;
    OldRefLabel.Top := 48;
    OldRefLabel.Width := 64;
    OldRefLabel.Height := 15;
    OldRefLabel.Caption := 'OldRefLabel';
    OldRefLabel.ElementFont := efCSS;
    OldRefLabel.HeightStyle := ssAuto;
    OldRefLabel.HeightPercent := 100.000000000000000000;
    OldRefLabel.WidthPercent := 100.000000000000000000;
    OldRefLabel.DataField := 'OldRef';
    OldRefLabel.DataSource := DataSource;
    GiftAidScheme.SetParentComponent(Self);
    GiftAidScheme.Name := 'GiftAidScheme';
    GiftAidScheme.Left := 318;
    GiftAidScheme.Top := 315;
    GiftAidScheme.Width := 79;
    GiftAidScheme.Height := 15;
    GiftAidScheme.Caption := 'GiftAidScheme';
    GiftAidScheme.ElementFont := efCSS;
    GiftAidScheme.HeightStyle := ssAuto;
    GiftAidScheme.HeightPercent := 100.000000000000000000;
    GiftAidScheme.WidthPercent := 100.000000000000000000;
    GiftAidScheme.DataField := 'GiftAidScheme';
    GiftAidScheme.DataSource := DataSource;
    ShopNameLabel.SetParentComponent(Self);
    ShopNameLabel.Name := 'ShopNameLabel';
    ShopNameLabel.Left := 48;
    ShopNameLabel.Top := 64;
    ShopNameLabel.Width := 87;
    ShopNameLabel.Height := 15;
    ShopNameLabel.Caption := 'ShopNameLabel';
    ShopNameLabel.ElementFont := efCSS;
    ShopNameLabel.ElementPosition := epIgnore;
    ShopNameLabel.HeightStyle := ssAuto;
    ShopNameLabel.HeightPercent := 100.000000000000000000;
    ShopNameLabel.HTMLType := tSPAN;
    ShopNameLabel.WidthStyle := ssAuto;
    ShopNameLabel.WidthPercent := 100.000000000000000000;
    ShopNameLabel.DataField := 'ShopName';
    ShopNameLabel.DataSource := DataSource;
    ConsIdLabel.SetParentComponent(Self);
    ConsIdLabel.Name := 'ConsIdLabel';
    ConsIdLabel.Left := 141;
    ConsIdLabel.Top := 80;
    ConsIdLabel.Width := 65;
    ConsIdLabel.Height := 15;
    ConsIdLabel.Caption := 'ConsIdLabel';
    ConsIdLabel.ElementFont := efCSS;
    ConsIdLabel.HeightStyle := ssAuto;
    ConsIdLabel.HeightPercent := 100.000000000000000000;
    ConsIdLabel.WidthStyle := ssAuto;
    ConsIdLabel.WidthPercent := 100.000000000000000000;
    ConsIdLabel.DataField := 'ConsId';
    ConsIdLabel.DataSource := DataSource;
    LastStatement.SetParentComponent(Self);
    LastStatement.Name := 'LastStatement';
    LastStatement.Left := 472;
    LastStatement.Top := 344;
    LastStatement.Width := 75;
    LastStatement.Height := 15;
    LastStatement.Caption := 'LastStatement';
    LastStatement.ElementFont := efCSS;
    LastStatement.ElementPosition := epIgnore;
    LastStatement.HeightStyle := ssAuto;
    LastStatement.HeightPercent := 100.000000000000000000;
    LastStatement.WidthStyle := ssAuto;
    LastStatement.WidthPercent := 100.000000000000000000;
    LastStatement.DataField := 'LastStatementText';
    LastStatement.DataSource := DataSource;
    CloseThisButton.TabOrder := -1;
    CancelButton.Left := 136;
    CancelButton.Top := 404;
    CancelButton.TabOrder := 22;
    edFirstName.SetParentComponent(Self);
    edFirstName.Name := 'edFirstName';
    edFirstName.Left := 24;
    edFirstName.Top := 161;
    edFirstName.Width := 200;
    edFirstName.Height := 21;
    edFirstName.ChildOrder := 9;
    edFirstName.ElementClassName := 'form-control';
    edFirstName.ElementFont := efCSS;
    edFirstName.ElementPosition := epIgnore;
    edFirstName.HeightStyle := ssAuto;
    edFirstName.HeightPercent := 100.000000000000000000;
    edFirstName.TabOrder := 2;
    edFirstName.Text := 'edFirstName';
    edFirstName.WidthStyle := ssAuto;
    edFirstName.WidthPercent := 100.000000000000000000;
    edFirstName.DataField := 'FirstName';
    edFirstName.DataSource := DataSource;
    edLastName.SetParentComponent(Self);
    edLastName.Name := 'edLastName';
    edLastName.Left := 24;
    edLastName.Top := 188;
    edLastName.Width := 200;
    edLastName.Height := 21;
    edLastName.ChildOrder := 10;
    edLastName.ElementClassName := 'form-control';
    edLastName.ElementFont := efCSS;
    edLastName.ElementPosition := epIgnore;
    edLastName.HeightStyle := ssAuto;
    edLastName.HeightPercent := 100.000000000000000000;
    edLastName.TabOrder := 3;
    edLastName.Text := 'edLastName';
    edLastName.WidthStyle := ssAuto;
    edLastName.WidthPercent := 100.000000000000000000;
    edLastName.DataField := 'LastName';
    edLastName.DataSource := DataSource;
    edAdd1.SetParentComponent(Self);
    edAdd1.Name := 'edAdd1';
    edAdd1.Left := 253;
    edAdd1.Top := 91;
    edAdd1.Width := 200;
    edAdd1.Height := 21;
    edAdd1.ChildOrder := 11;
    edAdd1.ElementClassName := 'form-control';
    edAdd1.ElementFont := efCSS;
    edAdd1.ElementPosition := epIgnore;
    edAdd1.HeightStyle := ssAuto;
    edAdd1.HeightPercent := 100.000000000000000000;
    edAdd1.TabOrder := 4;
    edAdd1.Text := 'edAdd1';
    edAdd1.WidthStyle := ssAuto;
    edAdd1.WidthPercent := 100.000000000000000000;
    edAdd1.DataField := 'Add1';
    edAdd1.DataSource := DataSource;
    edAdd2.SetParentComponent(Self);
    edAdd2.Name := 'edAdd2';
    edAdd2.Left := 253;
    edAdd2.Top := 118;
    edAdd2.Width := 200;
    edAdd2.Height := 21;
    edAdd2.ChildOrder := 12;
    edAdd2.ElementClassName := 'form-control';
    edAdd2.ElementFont := efCSS;
    edAdd2.ElementPosition := epIgnore;
    edAdd2.HeightStyle := ssAuto;
    edAdd2.HeightPercent := 100.000000000000000000;
    edAdd2.TabOrder := 5;
    edAdd2.Text := 'edAdd2';
    edAdd2.WidthStyle := ssAuto;
    edAdd2.WidthPercent := 100.000000000000000000;
    edAdd2.DataField := 'Add2';
    edAdd2.DataSource := DataSource;
    edAdd3.SetParentComponent(Self);
    edAdd3.Name := 'edAdd3';
    edAdd3.Left := 253;
    edAdd3.Top := 145;
    edAdd3.Width := 200;
    edAdd3.Height := 21;
    edAdd3.ChildOrder := 13;
    edAdd3.ElementClassName := 'form-control';
    edAdd3.ElementFont := efCSS;
    edAdd3.ElementPosition := epIgnore;
    edAdd3.HeightStyle := ssAuto;
    edAdd3.HeightPercent := 100.000000000000000000;
    edAdd3.TabOrder := 6;
    edAdd3.Text := 'edAdd3';
    edAdd3.WidthStyle := ssAuto;
    edAdd3.WidthPercent := 100.000000000000000000;
    edAdd3.DataField := 'Add3';
    edAdd3.DataSource := DataSource;
    edCity.SetParentComponent(Self);
    edCity.Name := 'edCity';
    edCity.Left := 253;
    edCity.Top := 171;
    edCity.Width := 200;
    edCity.Height := 21;
    edCity.ChildOrder := 14;
    edCity.ElementClassName := 'form-control';
    edCity.ElementFont := efCSS;
    edCity.ElementPosition := epIgnore;
    edCity.HeightStyle := ssAuto;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 7;
    edCity.Text := 'edCity';
    edCity.WidthStyle := ssAuto;
    edCity.WidthPercent := 100.000000000000000000;
    edCity.DataField := 'City';
    edCity.DataSource := DataSource;
    edPostCode.SetParentComponent(Self);
    edPostCode.Name := 'edPostCode';
    edPostCode.Left := 253;
    edPostCode.Top := 198;
    edPostCode.Width := 200;
    edPostCode.Height := 21;
    edPostCode.CharCase := wecUpperCase;
    edPostCode.ChildOrder := 15;
    edPostCode.ElementClassName := 'form-control';
    edPostCode.ElementFont := efCSS;
    edPostCode.ElementPosition := epIgnore;
    edPostCode.HeightStyle := ssAuto;
    edPostCode.HeightPercent := 100.000000000000000000;
    edPostCode.TabOrder := 8;
    edPostCode.Text := 'edPostCode';
    edPostCode.WidthStyle := ssAuto;
    edPostCode.WidthPercent := 100.000000000000000000;
    SetEvent(edPostCode, Self, 'OnExit', 'edPostCodeExit');
    edPostCode.DataField := 'PostCode';
    edPostCode.DataSource := DataSource;
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 24;
    edEmail.Top := 217;
    edEmail.Width := 200;
    edEmail.Height := 21;
    edEmail.CharCase := wecLowerCase;
    edEmail.ChildOrder := 16;
    edEmail.ElementClassName := 'form-control';
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.TabOrder := 11;
    edEmail.Text := 'edEmail';
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    edEmail.DataField := 'Email';
    edEmail.DataSource := DataSource;
    edMobile.SetParentComponent(Self);
    edMobile.Name := 'edMobile';
    edMobile.Left := 24;
    edMobile.Top := 244;
    edMobile.Width := 200;
    edMobile.Height := 21;
    edMobile.ChildOrder := 17;
    edMobile.ElementClassName := 'form-control';
    edMobile.ElementFont := efCSS;
    edMobile.ElementPosition := epIgnore;
    edMobile.HeightStyle := ssAuto;
    edMobile.HeightPercent := 100.000000000000000000;
    edMobile.TabOrder := 10;
    edMobile.Text := 'edMobile';
    edMobile.WidthStyle := ssAuto;
    edMobile.WidthPercent := 100.000000000000000000;
    edMobile.DataField := 'Mobile';
    edMobile.DataSource := DataSource;
    edTelephone.SetParentComponent(Self);
    edTelephone.Name := 'edTelephone';
    edTelephone.Left := 24;
    edTelephone.Top := 271;
    edTelephone.Width := 200;
    edTelephone.Height := 21;
    edTelephone.ChildOrder := 18;
    edTelephone.ElementClassName := 'form-control';
    edTelephone.ElementFont := efCSS;
    edTelephone.ElementPosition := epIgnore;
    edTelephone.HeightStyle := ssAuto;
    edTelephone.HeightPercent := 100.000000000000000000;
    edTelephone.TabOrder := 9;
    edTelephone.Text := 'edTelephone';
    edTelephone.WidthStyle := ssAuto;
    edTelephone.WidthPercent := 100.000000000000000000;
    edTelephone.DataField := 'Telephone';
    edTelephone.DataSource := DataSource;
    ScannedDocsExist.SetParentComponent(Self);
    ScannedDocsExist.Name := 'ScannedDocsExist';
    ScannedDocsExist.Left := 32;
    ScannedDocsExist.Top := 312;
    ScannedDocsExist.Width := 161;
    ScannedDocsExist.Height := 22;
    ScannedDocsExist.ChildOrder := 23;
    ScannedDocsExist.ElementClassName := 'form-check-input';
    ScannedDocsExist.ElementFont := efCSS;
    ScannedDocsExist.ElementPosition := epIgnore;
    ScannedDocsExist.HeightStyle := ssAuto;
    ScannedDocsExist.HeightPercent := 100.000000000000000000;
    ScannedDocsExist.TabOrder := 14;
    ScannedDocsExist.WidthStyle := ssAuto;
    ScannedDocsExist.WidthPercent := 100.000000000000000000;
    ScannedDocsExist.DataField := 'ScannedDocsExist';
    ScannedDocsExist.DataSource := DataSource;
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 269;
    ClaimStatus.Top := 256;
    ClaimStatus.Width := 197;
    ClaimStatus.Height := 22;
    ClaimStatus.ElementClassName := 'form-select form-control';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.TabOrder := 12;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    SetEvent(ClaimStatus, Self, 'OnChange', 'ClaimStatusChange');
    ClaimStatus.DataField := 'ClaimStatus';
    ClaimStatus.DataSource := DataSource;
    NotifyAllSales.SetParentComponent(Self);
    NotifyAllSales.Name := 'NotifyAllSales';
    NotifyAllSales.Left := 199;
    NotifyAllSales.Top := 312;
    NotifyAllSales.Width := 113;
    NotifyAllSales.Height := 22;
    NotifyAllSales.ChildOrder := 24;
    NotifyAllSales.ElementClassName := 'form-check-input';
    NotifyAllSales.ElementFont := efCSS;
    NotifyAllSales.ElementPosition := epIgnore;
    NotifyAllSales.HeightStyle := ssAuto;
    NotifyAllSales.HeightPercent := 100.000000000000000000;
    NotifyAllSales.TabOrder := 17;
    NotifyAllSales.WidthStyle := ssAuto;
    NotifyAllSales.WidthPercent := 100.000000000000000000;
    SetEvent(NotifyAllSales, Self, 'OnClick', 'NotifyAllSalesClick');
    Comments.SetParentComponent(Self);
    Comments.Name := 'Comments';
    Comments.Left := 472;
    Comments.Top := 256;
    Comments.Width := 121;
    Comments.Height := 22;
    Comments.ChildOrder := 25;
    Comments.ElementClassName := 'form-control';
    Comments.ElementFont := efCSS;
    Comments.ElementPosition := epIgnore;
    Comments.HeightStyle := ssAuto;
    Comments.HeightPercent := 100.000000000000000000;
    Comments.TabOrder := 13;
    Comments.Text := 'Comments';
    Comments.Visible := False;
    Comments.WidthStyle := ssAuto;
    Comments.WidthPercent := 100.000000000000000000;
    Comments.DataField := 'Comments';
    Comments.DataSource := DataSource;
    DateSigned.SetParentComponent(Self);
    DateSigned.Name := 'DateSigned';
    DateSigned.Left := 464;
    DateSigned.Top := 296;
    DateSigned.Width := 129;
    DateSigned.Height := 22;
    DateSigned.ElementClassName := 'form-control';
    DateSigned.HeightStyle := ssAuto;
    DateSigned.WidthStyle := ssAuto;
    DateSigned.BorderStyle := bsSingle;
    DateSigned.ChildOrder := 26;
    DateSigned.Color := clWhite;
    DateSigned.Date := 44461.755385451390000000;
    DateSigned.ElementFont := efCSS;
    DateSigned.ElementPosition := epIgnore;
    DateSigned.Role := '';
    DateSigned.TabOrder := 20;
    DateSigned.Text := '';
    DateSigned.DataField := 'DateSigned';
    DateSigned.DataSource := DataSource;
    ContactByPhone.SetParentComponent(Self);
    ContactByPhone.Name := 'ContactByPhone';
    ContactByPhone.Left := 318;
    ContactByPhone.Top := 340;
    ContactByPhone.Width := 113;
    ContactByPhone.Height := 22;
    ContactByPhone.ChildOrder := 27;
    ContactByPhone.ElementClassName := 'form-check-input';
    ContactByPhone.ElementFont := efCSS;
    ContactByPhone.ElementPosition := epIgnore;
    ContactByPhone.HeightStyle := ssAuto;
    ContactByPhone.HeightPercent := 100.000000000000000000;
    ContactByPhone.TabOrder := 14;
    ContactByPhone.WidthStyle := ssAuto;
    ContactByPhone.WidthPercent := 100.000000000000000000;
    ContactByPhone.DataField := 'ContactByPhone';
    ContactByPhone.DataSource := DataSource;
    ContactByEmail.SetParentComponent(Self);
    ContactByEmail.Name := 'ContactByEmail';
    ContactByEmail.Left := 32;
    ContactByEmail.Top := 368;
    ContactByEmail.Width := 113;
    ContactByEmail.Height := 22;
    ContactByEmail.ChildOrder := 27;
    ContactByEmail.ElementClassName := 'form-check-input';
    ContactByEmail.ElementFont := efCSS;
    ContactByEmail.ElementPosition := epIgnore;
    ContactByEmail.HeightStyle := ssAuto;
    ContactByEmail.HeightPercent := 100.000000000000000000;
    ContactByEmail.TabOrder := 16;
    ContactByEmail.WidthStyle := ssAuto;
    ContactByEmail.WidthPercent := 100.000000000000000000;
    ContactByEmail.DataField := 'ContactByEmail';
    ContactByEmail.DataSource := DataSource;
    ContactByText.SetParentComponent(Self);
    ContactByText.Name := 'ContactByText';
    ContactByText.Left := 183;
    ContactByText.Top := 368;
    ContactByText.Width := 113;
    ContactByText.Height := 22;
    ContactByText.ChildOrder := 27;
    ContactByText.ElementClassName := 'form-check-input';
    ContactByText.ElementFont := efCSS;
    ContactByText.ElementPosition := epIgnore;
    ContactByText.HeightStyle := ssAuto;
    ContactByText.HeightPercent := 100.000000000000000000;
    ContactByText.TabOrder := 19;
    ContactByText.WidthStyle := ssAuto;
    ContactByText.WidthPercent := 100.000000000000000000;
    ContactByText.DataField := 'ContactByText';
    ContactByText.DataSource := DataSource;
    ContactByPost.SetParentComponent(Self);
    ContactByPost.Name := 'ContactByPost';
    ContactByPost.Left := 291;
    ContactByPost.Top := 368;
    ContactByPost.Width := 113;
    ContactByPost.Height := 22;
    ContactByPost.ChildOrder := 27;
    ContactByPost.ElementClassName := 'form-check-input';
    ContactByPost.ElementFont := efCSS;
    ContactByPost.ElementPosition := epIgnore;
    ContactByPost.HeightStyle := ssAuto;
    ContactByPost.HeightPercent := 100.000000000000000000;
    ContactByPost.TabOrder := 13;
    ContactByPost.WidthStyle := ssAuto;
    ContactByPost.WidthPercent := 100.000000000000000000;
    ContactByPost.DataField := 'ContactByPost';
    ContactByPost.DataSource := DataSource;
    SaveButton.Left := 24;
    SaveButton.Top := 404;
    SaveButton.TabOrder := 21;
    DataState.Left := 24;
    DataState.Top := 103;
    PriorButton.Left := 556;
    PriorButton.Top := 28;
    NextButton.Left := 631;
    NextButton.Top := 31;
    Title.SetParentComponent(Self);
    Title.Name := 'Title';
    Title.Left := 24;
    Title.Top := 134;
    Title.Width := 145;
    Title.Height := 23;
    Title.ElementClassName := 'form-select';
    Title.ElementFont := efCSS;
    Title.ElementPosition := epIgnore;
    Title.HeightStyle := ssAuto;
    Title.HeightPercent := 100.000000000000000000;
    Title.TabOrder := 1;
    Title.Text := 'Title';
    Title.WidthStyle := ssAuto;
    Title.WidthPercent := 100.000000000000000000;
    Title.ItemIndex := -1;
    Title.DataField := 'Title';
    Title.DataSource := DataSource;
    DataSource.Left := 136;
    DataSource.Top := 16;
  finally
    DonorIdLabel.AfterLoadDFMValues;
    OldRefLabel.AfterLoadDFMValues;
    GiftAidScheme.AfterLoadDFMValues;
    ShopNameLabel.AfterLoadDFMValues;
    ConsIdLabel.AfterLoadDFMValues;
    LastStatement.AfterLoadDFMValues;
    CloseThisButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    edFirstName.AfterLoadDFMValues;
    edLastName.AfterLoadDFMValues;
    edAdd1.AfterLoadDFMValues;
    edAdd2.AfterLoadDFMValues;
    edAdd3.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edPostCode.AfterLoadDFMValues;
    edEmail.AfterLoadDFMValues;
    edMobile.AfterLoadDFMValues;
    edTelephone.AfterLoadDFMValues;
    ScannedDocsExist.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    NotifyAllSales.AfterLoadDFMValues;
    Comments.AfterLoadDFMValues;
    DateSigned.AfterLoadDFMValues;
    ContactByPhone.AfterLoadDFMValues;
    ContactByEmail.AfterLoadDFMValues;
    ContactByText.AfterLoadDFMValues;
    ContactByPost.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    PriorButton.AfterLoadDFMValues;
    NextButton.AfterLoadDFMValues;
    Title.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.
