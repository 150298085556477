unit DashboardForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.DB,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  Vcl.Grids,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  BaseCoreForm,
  DashboardManager;

type
  TDashboard = class(TCoreWebForm)
    WhatsNew: THTMLDiv;
    JobsSource: TDataSource;
    JobsList: TDBResponsiveGrid;
    ThingsToDo: TResponsiveGrid;
    procedure JobsListItemClick(Sender: TObject; Index: Integer);
    procedure JobsListItemGetFieldValue(Sender: TObject; Index: Integer; AFieldName: string; var AValue: string);
    procedure ThingsToDoItemClick(Sender: TObject; Index: Integer);
    procedure WebFormCreate(Sender: TObject);
  private
    FDashboardManager: TDashboardManager;
    [async]
    procedure LoadThingsToDo; async;
    procedure SetJobsDataset(const Value: TDataset);
    [async]
    procedure ShowJobDetail; async;
    [async]
    procedure ShowRefund(const RefundId: string); async;
    { Private declarations }
  public
    { Public declarations }
    procedure TearDown; override;
    procedure RefreshJobsList;
    property JobsDataset: TDataset write SetJobsDataset;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  MainForm,
  JobDetailForm,
  AppUtils,
  MainDataModule,
  DonorRefundForm;

{$R *.dfm}

procedure TDashboard.JobsListItemClick(Sender: TObject; Index: Integer);
begin
  MainPage.LockJobsData;
  JobsList.DataSource.DataSet.First;
  JobsList.DataSource.DataSet.MoveBy(index);
  ShowJobDetail;
end;

procedure TDashboard.JobsListItemGetFieldValue(Sender: TObject; Index: Integer; AFieldName: string; var AValue: string);
var
  lProgress: Double;
begin
  if AFieldName = 'RunLevel' then
  begin
    if AValue = 'Preview' then
      AValue := '(Preview)'
    else
      AValue := '';
  end

  else if AFieldName = 'Progress' then
  begin
    // lProgress := StrToFloatDef(AValue, 0);
    // if lProgress > 0 then
    // AValue := '(' + FormatFloat('##0.0', lProgress) + '% Complete)'
    // else
    AValue := '';
  end

  else if AFieldName = 'HasErrors' then
  begin
    if AValue = 'True' then
      AValue := '[with errors]'
    else
      AValue := '';
  end

  else if AFieldName = 'JobCategory' then
  begin
    if AValue = 'Poll' then
      { TODO : Maybe include last poll time? }
      AValue := '[Poll]'
    else
      AValue := '';
  end;

end;

procedure TDashboard.LoadThingsToDo;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  lToDoList: JS.TJSArray;
begin
  try
    ThingsToDo.BeginUpdate;
    try
      ThingsToDo.Items.Clear;
      lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ITUTILSSVC_GET_THINGS_TODO, []));
      lResult := TJSObject(lRetval.Result);
      lToDoList := JS.toArray(lResult['value']);
      if (lToDoList <> nil) and (lToDoList.Length > 0) then
      begin
        ThingsToDo.LoadFromJSON(lResult, 'value');
        ThingsToDo.Visible := True;
      end
      else
        ThingsToDo.Visible := False;

    finally
      ThingsToDo.EndUpdate;
    end;
  except
  end;
end;

procedure TDashboard.RefreshJobsList;
begin
  JobsList.Refresh;
end;

procedure TDashboard.SetJobsDataset(const Value: TDataset);
begin
  JobsSource.DataSet := Value;
end;

procedure TDashboard.ShowJobDetail;
var
  AForm: TJobDetail;
  lRetval: TModalResult;
begin
  if JobsSource.Dataset.FieldByName('Status').AsString = 'Processing' then
  begin
    Exit;
  end;

  AForm := TJobDetail.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TJobDetail, AForm.Load());
    AForm.DataSet := JobsSource.DataSet;
    lRetval := await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
    MainPage.UnlockJobsData(lRetval = mrOK);
  end;
end;

procedure TDashboard.ShowRefund(const RefundId: string);
var
  AForm: TDonorRefundFinal;
begin
  AForm := TDonorRefundFinal.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    AForm.RefundId := RefundId;
    await(TModalResult, AForm.Execute);
    LoadThingsToDo;
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TDashboard.TearDown;
begin
  inherited;
  MainData.OnDashboardItem := nil;
  MainData.OnClearDashboard := nil;
end;

procedure TDashboard.ThingsToDoItemClick(Sender: TObject; Index: Integer);
var
  lItem: TResponsiveGridItem;
  lLinkType: string;
begin
  lItem := ThingsToDo.Items[index];
  lLinkType := lItem.JSONElementValue['LinkType'];
  if lLinkType = 'Dummy' then
  begin
    ShowMessage('Nothing to do here');
  end
  else if lLinkType = 'Refund' then
  begin
    ShowRefund(lItem.JSONElementValue['LinkedId']);
  end;
end;

procedure TDashboard.WebFormCreate(Sender: TObject);
begin
  CloseThisButton.Visible := False;
  FDashboardManager := TDashboardManager.Create;
  FDashboardManager.ContainerId := 'WhatsNew';

  // Application.CreateForm(TDashBoardData, 'WhatsNew', FDashboardData);
  // asm
  // $("#WhatsNew").load("Support/WhatsNew.html");
  // end;

  JobsList.Options.ItemTemplate :=
    '<div class="card mb-2 (%Status%)"><div class="card-body"><h4 class="card-title">(%Title%) (%RunLevel%) (%JobCategory%)</h4>'
    + '<h6 class="text-muted card-subtitle mb-2">Job No: (%Id%)</h6><p class="card-text">Submitted: (%DateSubmitted%)</p>'
    + '<p class="card-text">Status: (%Status%) (%DateCompleted%) (%HasErrors%)</p></div></div>';

  ThingsToDo.Options.ItemTemplate := '<div class="card mb-2 (%ItemStatus%)"><div class="card-body">' +
    '<h4 class="card-title">(%Title%)</h4>' + '<h6 class="text-muted card-subtitle mb-2">(%Caption%)</h6>' +
    '<p class="card-text">(%ItemStatus%)</p>' + '</div></div>';

  LoadThingsToDo;

  MainData.OnDashboardItem := FDashboardManager.LoadDashboardItem;
  MainData.OnClearDashboard := FDashboardManager.ClearDashboard;

end;

procedure TDashboard.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WhatsNew := THTMLDiv.Create('WhatsNew');
  JobsList := TDBResponsiveGrid.Create('JobsList');
  ThingsToDo := TResponsiveGrid.Create('ThingsToDo');
  JobsSource := TDataSource.Create(Self);

  WhatsNew.BeforeLoadDFMValues;
  JobsList.BeforeLoadDFMValues;
  ThingsToDo.BeforeLoadDFMValues;
  JobsSource.BeforeLoadDFMValues;
  try
    Width := 727;
    Height := 480;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WhatsNew.SetParentComponent(Self);
    WhatsNew.Name := 'WhatsNew';
    WhatsNew.Left := 16;
    WhatsNew.Top := 32;
    WhatsNew.Width := 177;
    WhatsNew.Height := 393;
    WhatsNew.HeightStyle := ssAuto;
    WhatsNew.WidthStyle := ssAuto;
    WhatsNew.ChildOrder := 1;
    WhatsNew.ElementPosition := epIgnore;
    WhatsNew.ElementFont := efCSS;
    WhatsNew.Role := '';
    JobsList.SetParentComponent(Self);
    JobsList.Name := 'JobsList';
    JobsList.Left := 496;
    JobsList.Top := 32;
    JobsList.Width := 200;
    JobsList.Height := 393;
    JobsList.Cursor := crHandPoint;
    JobsList.HeightStyle := ssAuto;
    JobsList.WidthStyle := ssAuto;
    JobsList.ChildOrder := 1;
    JobsList.ElementFont := efCSS;
    JobsList.ElementPosition := epIgnore;
    JobsList.ItemIndex := 0;
    JobsList.Options.ItemClassName := 'JobItem';
    JobsList.Options.ItemWidthMin := 200;
    SetEvent(JobsList, Self, 'OnItemGetFieldValue', 'JobsListItemGetFieldValue');
    SetEvent(JobsList, Self, 'OnItemClick', 'JobsListItemClick');
    JobsList.DataSource := JobsSource;
    ThingsToDo.SetParentComponent(Self);
    ThingsToDo.Name := 'ThingsToDo';
    ThingsToDo.Left := 256;
    ThingsToDo.Top := 40;
    ThingsToDo.Width := 201;
    ThingsToDo.Height := 377;
    ThingsToDo.Cursor := crHandPoint;
    ThingsToDo.HeightStyle := ssAuto;
    ThingsToDo.WidthStyle := ssAuto;
    ThingsToDo.ChildOrder := 2;
    ThingsToDo.ElementFont := efCSS;
    ThingsToDo.ElementPosition := epIgnore;
    ThingsToDo.ItemIndex := 0;
    ThingsToDo.Options.ItemWidthMin := 200;
    SetEvent(ThingsToDo, Self, 'OnItemClick', 'ThingsToDoItemClick');
    JobsSource.SetParentComponent(Self);
    JobsSource.Name := 'JobsSource';
    JobsSource.Left := 560;
    JobsSource.Top := 152;
  finally
    WhatsNew.AfterLoadDFMValues;
    JobsList.AfterLoadDFMValues;
    ThingsToDo.AfterLoadDFMValues;
    JobsSource.AfterLoadDFMValues;
  end;
end;

end.
