unit SalesList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  AppUtils,
  BaseForm;

type
  TSalesListForm = class(TBaseListForm)
    ShopFilter: TLookupComboBox;
    Shops: TXDataWebDataSet;
    ShopsRef: TStringField;
    ShopsRegionRef: TStringField;
    ShopsName: TStringField;
    ShopsPhone: TStringField;
    ShopsAdd1: TStringField;
    ShopsAdd2: TStringField;
    ShopsAdd3: TStringField;
    ShopsCity: TStringField;
    ShopsCounty: TStringField;
    ShopsPostCode: TStringField;
    ShopsCountry: TStringField;
    ShopsLocationType: TStringField;
    ShopsStatus: TStringField;
    ShopsAddedBy: TIntegerField;
    ShopsDateAdded: TDateTimeField;
    ShopsLastUpdatedBy: TIntegerField;
    ShopsLastUpdated: TDateTimeField;
    MainDatasetId: TIntegerField;
    MainDatasetShopRef: TStringField;
    MainDatasetSaleDate: TDateField;
    MainDatasetCreditCards: TFloatField;
    MainDatasetCashAndCheques: TFloatField;
    MainDatasetTotalBanked: TFloatField;
    MainDatasetCompletedBy: TStringField;
    MainDatasetSheetNumber: TIntegerField;
    MainDatasetSheetCount: TIntegerField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    FromDate: TDateTimePicker;
    ToDate: TDateTimePicker;
    MainDatasetShopName: TStringField;
    MainDatasetBatchId: TIntegerField;
    MainDatasetSheetInfo: TStringField;
    DateTarget: TComboBox;
    JustMine: TCheckBox;
    MineTodayButton: TButton;
    MainDatasetTotalSales: TFloatField;
    MineThisWeekButton: TButton;
    UserData: TXDataWebDataSet;
    UserDataId: TIntegerField;
    UserDataShopRef: TStringField;
    UserDataFirstName: TStringField;
    UserDataLastName: TStringField;
    UserDataUserLevel: TStringField;
    MainDatasetAddedByName: TStringField;
    procedure MainDatasetAfterOpen(Dataset: TDataSet);
    procedure MainDatasetAfterPost(Dataset: TDataSet);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetDateAddedGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetSaleDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MineThisWeekButtonClick(Sender: TObject);
    procedure MineTodayButtonClick(Sender: TObject);
    procedure SearchButtonClick(Sender: TObject);
    procedure ShopsAfterOpen(Dataset: TDataSet);
    procedure UserDataAfterOpen(Dataset: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    FFindSheetId: Integer;
    { Private declarations }
    FStoredPage: Integer;
    FStoredId: Integer;
    FRefreshingList: Boolean;
    procedure BuildAndRunSearch;
    [Async]
    procedure RefreshList(const ASelectedId: Integer); async;
    [Async]
    procedure AfterEditFormLoaded(AForm: TBaseEditForm); async; override;
    [async]
    procedure EditBanking(const ARecordId: Integer); async;
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    procedure UnLockControls(const AUnLock: Boolean); override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SalesListForm: TSalesListForm;

implementation

uses
  MainDataModule,
  System.DateUtils,
  Auth.Service,
  SalesDetailForm,
  SalesBankingForm;

{$R *.dfm}

procedure TSalesListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure BankingClick(Sender: TObject);
  begin
    EditBanking(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin
  inherited;

  if (RecordViewOption in [voEdit, voCreateAndEdit]) then
  begin
    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-money-check-edit', 'edit banking', True);
    Span.OnClick := @BankingClick;
  end
end;

procedure TSalesListForm.AfterEditFormLoaded(AForm: TBaseEditForm);
begin
  inherited;
  TSalesDetail(AForm).RefreshMaster := RefreshList;
end;

procedure TSalesListForm.BuildAndRunSearch;
var
  query, lValue, lDateTarget, lOrder: string;
begin
  UnLockControls(False);
  query := '(SheetNumber eq 1) and ';
  lValue := ShopFilter.Value;
  if lValue <> 'All' then
    query := query + format('(ShopRef eq ''%s'') and ', [ShopFilter.Value]);

  if DateTarget.ItemIndex = 0 then
  begin
    lDateTarget := 'SaleDate';
    lOrder := '&$orderby=SaleDate';
    // SaleDate is a TDate
    if FromDate.Date = ToDate.Date then
      query := query + format('(SaleDate eq ''%s'')', [FormatDateTime('YYYY-MM-DD', FromDate.Date)])
    else
      query := query + format('(%s ge ''%s'') and ', [lDateTarget, FormatDateTime('YYYY-MM-DD', FromDate.Date)]) +
        format('(%s le ''%s'')', [lDateTarget, FormatDateTime('YYYY-MM-DD', ToDate.Date)]);
  end
  else
  begin
    lDateTarget := 'DateAdded';
    lOrder := '&$orderby=DateAdded';
    query := query + format('(%s ge ''%s'') and ', [lDateTarget, FormatDateTime('YYYY-MM-DD', FromDate.Date)]) +
      format('(%s lt ''%s'')', [lDateTarget, FormatDateTime('YYYY-MM-DD', IncDay(ToDate.Date))]);
  end;

  if JustMine.Checked then
    query := query + format(' and (AddedBy eq %d)', [AuthService.UserId]);

  FGridPlugin.SetPreparedFilter(query + lOrder, FStoredPage);

end;

procedure TSalesListForm.EditBanking(const ARecordId: Integer);
var
  AForm: TSalesBanking;
  lResult: TModalResult;
begin

  if not MainDataset.Locate('Id', ARecordId, []) then
    Exit;

  AForm := TSalesBanking.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;

    await(TSalesBanking, AForm.Load());

    AForm.ShopLookups := ShopFilter.LookupValues;
    AForm.Dataset := MainDataset;

    lResult := await(TModalResult, AForm.Execute);

    if lResult = mrOK then
    begin
      MainDatasetTotalBanked.Value := MainDatasetCreditCards.Value + MainDatasetCashAndCheques.Value;
      MainDataset.Post;
      MainDataset.ApplyUpdates;
    end
    else
      MainDataset.Cancel;

  finally
    AForm.Free;
  end;
end;

function TSalesListForm.GetEditFormClass: TFormClass;
begin
  Result := TSalesDetail;
end;

procedure TSalesListForm.LoadData;
begin
  Shops.Load;
end;

procedure TSalesListForm.MainDatasetAfterOpen(Dataset: TDataSet);
begin
  inherited;
  if FFindSheetId > 0 then
  begin
    MainDataset.Locate('Id', FFindSheetId, []);
    FFindSheetId := 0;
  end;
  FRefreshingList := False;
end;

procedure TSalesListForm.MainDatasetAfterPost(Dataset: TDataSet);
begin
  Exit;
  // Do Not Save - all done on the SalesDetailForm
  // inherited;
end;

procedure TSalesListForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetSheetInfo.Value := MainDatasetSheetNumber.AsString + ' of ' + MainDatasetSheetCount.AsString;
end;

procedure TSalesListForm.MainDatasetDateAddedGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy hh:nn', (Sender as TDateTimeField).AsDateTime);
end;

procedure TSalesListForm.MainDatasetSaleDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy', (Sender as TDateField).AsDateTime);
end;

procedure TSalesListForm.MineThisWeekButtonClick(Sender: TObject);
begin
  JustMine.Checked := True;
  DateTarget.ItemIndex := 1;
  FromDate.Date := StartOfTheWeek(Today);
  ToDate.Date := Today;
  ShopFilter.ItemIndex := 0;
  BuildAndRunSearch;
end;

procedure TSalesListForm.MineTodayButtonClick(Sender: TObject);
begin
  JustMine.Checked := True;
  DateTarget.ItemIndex := 1;
  FromDate.Date := Today;
  ToDate.Date := Today;
  ShopFilter.ItemIndex := 0;
  BuildAndRunSearch;
end;

procedure TSalesListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TSalesListForm.RecordViewOption: TViewOption;
begin
  if AuthService.IsAdminUserOrBetter then
    Result := voEdit
  else
    Result := voReadOnly;
end;

procedure TSalesListForm.RefreshList(const ASelectedId: Integer);
begin
  FRefreshingList := True;
  MainDataset.DisableControls;
  MainDataset.Close;
  FStoredPage := FGridPlugin.PageNumber;
  BuildAndRunSearch;
  FFindSheetId := ASelectedId;
  FStoredPage := 1;
end;

procedure TSalesListForm.SearchButtonClick(Sender: TObject);
begin
  BuildAndRunSearch;
end;

procedure TSalesListForm.ShopsAfterOpen(Dataset: TDataSet);
begin
  ShopFilter.LookupValues.AddPair('All', 'All Shops');

  while not Shops.eof do
  begin
    if (AuthService.LocationType = 'System') and (ShopsLocationType.Value = 'System') then
      ShopFilter.LookupValues.AddPair(ShopsRef.Value, format('%s (%s)', [ShopsName.Value, ShopsStatus.Value]))
    else
      ShopFilter.LookupValues.AddPair(ShopsRef.Value, format('%s (%s)', [ShopsName.Value, ShopsStatus.Value]));
    Shops.Next;
  end;

  ShopFilter.ItemIndex := 0;
  UserData.Load;

end;

procedure TSalesListForm.UnLockControls(const AUnLock: Boolean);
begin
  inherited;
  MineTodayButton.Enabled := AUnLock;
  MineThisWeekButton.Enabled := AUnLock;
end;

procedure TSalesListForm.UserDataAfterOpen(Dataset: TDataSet);
begin
  BuildAndRunSearch;
end;

procedure TSalesListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FromDate.Date := Today;
  ToDate.Date := Today;
  DateTarget.ItemIndex := 1;
  FStoredPage := 1;
  FStoredId := -1;
  FFindSheetId := 0;
end;

procedure TSalesListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopFilter := TLookupComboBox.Create('ShopFilter');
  FromDate := TDateTimePicker.Create('FromDate');
  ToDate := TDateTimePicker.Create('ToDate');
  DateTarget := TComboBox.Create('DateTarget');
  JustMine := TCheckBox.Create('JustMine');
  MineTodayButton := TButton.Create('MineTodayButton');
  MineThisWeekButton := TButton.Create('MineThisWeekButton');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetShopRef := TStringField.Create(Self);
  MainDatasetSaleDate := TDateField.Create(Self);
  MainDatasetCreditCards := TFloatField.Create(Self);
  MainDatasetCashAndCheques := TFloatField.Create(Self);
  MainDatasetTotalBanked := TFloatField.Create(Self);
  MainDatasetCompletedBy := TStringField.Create(Self);
  MainDatasetSheetNumber := TIntegerField.Create(Self);
  MainDatasetSheetCount := TIntegerField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetShopName := TStringField.Create(Self);
  MainDatasetBatchId := TIntegerField.Create(Self);
  MainDatasetSheetInfo := TStringField.Create(Self);
  MainDatasetTotalSales := TFloatField.Create(Self);
  MainDatasetAddedByName := TStringField.Create(Self);
  Shops := TXDataWebDataSet.Create(Self);
  ShopsRef := TStringField.Create(Self);
  ShopsRegionRef := TStringField.Create(Self);
  ShopsName := TStringField.Create(Self);
  ShopsPhone := TStringField.Create(Self);
  ShopsAdd1 := TStringField.Create(Self);
  ShopsAdd2 := TStringField.Create(Self);
  ShopsAdd3 := TStringField.Create(Self);
  ShopsCity := TStringField.Create(Self);
  ShopsCounty := TStringField.Create(Self);
  ShopsPostCode := TStringField.Create(Self);
  ShopsCountry := TStringField.Create(Self);
  ShopsLocationType := TStringField.Create(Self);
  ShopsStatus := TStringField.Create(Self);
  ShopsAddedBy := TIntegerField.Create(Self);
  ShopsDateAdded := TDateTimeField.Create(Self);
  ShopsLastUpdatedBy := TIntegerField.Create(Self);
  ShopsLastUpdated := TDateTimeField.Create(Self);
  UserData := TXDataWebDataSet.Create(Self);
  UserDataId := TIntegerField.Create(Self);
  UserDataShopRef := TStringField.Create(Self);
  UserDataFirstName := TStringField.Create(Self);
  UserDataLastName := TStringField.Create(Self);
  UserDataUserLevel := TStringField.Create(Self);

  edSearch.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  ShopFilter.BeforeLoadDFMValues;
  FromDate.BeforeLoadDFMValues;
  ToDate.BeforeLoadDFMValues;
  DateTarget.BeforeLoadDFMValues;
  JustMine.BeforeLoadDFMValues;
  MineTodayButton.BeforeLoadDFMValues;
  MineThisWeekButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetShopRef.BeforeLoadDFMValues;
  MainDatasetSaleDate.BeforeLoadDFMValues;
  MainDatasetCreditCards.BeforeLoadDFMValues;
  MainDatasetCashAndCheques.BeforeLoadDFMValues;
  MainDatasetTotalBanked.BeforeLoadDFMValues;
  MainDatasetCompletedBy.BeforeLoadDFMValues;
  MainDatasetSheetNumber.BeforeLoadDFMValues;
  MainDatasetSheetCount.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetShopName.BeforeLoadDFMValues;
  MainDatasetBatchId.BeforeLoadDFMValues;
  MainDatasetSheetInfo.BeforeLoadDFMValues;
  MainDatasetTotalSales.BeforeLoadDFMValues;
  MainDatasetAddedByName.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  Shops.BeforeLoadDFMValues;
  ShopsRef.BeforeLoadDFMValues;
  ShopsRegionRef.BeforeLoadDFMValues;
  ShopsName.BeforeLoadDFMValues;
  ShopsPhone.BeforeLoadDFMValues;
  ShopsAdd1.BeforeLoadDFMValues;
  ShopsAdd2.BeforeLoadDFMValues;
  ShopsAdd3.BeforeLoadDFMValues;
  ShopsCity.BeforeLoadDFMValues;
  ShopsCounty.BeforeLoadDFMValues;
  ShopsPostCode.BeforeLoadDFMValues;
  ShopsCountry.BeforeLoadDFMValues;
  ShopsLocationType.BeforeLoadDFMValues;
  ShopsStatus.BeforeLoadDFMValues;
  ShopsAddedBy.BeforeLoadDFMValues;
  ShopsDateAdded.BeforeLoadDFMValues;
  ShopsLastUpdatedBy.BeforeLoadDFMValues;
  ShopsLastUpdated.BeforeLoadDFMValues;
  UserData.BeforeLoadDFMValues;
  UserDataId.BeforeLoadDFMValues;
  UserDataShopRef.BeforeLoadDFMValues;
  UserDataFirstName.BeforeLoadDFMValues;
  UserDataLastName.BeforeLoadDFMValues;
  UserDataUserLevel.BeforeLoadDFMValues;
  try
    edSearch.Left := 511;
    edSearch.Top := 39;
    edSearch.Visible := False;
    DataTable.Top := 121;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'BatchId';
      Title := 'Batch';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ShopName';
      Title := 'Shop';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SaleDate';
      Title := 'Sale Date';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'TotalSales';
      Title := 'Sales';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'CreditCards';
      Title := 'Cards';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'CashAndCheques';
      Title := 'Cash';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'TotalBanked';
      Title := 'Total';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-center';
      DataField := 'SheetCount';
      Title := 'Sheets';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'AddedByName';
      Title := 'Who';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'DateAdded';
      Title := 'Added';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.Visible := False;
    SearchButton.Left := 471;
    SearchButton.Top := 66;
    ShopFilter.SetParentComponent(Self);
    ShopFilter.Name := 'ShopFilter';
    ShopFilter.Left := 104;
    ShopFilter.Top := 67;
    ShopFilter.Width := 145;
    ShopFilter.Height := 22;
    ShopFilter.ElementClassName := 'form-select';
    ShopFilter.ElementFont := efCSS;
    ShopFilter.HeightPercent := 100.000000000000000000;
    ShopFilter.WidthPercent := 100.000000000000000000;
    ShopFilter.ItemIndex := -1;
    FromDate.SetParentComponent(Self);
    FromDate.Name := 'FromDate';
    FromDate.Left := 264;
    FromDate.Top := 66;
    FromDate.Width := 81;
    FromDate.Height := 22;
    FromDate.ElementClassName := 'form-control';
    FromDate.HeightStyle := ssAuto;
    FromDate.WidthStyle := ssAuto;
    FromDate.BorderStyle := bsSingle;
    FromDate.ChildOrder := 10;
    FromDate.Color := clWhite;
    FromDate.Date := 44490.459474930550000000;
    FromDate.ElementFont := efCSS;
    FromDate.ElementPosition := epIgnore;
    FromDate.Role := '';
    FromDate.Text := '';
    ToDate.SetParentComponent(Self);
    ToDate.Name := 'ToDate';
    ToDate.Left := 360;
    ToDate.Top := 66;
    ToDate.Width := 81;
    ToDate.Height := 22;
    ToDate.ElementClassName := 'form-control';
    ToDate.HeightStyle := ssAuto;
    ToDate.WidthStyle := ssAuto;
    ToDate.BorderStyle := bsSingle;
    ToDate.ChildOrder := 10;
    ToDate.Color := clWhite;
    ToDate.Date := 44490.459474930550000000;
    ToDate.ElementFont := efCSS;
    ToDate.ElementPosition := epIgnore;
    ToDate.Role := '';
    ToDate.Text := '';
    DateTarget.SetParentComponent(Self);
    DateTarget.Name := 'DateTarget';
    DateTarget.Left := 510;
    DateTarget.Top := 64;
    DateTarget.Width := 124;
    DateTarget.Height := 23;
    DateTarget.ElementClassName := 'form-select';
    DateTarget.ElementFont := efCSS;
    DateTarget.ElementPosition := epIgnore;
    DateTarget.HeightStyle := ssAuto;
    DateTarget.HeightPercent := 100.000000000000000000;
    DateTarget.Text := 'DateTarget';
    DateTarget.WidthStyle := ssAuto;
    DateTarget.WidthPercent := 100.000000000000000000;
    DateTarget.ItemIndex := -1;
    DateTarget.Items.BeginUpdate;
    try
      DateTarget.Items.Clear;
      DateTarget.Items.Add('Sale Date');
      DateTarget.Items.Add('Date Entered');
    finally
      DateTarget.Items.EndUpdate;
    end;
    JustMine.SetParentComponent(Self);
    JustMine.Name := 'JustMine';
    JustMine.Left := 408;
    JustMine.Top := 93;
    JustMine.Width := 113;
    JustMine.Height := 22;
    JustMine.Caption := 'Just my data entry';
    JustMine.ChildOrder := 13;
    JustMine.Color := clNone;
    JustMine.ElementClassName := 'custom-control custom-checkbox';
    JustMine.ElementButtonClassName := 'custom-control-input';
    JustMine.ElementLabelClassName := 'custom-control-label';
    JustMine.ElementFont := efCSS;
    JustMine.ElementPosition := epIgnore;
    JustMine.HeightStyle := ssAuto;
    JustMine.HeightPercent := 100.000000000000000000;
    JustMine.State := cbUnchecked;
    JustMine.WidthStyle := ssAuto;
    JustMine.WidthPercent := 100.000000000000000000;
    MineTodayButton.SetParentComponent(Self);
    MineTodayButton.Name := 'MineTodayButton';
    MineTodayButton.Left := 106;
    MineTodayButton.Top := 95;
    MineTodayButton.Width := 96;
    MineTodayButton.Height := 25;
    MineTodayButton.Caption := 'Mine Today';
    MineTodayButton.ChildOrder := 14;
    MineTodayButton.ElementClassName := 'btn btn-primary btn-sm';
    MineTodayButton.ElementFont := efCSS;
    MineTodayButton.ElementPosition := epIgnore;
    MineTodayButton.HeightStyle := ssAuto;
    MineTodayButton.HeightPercent := 100.000000000000000000;
    MineTodayButton.WidthStyle := ssAuto;
    MineTodayButton.WidthPercent := 100.000000000000000000;
    SetEvent(MineTodayButton, Self, 'OnClick', 'MineTodayButtonClick');
    MineThisWeekButton.SetParentComponent(Self);
    MineThisWeekButton.Name := 'MineThisWeekButton';
    MineThisWeekButton.Left := 208;
    MineThisWeekButton.Top := 96;
    MineThisWeekButton.Width := 96;
    MineThisWeekButton.Height := 25;
    MineThisWeekButton.Caption := 'Mine This Week';
    MineThisWeekButton.ChildOrder := 15;
    MineThisWeekButton.ElementClassName := 'btn btn-primary btn-sm';
    MineThisWeekButton.ElementFont := efCSS;
    MineThisWeekButton.ElementPosition := epIgnore;
    MineThisWeekButton.HeightStyle := ssAuto;
    MineThisWeekButton.HeightPercent := 100.000000000000000000;
    MineThisWeekButton.WidthStyle := ssAuto;
    MineThisWeekButton.WidthPercent := 100.000000000000000000;
    SetEvent(MineThisWeekButton, Self, 'OnClick', 'MineThisWeekButtonClick');
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'SalesSheet';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetShopRef.SetParentComponent(MainDataset);
    MainDatasetShopRef.Name := 'MainDatasetShopRef';
    MainDatasetShopRef.FieldName := 'ShopRef';
    MainDatasetShopRef.Size := 10;
    MainDatasetSaleDate.SetParentComponent(MainDataset);
    MainDatasetSaleDate.Name := 'MainDatasetSaleDate';
    MainDatasetSaleDate.FieldName := 'SaleDate';
    SetEvent(MainDatasetSaleDate, Self, 'OnGetText', 'MainDatasetSaleDateGetText');
    MainDatasetSaleDate.DisplayFormat := 'dd/mm/yyyy';
    MainDatasetCreditCards.SetParentComponent(MainDataset);
    MainDatasetCreditCards.Name := 'MainDatasetCreditCards';
    MainDatasetCreditCards.FieldName := 'CreditCards';
    MainDatasetCreditCards.DisplayFormat := '#,##0.00';
    MainDatasetCashAndCheques.SetParentComponent(MainDataset);
    MainDatasetCashAndCheques.Name := 'MainDatasetCashAndCheques';
    MainDatasetCashAndCheques.FieldName := 'CashAndCheques';
    MainDatasetCashAndCheques.DisplayFormat := '#,##0.00';
    MainDatasetTotalBanked.SetParentComponent(MainDataset);
    MainDatasetTotalBanked.Name := 'MainDatasetTotalBanked';
    MainDatasetTotalBanked.FieldName := 'TotalBanked';
    MainDatasetTotalBanked.DisplayFormat := '#,##0.00';
    MainDatasetCompletedBy.SetParentComponent(MainDataset);
    MainDatasetCompletedBy.Name := 'MainDatasetCompletedBy';
    MainDatasetCompletedBy.FieldName := 'CompletedBy';
    MainDatasetCompletedBy.Size := 50;
    MainDatasetSheetNumber.SetParentComponent(MainDataset);
    MainDatasetSheetNumber.Name := 'MainDatasetSheetNumber';
    MainDatasetSheetNumber.FieldName := 'SheetNumber';
    MainDatasetSheetCount.SetParentComponent(MainDataset);
    MainDatasetSheetCount.Name := 'MainDatasetSheetCount';
    MainDatasetSheetCount.FieldName := 'SheetCount';
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    SetEvent(MainDatasetDateAdded, Self, 'OnGetText', 'MainDatasetDateAddedGetText');
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetShopName.SetParentComponent(MainDataset);
    MainDatasetShopName.Name := 'MainDatasetShopName';
    MainDatasetShopName.FieldKind := fkLookup;
    MainDatasetShopName.FieldName := 'ShopName';
    MainDatasetShopName.LookupDataSet := Shops;
    MainDatasetShopName.LookupKeyFields := 'Ref';
    MainDatasetShopName.LookupResultField := 'Name';
    MainDatasetShopName.KeyFields := 'ShopRef';
    MainDatasetShopName.Size := 50;
    MainDatasetShopName.Lookup := True;
    MainDatasetBatchId.SetParentComponent(MainDataset);
    MainDatasetBatchId.Name := 'MainDatasetBatchId';
    MainDatasetBatchId.FieldName := 'BatchId';
    MainDatasetSheetInfo.SetParentComponent(MainDataset);
    MainDatasetSheetInfo.Name := 'MainDatasetSheetInfo';
    MainDatasetSheetInfo.FieldKind := fkCalculated;
    MainDatasetSheetInfo.FieldName := 'SheetInfo';
    MainDatasetSheetInfo.Size := 25;
    MainDatasetSheetInfo.Calculated := True;
    MainDatasetTotalSales.SetParentComponent(MainDataset);
    MainDatasetTotalSales.Name := 'MainDatasetTotalSales';
    MainDatasetTotalSales.FieldName := 'TotalSales';
    MainDatasetTotalSales.DisplayFormat := '#,##0.00';
    MainDatasetAddedByName.SetParentComponent(MainDataset);
    MainDatasetAddedByName.Name := 'MainDatasetAddedByName';
    MainDatasetAddedByName.FieldKind := fkLookup;
    MainDatasetAddedByName.FieldName := 'AddedByName';
    MainDatasetAddedByName.LookupDataSet := UserData;
    MainDatasetAddedByName.LookupKeyFields := 'Id';
    MainDatasetAddedByName.LookupResultField := 'FirstName';
    MainDatasetAddedByName.KeyFields := 'AddedBy';
    MainDatasetAddedByName.Size := 25;
    MainDatasetAddedByName.Lookup := True;
    MainDataSource.Left := 136;
    WebElementActionList1.Left := 424;
    WebElementActionList1.Top := 16;
    Shops.SetParentComponent(Self);
    Shops.Name := 'Shops';
    Shops.AfterOpen := ShopsAfterOpen;
    Shops.EntitySetName := 'Shop';
    Shops.Connection := MainData.DataConnection;
    Shops.QueryString := '$orderby=Name';
    Shops.Left := 360;
    Shops.Top := 8;
    ShopsRef.SetParentComponent(Shops);
    ShopsRef.Name := 'ShopsRef';
    ShopsRef.FieldName := 'Ref';
    ShopsRef.Size := 5;
    ShopsRegionRef.SetParentComponent(Shops);
    ShopsRegionRef.Name := 'ShopsRegionRef';
    ShopsRegionRef.FieldName := 'RegionRef';
    ShopsRegionRef.Size := 5;
    ShopsName.SetParentComponent(Shops);
    ShopsName.Name := 'ShopsName';
    ShopsName.FieldName := 'Name';
    ShopsName.Size := 250;
    ShopsPhone.SetParentComponent(Shops);
    ShopsPhone.Name := 'ShopsPhone';
    ShopsPhone.FieldName := 'Phone';
    ShopsPhone.Size := 50;
    ShopsAdd1.SetParentComponent(Shops);
    ShopsAdd1.Name := 'ShopsAdd1';
    ShopsAdd1.FieldName := 'Add1';
    ShopsAdd1.Size := 100;
    ShopsAdd2.SetParentComponent(Shops);
    ShopsAdd2.Name := 'ShopsAdd2';
    ShopsAdd2.FieldName := 'Add2';
    ShopsAdd2.Size := 100;
    ShopsAdd3.SetParentComponent(Shops);
    ShopsAdd3.Name := 'ShopsAdd3';
    ShopsAdd3.FieldName := 'Add3';
    ShopsAdd3.Size := 100;
    ShopsCity.SetParentComponent(Shops);
    ShopsCity.Name := 'ShopsCity';
    ShopsCity.FieldName := 'City';
    ShopsCity.Size := 50;
    ShopsCounty.SetParentComponent(Shops);
    ShopsCounty.Name := 'ShopsCounty';
    ShopsCounty.FieldName := 'County';
    ShopsCounty.Size := 50;
    ShopsPostCode.SetParentComponent(Shops);
    ShopsPostCode.Name := 'ShopsPostCode';
    ShopsPostCode.FieldName := 'PostCode';
    ShopsPostCode.Size := 25;
    ShopsCountry.SetParentComponent(Shops);
    ShopsCountry.Name := 'ShopsCountry';
    ShopsCountry.FieldName := 'Country';
    ShopsCountry.Size := 3;
    ShopsLocationType.SetParentComponent(Shops);
    ShopsLocationType.Name := 'ShopsLocationType';
    ShopsLocationType.FieldName := 'LocationType';
    ShopsLocationType.Size := 10;
    ShopsStatus.SetParentComponent(Shops);
    ShopsStatus.Name := 'ShopsStatus';
    ShopsStatus.FieldName := 'Status';
    ShopsStatus.Size := 6;
    ShopsAddedBy.SetParentComponent(Shops);
    ShopsAddedBy.Name := 'ShopsAddedBy';
    ShopsAddedBy.FieldName := 'AddedBy';
    ShopsDateAdded.SetParentComponent(Shops);
    ShopsDateAdded.Name := 'ShopsDateAdded';
    ShopsDateAdded.FieldName := 'DateAdded';
    ShopsLastUpdatedBy.SetParentComponent(Shops);
    ShopsLastUpdatedBy.Name := 'ShopsLastUpdatedBy';
    ShopsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ShopsLastUpdated.SetParentComponent(Shops);
    ShopsLastUpdated.Name := 'ShopsLastUpdated';
    ShopsLastUpdated.FieldName := 'LastUpdated';
    UserData.SetParentComponent(Self);
    UserData.Name := 'UserData';
    UserData.AfterOpen := UserDataAfterOpen;
    UserData.EntitySetName := 'User';
    UserData.Connection := MainData.DataConnection;
    UserData.Left := 488;
    UserData.Top := 8;
    UserDataId.SetParentComponent(UserData);
    UserDataId.Name := 'UserDataId';
    UserDataId.FieldName := 'Id';
    UserDataShopRef.SetParentComponent(UserData);
    UserDataShopRef.Name := 'UserDataShopRef';
    UserDataShopRef.FieldName := 'ShopRef';
    UserDataShopRef.Size := 5;
    UserDataFirstName.SetParentComponent(UserData);
    UserDataFirstName.Name := 'UserDataFirstName';
    UserDataFirstName.FieldName := 'FirstName';
    UserDataFirstName.Size := 50;
    UserDataLastName.SetParentComponent(UserData);
    UserDataLastName.Name := 'UserDataLastName';
    UserDataLastName.FieldName := 'LastName';
    UserDataLastName.Size := 50;
    UserDataUserLevel.SetParentComponent(UserData);
    UserDataUserLevel.Name := 'UserDataUserLevel';
    UserDataUserLevel.FieldName := 'UserLevel';
    UserDataUserLevel.Size := 12;
  finally
    edSearch.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    ShopFilter.AfterLoadDFMValues;
    FromDate.AfterLoadDFMValues;
    ToDate.AfterLoadDFMValues;
    DateTarget.AfterLoadDFMValues;
    JustMine.AfterLoadDFMValues;
    MineTodayButton.AfterLoadDFMValues;
    MineThisWeekButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetShopRef.AfterLoadDFMValues;
    MainDatasetSaleDate.AfterLoadDFMValues;
    MainDatasetCreditCards.AfterLoadDFMValues;
    MainDatasetCashAndCheques.AfterLoadDFMValues;
    MainDatasetTotalBanked.AfterLoadDFMValues;
    MainDatasetCompletedBy.AfterLoadDFMValues;
    MainDatasetSheetNumber.AfterLoadDFMValues;
    MainDatasetSheetCount.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetShopName.AfterLoadDFMValues;
    MainDatasetBatchId.AfterLoadDFMValues;
    MainDatasetSheetInfo.AfterLoadDFMValues;
    MainDatasetTotalSales.AfterLoadDFMValues;
    MainDatasetAddedByName.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
    Shops.AfterLoadDFMValues;
    ShopsRef.AfterLoadDFMValues;
    ShopsRegionRef.AfterLoadDFMValues;
    ShopsName.AfterLoadDFMValues;
    ShopsPhone.AfterLoadDFMValues;
    ShopsAdd1.AfterLoadDFMValues;
    ShopsAdd2.AfterLoadDFMValues;
    ShopsAdd3.AfterLoadDFMValues;
    ShopsCity.AfterLoadDFMValues;
    ShopsCounty.AfterLoadDFMValues;
    ShopsPostCode.AfterLoadDFMValues;
    ShopsCountry.AfterLoadDFMValues;
    ShopsLocationType.AfterLoadDFMValues;
    ShopsStatus.AfterLoadDFMValues;
    ShopsAddedBy.AfterLoadDFMValues;
    ShopsDateAdded.AfterLoadDFMValues;
    ShopsLastUpdatedBy.AfterLoadDFMValues;
    ShopsLastUpdated.AfterLoadDFMValues;
    UserData.AfterLoadDFMValues;
    UserDataId.AfterLoadDFMValues;
    UserDataShopRef.AfterLoadDFMValues;
    UserDataFirstName.AfterLoadDFMValues;
    UserDataLastName.AfterLoadDFMValues;
    UserDataUserLevel.AfterLoadDFMValues;
  end;
end;

end.
