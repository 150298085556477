unit ImportDonorStatusesForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  Vcl.Imaging.GIFImg,
  WEBLib.Toast;

type
  TImportDonorStatuses = class(TJobBase)
    FilePicker: TFilePicker;
    procedure FilePickerChange(Sender: TObject);
  private
    { Private declarations }
  protected
    function CanPlaceJob: boolean; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    [async]
    procedure PlaceJob; async; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  AppUtils,
  MainDataModule;

{$R *.dfm}

{ TImportDonorStatusesJob }

function TImportDonorStatuses.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    while not JobsPlaced.eof do
    begin
      if TSysHelper.JobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new import while there is still one in progress');
        Exit(False);
      end;
      JobsPlaced.Next;
    end;
  end;

  if FLoading then
    Result := False
  else if (not Assigned(FilePicker.Files)) or (FilePicker.Files.Count = 0) then
  begin
    SetJobMessage('Please select the CSV file to upload');
    Result := False;
  end
  else if FilePicker.Files.Count > 1 then
  begin
    SetJobMessage('You can only select one CSV file to upload');
    Result := False;
  end;

end;

procedure TImportDonorStatuses.FilePickerChange(Sender: TObject);
begin
  ValidateJob;
end;

function TImportDonorStatuses.JobClass: string;
begin
  result := 'TREDonorStatusImport';
end;

function TImportDonorStatuses.JobDescription: string;
begin
  Result := 'Import Donor Statuses from Raiser''s Edge';
end;

function TImportDonorStatuses.JobDisplayName: string;
begin
  Result := 'Donor Status Import';
end;

procedure TImportDonorStatuses.PlaceJob;
var
  lParams: string;
  lFileName, lFile: string;
  lRetval: TXDataClientResponse;
begin
  WaitMessage.Show;

  lParams := GetParams;

  lFileName := FilePicker.Files[0].Name;

  lFile := await(string, FilePicker.Files[0].FileAsBase64);

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULE_FILE_JOB, [JobClass,
    JobVisibility.Value, lParams, OutputOption.Value, lFileName, lFile]));

  FJobId := JS.toInteger(lRetval.ResultAsObject['value']);

  AfterPlaceJob;

  FilePicker.Files.Clear;

  ShowAnAwesomeToast(JobDisplayName, 'Your job ' + JobDescription + ' has been placed. The Job Number is ' + FJobId.ToString,
    'far fa-clock');

  CloseTimer.Enabled := True;
//  ModalResult := mrOK;
end;

procedure TImportDonorStatuses.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FilePicker := TFilePicker.Create('FilePicker');

  OutputOption.BeforeLoadDFMValues;
  JobMessage.BeforeLoadDFMValues;
  FilePicker.BeforeLoadDFMValues;
  try
    OutputOption.Visible := False;
    JobMessage.Left := 64;
    JobMessage.Top := 382;
    FilePicker.SetParentComponent(Self);
    FilePicker.Name := 'FilePicker';
    FilePicker.Left := 72;
    FilePicker.Top := 312;
    FilePicker.Width := 321;
    FilePicker.Height := 19;
    FilePicker.ElementClassName := 'form-control';
    FilePicker.HeightStyle := ssAuto;
    FilePicker.WidthStyle := ssAuto;
    FilePicker.Accept := '.csv';
    FilePicker.ChildOrder := 7;
    FilePicker.ElementFont := efCSS;
    FilePicker.ElementPosition := epIgnore;
    SetEvent(FilePicker, Self, 'OnChange', 'FilePickerChange');
  finally
    OutputOption.AfterLoadDFMValues;
    JobMessage.AfterLoadDFMValues;
    FilePicker.AfterLoadDFMValues;
  end;
end;

end.

