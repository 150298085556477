unit NotificationsList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  AppUtils,
  smx.webcore.types,
  GiftAidClaimForm,
  Vcl.Imaging.GIFImg,
  WEBLib.ExtCtrls;

type
  TNotificationsListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetClaimRef: TStringField;
    MainDatasetLetterThreshold: TFloatField;
    MainDatasetEmailThreshold: TFloatField;
    MainDatasetSalesDateCutOff: TDateField;
    MainDatasetLetterDate: TDateField;
    MainDatasetStatus: TStringField;
    MainDatasetEarliestSubmisionDate: TDateField;
    MainDatasetEarliestClaimableDate: TDateField;
    MainDatasetEmailCount: TIntegerField;
    MainDatasetNotNeededCount: TIntegerField;
    MainDatasetLetterCount: TIntegerField;
    MainDatasetUnderThresholdCount: TIntegerField;
    MainDatasetEarliestSalesDate: TDateField;
    MainDatasetLatestSalesDate: TDateField;
    MainDatasetGiftAidId: TIntegerField;
    MainDatasetTotalCount: TIntegerField;
    MainDatasetGiftAidClaim: TFloatField;
    MainDatasetDonationsValue: TFloatField;
    MainDatasetSalesCount: TIntegerField;
    MainDatasetNonRefundDeductions: TFloatField;
    MainDatasetRefunded: TFloatField;
    MainDatasetCancellationJob: TIntegerField;
    MainDatasetProductionJob: TIntegerField;
    WebMessageDlg1: TMessageDlg;
    MainDatasetStatusText: TStringField;
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterOpen(Dataset: TDataSet);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
  private
    { Private declarations }
    FCancellingId: Integer;
    FClaimForm: TGiftAidClaimJob;
    FSubmitClaim: Boolean;
    [async]
    procedure CancelNotification(const AId: Integer); async;
    [async]
    procedure CancelThisNotification; async;
    [async]
    procedure ClaimGiftAid(const AId: Integer); async;
    [async]
    procedure HandleClaimSubmit; async;
    [async]
    procedure RedoOutput(const AId: Integer); async;
  protected
    function RecordViewOption: TViewOption; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    function GetEditFormClass: TFormClass; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure PrepareForm; override;
    procedure LoadData; override;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  NotificationsForm,
  WEBLib.WebCtrls,
  XData.Web.Client,
  MainDataModule,
  Auth.Service,
  NotificationsRedoJobForm;

procedure TNotificationsListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure CancelClick(Sender: TObject);
  begin
    CancelNotification(ARecordId.ToInteger);
  end;

  procedure SubmitClick(Sender: TObject);
  begin
    ClaimGiftAid(ARecordId.ToInteger);
  end;

  procedure ReSubmitClick(Sender: TObject);
  begin
    ClaimGiftAid(ARecordId.ToInteger);
  end;

  procedure RedoClick(Sender: TObject);
  begin
    RedoOutput(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin
  inherited;

  if not AuthService.IsAdministrator then
    Exit;

  if (MainDatasetStatus.Value = 'Processed')
{$IFDEF DEBUG} or (MainDatasetStatus.Value = 'Complete'){$ENDIF} then
  begin
    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-trash-alt', 'delete');
    Span.OnClick := @CancelClick;

    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-recycle', 'redo');
    Span.OnClick := @RedoClick;

  end;

  if (MainDatasetStatus.Value = 'Complete') and (Now >= MainDatasetEarliestSubmisionDate.Value) then
  begin
    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-hand-holding-heart', 'submit claim');
    Span.OnClick := @SubmitClick;
  end;

  if (MainDatasetStatus.Value = 'Failed') then
  begin
    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-hand-holding-magic', 'submit claim');
    Span.OnClick := @ReSubmitClick;
  end;

end;

procedure TNotificationsListForm.CancelNotification(const AId: Integer);
var
  mr: TModalResult;
begin

  if not MainDataset.Locate('Id', AId, []) then
  begin
    ShowMessage('please refresh the page and try again');
    Exit;
  end;

  FCancellingId := AId;

  mr := await(TModalResult, WebMessageDlg1.ShowDialog('Are you sure you want to cancel this Notification Process?',
    WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

  if mr = mrYes then
  begin
    MainDataset.Edit;
    MainDatasetStatus.Value := 'Cancelling';
    MainDataset.Post;
  end;

end;

procedure TNotificationsListForm.CancelThisNotification;
var
  lParams: string;
  lRetval: TXDataClientResponse;
  lJobId: Integer;
begin
  lParams := Format('{"NotificationId":%d}', [FCancellingId]);
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
    ['TCancelNotifications', 'AnyAdministrator', lParams, 'Undecided', 'Production']));

  lJobId := JS.ToInteger(lRetval.ResultAsObject['value']);
  ShowAnAwesomeToast('Cancellation',
    'The cancellation of this notification has been scheduled, keep an eye on Jobs for updates (Job Id: ' +
    lJobId.ToString + ')', 'far fa-clock');

  MainDataset.DisableControls;
  MainDataset.Close;
  MainDataset.Load;
end;

procedure TNotificationsListForm.ClaimGiftAid(const AId: Integer);
var
  lRetval: TModalResult;
begin

  if not MainDataset.Locate('Id', AId, []) then
  begin
    ShowMessage('please refresh the page and try again');
    Exit;
  end;

  FSubmitClaim := False;
  FClaimForm := TGiftAidClaimJob.Create(Self);
  try
    FClaimForm.Popup := True;
    FClaimForm.PopupOpacity := 1;
    await(TForm, FClaimForm.Load());
    FClaimForm.PlaceJobManagedExternally := True;
    FClaimForm.NotificationDataset := MainDataset;
    lRetval := await(TModalResult, FClaimForm.Execute);
    if (lRetval = mrYes) then
    begin
      ListWaitMessage.Show;
      FSubmitClaim := True;
      MainDataset.Edit;
      MainDatasetStatus.Value := 'Queued';
      MainDataset.Post;
    end;
  finally
    if not FSubmitClaim then
    begin
      FClaimForm.Free;
      FClaimForm := nil;
    end;
  end;

end;

function TNotificationsListForm.GetEditFormClass: TFormClass;
begin
  Result := TNotificationDetails;
end;

procedure TNotificationsListForm.HandleClaimSubmit;
begin
  await(FClaimForm.PlaceJob);
  FClaimForm.Free;
  FClaimForm := nil;
  FSubmitClaim := False;
  ListCloseTimer.Enabled := True;
end;

function TNotificationsListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TNotificationsListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TNotificationsListForm.LoadData;
begin
  FGridPlugin.Load;
end;

procedure TNotificationsListForm.MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  if FSubmitClaim then
  begin
    HandleClaimSubmit;
  end
  else if MainDataset.Locate('Id', FCancellingId, []) then
  begin
    if MainDatasetStatus.Value = 'Cancelling' then
      CancelThisNotification;
  end;
end;

procedure TNotificationsListForm.MainDatasetAfterOpen(Dataset: TDataSet);
begin
  MainDataset.EnableControls;
end;

procedure TNotificationsListForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetTotalCount.Value := MainDatasetEmailCount.Value + MainDatasetLetterCount.Value +
    MainDatasetNotNeededCount.Value;

  if MainDatasetStatus.Value = 'Failed' then
    MainDatasetStatusText.Value := 'Failed Claim'
  else
    MainDatasetStatusText.Value := TSysHelper.SplitOnCaps(MainDatasetStatus.Value);
end;

procedure TNotificationsListForm.PrepareForm;
begin
  FIdField := 'Id';
  FSubmitClaim := False;
end;

function TNotificationsListForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voNone;
  { TODO : Do the detail form NotificationsForm.pas }
end;

procedure TNotificationsListForm.RedoOutput(const AId: Integer);
var
  lRetval: TModalResult;
  lForm: TRedoNotificationsJob;
begin

  if not MainDataset.Locate('Id', AId, []) then
  begin
    ShowMessage('please refresh the page and try again');
    Exit;
  end;

  lForm := TRedoNotificationsJob.Create(Self);
  try
    lForm.Popup := True;
    lForm.PopupOpacity := 1;
    await(TForm, lForm.Load());
    lForm.NotificationDataset := MainDataset;
    lRetval := await(TModalResult, lForm.Execute);
    if (lRetval = mrYes) then
    begin

    end;
  finally
    lForm.Free;
    lForm := nil;
  end;
end;

procedure TNotificationsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebMessageDlg1 := TMessageDlg.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetClaimRef := TStringField.Create(Self);
  MainDatasetLetterThreshold := TFloatField.Create(Self);
  MainDatasetEmailThreshold := TFloatField.Create(Self);
  MainDatasetSalesDateCutOff := TDateField.Create(Self);
  MainDatasetLetterDate := TDateField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);
  MainDatasetEarliestSubmisionDate := TDateField.Create(Self);
  MainDatasetEarliestClaimableDate := TDateField.Create(Self);
  MainDatasetEmailCount := TIntegerField.Create(Self);
  MainDatasetNotNeededCount := TIntegerField.Create(Self);
  MainDatasetLetterCount := TIntegerField.Create(Self);
  MainDatasetUnderThresholdCount := TIntegerField.Create(Self);
  MainDatasetEarliestSalesDate := TDateField.Create(Self);
  MainDatasetLatestSalesDate := TDateField.Create(Self);
  MainDatasetGiftAidId := TIntegerField.Create(Self);
  MainDatasetTotalCount := TIntegerField.Create(Self);
  MainDatasetGiftAidClaim := TFloatField.Create(Self);
  MainDatasetDonationsValue := TFloatField.Create(Self);
  MainDatasetSalesCount := TIntegerField.Create(Self);
  MainDatasetNonRefundDeductions := TFloatField.Create(Self);
  MainDatasetRefunded := TFloatField.Create(Self);
  MainDatasetCancellationJob := TIntegerField.Create(Self);
  MainDatasetProductionJob := TIntegerField.Create(Self);
  MainDatasetStatusText := TStringField.Create(Self);

  edSearch.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetClaimRef.BeforeLoadDFMValues;
  MainDatasetLetterThreshold.BeforeLoadDFMValues;
  MainDatasetEmailThreshold.BeforeLoadDFMValues;
  MainDatasetSalesDateCutOff.BeforeLoadDFMValues;
  MainDatasetLetterDate.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  MainDatasetEarliestSubmisionDate.BeforeLoadDFMValues;
  MainDatasetEarliestClaimableDate.BeforeLoadDFMValues;
  MainDatasetEmailCount.BeforeLoadDFMValues;
  MainDatasetNotNeededCount.BeforeLoadDFMValues;
  MainDatasetLetterCount.BeforeLoadDFMValues;
  MainDatasetUnderThresholdCount.BeforeLoadDFMValues;
  MainDatasetEarliestSalesDate.BeforeLoadDFMValues;
  MainDatasetLatestSalesDate.BeforeLoadDFMValues;
  MainDatasetGiftAidId.BeforeLoadDFMValues;
  MainDatasetTotalCount.BeforeLoadDFMValues;
  MainDatasetGiftAidClaim.BeforeLoadDFMValues;
  MainDatasetDonationsValue.BeforeLoadDFMValues;
  MainDatasetSalesCount.BeforeLoadDFMValues;
  MainDatasetNonRefundDeductions.BeforeLoadDFMValues;
  MainDatasetRefunded.BeforeLoadDFMValues;
  MainDatasetCancellationJob.BeforeLoadDFMValues;
  MainDatasetProductionJob.BeforeLoadDFMValues;
  MainDatasetStatusText.BeforeLoadDFMValues;
  try
    edSearch.Visible := False;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'StatusText';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SalesDateCutOff';
      Title := 'Cut Off';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'EarliestClaimableDate';
      Title := 'Earliest Claimable';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'EarliestSubmisionDate';
      Title := 'Earliest Submision';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SalesCount';
      Title := 'Sales';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'EmailCount';
      Title := 'Emails';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LetterCount';
      Title := 'Letters';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'UnderThresholdCount';
      Title := 'Under Threshold';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'DonationsValue';
      Title := 'Donations';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'GiftAidClaim';
      Title := 'GiftAid';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SearchButton.Visible := False;
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 192;
    WebMessageDlg1.Top := 24;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.HeightStyle := ssAuto;
    WebMessageDlg1.WidthStyle := ssAuto;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    WebMessageDlg1.ElementButtonClassName := 'btn';
    WebMessageDlg1.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    WebMessageDlg1.ElementTitleClassName := 'text-body';
    WebMessageDlg1.ElementContentClassName := 'text-body';
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'GiftaidNotification';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.DisplayFormat := '#,##0';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetClaimRef.SetParentComponent(MainDataset);
    MainDatasetClaimRef.Name := 'MainDatasetClaimRef';
    MainDatasetClaimRef.FieldName := 'ClaimRef';
    MainDatasetClaimRef.Size := 32;
    MainDatasetLetterThreshold.SetParentComponent(MainDataset);
    MainDatasetLetterThreshold.Name := 'MainDatasetLetterThreshold';
    MainDatasetLetterThreshold.FieldName := 'LetterThreshold';
    MainDatasetLetterThreshold.Required := True;
    MainDatasetEmailThreshold.SetParentComponent(MainDataset);
    MainDatasetEmailThreshold.Name := 'MainDatasetEmailThreshold';
    MainDatasetEmailThreshold.FieldName := 'EmailThreshold';
    MainDatasetEmailThreshold.Required := True;
    MainDatasetSalesDateCutOff.SetParentComponent(MainDataset);
    MainDatasetSalesDateCutOff.Name := 'MainDatasetSalesDateCutOff';
    MainDatasetSalesDateCutOff.FieldName := 'SalesDateCutOff';
    MainDatasetSalesDateCutOff.Required := True;
    MainDatasetLetterDate.SetParentComponent(MainDataset);
    MainDatasetLetterDate.Name := 'MainDatasetLetterDate';
    MainDatasetLetterDate.FieldName := 'LetterDate';
    MainDatasetLetterDate.Required := True;
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Required := True;
    MainDatasetStatus.Size := 11;
    MainDatasetEarliestSubmisionDate.SetParentComponent(MainDataset);
    MainDatasetEarliestSubmisionDate.Name := 'MainDatasetEarliestSubmisionDate';
    MainDatasetEarliestSubmisionDate.FieldName := 'EarliestSubmisionDate';
    MainDatasetEarliestSubmisionDate.Required := True;
    MainDatasetEarliestClaimableDate.SetParentComponent(MainDataset);
    MainDatasetEarliestClaimableDate.Name := 'MainDatasetEarliestClaimableDate';
    MainDatasetEarliestClaimableDate.FieldName := 'EarliestClaimableDate';
    MainDatasetEarliestClaimableDate.Required := True;
    MainDatasetEmailCount.SetParentComponent(MainDataset);
    MainDatasetEmailCount.Name := 'MainDatasetEmailCount';
    MainDatasetEmailCount.FieldName := 'EmailCount';
    MainDatasetEmailCount.DisplayFormat := '#,##0';
    MainDatasetNotNeededCount.SetParentComponent(MainDataset);
    MainDatasetNotNeededCount.Name := 'MainDatasetNotNeededCount';
    MainDatasetNotNeededCount.FieldName := 'NotNeededCount';
    MainDatasetNotNeededCount.DisplayFormat := '#,##0';
    MainDatasetLetterCount.SetParentComponent(MainDataset);
    MainDatasetLetterCount.Name := 'MainDatasetLetterCount';
    MainDatasetLetterCount.FieldName := 'LetterCount';
    MainDatasetLetterCount.DisplayFormat := '#,##0';
    MainDatasetUnderThresholdCount.SetParentComponent(MainDataset);
    MainDatasetUnderThresholdCount.Name := 'MainDatasetUnderThresholdCount';
    MainDatasetUnderThresholdCount.FieldName := 'UnderThresholdCount';
    MainDatasetEarliestSalesDate.SetParentComponent(MainDataset);
    MainDatasetEarliestSalesDate.Name := 'MainDatasetEarliestSalesDate';
    MainDatasetEarliestSalesDate.FieldName := 'EarliestSalesDate';
    MainDatasetLatestSalesDate.SetParentComponent(MainDataset);
    MainDatasetLatestSalesDate.Name := 'MainDatasetLatestSalesDate';
    MainDatasetLatestSalesDate.FieldName := 'LatestSalesDate';
    MainDatasetGiftAidId.SetParentComponent(MainDataset);
    MainDatasetGiftAidId.Name := 'MainDatasetGiftAidId';
    MainDatasetGiftAidId.FieldName := 'GiftAidId';
    MainDatasetTotalCount.SetParentComponent(MainDataset);
    MainDatasetTotalCount.Name := 'MainDatasetTotalCount';
    MainDatasetTotalCount.FieldKind := fkCalculated;
    MainDatasetTotalCount.FieldName := 'TotalCount';
    MainDatasetTotalCount.DisplayFormat := '#,##0';
    MainDatasetTotalCount.Calculated := True;
    MainDatasetGiftAidClaim.SetParentComponent(MainDataset);
    MainDatasetGiftAidClaim.Name := 'MainDatasetGiftAidClaim';
    MainDatasetGiftAidClaim.FieldName := 'GiftAidClaim';
    MainDatasetGiftAidClaim.DisplayFormat := '#,##0.00';
    MainDatasetDonationsValue.SetParentComponent(MainDataset);
    MainDatasetDonationsValue.Name := 'MainDatasetDonationsValue';
    MainDatasetDonationsValue.FieldName := 'DonationsValue';
    MainDatasetDonationsValue.DisplayFormat := '#,##0.00';
    MainDatasetSalesCount.SetParentComponent(MainDataset);
    MainDatasetSalesCount.Name := 'MainDatasetSalesCount';
    MainDatasetSalesCount.FieldName := 'SalesCount';
    MainDatasetSalesCount.DisplayFormat := '#,##0';
    MainDatasetNonRefundDeductions.SetParentComponent(MainDataset);
    MainDatasetNonRefundDeductions.Name := 'MainDatasetNonRefundDeductions';
    MainDatasetNonRefundDeductions.FieldName := 'NonRefundDeductions';
    MainDatasetRefunded.SetParentComponent(MainDataset);
    MainDatasetRefunded.Name := 'MainDatasetRefunded';
    MainDatasetRefunded.FieldName := 'Refunded';
    MainDatasetCancellationJob.SetParentComponent(MainDataset);
    MainDatasetCancellationJob.Name := 'MainDatasetCancellationJob';
    MainDatasetCancellationJob.FieldName := 'CancellationJob';
    MainDatasetProductionJob.SetParentComponent(MainDataset);
    MainDatasetProductionJob.Name := 'MainDatasetProductionJob';
    MainDatasetProductionJob.FieldName := 'ProductionJob';
    MainDatasetStatusText.SetParentComponent(MainDataset);
    MainDatasetStatusText.Name := 'MainDatasetStatusText';
    MainDatasetStatusText.FieldKind := fkCalculated;
    MainDatasetStatusText.FieldName := 'StatusText';
    MainDatasetStatusText.Size := 40;
    MainDatasetStatusText.Calculated := True;
  finally
    edSearch.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetClaimRef.AfterLoadDFMValues;
    MainDatasetLetterThreshold.AfterLoadDFMValues;
    MainDatasetEmailThreshold.AfterLoadDFMValues;
    MainDatasetSalesDateCutOff.AfterLoadDFMValues;
    MainDatasetLetterDate.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
    MainDatasetEarliestSubmisionDate.AfterLoadDFMValues;
    MainDatasetEarliestClaimableDate.AfterLoadDFMValues;
    MainDatasetEmailCount.AfterLoadDFMValues;
    MainDatasetNotNeededCount.AfterLoadDFMValues;
    MainDatasetLetterCount.AfterLoadDFMValues;
    MainDatasetUnderThresholdCount.AfterLoadDFMValues;
    MainDatasetEarliestSalesDate.AfterLoadDFMValues;
    MainDatasetLatestSalesDate.AfterLoadDFMValues;
    MainDatasetGiftAidId.AfterLoadDFMValues;
    MainDatasetTotalCount.AfterLoadDFMValues;
    MainDatasetGiftAidClaim.AfterLoadDFMValues;
    MainDatasetDonationsValue.AfterLoadDFMValues;
    MainDatasetSalesCount.AfterLoadDFMValues;
    MainDatasetNonRefundDeductions.AfterLoadDFMValues;
    MainDatasetRefunded.AfterLoadDFMValues;
    MainDatasetCancellationJob.AfterLoadDFMValues;
    MainDatasetProductionJob.AfterLoadDFMValues;
    MainDatasetStatusText.AfterLoadDFMValues;
  end;
end;

end.
