unit ExportDonorStatusesForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls, WEBLib.Toast;

type
  TExportDonorStatuses = class(TJobBase)
  private
    { Private declarations }

  protected
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: boolean; override;

  public
    { Public declarations }

  protected procedure LoadDFMValues; override; end;

var
  ExportDonorStatuses: TExportDonorStatuses;

implementation

uses
  AppUtils;

{$R *.dfm}

{ TExportDonorStatuses }

function TExportDonorStatuses.CanPlaceJob: boolean;
begin
  Result := inherited CanPlaceJob;

  if Result then
  begin
    while not JobsPlaced.eof do
    begin
      if TSysHelper.JobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new export while there is still one in progress');
        Exit(False);

      end;
      JobsPlaced.Next;

    end;

  end;

end;

function TExportDonorStatuses.JobClass: string;
begin
  Result := 'TREDonorStatusExport';

end;

function TExportDonorStatuses.JobDescription: string;
begin
  Result := 'Export Donor Statuses for Rasier''s Edge';

end;

function TExportDonorStatuses.JobDisplayName: string;
begin
  Result := 'Donor Status Export';

end;

procedure TExportDonorStatuses.LoadDFMValues;
begin
  inherited LoadDFMValues;


  OutputOption.BeforeLoadDFMValues;
  JobVisibility.BeforeLoadDFMValues;
  JobMessage.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  try
    OutputOption.Left := 214;
    OutputOption.Top := 256;
    JobVisibility.Left := 63;
    JobVisibility.Top := 256;
    JobMessage.Top := 312;
    JobRunType.Left := 365;
    JobRunType.Top := 256;
  finally
    OutputOption.AfterLoadDFMValues;
    JobVisibility.AfterLoadDFMValues;
    JobMessage.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
  end;
end;

end.

