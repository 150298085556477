/// <summary>
/// see <href="https://support.tmssoftware.com/t/web-core-xdata-service-query-example-v2/15041">
/// Andrew's WebCore Article</see>
/// </summary>
unit ReportResult;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Grids,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.CDS,
  XData.Web.JsonDataset,
  XData.Web.Dataset;

type
  TReportResultForm = class(TCoreWebForm)
    ReportResultGrid: TDBGrid;
    ReportResultSource: TDataSource;
    ResultCloseButton: TButton;
    ReportResultDataset: TXDataWebDataSet;
    WebLabel1: TLabel;
    procedure ResultCloseButtonClick(Sender: TObject);
  private
    { Private declarations }
    procedure SetData(const Value: JS.TJSObject);
  public
    { Public declarations }
    property Data: JS.TJSObject write SetData;
  protected procedure LoadDFMValues; override; end;

var
  ReportResultForm: TReportResultForm;

implementation

{$R *.dfm}

procedure TReportResultForm.ResultCloseButtonClick(Sender: TObject);
begin
  ModalResult := mrClose;
end;

{ TReportResultForm }

procedure TReportResultForm.SetData(const Value: JS.TJSObject);
var
  lValue: JS.TJSObject;
  JFDBS: JS.TJSObject;
  JManager: JS.TJSObject;
  JTableList: JS.TJSObject;
  JColumnList: JS.TJSArray;
  JRowList: JS.TJSArray;

  i: Integer;
  lDataType: string;
  lField: TField;
  Dataset: TXDataWebDataSet;
  lCol: TGridColumn;
begin
  Dataset := ReportResultDataset;
  Dataset.DisableControls;
  try

    JFDBS := JS.toObject(lValue['FDBS']);
    JManager := TJSObject(JFDBS['Manager']);
    JTableList := TJSObject(TJSArray(JManager['TableList'])[0]);
    JColumnList := TJSArray(JTableList['ColumnList']);
    JRowList := TJSArray(JTableList['RowList']);

    for i := 0 to JRowList.Length - 1 do
      JRowList.Elements[i] := TJSObject(JRowList.Elements[i])['Original'];

    ReportResultGrid.BeginUpdate;
    try
      ReportResultGrid.Columns.Clear;

      for i := 0 to JColumnList.Length - 1 do
      begin
        lDataType := string(TJSObject(JColumnList.Elements[i])['DataType']);
        lField := nil;

        if (lDataType = 'AnsiString') or (lDataType = 'WideString') then
        begin
          lField := TStringField.Create(Dataset);
          lField.FieldName := string(TJSObject(JColumnList.Elements[i])['Name']);
          lField.Size := Integer(TJSObject(JColumnList.Elements[i])['Size']);
          lField.Dataset := Dataset;
        end
        else if (lDataType = 'Int32') then
        begin
          lField := TIntegerField.Create(Dataset);
          lField.FieldName := string(TJSObject(JColumnList.Elements[i])['Name']);
          lField.Dataset := Dataset;
        end
        else if (lDataType = 'DateTimeStamp') then
        begin
          lField := TDateTimeField.Create(Dataset);
          lField.FieldName := string(TJSObject(JColumnList.Elements[i])['Name']);
          lField.Dataset := Dataset;
        end
        else if (lDataType = 'Date') then
        begin
          lField := TDateField.Create(Dataset);
          lField.FieldName := string(TJSObject(JColumnList.Elements[i])['Name']);
          lField.Dataset := Dataset;
        end

        else
        begin
          console.log('ERROR: Field [' + string(TJSObject(JColumnList.Elements[i])['Name']) +
            '] has an unexpected datatype [' + string(TJSObject(JColumnList.Elements[i])['DataType']) + ']');
        end;

        if lField <> nil then
        begin
          lCol := ReportResultGrid.Columns.Add;
          lCol.DataField := lField.FieldName;
          lCol.Title := lField.FieldName;
          lCol.Width := 72;
          //x := lCol.Index
        end;

      end;

      ReportResultGrid.ColWidths[0] := 15;

    finally
      ReportResultGrid.EndUpdate;
    end;

    Dataset.SetJSONData(JRowList);

    Dataset.Open;

    WebLabel1.Caption := Dataset.RecordCount.ToString;

  finally
    Dataset.EnableControls;
  end;

end;

procedure TReportResultForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  ReportResultGrid := TDBGrid.Create('ReportResultGrid');
  ResultCloseButton := TButton.Create('ResultCloseButton');
  ReportResultSource := TDataSource.Create(Self);
  ReportResultDataset := TXDataWebDataSet.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  ReportResultGrid.BeforeLoadDFMValues;
  ResultCloseButton.BeforeLoadDFMValues;
  ReportResultSource.BeforeLoadDFMValues;
  ReportResultDataset.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 168;
    WebLabel1.Top := 628;
    WebLabel1.Width := 58;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    ReportResultGrid.SetParentComponent(Self);
    ReportResultGrid.Name := 'ReportResultGrid';
    ReportResultGrid.Left := 72;
    ReportResultGrid.Top := 168;
    ReportResultGrid.Width := 785;
    ReportResultGrid.Height := 401;
    ReportResultGrid.Columns.Clear;
    with ReportResultGrid.Columns.Add do
    begin
    end;
    with ReportResultGrid.Columns.Add do
    begin
    end;
    with ReportResultGrid.Columns.Add do
    begin
    end;
    with ReportResultGrid.Columns.Add do
    begin
    end;
    ReportResultGrid.DataSource := ReportResultSource;
    ReportResultGrid.ElementPosition := epIgnore;
    ReportResultGrid.FixedCols := 1;
    ReportResultGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goColSizing,goThumbTracking,goFixedRowDefAlign];
    ReportResultGrid.TabOrder := 1;
    ReportResultGrid.HeightStyle := ssAuto;
    ReportResultGrid.HeightPercent := 100.000000000000000000;
    ReportResultGrid.ShowIndicator := False;
    ReportResultGrid.WidthStyle := ssAuto;
    ReportResultGrid.WidthPercent := 100.000000000000000000;
    ReportResultGrid.ColWidths[0] := 24;
    ReportResultGrid.ColWidths[1] := 64;
    ReportResultGrid.ColWidths[2] := 64;
    ReportResultGrid.ColWidths[3] := 64;
    ReportResultGrid.ColWidths[4] := 64;
    ResultCloseButton.SetParentComponent(Self);
    ResultCloseButton.Name := 'ResultCloseButton';
    ResultCloseButton.Left := 736;
    ResultCloseButton.Top := 624;
    ResultCloseButton.Width := 96;
    ResultCloseButton.Height := 25;
    ResultCloseButton.Caption := 'Close';
    ResultCloseButton.ChildOrder := 2;
    ResultCloseButton.ElementClassName := 'btn btn-primary';
    ResultCloseButton.ElementFont := efCSS;
    ResultCloseButton.ElementPosition := epIgnore;
    ResultCloseButton.HeightStyle := ssAuto;
    ResultCloseButton.HeightPercent := 100.000000000000000000;
    ResultCloseButton.WidthStyle := ssAuto;
    ResultCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(ResultCloseButton, Self, 'OnClick', 'ResultCloseButtonClick');
    ReportResultSource.SetParentComponent(Self);
    ReportResultSource.Name := 'ReportResultSource';
    ReportResultSource.DataSet := ReportResultDataset;
    ReportResultSource.Left := 312;
    ReportResultSource.Top := 96;
    ReportResultDataset.SetParentComponent(Self);
    ReportResultDataset.Name := 'ReportResultDataset';
    ReportResultDataset.Left := 185;
    ReportResultDataset.Top := 96;
  finally
    WebLabel1.AfterLoadDFMValues;
    ReportResultGrid.AfterLoadDFMValues;
    ResultCloseButton.AfterLoadDFMValues;
    ReportResultSource.AfterLoadDFMValues;
    ReportResultDataset.AfterLoadDFMValues;
  end;
end;

end.
