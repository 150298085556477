unit DupesForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  DonorBaseViewForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Grids,
  WEBLib.Grids;

type
  TDupesDisplayForm = class(TDonorBaseView)
    DupesList: TDBTableControl;
    dpPrior: TButton;
    dpNext: TButton;
    MatchButton: TButton;
    procedure dpNextClick(Sender: TObject);
    procedure dpPriorClick(Sender: TObject);
    procedure MatchButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    FMatchId: Integer;
    procedure SetMatchingElements(const Value: string);
    { Private declarations }
  protected
    procedure AfterSetDataset; override;
    function GetTheModalResult: TModalResult; override;
  public
    { Public declarations }
    property MatchingElements: string write SetMatchingElements;
    property MatchId: Integer read FMatchId;
  protected procedure LoadDFMValues; override; end;

var
  DupesDisplayForm: TDupesDisplayForm;

implementation

uses
  App.Helper;

{$R *.dfm}

procedure TDupesDisplayForm.SetMatchingElements(const Value: string);
var
  lElements: TArray<string>;
  lElement: string;
begin
  if Value = '' then
    Exit;

  Assert(Value.IndexOf(' ') = -1, 'no spaces allowed');
  Assert(Value.IndexOf('#') = -1, 'no # allowed');

  lElements := Value.Split([',']);

  //Leading '#' will be added by the method
  for lElement in lElements do
    THTMLHelper.addClass('dp' + lElement, 'text-info border border-info');

end;

procedure TDupesDisplayForm.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TDupesDisplayForm }

procedure TDupesDisplayForm.AfterSetDataset;
begin
  inherited;
  //DupesGrid.Visible := Datasource.DataSet.RecordCount > 1;
  dpPrior.Visible := Datasource.DataSet.RecordCount > 1;
  dpNext.Visible := dpPrior.Visible;
end;

procedure TDupesDisplayForm.dpNextClick(Sender: TObject);
begin
  Datasource.DataSet.Next;
  dpNext.Enabled := (not Datasource.DataSet.Eof);
  dpPrior.Enabled := (not Datasource.DataSet.Bof);
end;

procedure TDupesDisplayForm.dpPriorClick(Sender: TObject);
begin
  Datasource.DataSet.Prior;
  dpPrior.Enabled := (not Datasource.DataSet.Bof);
  dpNext.Enabled := (not Datasource.DataSet.Eof);
end;

function TDupesDisplayForm.GetTheModalResult: TModalResult;
begin
  if FMatchId > 0 then
    Result := mrYes
  else
    Result := inherited;
end;

procedure TDupesDisplayForm.MatchButtonClick(Sender: TObject);
var
  lMaster: Integer;
begin
  lMaster := Datasource.DataSet.FieldByName('MasterId').AsInteger;
  if lMaster = 0 then
  begin
    FMatchId := Datasource.DataSet.FieldByName('Id').AsInteger;
    Datasource.DataSet.Edit;
    Datasource.DataSet.FieldByName('MasterId').AsInteger := FMatchId;
    Datasource.DataSet.Post;
  end
  else
    FMatchId := Datasource.DataSet.FieldByName('MasterId').AsInteger;

end;

procedure TDupesDisplayForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DupesList := TDBTableControl.Create('DupesList');
  dpPrior := TButton.Create('dpPrior');
  dpNext := TButton.Create('dpNext');
  MatchButton := TButton.Create('MatchButton');

  dpConsIdLabel.BeforeLoadDFMValues;
  dpOldRefLabel.BeforeLoadDFMValues;
  DupesList.BeforeLoadDFMValues;
  dpPrior.BeforeLoadDFMValues;
  dpNext.BeforeLoadDFMValues;
  MatchButton.BeforeLoadDFMValues;
  try
    dpConsIdLabel.ElementPosition := epIgnore;
    dpOldRefLabel.ElementPosition := epIgnore;
    DupesList.SetParentComponent(Self);
    DupesList.Name := 'DupesList';
    DupesList.Left := 360;
    DupesList.Top := 37;
    DupesList.Width := 300;
    DupesList.Height := 200;
    DupesList.HeightStyle := ssAuto;
    DupesList.WidthStyle := ssAuto;
    DupesList.HeightPercent := 100.000000000000000000;
    DupesList.WidthPercent := 100.000000000000000000;
    DupesList.BorderColor := clSilver;
    DupesList.ChildOrder := 22;
    DupesList.ElementFont := efCSS;
    DupesList.ElementHeaderClassName := 'thead-light';
    DupesList.ElementPosition := epIgnore;
    DupesList.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    DupesList.Columns.Clear;
    with DupesList.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DupesList.Columns.Add do
    begin
      DataField := 'OldRef';
      Title := 'Old Id';
    end;
    with DupesList.Columns.Add do
    begin
      DataField := 'ConsId';
      Title := 'Cons Id';
    end;
    with DupesList.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'Given Name';
    end;
    with DupesList.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Family Name';
    end;
    DupesList.DataSource := DataSource;
    dpPrior.SetParentComponent(Self);
    dpPrior.Name := 'dpPrior';
    dpPrior.Left := 368;
    dpPrior.Top := 256;
    dpPrior.Width := 57;
    dpPrior.Height := 25;
    dpPrior.Caption := '<';
    dpPrior.ChildOrder := 15;
    dpPrior.ElementClassName := 'btn btn-primary';
    dpPrior.ElementFont := efCSS;
    dpPrior.ElementPosition := epIgnore;
    dpPrior.Enabled := False;
    dpPrior.HeightStyle := ssAuto;
    dpPrior.HeightPercent := 100.000000000000000000;
    dpPrior.WidthStyle := ssAuto;
    dpPrior.WidthPercent := 100.000000000000000000;
    SetEvent(dpPrior, Self, 'OnClick', 'dpPriorClick');
    dpNext.SetParentComponent(Self);
    dpNext.Name := 'dpNext';
    dpNext.Left := 368;
    dpNext.Top := 287;
    dpNext.Width := 57;
    dpNext.Height := 25;
    dpNext.Caption := '>';
    dpNext.ChildOrder := 16;
    dpNext.ElementClassName := 'btn btnprimary';
    dpNext.ElementFont := efCSS;
    dpNext.ElementPosition := epIgnore;
    dpNext.HeightStyle := ssAuto;
    dpNext.HeightPercent := 100.000000000000000000;
    dpNext.WidthStyle := ssAuto;
    dpNext.WidthPercent := 100.000000000000000000;
    SetEvent(dpNext, Self, 'OnClick', 'dpNextClick');
    MatchButton.SetParentComponent(Self);
    MatchButton.Name := 'MatchButton';
    MatchButton.Left := 304;
    MatchButton.Top := 416;
    MatchButton.Width := 96;
    MatchButton.Height := 25;
    MatchButton.Caption := 'it'#39's a match!';
    MatchButton.ChildOrder := 17;
    MatchButton.ElementClassName := 'btn btn-primary';
    MatchButton.ElementFont := efCSS;
    MatchButton.ElementPosition := epIgnore;
    MatchButton.HeightStyle := ssAuto;
    MatchButton.HeightPercent := 100.000000000000000000;
    MatchButton.WidthStyle := ssAuto;
    MatchButton.WidthPercent := 100.000000000000000000;
    SetEvent(MatchButton, Self, 'OnClick', 'MatchButtonClick');
  finally
    dpConsIdLabel.AfterLoadDFMValues;
    dpOldRefLabel.AfterLoadDFMValues;
    DupesList.AfterLoadDFMValues;
    dpPrior.AfterLoadDFMValues;
    dpNext.AfterLoadDFMValues;
    MatchButton.AfterLoadDFMValues;
  end;
end;

end.

