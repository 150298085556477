unit SalesDataEditForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls;

type
  TLastIdResultType = (ltrEmpty, ltrOK, ltrNotFound, ltrFailed);

  TEditSalesData = class(TForm)
    SalesEditId: TEdit;
    SalesEditSaveButton: TButton;
    SalesEditCancelButton: TButton;
    SalesEditAmount: TEdit;
    SalesEditMessage: THTMLSpan;
    VoidCheckBox: TCheckBox;
    procedure SalesEditAmountChange(Sender: TObject);
    procedure SalesEditCancelButtonClick(Sender: TObject);
    procedure SalesEditIdExit(Sender: TObject);
    procedure SalesEditSaveButtonClick(Sender: TObject);
    procedure VoidCheckBoxClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    FCreatingForm: Boolean;
    FOriginalEditId: string;
    FOriginalEditAmount: Double;
    FEditClaimStatus: string;
    FDonorId: Integer;
    FDonorRef: string;
    FLastAttemptedId: string;
    FLastIdResult: TLastIdResultType;
    function GetEditAmount: Double;
    function GetEditId: string;
    procedure SetEditAmount(const Value: Double);
    procedure SetEditId(const Value: string);
    procedure SetSaveStatus;
    [async]
     procedure EditIdChanged; async;
    [async]
    function ValidateEditId: Boolean; async;
    [async]
    procedure SaveSalesEdit; async;
    [async]
    procedure AfterSetEditClaimStatus; async;
    procedure SetEditClaimStatus(const Value: string);
  public
    { Public declarations }
    property EditId: string read GetEditId write SetEditId;
    property EditAmount: Double read GetEditAmount write SetEditAmount;
    property DonorId: Integer read FDonorId;
    property DonorRef: string read FDonorRef;
    property EditClaimStatus: string read FEditClaimStatus write SetEditClaimStatus;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  App.Helper,
  AppUtils,
  MainDataModule;

procedure TEditSalesData.WebFormCreate(Sender: TObject);
begin
  FCreatingForm := True;
  FOriginalEditId := '';;
  FOriginalEditAmount := 0;
  SalesEditId.Text := '';
  SalesEditAmount.Text := '';
  FLastAttemptedId := '';
  FLastIdResult := ltrOK;
  FCreatingForm := False;
end;

{ TEditSalesData }

procedure TEditSalesData.AfterSetEditClaimStatus;
var
  lRetval: TXDataClientResponse;
  lName: String;
begin
  if FEditClaimStatus <> 'Void' then
  begin
   lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_GET_DONORNAME, [FOriginalEditId]));
   lName := JS.ToString(lRetval.ResultAsObject['value']);
   if lName <> 'Oops' then
   begin
     SalesEditMessage.HTML.Text := 'Donor: ' + lName;
     SalesEditMessage.Visible := True;
   end;
  end;
end;

procedure TEditSalesData.EditIdChanged;
var
  lOK: Boolean;
begin
  if FCreatingForm then
     Exit;
  lOK := await(Boolean, ValidateEditId);
  SetSaveStatus;
end;

function TEditSalesData.GetEditAmount: Double;
begin
  Result := StrToFloatDef(SalesEditAmount.Text, 0);
end;

function TEditSalesData.GetEditId: string;
begin
  Result := SalesEditId.Text;
  Result := Result.Trim.ToUpper;
  if Result.Contains('O') then
  begin
    Result := Result.Replace('O', '0');
    SalesEditId.Text := Result;
  end;
end;

procedure TEditSalesData.SalesEditAmountChange(Sender: TObject);
begin
  SetSaveStatus;
end;

procedure TEditSalesData.SalesEditCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TEditSalesData.SalesEditIdExit(Sender: TObject);
begin
  EditIdChanged;
end;

procedure TEditSalesData.SalesEditSaveButtonClick(Sender: TObject);
begin
  SaveSalesEdit;
end;

procedure TEditSalesData.SaveSalesEdit;
var
  lRetval: Boolean;
begin

  if (EditId <> FOriginalEditId) and (not VoidCheckBox.Visible) then
    lRetval := await(Boolean, ValidateEditId)
  else
    lRetval := True;

  if lRetval then
    ModalResult := mrOK;
end;

procedure TEditSalesData.SetEditAmount(const Value: Double);
begin
  SalesEditAmount.Text := Value.ToString;
  FOriginalEditAmount := Value;
end;

procedure TEditSalesData.SetEditClaimStatus(const Value: string);
begin
  FEditClaimStatus := Value;
  AfterSetEditClaimStatus;
end;

procedure TEditSalesData.SetEditId(const Value: string);
var lId: String;
begin
  lId := Value.Trim(['[',']']);
  SalesEditId.Text := lId;
  FOriginalEditId := lId;
end;

procedure TEditSalesData.SetSaveStatus;
var
  lAmt: Double;
  lIdOK, lAmtOK, lAmtValid: Boolean;
begin

   case FLastIdResult of
     ltrEmpty: lIdOK := False;
     ltrOK: lIdOK := True;
     ltrNotFound: lIdOK := VoidCheckBox.Checked;
     ltrFailed: lIdOK := False;
   end;

   if not lIdOK then
   begin
     SalesEditSaveButton.Enabled := False;
     Exit;
  end;

  lAmt := StrToFloatDef(SalesEditAmount.Text, -999999);

  lAmtValid := (SalesEditAmount.Text <> '') and (lAmt > -999999);

  lAmtOK := lAmtValid and (lAmt <> FOriginalEditAmount);

  lIdOK := (EditId <> FOriginalEditId);

  SalesEditSaveButton.Enabled := (lIdOK or lAmtOK);

end;

function TEditSalesData.ValidateEditId: Boolean;
var
  lDonorId, lStatus, lMessage: string;
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
begin
  Result := False;

  lDonorId := EditId;

  if lDonorId = '' then
  begin
    VoidCheckBox.Visible := False;
     VoidCheckBox.Checked := False;
     SalesEditMessage.HTML.Text := '';
    Exit(False);
  end;

  if (lDonorId = FOriginalEditId) then
  begin
//     FLastAttemptedId := lDonorId;
//     FLastIdResult := ltrOK;
//     VoidCheckBox.Visible := False;
//     VoidCheckBox.Checked := False;
//     SalesEditMessage.HTML.Text := '';
     Exit(True);
  end;

  if lDonorId = FLastAttemptedId then
  begin
    case FLastIdResult of
//      ltrNotTested: ;
      ltrOK: Exit(True);
      ltrNotFound: Exit(VoidCheckBox.Checked);
      ltrFailed: Exit(False);
    end;
  end;

  FLastAttemptedId := lDonorId;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_VALIDATE_DONORID, [lDonorId]));
  lResult := lRetval.ResultAsObject;

  lStatus := JS.ToString(lResult['Status']);
  if (lStatus = 'Exists') then
  begin
    FLastIdResult := ltrOK;
    FDonorId := JS.toInteger(lResult['DonorId']);
    FDonorRef := JS.ToString(lResult['OldRef']);
    FEditClaimStatus := 'Unclaimed';
    SalesEditMessage.HTML.Text := JS.toString(lResult['DonorName']);
    VoidCheckBox.Visible := False;
    Result := True;
  end
  else
  begin
    lMessage := JS.ToString(lResult['StatusMessage']);
    if lStatus = 'NotFound' then
    begin
      FLastIdResult := ltrNotFound;
      FEditClaimStatus := 'Void';
      VoidCheckBox.Visible := True;
      SalesEditMessage.HTML.Text := lMessage + ' Tick here if you want to save this as a Void sale.';
    end
    else
    begin
      FLastIdResult := ltrFailed;
      SalesEditMessage.HTML.Text := lMessage;
    end;
  end;
end;

procedure TEditSalesData.VoidCheckBoxClick(Sender: TObject);
begin
  SetSaveStatus;
end;

procedure TEditSalesData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SalesEditId := TEdit.Create('SalesEditId');
  SalesEditSaveButton := TButton.Create('SalesEditSaveButton');
  SalesEditCancelButton := TButton.Create('SalesEditCancelButton');
  SalesEditAmount := TEdit.Create('SalesEditAmount');
  SalesEditMessage := THTMLSpan.Create('SalesEditMessage');
  VoidCheckBox := TCheckBox.Create('VoidCheckBox');

  SalesEditId.BeforeLoadDFMValues;
  SalesEditSaveButton.BeforeLoadDFMValues;
  SalesEditCancelButton.BeforeLoadDFMValues;
  SalesEditAmount.BeforeLoadDFMValues;
  SalesEditMessage.BeforeLoadDFMValues;
  VoidCheckBox.BeforeLoadDFMValues;
  try
    Name := 'EditSalesData';
    Width := 402;
    Height := 220;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'PopUpForm';
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SalesEditId.SetParentComponent(Self);
    SalesEditId.Name := 'SalesEditId';
    SalesEditId.Left := 112;
    SalesEditId.Top := 64;
    SalesEditId.Width := 121;
    SalesEditId.Height := 22;
    SalesEditId.CharCase := wecUpperCase;
    SalesEditId.ChildOrder := 1;
    SalesEditId.ElementClassName := 'form-control';
    SalesEditId.HeightPercent := 100.000000000000000000;
    SalesEditId.Text := 'SalesEditId';
    SalesEditId.WidthPercent := 100.000000000000000000;
    SetEvent(SalesEditId, Self, 'OnExit', 'SalesEditIdExit');
    SalesEditSaveButton.SetParentComponent(Self);
    SalesEditSaveButton.Name := 'SalesEditSaveButton';
    SalesEditSaveButton.Left := 224;
    SalesEditSaveButton.Top := 168;
    SalesEditSaveButton.Width := 73;
    SalesEditSaveButton.Height := 25;
    SalesEditSaveButton.Caption := 'Save';
    SalesEditSaveButton.ChildOrder := 2;
    SalesEditSaveButton.ElementClassName := 'btn btn-primary';
    SalesEditSaveButton.ElementFont := efCSS;
    SalesEditSaveButton.Enabled := False;
    SalesEditSaveButton.HeightStyle := ssAuto;
    SalesEditSaveButton.HeightPercent := 100.000000000000000000;
    SalesEditSaveButton.TabOrder := 3;
    SalesEditSaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SalesEditSaveButton, Self, 'OnClick', 'SalesEditSaveButtonClick');
    SalesEditCancelButton.SetParentComponent(Self);
    SalesEditCancelButton.Name := 'SalesEditCancelButton';
    SalesEditCancelButton.Left := 303;
    SalesEditCancelButton.Top := 168;
    SalesEditCancelButton.Width := 73;
    SalesEditCancelButton.Height := 25;
    SalesEditCancelButton.Caption := 'Cancel';
    SalesEditCancelButton.ChildOrder := 2;
    SalesEditCancelButton.ElementClassName := 'btn btn-secondary';
    SalesEditCancelButton.ElementFont := efCSS;
    SalesEditCancelButton.HeightStyle := ssAuto;
    SalesEditCancelButton.HeightPercent := 100.000000000000000000;
    SalesEditCancelButton.TabOrder := 4;
    SalesEditCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(SalesEditCancelButton, Self, 'OnClick', 'SalesEditCancelButtonClick');
    SalesEditAmount.SetParentComponent(Self);
    SalesEditAmount.Name := 'SalesEditAmount';
    SalesEditAmount.Left := 112;
    SalesEditAmount.Top := 96;
    SalesEditAmount.Width := 121;
    SalesEditAmount.Height := 22;
    SalesEditAmount.ChildOrder := 2;
    SalesEditAmount.EditType := weFloat;
    SalesEditAmount.ElementClassName := 'form-control';
    SalesEditAmount.ElementFont := efCSS;
    SalesEditAmount.HeightStyle := ssAuto;
    SalesEditAmount.HeightPercent := 100.000000000000000000;
    SalesEditAmount.TabOrder := 1;
    SalesEditAmount.Text := 'SalesEditAmount';
    SalesEditAmount.WidthPercent := 100.000000000000000000;
    SetEvent(SalesEditAmount, Self, 'OnChange', 'SalesEditAmountChange');
    SalesEditMessage.SetParentComponent(Self);
    SalesEditMessage.Name := 'SalesEditMessage';
    SalesEditMessage.Left := 112;
    SalesEditMessage.Top := 124;
    SalesEditMessage.Width := 100;
    SalesEditMessage.Height := 25;
    SalesEditMessage.ChildOrder := 4;
    SalesEditMessage.ElementFont := efCSS;
    SalesEditMessage.Role := '';
    VoidCheckBox.SetParentComponent(Self);
    VoidCheckBox.Name := 'VoidCheckBox';
    VoidCheckBox.Left := 232;
    VoidCheckBox.Top := 128;
    VoidCheckBox.Width := 17;
    VoidCheckBox.Height := 22;
    VoidCheckBox.ChildOrder := 5;
    VoidCheckBox.Color := clNone;
    VoidCheckBox.ElementClassName := 'form-control custom-checkbox';
    VoidCheckBox.ElementButtonClassName := 'form-control-input';
    VoidCheckBox.ElementLabelClassName := 'form-control-label';
    VoidCheckBox.ElementFont := efCSS;
    VoidCheckBox.ElementPosition := epIgnore;
    VoidCheckBox.HeightStyle := ssAuto;
    VoidCheckBox.HeightPercent := 100.000000000000000000;
    VoidCheckBox.State := cbUnchecked;
    VoidCheckBox.Visible := False;
    VoidCheckBox.WidthStyle := ssAuto;
    VoidCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(VoidCheckBox, Self, 'OnClick', 'VoidCheckBoxClick');
  finally
    SalesEditId.AfterLoadDFMValues;
    SalesEditSaveButton.AfterLoadDFMValues;
    SalesEditCancelButton.AfterLoadDFMValues;
    SalesEditAmount.AfterLoadDFMValues;
    SalesEditMessage.AfterLoadDFMValues;
    VoidCheckBox.AfterLoadDFMValues;
  end;
end;

end.
