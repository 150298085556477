unit JobErrorForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseCoreForm, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, Data.DB, WEBLib.CDS,
  WEBLib.DB, WEBLib.Grids, WEBLib.DBCtrls;

type
  TJobErrors = class(TCoreWebForm)
    ErrorDataSet: TClientDataSet;
    ErrorConnection: TClientConnection;
    JobSource: TDataSource;
    ErrorDataSetkey: TStringField;
    ErrorDataSetvalue: TStringField;
    ErrorDataSeterror: TStringField;
    WebDBTableControl1: TDBTableControl;
    ErrorSource: TDataSource;
    JobErrorCloseButton: TButton;
    procedure ErrorConnectionBeforeConnect(Sender: TObject);
    procedure ErrorConnectionDataReceived(Sender: TObject; ARequest:
        TJSXMLHttpRequestRecord; var AResponse: string);
    procedure JobErrorCloseButtonClick(Sender: TObject);
  private
    procedure SetDataset(const Value: TDataset);
    { Private declarations }
  public
    { Public declarations }
    property Dataset: TDataset write SetDataset;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule, Auth.Service;

{$R *.dfm}

procedure TJobErrors.ErrorConnectionBeforeConnect(Sender: TObject);
begin
 ErrorConnection.Headers.Add('Authorization=' + 'Bearer ' + AuthService.GetToken);
end;

procedure TJobErrors.ErrorConnectionDataReceived(Sender: TObject; ARequest:
    TJSXMLHttpRequestRecord; var AResponse: string);
//    var lResponse, lsomething: string;
begin
{ TODO : ???? }
//  lResponse := AResponse;
//  lsomething := 'data received';

end;

procedure TJobErrors.JobErrorCloseButtonClick(Sender: TObject);
begin
   ModalResult := mrClose;
end;

{ TJobErrors }

procedure TJobErrors.SetDataset(const Value: TDataset);
begin
  JobSource.DataSet := Value;
  ErrorConnection.URI := MainData.DataConnection.URL + '/JobsService/GetErrorFile?AJobId=' + JobSource.DataSet.FieldByName('Id').AsString;
  ErrorConnection.Active := True;
end;

procedure TJobErrors.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebDBTableControl1 := TDBTableControl.Create(Self);
  JobErrorCloseButton := TButton.Create('JobErrorCloseButton');
  ErrorDataSet := TClientDataSet.Create(Self);
  ErrorDataSetkey := TStringField.Create(Self);
  ErrorDataSetvalue := TStringField.Create(Self);
  ErrorDataSeterror := TStringField.Create(Self);
  ErrorConnection := TClientConnection.Create(Self);
  JobSource := TDataSource.Create(Self);
  ErrorSource := TDataSource.Create(Self);

  CloseThisButton.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  JobErrorCloseButton.BeforeLoadDFMValues;
  ErrorDataSet.BeforeLoadDFMValues;
  ErrorDataSetkey.BeforeLoadDFMValues;
  ErrorDataSetvalue.BeforeLoadDFMValues;
  ErrorDataSeterror.BeforeLoadDFMValues;
  ErrorConnection.BeforeLoadDFMValues;
  JobSource.BeforeLoadDFMValues;
  ErrorSource.BeforeLoadDFMValues;
  try
    CloseThisButton.Visible := False;
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 96;
    WebDBTableControl1.Top := 144;
    WebDBTableControl1.Width := 425;
    WebDBTableControl1.Height := 200;
    WebDBTableControl1.HeightStyle := ssAuto;
    WebDBTableControl1.WidthStyle := ssAuto;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ChildOrder := 1;
    WebDBTableControl1.ElementFont := efCSS;
    WebDBTableControl1.ElementHeaderClassName := 'thead-light';
    WebDBTableControl1.ElementPosition := epIgnore;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'key';
      Title := 'Key';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'value';
      Title := 'Value';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'error';
      Title := 'Error';
    end;
    WebDBTableControl1.DataSource := ErrorSource;
    JobErrorCloseButton.SetParentComponent(Self);
    JobErrorCloseButton.Name := 'JobErrorCloseButton';
    JobErrorCloseButton.Left := 496;
    JobErrorCloseButton.Top := 424;
    JobErrorCloseButton.Width := 96;
    JobErrorCloseButton.Height := 25;
    JobErrorCloseButton.Caption := 'Close';
    JobErrorCloseButton.ChildOrder := 2;
    JobErrorCloseButton.ElementClassName := 'btn btn-secondary';
    JobErrorCloseButton.ElementFont := efCSS;
    JobErrorCloseButton.ElementPosition := epIgnore;
    JobErrorCloseButton.HeightStyle := ssAuto;
    JobErrorCloseButton.HeightPercent := 100.000000000000000000;
    JobErrorCloseButton.WidthStyle := ssAuto;
    JobErrorCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(JobErrorCloseButton, Self, 'OnClick', 'JobErrorCloseButtonClick');
    ErrorDataSet.SetParentComponent(Self);
    ErrorDataSet.Name := 'ErrorDataSet';
    ErrorDataSet.Active := True;
    ErrorDataSet.Connection := ErrorConnection;
    ErrorDataSet.Left := 65;
    ErrorDataSet.Top := 96;
    ErrorDataSetkey.SetParentComponent(ErrorDataSet);
    ErrorDataSetkey.Name := 'ErrorDataSetkey';
    ErrorDataSetkey.FieldName := 'key';
    ErrorDataSetkey.Size := 64;
    ErrorDataSetvalue.SetParentComponent(ErrorDataSet);
    ErrorDataSetvalue.Name := 'ErrorDataSetvalue';
    ErrorDataSetvalue.FieldName := 'value';
    ErrorDataSetvalue.Size := 64;
    ErrorDataSeterror.SetParentComponent(ErrorDataSet);
    ErrorDataSeterror.Name := 'ErrorDataSeterror';
    ErrorDataSeterror.FieldName := 'error';
    ErrorDataSeterror.Size := 512;
    ErrorConnection.SetParentComponent(Self);
    ErrorConnection.Name := 'ErrorConnection';
    ErrorConnection.Active := False;
    ErrorConnection.DataNode := 'value';
    ErrorConnection.URI := 'http://localhost:2015/tenovus/JobsService/GetErrorFile?AJobId=27';
    ErrorConnection.BeforeConnect := ErrorConnectionBeforeConnect;
    SetEvent(ErrorConnection, Self, 'OnDataReceived', 'ErrorConnectionDataReceived');
    ErrorConnection.Left := 64;
    ErrorConnection.Top := 32;
    JobSource.SetParentComponent(Self);
    JobSource.Name := 'JobSource';
    JobSource.Left := 184;
    JobSource.Top := 32;
    ErrorSource.SetParentComponent(Self);
    ErrorSource.Name := 'ErrorSource';
    ErrorSource.DataSet := ErrorDataSet;
    ErrorSource.Left := 144;
    ErrorSource.Top := 88;
  finally
    CloseThisButton.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    JobErrorCloseButton.AfterLoadDFMValues;
    ErrorDataSet.AfterLoadDFMValues;
    ErrorDataSetkey.AfterLoadDFMValues;
    ErrorDataSetvalue.AfterLoadDFMValues;
    ErrorDataSeterror.AfterLoadDFMValues;
    ErrorConnection.AfterLoadDFMValues;
    JobSource.AfterLoadDFMValues;
    ErrorSource.AfterLoadDFMValues;
  end;
end;

end.