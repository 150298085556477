unit DonorList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  XData.Web.Client,
  AppUtils,
  GA.Shop.Shared;

type
  TDonorListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetConsId: TIntegerField;
    MainDatasetOldRef: TStringField;
    MainDatasetShopRef: TStringField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetTelephone: TStringField;
    MainDatasetGiftAidScheme: TStringField;
    MainDatasetTaxReportRequested: TBooleanField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetScannedDocsExist: TBooleanField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    ShopsLookup: TXDataWebDataSet;
    ShopsLookupRef: TStringField;
    ShopsLookupRegionRef: TStringField;
    ShopsLookupName: TStringField;
    ShopsLookupStatus: TStringField;
    MainDatasetShopName: TStringField;
    ShopFilter: TLookupComboBox;
    ShopsLookupLocationType: TStringField;
    MainDatasetFurtherContact: TBooleanField;
    MainDatasetComments: TStringField;
    MainDatasetDateSigned: TDateField;
    MainDatasetContactByPhone: TBooleanField;
    MainDatasetContactByPost: TBooleanField;
    MainDatasetContactByText: TBooleanField;
    MainDatasetContactByEmail: TBooleanField;
    MainDatasetREExported: TDateTimeField;
    MainDatasetREImported: TDateTimeField;
    MainDatasetMatchHash: TStringField;
    MainDatasetMasterId: TIntegerField;
    AdvancedSearchButton: TButton;
    MainDatasetClaimStatusText: TStringField;
    MainDatasetFullName: TStringField;
    MainDatasetFullAddress: TStringField;
    MainDatasetLastStatement: TIntegerField;
    MainDatasetLastStatementText: TStringField;
    procedure AdvancedSearchButtonClick(Sender: TObject);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
    procedure ShopFilterChange(Sender: TObject);
    procedure ShopsLookupAfterOpen(Dataset: TDataSet);
  private
    FBuildingQuery: Boolean;
    FNotificationId: Integer;
    FShops: JS.TJSArray;
    FOpenNotifications: JS.TJSArray;
    FOpenNotifcationCount: Integer;
    FAdvancedSearch: Boolean;
    [async]
    procedure ShowSalesRecord(const ARecordId: Integer); async;
    [async]
    procedure GetOpenNotifications; async;
    [async]
    procedure DoAdvancedSearch; async;
    [async]
    procedure ShowRefundForm(const ARecordId: Integer); async;
    { Private declarations }
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    [async]
    procedure EditRecord(const ARecordId: string); async; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SetEditFormLookUps(AForm: TForm); override;
    procedure SearchData(const Value: string); override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    [async]
    procedure AfterEditFormLoaded(AForm: TBaseEditForm); async; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DonorListForm: TDonorListForm;

implementation

uses
  MainDataModule,
  DonorForm,
  Grid.Settings,
  DonorSalesForm,
  DonorBaseViewForm,
  AdvancedSearchForm,
  DonorRefund,
  DonorRefundForm;

{$R *.dfm}

procedure TDonorListForm.WebFormCreate(Sender: TObject);
begin
  GetOpenNotifications;
  inherited;
end;

{ TDonorListForm }

procedure TDonorListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure SalesClick(Sender: TObject);
  begin
    ShowSalesRecord(ARecordId.ToInteger);
  end;

  procedure RefundClick(Sender: TObject);
  begin
    ShowRefundForm(ARecordId.ToInteger);
  end;

var
  Span, RefundSpan: THTMLSpan;
begin
  inherited;

  if RecordViewOption <> voNone then
  begin
    Span := TSysHelper.RowActionSpan(AParentElement, 'fad fa-pound-sign', 'view sales');
    Span.OnClick := @SalesClick;
  end;

  if FOpenNotifcationCount > 0 then
  begin
    RefundSpan := TSysHelper.RowActionSpan(AParentElement, 'fad fa-undo-alt', 'refunds');
    RefundSpan.OnClick := @RefundClick;
  end;
end;

procedure TDonorListForm.AdvancedSearchButtonClick(Sender: TObject);
begin
  DoAdvancedSearch;
end;

procedure TDonorListForm.AfterEditFormLoaded(AForm: TBaseEditForm);
begin
  inherited;
  AForm.Width := 860;
end;

procedure TDonorListForm.DoAdvancedSearch;
var
  AForm: TAdvancedSearch;
  lRetval: TModalResult;
  lQuery: string;
begin
  AForm := TAdvancedSearch.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TAdvancedSearch, AForm.Load());
    TSysHelper.LookUpValuesLoad(ShopFilter.LookUpValues, AForm.ShopSelect.LookUpValues);
    lRetval := await(TModalResult, AForm.Execute);
    if lRetval = mrOK then
    begin
      FAdvancedSearch := True;
      lQuery := AForm.FilterQuery;
      if lQuery <> '' then
        FGridPlugin.SetPreparedFilter(lQuery);
    end;
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TDonorListForm.EditRecord(const ARecordId: string);
var
  AForm: TDonorEditForm;
  lRetval: TModalResult;
  lReload: Boolean;
  lNewId: Integer;
begin
  if FListRefreshing then
  begin
    { TODO : Show message? }
    Exit;
  end;

  lReload := False;

  AForm := TDonorEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TBaseEditForm, AForm.Load());
    AForm.IdField := FIdField;
    SetEditFormLookUps(AForm);
    AForm.RecordViewOption := Self.RecordViewOption;

    AfterEditFormLoaded(AForm);

    if ARecordId = '' then
      MainDataset.Insert
    else
      MainDataset.Locate(FIdField, ARecordId, []);

    AForm.Dataset := MainDataset;

    lRetval := await(TModalResult, AForm.Execute);

    lReload :=  (lRetval = mrOK) and AForm.NeedsFullRefresh;

    lNewId := AForm.NewId;

    if lReload and (AForm.RefreshMessage <> '') then
       ShowAnAwesomeToast('Donor Id Changed', AForm.RefreshMessage, 'fa fa-info-circle');

  finally
    AForm.Free;
    AForm := nil;
  end;

  if lReload then
  begin
    if (lNewId > 0) and (edSearch.Text = ARecordId) then
       edSearch.Text := lNewId.ToString;
    SearchButton.Click;
  end;

end;

function TDonorListForm.GetEditFormClass: TFormClass;
begin
  Result := TDonorEditForm;
end;

procedure TDonorListForm.GetOpenNotifications;
var
  lRetval: TXDataClientResponse;
  lNotify: TJSObject;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_OPEN_NOTIFICATIONS, []));
  FOpenNotifications := JS.ToArray(lRetval.ResultAsObject['value']);
  FOpenNotifcationCount := FOpenNotifications.Length;
  if FOpenNotifcationCount > 0 then
  begin
    lNotify := TJSObject(FOpenNotifications[0]);
    FNotificationId := JS.ToInteger(lNotify['NotificationId']);
  end
  else FNotificationId := 0;

end;

procedure TDonorListForm.LoadData;
begin
  ShopsLookup.Load;
end;

procedure TDonorListForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetClaimStatusText.Value := TSysHelper.SplitOnCaps(MainDatasetClaimStatus.Value);
  MainDatasetFullName.Value := TSysHelper.FullName(MainDataset);
  MainDatasetFullAddress.Value := TSysHelper.FormattedAddress(MainDataset);
  MainDatasetLastStatementText.Value := TSysHelper.TaxYearStr(MainDatasetLastStatement.Value);
end;

procedure TDonorListForm.MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := TSysHelper.SplitOnCaps(Text);
end;

procedure TDonorListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TDonorListForm.RecordViewOption: TViewOption;
begin
  Result := voEdit;
end;

procedure TDonorListForm.SearchData(const Value: string);
var
  lPostCode: TPostCodePart;
  lShopQuery, lQuery, lValue2: string;
begin
  { TODO : Handle if Shop selected }
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  FAdvancedSearch := False;

  if (ShopFilter.Value <> '') and (ShopFilter.Value <> 'All') then
    lShopQuery := format('(ShopRef eq ''%s'') and ', [ShopFilter.Value])
  else
    lShopQuery := '';

  lPostCode := TSysHelper.IsPostCode(Value);
  if lPostCode = pcFull then
    lQuery := format('(PostCode eq ''%s'')', [Uppercase(Value)])
  else if lPostCode = pcPartial then
    lQuery := format('(startswith(PostCode, ''%s''))', [Uppercase(Value)])
  else if TSysHelper.IsRef(Value) then
    lQuery := format('(OldRef eq ''%s'')', [Uppercase(Value)])
  else if (Value.Chars[0] = 'G') and TSyshelper.IsInteger(Value.Substring(1)) then
    lQuery := format('(Id eq %s)', [Value.Substring(1)])
  else if TSysHelper.IsId(Value) then
    lQuery := format('((Id eq %s) or (ConsId eq %s))', [Value, Value])
  else if TSysHelper.IsPhoneNumber(Value) then
  begin
    if Value.Contains(' ') then
    begin
      lValue2 := Value.Replace(' ', '', [rfReplaceAll]);
      lQuery := format('((Telephone eq ''%s'') or (Mobile eq ''%s'') or (Telephone eq ''%s'') or (Mobile eq ''%s''))', [Value, Value, lValue2, lValue2])
    end
    else
      lQuery := format('((Telephone eq ''%s'') or (Mobile eq ''%s''))', [Value, Value])

  end
  else if Pos('@', Value) > 0 then
    lQuery := format('Email eq ''%s''', [Lowercase(Value)])
  else
  begin
    lValue2 := Value.ToUpper;
    lQuery := format('startswith(upper(LastName), ''%s'') or startswith(upper(Add1), ''%s'') or startswith(upper(FirstName), ''%s'')',
      [lValue2, lValue2, lValue2]);
  end;
  FGridPlugin.SetPreparedFilter(lShopQuery + lQuery);

  FBuildingQuery := False;
end;

procedure TDonorListForm.SetEditFormLookUps(AForm: TForm);
begin
  TDonorEditForm(AForm).SetLookUpValues('shops', ShopFilter.LookUpValues);
end;

procedure TDonorListForm.ShopFilterChange(Sender: TObject);
begin
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;
  edSearch.Text := '';

  if ShopFilter.Value = 'All' then
    FGridPlugin.SetFilterText('', True)
  else
    FGridPlugin.SetDefinedSearch(ShopFilter.Value, [TSearchField.Create('ShopRef')]);

  FBuildingQuery := False;
end;

procedure TDonorListForm.ShopsLookupAfterOpen(Dataset: TDataSet);
begin
  FShops := TSysHelper.LoadXDataRows(ShopsLookup);

  ShopFilter.LookUpValues.AddPair('All', 'All Shops');
  ShopsLookup.First;
  while not ShopsLookup.eof do
  begin
    if not(ShopsLookupLocationType.Value = 'System') then
      ShopFilter.LookUpValues.AddPair(ShopsLookupRef.Value, ShopsLookupName.Value);
    ShopsLookup.Next;
  end;

  UnLockControls(True);
  // Open a blank dataset
  // FGridPlugin.SetDefinedSearch('-1', [TSearchField.CreateIdSearch('Id')]);
end;

procedure TDonorListForm.ShowRefundForm(const ARecordId: Integer);
var
  lRetval: TXDataClientResponse;
  lTotals: JS.TJSObject;
  lRefundId: Integer;
  AForm: TDonorRefundDetail;
  AForm2: TDonorRefundFinal;
begin

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_REFUND_TOTALS,
    [ARecordId, FNotificationId]));
  lTotals := JS.toObject(lRetval.ResultAsObject);

  if JS.toNumber(lTotals['TotalSales']) = 0 then
  begin
    ShowMessage('There are no sales available to refund for this donor.');
    Exit;
  end
  else if not JS.toBoolean(lTotals['Notified']) then
  begin
    ShowMessage
      ('Although there are sales for this donor which will be included in the next GiftAid claim, there was no need to to notify them.');
    Exit;
  end;

  if JS.toString(lTotals['ClaimStatus']) = 'RefundRequest' then
  begin
    lRefundId := JS.ToInteger(lTotals['RefundId']);
    if lRefundId = 0 then
    begin
      ShowMessage('There wasn''t a refund id mapped for this. Please check with support.');
      Exit;
    end;

    AForm2 := TDonorRefundFinal.Create(Self);
    try
      AForm2.Popup := True;
      AForm2.PopupOpacity := 1;
      await(TDonorRefundDetail, AForm2.Load());
      MainDataset.Locate(FIdField, ARecordId, []);
      // AForm2.Dataset := MainDataset;
      AForm2.RefundId := lRefundId.toString;
      await(TModalResult, AForm2.Execute);
    finally
      AForm2.Free;
      AForm2 := nil;
    end;
  end
  else
  begin
    AForm := TDonorRefundDetail.Create(Self);
    try
      AForm.Popup := True;
      AForm.PopupOpacity := 1;
      await(TDonorRefundDetail, AForm.Load());
      TSysHelper.JsonToClientDatset(FShops, AForm.Shops);
      AForm.OpenNotifications := FOpenNotifications;
      AForm.RefundTotals := lTotals;
      MainDataset.Locate(FIdField, ARecordId, []);
      AForm.Dataset := MainDataset;
      await(TModalResult, AForm.Execute);
    finally
      AForm.Free;
      AForm := nil;
    end;
  end;
end;

procedure TDonorListForm.ShowSalesRecord(const ARecordId: Integer);
var
  AForm: TDonorSalesDetail;
begin
  AForm := TDonorSalesDetail.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TDonorSalesDetail, AForm.Load());
    TSysHelper.JsonToClientDatset(FShops, AForm.Shops);
    MainDataset.Locate(FIdField, ARecordId, []);
    AForm.Dataset := MainDataset;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TDonorListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopFilter := TLookupComboBox.Create('ShopFilter');
  AdvancedSearchButton := TButton.Create('AdvancedSearchButton');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetConsId := TIntegerField.Create(Self);
  MainDatasetOldRef := TStringField.Create(Self);
  MainDatasetShopRef := TStringField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetTelephone := TStringField.Create(Self);
  MainDatasetGiftAidScheme := TStringField.Create(Self);
  MainDatasetTaxReportRequested := TBooleanField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetScannedDocsExist := TBooleanField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetShopName := TStringField.Create(Self);
  MainDatasetFurtherContact := TBooleanField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetDateSigned := TDateField.Create(Self);
  MainDatasetContactByPhone := TBooleanField.Create(Self);
  MainDatasetContactByPost := TBooleanField.Create(Self);
  MainDatasetContactByText := TBooleanField.Create(Self);
  MainDatasetContactByEmail := TBooleanField.Create(Self);
  MainDatasetREExported := TDateTimeField.Create(Self);
  MainDatasetREImported := TDateTimeField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetMasterId := TIntegerField.Create(Self);
  MainDatasetClaimStatusText := TStringField.Create(Self);
  MainDatasetFullName := TStringField.Create(Self);
  MainDatasetFullAddress := TStringField.Create(Self);
  MainDatasetLastStatement := TIntegerField.Create(Self);
  MainDatasetLastStatementText := TStringField.Create(Self);
  ShopsLookup := TXDataWebDataSet.Create(Self);
  ShopsLookupRef := TStringField.Create(Self);
  ShopsLookupRegionRef := TStringField.Create(Self);
  ShopsLookupName := TStringField.Create(Self);
  ShopsLookupStatus := TStringField.Create(Self);
  ShopsLookupLocationType := TStringField.Create(Self);

  edSearch.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  ShopFilter.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  AdvancedSearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetConsId.BeforeLoadDFMValues;
  MainDatasetOldRef.BeforeLoadDFMValues;
  MainDatasetShopRef.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetTelephone.BeforeLoadDFMValues;
  MainDatasetGiftAidScheme.BeforeLoadDFMValues;
  MainDatasetTaxReportRequested.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetScannedDocsExist.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetShopName.BeforeLoadDFMValues;
  MainDatasetFurtherContact.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetDateSigned.BeforeLoadDFMValues;
  MainDatasetContactByPhone.BeforeLoadDFMValues;
  MainDatasetContactByPost.BeforeLoadDFMValues;
  MainDatasetContactByText.BeforeLoadDFMValues;
  MainDatasetContactByEmail.BeforeLoadDFMValues;
  MainDatasetREExported.BeforeLoadDFMValues;
  MainDatasetREImported.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetMasterId.BeforeLoadDFMValues;
  MainDatasetClaimStatusText.BeforeLoadDFMValues;
  MainDatasetFullName.BeforeLoadDFMValues;
  MainDatasetFullAddress.BeforeLoadDFMValues;
  MainDatasetLastStatement.BeforeLoadDFMValues;
  MainDatasetLastStatementText.BeforeLoadDFMValues;
  ShopsLookup.BeforeLoadDFMValues;
  ShopsLookupRef.BeforeLoadDFMValues;
  ShopsLookupRegionRef.BeforeLoadDFMValues;
  ShopsLookupName.BeforeLoadDFMValues;
  ShopsLookupStatus.BeforeLoadDFMValues;
  ShopsLookupLocationType.BeforeLoadDFMValues;
  try
    edSearch.Hint := 'Any Id, Last Name, Post Code, 1st line of address';
    edSearch.ShowHint := True;
    edSearch.TextHint := 'Any Id, Last Name, Post Code, 1st line of address';
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'OldRef';
      Title := 'Old Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ConsId';
      Title := 'Cons Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'First Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Surname';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatusText';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Telephone';
      Title := 'Telephone';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ShopName';
      Title := 'ShopName';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ShopFilter.SetParentComponent(Self);
    ShopFilter.Name := 'ShopFilter';
    ShopFilter.Left := 176;
    ShopFilter.Top := 67;
    ShopFilter.Width := 145;
    ShopFilter.Height := 22;
    ShopFilter.ElementClassName := 'form-select';
    ShopFilter.ElementFont := efCSS;
    ShopFilter.HeightPercent := 100.000000000000000000;
    ShopFilter.WidthPercent := 100.000000000000000000;
    SetEvent(ShopFilter, Self, 'OnChange', 'ShopFilterChange');
    ShopFilter.ItemIndex := -1;
    AddButton.Visible := False;
    AdvancedSearchButton.SetParentComponent(Self);
    AdvancedSearchButton.Name := 'AdvancedSearchButton';
    AdvancedSearchButton.Left := 574;
    AdvancedSearchButton.Top := 65;
    AdvancedSearchButton.Width := 56;
    AdvancedSearchButton.Height := 25;
    AdvancedSearchButton.Caption := 'Advanced Search';
    AdvancedSearchButton.ChildOrder := 10;
    AdvancedSearchButton.ElementClassName := 'btn btn-primary';
    AdvancedSearchButton.ElementFont := efCSS;
    AdvancedSearchButton.ElementPosition := epIgnore;
    AdvancedSearchButton.HeightStyle := ssAuto;
    AdvancedSearchButton.HeightPercent := 100.000000000000000000;
    AdvancedSearchButton.WidthStyle := ssAuto;
    AdvancedSearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(AdvancedSearchButton, Self, 'OnClick', 'AdvancedSearchButtonClick');
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'Donor';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetConsId.SetParentComponent(MainDataset);
    MainDatasetConsId.Name := 'MainDatasetConsId';
    MainDatasetConsId.FieldName := 'ConsId';
    MainDatasetOldRef.SetParentComponent(MainDataset);
    MainDatasetOldRef.Name := 'MainDatasetOldRef';
    MainDatasetOldRef.FieldName := 'OldRef';
    MainDatasetOldRef.Size := 10;
    MainDatasetShopRef.SetParentComponent(MainDataset);
    MainDatasetShopRef.Name := 'MainDatasetShopRef';
    MainDatasetShopRef.FieldName := 'ShopRef';
    MainDatasetShopRef.Size := 10;
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 75;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 75;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 75;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 75;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 75;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetTelephone.SetParentComponent(MainDataset);
    MainDatasetTelephone.Name := 'MainDatasetTelephone';
    MainDatasetTelephone.FieldName := 'Telephone';
    MainDatasetTelephone.Size := 25;
    MainDatasetGiftAidScheme.SetParentComponent(MainDataset);
    MainDatasetGiftAidScheme.Name := 'MainDatasetGiftAidScheme';
    MainDatasetGiftAidScheme.FieldName := 'GiftAidScheme';
    MainDatasetGiftAidScheme.Size := 8;
    MainDatasetTaxReportRequested.SetParentComponent(MainDataset);
    MainDatasetTaxReportRequested.Name := 'MainDatasetTaxReportRequested';
    MainDatasetTaxReportRequested.FieldName := 'TaxReportRequested';
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    SetEvent(MainDatasetClaimStatus, Self, 'OnGetText', 'MainDatasetClaimStatusGetText');
    MainDatasetClaimStatus.Size := 22;
    MainDatasetScannedDocsExist.SetParentComponent(MainDataset);
    MainDatasetScannedDocsExist.Name := 'MainDatasetScannedDocsExist';
    MainDatasetScannedDocsExist.FieldName := 'ScannedDocsExist';
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetShopName.SetParentComponent(MainDataset);
    MainDatasetShopName.Name := 'MainDatasetShopName';
    MainDatasetShopName.FieldKind := fkLookup;
    MainDatasetShopName.FieldName := 'ShopName';
    MainDatasetShopName.LookupDataSet := ShopsLookup;
    MainDatasetShopName.LookupKeyFields := 'Ref';
    MainDatasetShopName.LookupResultField := 'Name';
    MainDatasetShopName.KeyFields := 'ShopRef';
    MainDatasetShopName.Size := 50;
    MainDatasetShopName.Lookup := True;
    MainDatasetFurtherContact.SetParentComponent(MainDataset);
    MainDatasetFurtherContact.Name := 'MainDatasetFurtherContact';
    MainDatasetFurtherContact.FieldName := 'FurtherContact';
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 255;
    MainDatasetDateSigned.SetParentComponent(MainDataset);
    MainDatasetDateSigned.Name := 'MainDatasetDateSigned';
    MainDatasetDateSigned.FieldName := 'DateSigned';
    MainDatasetContactByPhone.SetParentComponent(MainDataset);
    MainDatasetContactByPhone.Name := 'MainDatasetContactByPhone';
    MainDatasetContactByPhone.FieldName := 'ContactByPhone';
    MainDatasetContactByPost.SetParentComponent(MainDataset);
    MainDatasetContactByPost.Name := 'MainDatasetContactByPost';
    MainDatasetContactByPost.FieldName := 'ContactByPost';
    MainDatasetContactByText.SetParentComponent(MainDataset);
    MainDatasetContactByText.Name := 'MainDatasetContactByText';
    MainDatasetContactByText.FieldName := 'ContactByText';
    MainDatasetContactByEmail.SetParentComponent(MainDataset);
    MainDatasetContactByEmail.Name := 'MainDatasetContactByEmail';
    MainDatasetContactByEmail.FieldName := 'ContactByEmail';
    MainDatasetREExported.SetParentComponent(MainDataset);
    MainDatasetREExported.Name := 'MainDatasetREExported';
    MainDatasetREExported.FieldName := 'REExported';
    MainDatasetREImported.SetParentComponent(MainDataset);
    MainDatasetREImported.Name := 'MainDatasetREImported';
    MainDatasetREImported.FieldName := 'REImported';
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetMasterId.SetParentComponent(MainDataset);
    MainDatasetMasterId.Name := 'MainDatasetMasterId';
    MainDatasetMasterId.FieldName := 'MasterId';
    MainDatasetClaimStatusText.SetParentComponent(MainDataset);
    MainDatasetClaimStatusText.Name := 'MainDatasetClaimStatusText';
    MainDatasetClaimStatusText.FieldKind := fkCalculated;
    MainDatasetClaimStatusText.FieldName := 'ClaimStatusText';
    MainDatasetClaimStatusText.Size := 25;
    MainDatasetClaimStatusText.Calculated := True;
    MainDatasetFullName.SetParentComponent(MainDataset);
    MainDatasetFullName.Name := 'MainDatasetFullName';
    MainDatasetFullName.FieldKind := fkCalculated;
    MainDatasetFullName.FieldName := 'FullName';
    MainDatasetFullName.Size := 50;
    MainDatasetFullName.Calculated := True;
    MainDatasetFullAddress.SetParentComponent(MainDataset);
    MainDatasetFullAddress.Name := 'MainDatasetFullAddress';
    MainDatasetFullAddress.FieldKind := fkCalculated;
    MainDatasetFullAddress.FieldName := 'FullAddress';
    MainDatasetFullAddress.Size := 250;
    MainDatasetFullAddress.Calculated := True;
    MainDatasetLastStatement.SetParentComponent(MainDataset);
    MainDatasetLastStatement.Name := 'MainDatasetLastStatement';
    MainDatasetLastStatement.FieldName := 'LastStatement';
    MainDatasetLastStatementText.SetParentComponent(MainDataset);
    MainDatasetLastStatementText.Name := 'MainDatasetLastStatementText';
    MainDatasetLastStatementText.FieldKind := fkCalculated;
    MainDatasetLastStatementText.FieldName := 'LastStatementText';
    MainDatasetLastStatementText.Size := 10;
    MainDatasetLastStatementText.Calculated := True;
    ShopsLookup.SetParentComponent(Self);
    ShopsLookup.Name := 'ShopsLookup';
    ShopsLookup.AfterOpen := ShopsLookupAfterOpen;
    ShopsLookup.EntitySetName := 'Shop';
    ShopsLookup.Connection := MainData.DataConnection;
    ShopsLookup.QueryString := '$orderby=Name';
    ShopsLookup.Left := 352;
    ShopsLookup.Top := 8;
    ShopsLookupRef.SetParentComponent(ShopsLookup);
    ShopsLookupRef.Name := 'ShopsLookupRef';
    ShopsLookupRef.FieldName := 'Ref';
    ShopsLookupRef.Size := 5;
    ShopsLookupRegionRef.SetParentComponent(ShopsLookup);
    ShopsLookupRegionRef.Name := 'ShopsLookupRegionRef';
    ShopsLookupRegionRef.FieldName := 'RegionRef';
    ShopsLookupRegionRef.Size := 5;
    ShopsLookupName.SetParentComponent(ShopsLookup);
    ShopsLookupName.Name := 'ShopsLookupName';
    ShopsLookupName.FieldName := 'Name';
    ShopsLookupName.Size := 250;
    ShopsLookupStatus.SetParentComponent(ShopsLookup);
    ShopsLookupStatus.Name := 'ShopsLookupStatus';
    ShopsLookupStatus.FieldName := 'Status';
    ShopsLookupStatus.Size := 6;
    ShopsLookupLocationType.SetParentComponent(ShopsLookup);
    ShopsLookupLocationType.Name := 'ShopsLookupLocationType';
    ShopsLookupLocationType.FieldName := 'LocationType';
    ShopsLookupLocationType.Size := 10;
  finally
    edSearch.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    ShopFilter.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    AdvancedSearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetConsId.AfterLoadDFMValues;
    MainDatasetOldRef.AfterLoadDFMValues;
    MainDatasetShopRef.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetTelephone.AfterLoadDFMValues;
    MainDatasetGiftAidScheme.AfterLoadDFMValues;
    MainDatasetTaxReportRequested.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetScannedDocsExist.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetShopName.AfterLoadDFMValues;
    MainDatasetFurtherContact.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetDateSigned.AfterLoadDFMValues;
    MainDatasetContactByPhone.AfterLoadDFMValues;
    MainDatasetContactByPost.AfterLoadDFMValues;
    MainDatasetContactByText.AfterLoadDFMValues;
    MainDatasetContactByEmail.AfterLoadDFMValues;
    MainDatasetREExported.AfterLoadDFMValues;
    MainDatasetREImported.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetMasterId.AfterLoadDFMValues;
    MainDatasetClaimStatusText.AfterLoadDFMValues;
    MainDatasetFullName.AfterLoadDFMValues;
    MainDatasetFullAddress.AfterLoadDFMValues;
    MainDatasetLastStatement.AfterLoadDFMValues;
    MainDatasetLastStatementText.AfterLoadDFMValues;
    ShopsLookup.AfterLoadDFMValues;
    ShopsLookupRef.AfterLoadDFMValues;
    ShopsLookupRegionRef.AfterLoadDFMValues;
    ShopsLookupName.AfterLoadDFMValues;
    ShopsLookupStatus.AfterLoadDFMValues;
    ShopsLookupLocationType.AfterLoadDFMValues;
  end;
end;

end.
