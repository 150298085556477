unit RegionForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls, WEBLib.Actions, WEBLib.WebCtrls, WEBLib.ExtCtrls, WEBLib.Toast, Vcl.Imaging.GIFImg;

type
  TRegionEditForm = class(TBaseEditForm)
    RegionRef: TDBEdit;
    RegionName: TDBEdit;
    RegionStatus: TDBComboBox;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    protected
    procedure AfterSetDataset; override;
     function GetRequiredEditLevel: String; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  RegionEditForm: TRegionEditForm;

implementation

uses
  GA.Shop.Shared;

{$R *.dfm}

procedure TRegionEditForm.AfterSetDataset;
begin
  inherited;
  RegionRef.SetFocus;
end;


function TRegionEditForm.GetRequiredEditLevel: String;
begin
  Result := SCOPE_ADMIN;
end;

procedure TRegionEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FormCaption.Caption := 'Region Management';
  TrapEnterPressSetUp;
end;

procedure TRegionEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RegionRef := TDBEdit.Create('RegionRef');
  RegionName := TDBEdit.Create('RegionName');
  RegionStatus := TDBComboBox.Create('RegionStatus');

  RegionRef.BeforeLoadDFMValues;
  RegionName.BeforeLoadDFMValues;
  RegionStatus.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  try
    RegionRef.SetParentComponent(Self);
    RegionRef.Name := 'RegionRef';
    RegionRef.Left := 72;
    RegionRef.Top := 136;
    RegionRef.Width := 121;
    RegionRef.Height := 22;
    RegionRef.ChildOrder := 3;
    RegionRef.ElementClassName := 'form-control fcs';
    RegionRef.ElementPosition := epIgnore;
    RegionRef.HeightStyle := ssAuto;
    RegionRef.HeightPercent := 100.000000000000000000;
    RegionRef.Text := 'RegionRef';
    RegionRef.WidthStyle := ssAuto;
    RegionRef.WidthPercent := 100.000000000000000000;
    SetEvent(RegionRef, Self, 'OnKeyDown', 'CheckEnterKeyDown');
    RegionRef.DataField := 'Ref';
    RegionRef.DataSource := DataSource;
    RegionName.SetParentComponent(Self);
    RegionName.Name := 'RegionName';
    RegionName.Left := 72;
    RegionName.Top := 176;
    RegionName.Width := 121;
    RegionName.Height := 22;
    RegionName.ChildOrder := 1;
    RegionName.ElementClassName := 'form-control fcs';
    RegionName.ElementPosition := epIgnore;
    RegionName.HeightStyle := ssAuto;
    RegionName.HeightPercent := 100.000000000000000000;
    RegionName.TabOrder := 1;
    RegionName.Text := 'RegionName';
    RegionName.WidthStyle := ssAuto;
    RegionName.WidthPercent := 100.000000000000000000;
    SetEvent(RegionName, Self, 'OnKeyDown', 'CheckEnterKeyDown');
    RegionName.DataField := 'Name';
    RegionName.DataSource := DataSource;
    RegionStatus.SetParentComponent(Self);
    RegionStatus.Name := 'RegionStatus';
    RegionStatus.Left := 72;
    RegionStatus.Top := 224;
    RegionStatus.Width := 145;
    RegionStatus.Height := 23;
    RegionStatus.ElementClassName := 'form-select fcs';
    RegionStatus.ElementFont := efCSS;
    RegionStatus.ElementPosition := epIgnore;
    RegionStatus.HeightStyle := ssAuto;
    RegionStatus.HeightPercent := 100.000000000000000000;
    RegionStatus.TabOrder := 2;
    RegionStatus.Text := 'RegionStatus';
    RegionStatus.WidthStyle := ssAuto;
    RegionStatus.WidthPercent := 100.000000000000000000;
    SetEvent(RegionStatus, Self, 'OnKeyDown', 'CheckEnterKeyDown');
    RegionStatus.ItemIndex := -1;
    RegionStatus.Items.BeginUpdate;
    try
      RegionStatus.Items.Clear;
      RegionStatus.Items.Add('Active');
      RegionStatus.Items.Add('Deleted');
      RegionStatus.Items.Add('System');
    finally
      RegionStatus.Items.EndUpdate;
    end;
    RegionStatus.DataField := 'Status';
    RegionStatus.DataSource := DataSource;
    SaveButton.ElementClassName := 'btn btn-secondary fcs';
  finally
    RegionRef.AfterLoadDFMValues;
    RegionName.AfterLoadDFMValues;
    RegionStatus.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
  end;
end;

end.

