unit LoginForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  App.Types;

type
  TLoginPage = class(TForm)
    UserName: TEdit;
    Password: TEdit;
    LoginButton: TButton;
    MessageLabel: TLabel;
    ForgottenPasswordLabel: TLabel;
    procedure ForgottenPasswordLabelClick(Sender: TObject);
    procedure LoginButtonClick(Sender: TObject);
    procedure PasswordKeyPress(Sender: TObject; var Key: Char);
    procedure UserNameKeyPress(Sender: TObject; var Key: Char);
    procedure WebFormCreate(Sender: TObject);
  private
    FLoginSuccessProc: TSuccessProc;
    FMessage: string;
    FIsModalPopUp: Boolean;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    [async]
    procedure HandleForgottenPassword; async;
    procedure SetMessage(const Value: string);
    procedure SetIsModalPopUp(const Value: Boolean);
  public
    class procedure Display(AElementId: string; LoginSuccessProc: TSuccessProc); overload;
    class procedure Display(AElementId: string; LoginSuccessProc: TSuccessProc; AMsg: string); overload;
    property AMessage: string write SetMessage;
    property IsModalPopUp: Boolean write SetIsModalPopUp;
  protected procedure LoadDFMValues; override; end;

var
  LoginPage: TLoginPage;

implementation

uses
  Auth.Service,
  ForgottenPasswordForm;

{$R *.dfm}

procedure TLoginPage.WebFormCreate(Sender: TObject);
begin
  if FMessage <> '' then
    ShowNotification(FMessage)
  else
    HiddenNotification;
end;

{ TTLoginPage }

class procedure TLoginPage.Display(AElementId: string; LoginSuccessProc: TSuccessProc);
begin
  Display(AElementId, LoginSuccessProc, '');
end;

class procedure TLoginPage.Display(AElementId: string; LoginSuccessProc: TSuccessProc; AMsg: string);

  procedure AfterCreate(AForm: TObject);
  begin
    TLoginPage(AForm).FMessage := AMsg;
    TLoginPage(AForm).FLoginSuccessProc := LoginSuccessProc;
  end;

begin
  if Assigned(LoginPage) then
    LoginPage.Free;
  LoginPage := TLoginPage.CreateNew(AElementId, @AfterCreate);
end;

procedure TLoginPage.HandleForgottenPassword;
var
  AForm: TFortgottenPassword;
begin
  AForm := TFortgottenPassword.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TFortgottenPassword, AForm.Load());
    await(TModalResult, AForm.Execute);
    ShowNotification('If you have an account set up with an email address, a new password has been emailed to you');
  finally
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TLoginPage.ForgottenPasswordLabelClick(Sender: TObject);
begin
  HandleForgottenPassword;
end;

procedure TLoginPage.HiddenNotification;
begin
  MessageLabel.ElementHandle.hidden := True;
end;

procedure TLoginPage.LoginButtonClick(Sender: TObject);
  procedure LoginSuccess;
  begin
    if Assigned(FLoginSuccessProc) then
      FLoginSuccessProc
    else if FIsModalPopUp then
      ModalResult := MrOK;
  end;

  procedure LoginError(AMsg: string);
  begin
    ShowNotification('Login Error: ' + AMsg);
  end;

begin
  if (Trim(UserName.Text) = '') or (Trim(Password.Text) = '') then
  begin
    ShowNotification('Please enter your UserName and Password');
    Exit;
  end;

  AuthService.Login(UserName.Text, Password.Text, @LoginSuccess, @LoginError);
end;

procedure TLoginPage.PasswordKeyPress(Sender: TObject; var Key: Char);
begin
  if Key = #13 then
    LoginButton.Click;
end;

procedure TLoginPage.SetIsModalPopUp(const Value: Boolean);
begin
  FIsModalPopUp := Value;
  if Value then
  begin
     ElementClassName := 'PopUpForm Private';
  end;
end;

procedure TLoginPage.SetMessage(const Value: string);
begin
  ShowNotification(Value);
end;

procedure TLoginPage.ShowNotification(Notification: string);
begin
  if Notification <> '' then
  begin
    MessageLabel.Caption := Notification;
    MessageLabel.ElementHandle.hidden := False;
  end;
end;

procedure TLoginPage.UserNameKeyPress(Sender: TObject; var Key: Char);
begin
  if Key = #13 then
    Password.SetFocus;
end;

procedure TLoginPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MessageLabel := TLabel.Create('MessageLabel');
  ForgottenPasswordLabel := TLabel.Create('ForgottenPasswordLabel');
  UserName := TEdit.Create('UserName');
  Password := TEdit.Create('Password');
  LoginButton := TButton.Create('LoginButton');

  MessageLabel.BeforeLoadDFMValues;
  ForgottenPasswordLabel.BeforeLoadDFMValues;
  UserName.BeforeLoadDFMValues;
  Password.BeforeLoadDFMValues;
  LoginButton.BeforeLoadDFMValues;
  try
    Name := 'LoginPage';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 240;
    MessageLabel.Top := 96;
    MessageLabel.Width := 46;
    MessageLabel.Height := 15;
    MessageLabel.Caption := 'Message';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tSPAN;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    ForgottenPasswordLabel.SetParentComponent(Self);
    ForgottenPasswordLabel.Name := 'ForgottenPasswordLabel';
    ForgottenPasswordLabel.Left := 232;
    ForgottenPasswordLabel.Top := 272;
    ForgottenPasswordLabel.Width := 227;
    ForgottenPasswordLabel.Height := 13;
    ForgottenPasswordLabel.Cursor := crHandPoint;
    ForgottenPasswordLabel.Caption := 'Have you forgotten your password? Click here.';
    ForgottenPasswordLabel.ElementFont := efCSS;
    ForgottenPasswordLabel.Font.Charset := DEFAULT_CHARSET;
    ForgottenPasswordLabel.Font.Color := clWindowText;
    ForgottenPasswordLabel.Font.Height := -11;
    ForgottenPasswordLabel.Font.Name := 'Tahoma';
    ForgottenPasswordLabel.Font.Style := [fsUnderline];
    ForgottenPasswordLabel.HeightStyle := ssAuto;
    ForgottenPasswordLabel.HeightPercent := 100.000000000000000000;
    ForgottenPasswordLabel.HTMLType := tSPAN;
    ForgottenPasswordLabel.ParentFont := False;
    ForgottenPasswordLabel.WidthStyle := ssAuto;
    ForgottenPasswordLabel.WidthPercent := 100.000000000000000000;
    SetEvent(ForgottenPasswordLabel, Self, 'OnClick', 'ForgottenPasswordLabelClick');
    UserName.SetParentComponent(Self);
    UserName.Name := 'UserName';
    UserName.Left := 240;
    UserName.Top := 136;
    UserName.Width := 121;
    UserName.Height := 21;
    UserName.ElementClassName := 'form-control';
    UserName.ElementFont := efCSS;
    UserName.ElementPosition := epIgnore;
    UserName.HeightStyle := ssAuto;
    UserName.HeightPercent := 100.000000000000000000;
    UserName.TextHint := 'User';
    UserName.WidthStyle := ssAuto;
    UserName.WidthPercent := 100.000000000000000000;
    SetEvent(UserName, Self, 'OnKeyPress', 'UserNameKeyPress');
    Password.SetParentComponent(Self);
    Password.Name := 'Password';
    Password.Left := 240;
    Password.Top := 163;
    Password.Width := 121;
    Password.Height := 21;
    Password.ChildOrder := 1;
    Password.ElementClassName := 'form-control';
    Password.ElementFont := efCSS;
    Password.ElementPosition := epIgnore;
    Password.HeightStyle := ssAuto;
    Password.HeightPercent := 100.000000000000000000;
    Password.PasswordChar := '*';
    Password.TextHint := 'Password';
    Password.WidthStyle := ssAuto;
    Password.WidthPercent := 100.000000000000000000;
    SetEvent(Password, Self, 'OnKeyPress', 'PasswordKeyPress');
    LoginButton.SetParentComponent(Self);
    LoginButton.Name := 'LoginButton';
    LoginButton.Left := 240;
    LoginButton.Top := 190;
    LoginButton.Width := 121;
    LoginButton.Height := 35;
    LoginButton.Caption := 'Login';
    LoginButton.ChildOrder := 2;
    LoginButton.ElementClassName := 'btn btn-primary';
    LoginButton.ElementFont := efCSS;
    LoginButton.ElementPosition := epIgnore;
    LoginButton.HeightStyle := ssAuto;
    LoginButton.HeightPercent := 100.000000000000000000;
    LoginButton.WidthStyle := ssAuto;
    LoginButton.WidthPercent := 100.000000000000000000;
    SetEvent(LoginButton, Self, 'OnClick', 'LoginButtonClick');
  finally
    MessageLabel.AfterLoadDFMValues;
    ForgottenPasswordLabel.AfterLoadDFMValues;
    UserName.AfterLoadDFMValues;
    Password.AfterLoadDFMValues;
    LoginButton.AfterLoadDFMValues;
  end;
end;

end.
