unit ReportMaster;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  ReportParamsForm,
  AppUtils;

type
  TMainReportForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetReportName: TStringField;
    MainDatasetDescription: TStringField;
    MainDatasetParameters: TBlobField;
    MainDatasetReportGroup: TIntegerField;
    ReportResultInfo: THTMLSpan;
    MainDatasetReportType: TStringField;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FShopList: JS.TJSArray;
    FRegionList: JS.TJSArray;
    [ASync]
    function GetParams(const AReportId: Integer): string; async;

    [ASync]
    procedure RunReport(const ARecordId: Integer); async;
    [ASync]
    procedure DirectDownload(const ARecordId: Integer); async;
    [ASync]
    procedure LoadLookUpValues; async;
  protected
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainDataModule,
  ReportResult,
  App.Support, smx.Reports.Support;

resourcestring
  IREPORT_SVC_FETCHPARAMS = 'IReportService.FetchParams'; // (AReportId);
  IREPORT_RUN_REPORT = 'IReportService.RunReport';
  // (ReportId: Integer; ParamValues: string; MaxRecords: Integer): TStream;
  IREPORT_DIRECT_DOWNLOAD = 'IReportService.DirectDownload'; // (ReportId: Integer; ParamValues: string):
  REPORT_FOR_DOWNLOAD = '<h4>Report: %s - %s</h4><A href="%s" target="_blank">click here to download your report</a>';

procedure TMainReportForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  FIdField := 'Id';
  LoadLookUpValues;
end;

{ TMainReportForm }

procedure TMainReportForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure RunClick(Sender: TObject);
  begin
    RunReport(ARecordId.ToInteger);
  end;

  procedure DownloadClick(Sender: TObject);
  begin
    DirectDownload(ARecordId.ToInteger);
  end;


var
  Span: THTMLSpan;
begin
 { TODO : To be decided whether to offer this}
//  Span := TSysHelper.RowActionSpan(AParentElement, 'fa fa-bolt', 'run');
//  Span.OnClick := @RunClick;

  Span := TSysHelper.RowActionSpan(AParentElement, 'fa fa-cloud-download', 'create spreadsheet');
  Span.OnClick := @DownloadClick;

end;

procedure TMainReportForm.DirectDownload(const ARecordId: Integer);
var
  lParamValues: string;
  lResponse: TXDataClientResponse;
  lResponseObject: JS.TJSObject;
begin

  lParamValues := await(string, GetParams(ARecordId));

  if lParamValues = 'CANCEL' then
    Exit;

  ListWaitMessage.Show;

  lResponse := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IREPORT_DIRECT_DOWNLOAD,
    [ARecordId, lParamValues]));


  lResponseObject := lResponse.ResultAsObject;

  if lResponseObject = nil then
  begin
    ListWaitMessage.Hide;
    ShowMessage('Failed to download report');
    Exit;
  end;

  ListWaitMessage.Hide;
  try
    TFileSupport.SaveEncoded64File(JS.toString(lResponseObject['Filename']), JS.toString(lResponseObject['Data']));
  except
    on E: Exception do
    begin
      ShowMessage(E.Message);
    end;
  end;

end;

function TMainReportForm.GetEditFormClass: TFormClass;
begin
  Result := Nil;
  raise Exception.Create('Cannot call edit here');
end;

function TMainReportForm.GetParams(const AReportId: Integer): string;
var
  lParams: JS.TJSArray;
  lParamStr: String;
  lParamForm: TReportParamsPopup;
  lResponse: TXDataClientResponse;
  lRetval: TModalResult;
begin
  MainDataset.Locate('Id', AReportId, []);
  lResponse := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IREPORT_SVC_FETCHPARAMS, [AReportId]));
  lParamStr := JS.toString(lResponse.ResultAsObject['value']);

  asm
    lParams = JSON.parse(lParamStr);
  end;

  Result := '';

  if (lParams <> nil) and (lParams.Length > 0) then
  begin
    lParamForm := TReportParamsPopup.Create(Self);
    try
      lParamForm.Popup := True;
      lParamForm.PopupOpacity := 1;
      lParamForm.Height := 350;
      await(TReportParamsPopup, lParamForm.Load());
      lParamForm.ReportTitle := MainDatasetReportName.Value;
      lParamForm.ShopList := FShopList;
      lParamForm.RegionList := FRegionList;
      lParamForm.Params := lParams;
      lRetval := await(TModalResult, lParamForm.Execute);
      if lRetval = mrCancel then
        Exit('CANCEL');

      Result := lParamForm.ParamValues;

    finally
      lParamForm.Free;
      lParamForm := nil;
    end;
  end;
end;

procedure TMainReportForm.LoadData;
begin
  FGridPlugin.Load;
end;

procedure TMainReportForm.LoadLookUpValues;
var
  lRetval: TXDataClientResponse;
  lYE: String;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSVC_SHOPLIST, []));
  FShopList := JS.ToArray(lRetval.ResultAsObject['value']);

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSVC_GETREGIONS, []));
  FRegionList := JS.ToArray(lRetval.ResultAsObject['value']);

  lYE := Await(String, MainData.CompanyYearEnd);
  TReportSupport.SetCompanyYearEnd(lYE);

end;

procedure TMainReportForm.PrepareForm;
begin
  //
end;

function TMainReportForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voReadOnly;
end;

procedure TMainReportForm.RunReport(const ARecordId: Integer);
var
  lParamValues, lFile: string;

  lRetval: TModalResult;
  lValues: string;
  lResponse: TXDataClientResponse;
  lResponseVal: JSValue;
  lResponseObject: JS.TJSObject;
  lResultForm: TReportResultForm;
begin
  if not MainDataset.Locate('Id', ARecordId, []) then
  begin
    Exception.Create('Cannot locate report id ' + ARecordId.toString);
  end;

  ReportResultInfo.HTML.Clear;
  ReportResultInfo.Visible := False;

  lParamValues := await(string, GetParams(ARecordId));
  if lParamValues = 'CANCEL' then
    Exit;

  lResponse := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IREPORT_RUN_REPORT,
    [ARecordId, lParamValues, 250]));

  lResponseVal := lResponse.Result;
  asm
    lResponseObject = JSON.parse(lResponseVal);
  end;

  // lResponseObject := JS.ToObject(lResponse.Result);

  if lResponseObject.hasOwnProperty('Download') then
  begin
    lFile := JS.toString(lResponseObject['Download']);
    ReportResultInfo.HTML.Text := Format(REPORT_FOR_DOWNLOAD, [MainDatasetId.AsString, MainDatasetReportName.Value,
      'store/' + lFile]);
    ReportResultInfo.Visible := True;
  end
  else
  begin
    ReportResultInfo.Visible := False;
    lResultForm := TReportResultForm.Create(Self);
    try
      lResultForm.Popup := True;
      lResultForm.PopupOpacity := 1;
      await(TReportResultForm, lResultForm.Load());
      lResultForm.Data := lResponseObject;
      lRetval := await(TModalResult, lResultForm.Execute);
    finally
      lResultForm.Free;
      lResultForm := nil;
    end;
  end;

end;

procedure TMainReportForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ReportResultInfo := THTMLSpan.Create('ReportResultInfo');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetReportName := TStringField.Create(Self);
  MainDatasetDescription := TStringField.Create(Self);
  MainDatasetParameters := TBlobField.Create(Self);
  MainDatasetReportGroup := TIntegerField.Create(Self);
  MainDatasetReportType := TStringField.Create(Self);

  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  ListWaitMessage.BeforeLoadDFMValues;
  ReportResultInfo.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetReportName.BeforeLoadDFMValues;
  MainDatasetDescription.BeforeLoadDFMValues;
  MainDatasetParameters.BeforeLoadDFMValues;
  MainDatasetReportGroup.BeforeLoadDFMValues;
  MainDatasetReportType.BeforeLoadDFMValues;
  try
    DataTable.Width := 329;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ReportName';
      Title := 'Report';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.Left := 176;
    AddButton.Top := 372;
    AddButton.Enabled := False;
    AddButton.Visible := False;
    ListWaitMessage.Left := 424;
    ListWaitMessage.Top := 128;
    ReportResultInfo.SetParentComponent(Self);
    ReportResultInfo.Name := 'ReportResultInfo';
    ReportResultInfo.Left := 144;
    ReportResultInfo.Top := 448;
    ReportResultInfo.Width := 100;
    ReportResultInfo.Height := 40;
    ReportResultInfo.HeightStyle := ssAuto;
    ReportResultInfo.WidthStyle := ssAuto;
    ReportResultInfo.ChildOrder := 10;
    ReportResultInfo.ElementPosition := epIgnore;
    ReportResultInfo.ElementFont := efCSS;
    ReportResultInfo.Role := '';
    MainDataset.EntitySetName := 'Report';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetReportName.SetParentComponent(MainDataset);
    MainDatasetReportName.Name := 'MainDatasetReportName';
    MainDatasetReportName.FieldName := 'ReportName';
    MainDatasetReportName.Size := 256;
    MainDatasetDescription.SetParentComponent(MainDataset);
    MainDatasetDescription.Name := 'MainDatasetDescription';
    MainDatasetDescription.FieldName := 'Description';
    MainDatasetDescription.Size := 512;
    MainDatasetParameters.SetParentComponent(MainDataset);
    MainDatasetParameters.Name := 'MainDatasetParameters';
    MainDatasetParameters.FieldName := 'Parameters';
    MainDatasetReportGroup.SetParentComponent(MainDataset);
    MainDatasetReportGroup.Name := 'MainDatasetReportGroup';
    MainDatasetReportGroup.FieldName := 'ReportGroup';
    MainDatasetReportGroup.Required := True;
    MainDatasetReportType.SetParentComponent(MainDataset);
    MainDatasetReportType.Name := 'MainDatasetReportType';
    MainDatasetReportType.FieldName := 'ReportType';
    MainDatasetReportType.Required := True;
    MainDatasetReportType.Size := 5;
  finally
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    ListWaitMessage.AfterLoadDFMValues;
    ReportResultInfo.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetReportName.AfterLoadDFMValues;
    MainDatasetDescription.AfterLoadDFMValues;
    MainDatasetParameters.AfterLoadDFMValues;
    MainDatasetReportGroup.AfterLoadDFMValues;
    MainDatasetReportType.AfterLoadDFMValues;
  end;
end;

end.
