unit ExportDonorsForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls, WEBLib.Toast;

type
  TExportDonors = class(TJobBase)
  private
    { Private declarations }
  protected
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ExportDonors: TExportDonors;

implementation

uses
  AppUtils;

{$R *.dfm}

{ TExportDonors }

function TExportDonors.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    while not JobsPlaced.eof do
    begin
      if TSysHelper.JobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new export while there is still one in progress');
        Exit(False);
      end;
      JobsPlaced.Next;
    end;
  end;
end;

function TExportDonors.JobClass: string;
begin
  Result := 'TREDonorExport';
end;

function TExportDonors.JobDescription: string;
begin
  Result := 'Export Donors for Rasier''s Edge';
end;

function TExportDonors.JobDisplayName: string;
begin
  Result := 'Donor Export';
end;

procedure TExportDonors.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.

