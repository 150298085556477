unit DonorRefund;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  DonorBaseViewForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.CDS,
  WEBLib.Grids,
  Grid.Plugins,
  WEBLib.Lists;

type
  TDonorRefundDetail = class(TDonorBaseView)
    SalesTable: TDBTableControl;
    Shops: TClientDataSet;
    ShopsRef: TStringField;
    ShopsRegionRef: TStringField;
    ShopsName: TStringField;
    ShopsStatus: TStringField;
    ShopsLocationType: TStringField;
    SalesPageInfo: TLabel;
    SalesPageSize: TComboBox;
    SalesSource: TDataSource;
    SalesData: TXDataWebDataSet;
    SalesDataId: TIntegerField;
    SalesDataSheetId: TIntegerField;
    SalesDataPageNum: TIntegerField;
    SalesDataDonorId: TIntegerField;
    SalesDataShopRef: TStringField;
    SalesDataSaleDate: TDateField;
    SalesDataAmount: TFloatField;
    SalesDataCommission: TFloatField;
    SalesDataNetAmount: TFloatField;
    SalesDataClaimStatus: TStringField;
    SalesDataClaimId: TIntegerField;
    SalesDataClaimRef: TStringField;
    SalesDataClaimDate: TDateField;
    SalesDataClaimStatusChanged: TDateField;
    SalesDataNotificationId: TIntegerField;
    SalesDataRefundDate: TDateField;
    SalesDataRefundId: TIntegerField;
    SalesDataAddedBy: TIntegerField;
    SalesDataDateAdded: TDateTimeField;
    SalesDataLastUpdatedBy: TIntegerField;
    SalesDataLastUpdated: TDateTimeField;
    SalesDataShopName: TStringField;
    SalesDataClaimStatusText: TStringField;
    SalesDataNotificationDate: TDateField;
    SalesDataNotificationSent: TBooleanField;
    SalesDataNotifcationSentText: TStringField;
    SalesPagination: TListControl;
    AmountToRefund: THTMLSpan;
    RefundButton: TButton;
    NotificationsSource: TDataSource;
    NotificationId: TLabel;
    NotifyLettterDate: TLabel;
    NotifySubmissionDate: TLabel;
    SalesDataOldRef: TStringField;
    SalesDataTaxYear: TIntegerField;
    SalesDataEnteredId: TStringField;
    procedure RefundButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure SalesDataCalcFields(Dataset: TDataSet);
    procedure SalesDataSaleDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
  private
    { Private declarations }
    FSalesGridPlugin: TGridPlugin;
    FOpenNotifications: JS.TJSArray;
    FNotificationId: Integer;
    procedure SetOpenNotifications(const Value: JS.TJSArray);
    [async]
    procedure PrepareRefundList; async;
    [async]
    procedure CreateRefund; async;
    procedure SetRefundTotals(const Value: JS.TJSObject);
  protected
    procedure AfterSetDataset; override;
  public
    { Public declarations }
    property OpenNotifications: JS.TJSArray write SetOpenNotifications;
    property RefundTotals: JS.TJSObject write SetRefundTotals;

  protected procedure LoadDFMValues; override; end;

var
  DonorRefundDetail: TDonorRefundDetail;

implementation

{$R *.dfm}

uses
  System.StrUtils,
  XData.Web.Client,
  MainDataModule,
  smx.webcore.Types,
  AppUtils;

resourcestring
  summary_row = '<tr><td class="caption">%s</td><td align="right" class="value">&#163;%f</td></tr>';

procedure TDonorRefundDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FSalesGridPlugin := TGridPlugin.Create(SalesTable, SalesData, SalesPagination, SalesPageInfo,
    TSortPair.Create(1, sdDesc));
  FSalesGridPlugin.SetPageSize(StrToInt(SalesPageSize.Text));
  FSalesGridPlugin.SetFilterText('');
end;

procedure TDonorRefundDetail.AfterSetDataset;
begin
  inherited;
  PrepareRefundList;
end;

procedure TDonorRefundDetail.CreateRefund;
var
  lRetval: TXDataClientResponse;
begin
  RefundButton.Enabled := False;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_REFUND_CREATE,
    [DonorId, FNotificationId]));
  ShowAnAwesomeToast('Refund', 'Refund created for ' + TSysHelper.ShortName(Datasource.Dataset), 'fad fa-coins');
  PrepareRefundList;
  RefundButton.Visible := False;
  SubCaptionText := 'Refund Requested';
end;

procedure TDonorRefundDetail.PrepareRefundList;
begin
  FSalesGridPlugin.SetPreparedFilter(Format('(DonorId eq %d)', [DonorId]) + ' and ' + Format('(NotificationId eq %d)', [FNotificationId]));
end;

procedure TDonorRefundDetail.RefundButtonClick(Sender: TObject);
begin
  CreateRefund;
end;

procedure TDonorRefundDetail.SalesDataCalcFields(Dataset: TDataSet);
begin
  SalesDataClaimStatusText.Value := TSysHelper.SplitOnCaps(SalesDataClaimStatus.Value);
  if SalesDataNotificationSent.Value then
    SalesDataNotifcationSentText.Value := '<i class="fad fa-check-square fa-fw fa-lg"></i>';
end;

procedure TDonorRefundDetail.SalesDataSaleDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy', (Sender as TDateField).AsDateTime);
end;

procedure TDonorRefundDetail.SetOpenNotifications(const Value: JS.TJSArray);
var
  lNotify: TJSObject;
begin
  FOpenNotifications := Value;
  lNotify := TJSObject(FOpenNotifications[0]);
  FNotificationId := JS.toInteger(lNotify['NotificationId']);
  NotificationId.Caption := FNotificationId.ToString;
  NotifySubmissionDate.Caption := FormatDateTime('dd/mm/yyyy', TSysHelper.DateStrToDate(JS.toString(lNotify['EarliestSubmissionDate'])));
  NotifyLettterDate.Caption := FormatDateTime('dd/mm/yyyy', TSysHelper.DateStrToDate(JS.toString(lNotify['LetterDate'])));
end;

procedure TDonorRefundDetail.SetRefundTotals(const Value: JS.TJSObject);
begin
  AmountToRefund.HTML.Clear;
  AmountToRefund.HTML.Add('<table id="AmountToRefundTable">');
  AmountToRefund.HTML.Add(Format(summary_row, ['Total Sales Amount', JS.toNumber(Value['TotalSales'])]));
  AmountToRefund.HTML.Add(Format(summary_row, ['Handling Charge', JS.toNumber(Value['HandlingFee'])]));
  AmountToRefund.HTML.Add(Format(summary_row, ['Commision', JS.toNumber(Value['TotalCommission'])]));
  AmountToRefund.HTML.Add(Format(summary_row, ['Refund Amount', JS.toNumber(Value['RefundAmount'])]));

  AmountToRefund.HTML.Add('</table>');
  AmountToRefund.Visible := True;
  RefundButton.Visible := JS.toString(Value['ClaimStatus']) = 'Scheduled';
  SubCaptionText := 'Refund Status: ' + TSysHelper.SplitOnCaps(JS.toString(Value['ClaimStatus']));
end;

procedure TDonorRefundDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SalesPageInfo := TLabel.Create('SalesPageInfo');
  NotificationId := TLabel.Create('NotificationId');
  NotifyLettterDate := TLabel.Create('NotifyLettterDate');
  NotifySubmissionDate := TLabel.Create('NotifySubmissionDate');
  SalesTable := TDBTableControl.Create('SalesTable');
  SalesPageSize := TComboBox.Create('SalesPageSize');
  SalesPagination := TListControl.Create('SalesPagination');
  AmountToRefund := THTMLSpan.Create('AmountToRefund');
  RefundButton := TButton.Create('RefundButton');
  Shops := TClientDataSet.Create(Self);
  ShopsRef := TStringField.Create(Self);
  ShopsRegionRef := TStringField.Create(Self);
  ShopsName := TStringField.Create(Self);
  ShopsStatus := TStringField.Create(Self);
  ShopsLocationType := TStringField.Create(Self);
  SalesSource := TDataSource.Create(Self);
  SalesData := TXDataWebDataSet.Create(Self);
  SalesDataId := TIntegerField.Create(Self);
  SalesDataSheetId := TIntegerField.Create(Self);
  SalesDataPageNum := TIntegerField.Create(Self);
  SalesDataDonorId := TIntegerField.Create(Self);
  SalesDataShopRef := TStringField.Create(Self);
  SalesDataSaleDate := TDateField.Create(Self);
  SalesDataAmount := TFloatField.Create(Self);
  SalesDataCommission := TFloatField.Create(Self);
  SalesDataNetAmount := TFloatField.Create(Self);
  SalesDataClaimStatus := TStringField.Create(Self);
  SalesDataClaimId := TIntegerField.Create(Self);
  SalesDataClaimRef := TStringField.Create(Self);
  SalesDataClaimDate := TDateField.Create(Self);
  SalesDataClaimStatusChanged := TDateField.Create(Self);
  SalesDataNotificationId := TIntegerField.Create(Self);
  SalesDataRefundDate := TDateField.Create(Self);
  SalesDataRefundId := TIntegerField.Create(Self);
  SalesDataAddedBy := TIntegerField.Create(Self);
  SalesDataDateAdded := TDateTimeField.Create(Self);
  SalesDataLastUpdatedBy := TIntegerField.Create(Self);
  SalesDataLastUpdated := TDateTimeField.Create(Self);
  SalesDataShopName := TStringField.Create(Self);
  SalesDataClaimStatusText := TStringField.Create(Self);
  SalesDataNotificationDate := TDateField.Create(Self);
  SalesDataNotificationSent := TBooleanField.Create(Self);
  SalesDataNotifcationSentText := TStringField.Create(Self);
  SalesDataOldRef := TStringField.Create(Self);
  SalesDataTaxYear := TIntegerField.Create(Self);
  SalesDataEnteredId := TStringField.Create(Self);
  NotificationsSource := TDataSource.Create(Self);

  SalesPageInfo.BeforeLoadDFMValues;
  NotificationId.BeforeLoadDFMValues;
  NotifyLettterDate.BeforeLoadDFMValues;
  NotifySubmissionDate.BeforeLoadDFMValues;
  SalesTable.BeforeLoadDFMValues;
  SalesPageSize.BeforeLoadDFMValues;
  SalesPagination.BeforeLoadDFMValues;
  AmountToRefund.BeforeLoadDFMValues;
  RefundButton.BeforeLoadDFMValues;
  Shops.BeforeLoadDFMValues;
  ShopsRef.BeforeLoadDFMValues;
  ShopsRegionRef.BeforeLoadDFMValues;
  ShopsName.BeforeLoadDFMValues;
  ShopsStatus.BeforeLoadDFMValues;
  ShopsLocationType.BeforeLoadDFMValues;
  SalesSource.BeforeLoadDFMValues;
  SalesData.BeforeLoadDFMValues;
  SalesDataId.BeforeLoadDFMValues;
  SalesDataSheetId.BeforeLoadDFMValues;
  SalesDataPageNum.BeforeLoadDFMValues;
  SalesDataDonorId.BeforeLoadDFMValues;
  SalesDataShopRef.BeforeLoadDFMValues;
  SalesDataSaleDate.BeforeLoadDFMValues;
  SalesDataAmount.BeforeLoadDFMValues;
  SalesDataCommission.BeforeLoadDFMValues;
  SalesDataNetAmount.BeforeLoadDFMValues;
  SalesDataClaimStatus.BeforeLoadDFMValues;
  SalesDataClaimId.BeforeLoadDFMValues;
  SalesDataClaimRef.BeforeLoadDFMValues;
  SalesDataClaimDate.BeforeLoadDFMValues;
  SalesDataClaimStatusChanged.BeforeLoadDFMValues;
  SalesDataNotificationId.BeforeLoadDFMValues;
  SalesDataRefundDate.BeforeLoadDFMValues;
  SalesDataRefundId.BeforeLoadDFMValues;
  SalesDataAddedBy.BeforeLoadDFMValues;
  SalesDataDateAdded.BeforeLoadDFMValues;
  SalesDataLastUpdatedBy.BeforeLoadDFMValues;
  SalesDataLastUpdated.BeforeLoadDFMValues;
  SalesDataShopName.BeforeLoadDFMValues;
  SalesDataClaimStatusText.BeforeLoadDFMValues;
  SalesDataNotificationDate.BeforeLoadDFMValues;
  SalesDataNotificationSent.BeforeLoadDFMValues;
  SalesDataNotifcationSentText.BeforeLoadDFMValues;
  SalesDataOldRef.BeforeLoadDFMValues;
  SalesDataTaxYear.BeforeLoadDFMValues;
  SalesDataEnteredId.BeforeLoadDFMValues;
  NotificationsSource.BeforeLoadDFMValues;
  try
    SalesPageInfo.SetParentComponent(Self);
    SalesPageInfo.Name := 'SalesPageInfo';
    SalesPageInfo.Left := 280;
    SalesPageInfo.Top := 396;
    SalesPageInfo.Width := 96;
    SalesPageInfo.Height := 15;
    SalesPageInfo.Caption := 'No data to display';
    SalesPageInfo.ElementFont := efCSS;
    SalesPageInfo.ElementPosition := epIgnore;
    SalesPageInfo.HeightStyle := ssAuto;
    SalesPageInfo.HeightPercent := 100.000000000000000000;
    SalesPageInfo.WidthStyle := ssAuto;
    SalesPageInfo.WidthPercent := 100.000000000000000000;
    NotificationId.SetParentComponent(Self);
    NotificationId.Name := 'NotificationId';
    NotificationId.Left := 40;
    NotificationId.Top := 416;
    NotificationId.Width := 73;
    NotificationId.Height := 15;
    NotificationId.Caption := 'NotificationId';
    NotificationId.ElementFont := efCSS;
    NotificationId.ElementPosition := epIgnore;
    NotificationId.HeightStyle := ssAuto;
    NotificationId.HeightPercent := 100.000000000000000000;
    NotificationId.WidthStyle := ssAuto;
    NotificationId.WidthPercent := 100.000000000000000000;
    NotifyLettterDate.SetParentComponent(Self);
    NotifyLettterDate.Name := 'NotifyLettterDate';
    NotifyLettterDate.Left := 40;
    NotifyLettterDate.Top := 437;
    NotifyLettterDate.Width := 91;
    NotifyLettterDate.Height := 15;
    NotifyLettterDate.Caption := 'NotifyLettterDate';
    NotifyLettterDate.ElementFont := efCSS;
    NotifyLettterDate.ElementPosition := epIgnore;
    NotifyLettterDate.HeightStyle := ssAuto;
    NotifyLettterDate.HeightPercent := 100.000000000000000000;
    NotifyLettterDate.WidthStyle := ssAuto;
    NotifyLettterDate.WidthPercent := 100.000000000000000000;
    NotifySubmissionDate.SetParentComponent(Self);
    NotifySubmissionDate.Name := 'NotifySubmissionDate';
    NotifySubmissionDate.Left := 40;
    NotifySubmissionDate.Top := 457;
    NotifySubmissionDate.Width := 118;
    NotifySubmissionDate.Height := 15;
    NotifySubmissionDate.Caption := 'NotifySubmissionDate';
    NotifySubmissionDate.ElementFont := efCSS;
    NotifySubmissionDate.ElementPosition := epIgnore;
    NotifySubmissionDate.HeightStyle := ssAuto;
    NotifySubmissionDate.HeightPercent := 100.000000000000000000;
    NotifySubmissionDate.WidthStyle := ssAuto;
    NotifySubmissionDate.WidthPercent := 100.000000000000000000;
    SalesTable.SetParentComponent(Self);
    SalesTable.Name := 'SalesTable';
    SalesTable.Left := 272;
    SalesTable.Top := 123;
    SalesTable.Width := 593;
    SalesTable.Height := 244;
    SalesTable.HeightStyle := ssAuto;
    SalesTable.WidthStyle := ssAuto;
    SalesTable.BorderColor := clSilver;
    SalesTable.ChildOrder := 5;
    SalesTable.ElementFont := efCSS;
    SalesTable.ElementHeaderClassName := 'thead-light';
    SalesTable.ElementPosition := epIgnore;
    SalesTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SalesTable.Columns.Clear;
    with SalesTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'SaleDate';
      Title := 'SaleDate';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'Commission';
      Title := 'Commission';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'NetAmount';
      Title := 'NetAmount';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ClaimStatusText';
      Title := 'Claim Status';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ClaimDate';
      Title := 'ClaimDate';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ShopName';
      Title := 'ShopName';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'NotificationSent';
      Title := 'NotificationSent';
    end;
    SalesTable.DataSource := SalesSource;
    SalesPageSize.SetParentComponent(Self);
    SalesPageSize.Name := 'SalesPageSize';
    SalesPageSize.Left := 280;
    SalesPageSize.Top := 87;
    SalesPageSize.Width := 81;
    SalesPageSize.Height := 23;
    SalesPageSize.ElementClassName := 'form-select';
    SalesPageSize.ElementFont := efCSS;
    SalesPageSize.ElementPosition := epIgnore;
    SalesPageSize.HeightStyle := ssAuto;
    SalesPageSize.HeightPercent := 100.000000000000000000;
    SalesPageSize.Text := '10';
    SalesPageSize.WidthStyle := ssAuto;
    SalesPageSize.WidthPercent := 100.000000000000000000;
    SalesPageSize.ItemIndex := 0;
    SalesPageSize.Items.BeginUpdate;
    try
      SalesPageSize.Items.Clear;
      SalesPageSize.Items.Add('10');
      SalesPageSize.Items.Add('25');
      SalesPageSize.Items.Add('50');
      SalesPageSize.Items.Add('100');
    finally
      SalesPageSize.Items.EndUpdate;
    end;
    SalesPagination.SetParentComponent(Self);
    SalesPagination.Name := 'SalesPagination';
    SalesPagination.Left := 576;
    SalesPagination.Top := 384;
    SalesPagination.Width := 289;
    SalesPagination.Height := 25;
    SalesPagination.HeightStyle := ssAuto;
    SalesPagination.WidthStyle := ssAuto;
    SalesPagination.HeightPercent := 100.000000000000000000;
    SalesPagination.WidthPercent := 100.000000000000000000;
    SalesPagination.ChildOrder := 3;
    SalesPagination.DefaultItemClassName := 'page-item';
    SalesPagination.DefaultItemLinkClassName := 'page-link';
    SalesPagination.ElementFont := efCSS;
    SalesPagination.ElementPosition := epIgnore;
    SalesPagination.ElementListClassName := 'pagination';
    SalesPagination.Items.Clear;
    with SalesPagination.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 0';
    end;
    with SalesPagination.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 1';
    end;
    with SalesPagination.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 2';
    end;
    SalesPagination.Style := lsPagination;
    AmountToRefund.SetParentComponent(Self);
    AmountToRefund.Name := 'AmountToRefund';
    AmountToRefund.Left := 576;
    AmountToRefund.Top := 80;
    AmountToRefund.Width := 100;
    AmountToRefund.Height := 41;
    AmountToRefund.HeightStyle := ssAuto;
    AmountToRefund.WidthStyle := ssAuto;
    AmountToRefund.ChildOrder := 19;
    AmountToRefund.ElementPosition := epIgnore;
    AmountToRefund.ElementFont := efCSS;
    AmountToRefund.Role := '';
    AmountToRefund.Visible := False;
    RefundButton.SetParentComponent(Self);
    RefundButton.Name := 'RefundButton';
    RefundButton.Left := 304;
    RefundButton.Top := 417;
    RefundButton.Width := 96;
    RefundButton.Height := 25;
    RefundButton.Caption := 'Refund';
    RefundButton.ChildOrder := 20;
    RefundButton.ElementClassName := 'btn btn-primary';
    RefundButton.ElementFont := efCSS;
    RefundButton.ElementPosition := epIgnore;
    RefundButton.HeightStyle := ssAuto;
    RefundButton.HeightPercent := 100.000000000000000000;
    RefundButton.Visible := False;
    RefundButton.WidthStyle := ssAuto;
    RefundButton.WidthPercent := 100.000000000000000000;
    SetEvent(RefundButton, Self, 'OnClick', 'RefundButtonClick');
    Shops.SetParentComponent(Self);
    Shops.Name := 'Shops';
    Shops.Left := 696;
    Shops.Top := 24;
    ShopsRef.SetParentComponent(Shops);
    ShopsRef.Name := 'ShopsRef';
    ShopsRef.FieldName := 'Ref';
    ShopsRef.Size := 5;
    ShopsRegionRef.SetParentComponent(Shops);
    ShopsRegionRef.Name := 'ShopsRegionRef';
    ShopsRegionRef.FieldName := 'RegionRef';
    ShopsRegionRef.Size := 5;
    ShopsName.SetParentComponent(Shops);
    ShopsName.Name := 'ShopsName';
    ShopsName.FieldName := 'Name';
    ShopsName.Size := 250;
    ShopsStatus.SetParentComponent(Shops);
    ShopsStatus.Name := 'ShopsStatus';
    ShopsStatus.FieldName := 'Status';
    ShopsStatus.Size := 6;
    ShopsLocationType.SetParentComponent(Shops);
    ShopsLocationType.Name := 'ShopsLocationType';
    ShopsLocationType.FieldName := 'LocationType';
    ShopsLocationType.Size := 10;
    SalesSource.SetParentComponent(Self);
    SalesSource.Name := 'SalesSource';
    SalesSource.AutoEdit := False;
    SalesSource.DataSet := SalesData;
    SalesSource.Left := 460;
    SalesSource.Top := 24;
    SalesData.SetParentComponent(Self);
    SalesData.Name := 'SalesData';
    SetEvent(SalesData, Self, 'OnCalcFields', 'SalesDataCalcFields');
    SalesData.EntitySetName := 'Sale';
    SalesData.Connection := MainData.DataConnection;
    SalesData.Left := 394;
    SalesData.Top := 20;
    SalesDataId.SetParentComponent(SalesData);
    SalesDataId.Name := 'SalesDataId';
    SalesDataId.FieldName := 'Id';
    SalesDataSheetId.SetParentComponent(SalesData);
    SalesDataSheetId.Name := 'SalesDataSheetId';
    SalesDataSheetId.FieldName := 'SheetId';
    SalesDataPageNum.SetParentComponent(SalesData);
    SalesDataPageNum.Name := 'SalesDataPageNum';
    SalesDataPageNum.FieldName := 'PageNum';
    SalesDataDonorId.SetParentComponent(SalesData);
    SalesDataDonorId.Name := 'SalesDataDonorId';
    SalesDataDonorId.FieldName := 'DonorId';
    SalesDataShopRef.SetParentComponent(SalesData);
    SalesDataShopRef.Name := 'SalesDataShopRef';
    SalesDataShopRef.FieldName := 'ShopRef';
    SalesDataShopRef.Size := 10;
    SalesDataSaleDate.SetParentComponent(SalesData);
    SalesDataSaleDate.Name := 'SalesDataSaleDate';
    SalesDataSaleDate.FieldName := 'SaleDate';
    SetEvent(SalesDataSaleDate, Self, 'OnGetText', 'SalesDataSaleDateGetText');
    SalesDataAmount.SetParentComponent(SalesData);
    SalesDataAmount.Name := 'SalesDataAmount';
    SalesDataAmount.FieldName := 'Amount';
    SalesDataAmount.DisplayFormat := '#,##0.00';
    SalesDataCommission.SetParentComponent(SalesData);
    SalesDataCommission.Name := 'SalesDataCommission';
    SalesDataCommission.FieldName := 'Commission';
    SalesDataCommission.DisplayFormat := '#,##0.00';
    SalesDataNetAmount.SetParentComponent(SalesData);
    SalesDataNetAmount.Name := 'SalesDataNetAmount';
    SalesDataNetAmount.FieldName := 'NetAmount';
    SalesDataNetAmount.DisplayFormat := '#,##0.00';
    SalesDataClaimStatus.SetParentComponent(SalesData);
    SalesDataClaimStatus.Name := 'SalesDataClaimStatus';
    SalesDataClaimStatus.FieldName := 'ClaimStatus';
    SalesDataClaimStatus.Size := 18;
    SalesDataClaimId.SetParentComponent(SalesData);
    SalesDataClaimId.Name := 'SalesDataClaimId';
    SalesDataClaimId.FieldName := 'ClaimId';
    SalesDataClaimRef.SetParentComponent(SalesData);
    SalesDataClaimRef.Name := 'SalesDataClaimRef';
    SalesDataClaimRef.FieldName := 'ClaimRef';
    SalesDataClaimRef.Size := 255;
    SalesDataClaimDate.SetParentComponent(SalesData);
    SalesDataClaimDate.Name := 'SalesDataClaimDate';
    SalesDataClaimDate.FieldName := 'ClaimDate';
    SalesDataClaimStatusChanged.SetParentComponent(SalesData);
    SalesDataClaimStatusChanged.Name := 'SalesDataClaimStatusChanged';
    SalesDataClaimStatusChanged.FieldName := 'ClaimStatusChanged';
    SalesDataNotificationId.SetParentComponent(SalesData);
    SalesDataNotificationId.Name := 'SalesDataNotificationId';
    SalesDataNotificationId.FieldName := 'NotificationId';
    SalesDataRefundDate.SetParentComponent(SalesData);
    SalesDataRefundDate.Name := 'SalesDataRefundDate';
    SalesDataRefundDate.FieldName := 'RefundDate';
    SalesDataRefundId.SetParentComponent(SalesData);
    SalesDataRefundId.Name := 'SalesDataRefundId';
    SalesDataRefundId.FieldName := 'RefundId';
    SalesDataAddedBy.SetParentComponent(SalesData);
    SalesDataAddedBy.Name := 'SalesDataAddedBy';
    SalesDataAddedBy.FieldName := 'AddedBy';
    SalesDataDateAdded.SetParentComponent(SalesData);
    SalesDataDateAdded.Name := 'SalesDataDateAdded';
    SalesDataDateAdded.FieldName := 'DateAdded';
    SalesDataLastUpdatedBy.SetParentComponent(SalesData);
    SalesDataLastUpdatedBy.Name := 'SalesDataLastUpdatedBy';
    SalesDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesDataLastUpdated.SetParentComponent(SalesData);
    SalesDataLastUpdated.Name := 'SalesDataLastUpdated';
    SalesDataLastUpdated.FieldName := 'LastUpdated';
    SalesDataShopName.SetParentComponent(SalesData);
    SalesDataShopName.Name := 'SalesDataShopName';
    SalesDataShopName.FieldKind := fkLookup;
    SalesDataShopName.FieldName := 'ShopName';
    SalesDataShopName.LookupDataSet := Shops;
    SalesDataShopName.LookupKeyFields := 'Ref';
    SalesDataShopName.LookupResultField := 'Name';
    SalesDataShopName.KeyFields := 'ShopRef';
    SalesDataShopName.Size := 75;
    SalesDataShopName.Lookup := True;
    SalesDataClaimStatusText.SetParentComponent(SalesData);
    SalesDataClaimStatusText.Name := 'SalesDataClaimStatusText';
    SalesDataClaimStatusText.FieldKind := fkCalculated;
    SalesDataClaimStatusText.FieldName := 'ClaimStatusText';
    SalesDataClaimStatusText.Size := 25;
    SalesDataClaimStatusText.Calculated := True;
    SalesDataNotificationDate.SetParentComponent(SalesData);
    SalesDataNotificationDate.Name := 'SalesDataNotificationDate';
    SalesDataNotificationDate.FieldName := 'NotificationDate';
    SalesDataNotificationSent.SetParentComponent(SalesData);
    SalesDataNotificationSent.Name := 'SalesDataNotificationSent';
    SalesDataNotificationSent.FieldName := 'NotificationSent';
    SalesDataNotifcationSentText.SetParentComponent(SalesData);
    SalesDataNotifcationSentText.Name := 'SalesDataNotifcationSentText';
    SalesDataNotifcationSentText.FieldKind := fkCalculated;
    SalesDataNotifcationSentText.FieldName := 'NotifcationSentText';
    SalesDataNotifcationSentText.Size := 25;
    SalesDataNotifcationSentText.Calculated := True;
    SalesDataOldRef.SetParentComponent(SalesData);
    SalesDataOldRef.Name := 'SalesDataOldRef';
    SalesDataOldRef.FieldName := 'OldRef';
    SalesDataOldRef.Size := 10;
    SalesDataTaxYear.SetParentComponent(SalesData);
    SalesDataTaxYear.Name := 'SalesDataTaxYear';
    SalesDataTaxYear.FieldName := 'TaxYear';
    SalesDataEnteredId.SetParentComponent(SalesData);
    SalesDataEnteredId.Name := 'SalesDataEnteredId';
    SalesDataEnteredId.FieldName := 'EnteredId';
    SalesDataEnteredId.Size := 255;
    NotificationsSource.SetParentComponent(Self);
    NotificationsSource.Name := 'NotificationsSource';
    NotificationsSource.Left := 536;
    NotificationsSource.Top := 32;
  finally
    SalesPageInfo.AfterLoadDFMValues;
    NotificationId.AfterLoadDFMValues;
    NotifyLettterDate.AfterLoadDFMValues;
    NotifySubmissionDate.AfterLoadDFMValues;
    SalesTable.AfterLoadDFMValues;
    SalesPageSize.AfterLoadDFMValues;
    SalesPagination.AfterLoadDFMValues;
    AmountToRefund.AfterLoadDFMValues;
    RefundButton.AfterLoadDFMValues;
    Shops.AfterLoadDFMValues;
    ShopsRef.AfterLoadDFMValues;
    ShopsRegionRef.AfterLoadDFMValues;
    ShopsName.AfterLoadDFMValues;
    ShopsStatus.AfterLoadDFMValues;
    ShopsLocationType.AfterLoadDFMValues;
    SalesSource.AfterLoadDFMValues;
    SalesData.AfterLoadDFMValues;
    SalesDataId.AfterLoadDFMValues;
    SalesDataSheetId.AfterLoadDFMValues;
    SalesDataPageNum.AfterLoadDFMValues;
    SalesDataDonorId.AfterLoadDFMValues;
    SalesDataShopRef.AfterLoadDFMValues;
    SalesDataSaleDate.AfterLoadDFMValues;
    SalesDataAmount.AfterLoadDFMValues;
    SalesDataCommission.AfterLoadDFMValues;
    SalesDataNetAmount.AfterLoadDFMValues;
    SalesDataClaimStatus.AfterLoadDFMValues;
    SalesDataClaimId.AfterLoadDFMValues;
    SalesDataClaimRef.AfterLoadDFMValues;
    SalesDataClaimDate.AfterLoadDFMValues;
    SalesDataClaimStatusChanged.AfterLoadDFMValues;
    SalesDataNotificationId.AfterLoadDFMValues;
    SalesDataRefundDate.AfterLoadDFMValues;
    SalesDataRefundId.AfterLoadDFMValues;
    SalesDataAddedBy.AfterLoadDFMValues;
    SalesDataDateAdded.AfterLoadDFMValues;
    SalesDataLastUpdatedBy.AfterLoadDFMValues;
    SalesDataLastUpdated.AfterLoadDFMValues;
    SalesDataShopName.AfterLoadDFMValues;
    SalesDataClaimStatusText.AfterLoadDFMValues;
    SalesDataNotificationDate.AfterLoadDFMValues;
    SalesDataNotificationSent.AfterLoadDFMValues;
    SalesDataNotifcationSentText.AfterLoadDFMValues;
    SalesDataOldRef.AfterLoadDFMValues;
    SalesDataTaxYear.AfterLoadDFMValues;
    SalesDataEnteredId.AfterLoadDFMValues;
    NotificationsSource.AfterLoadDFMValues;
  end;
end;

end.



