unit DonorIdRequest;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset, XData.Web.Client,
  BaseCoreForm, WEBLib.Toast;

type
  TRequestDonorId = class(TCoreWebForm)
    ShopSelect: TLookupComboBox;
    RequiredNumber: TEdit;
    OrderButton: TButton;
    CloseButton: TButton;
    ShopMessage: THTMLSpan;
    JobVisibility: TLookupComboBox;
    ShopsLookUp: TXDataWebDataSet;
    ShopsLookUpRef: TStringField;
    ShopsLookUpName: TStringField;
    ShopsLookUpStatus: TStringField;
    ShopsLookUpLocationType: TStringField;
    OutputOption: TLookupComboBox;
    OutcomeMessage: THTMLSpan;
    WebClient: TXDataWebClient;
    LabelsOptions: TComboBox;
    procedure CloseButtonClick(Sender: TObject);
    procedure OrderButtonClick(Sender: TObject);
    procedure RequiredNumberExit(Sender: TObject);
    procedure ShopSelectChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure FetchShopList; async;
    [async]
    procedure ShopInfo(const ARef, AName: string); async;
    [async]
    procedure PlaceOrder(const ARef: string; const ACount: Integer; const
        AVisibility: String; const AOutputOption: String; const ALabelOption:
        Integer); async;
    procedure ValidateForm;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  RequestDonorId: TRequestDonorId;

implementation

uses
  MainDataModule,
  AppUtils, App.Helper;

{$R *.dfm}

procedure TRequestDonorId.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrClose;
end;

procedure TRequestDonorId.FetchShopList;
var
  lShopList: JS.TJSArray;
  lRetval: TXDataClientResponse;
    I: Integer;
  lShop: TJSObject;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSVC_SHOPLIST, []));
  lShopList := JS.ToArray(lRetval.ResultAsObject['value']);

  for I := 0 to lShopList.Length - 1 do
  begin
    lShop := TJSObject(lShopList[I]);
    if JS.toString(lShop['Status']) = 'Open' then
      ShopSelect.LookupValues.AddPair(JS.toString(lShop['Ref']), JS.toString(lShop['Name']));
  end;
   ShopSelect.SetFocus;
end;

procedure TRequestDonorId.OrderButtonClick(Sender: TObject);
var
  lCount: Integer;
begin
  lCount := StrToIntDef(RequiredNumber.Text, -1);
  if (ShopSelect.Value = '') or (lCount = -1) then
  begin
    Exit;
  end;

  CloseButton.Enabled := False;
  PlaceOrder(ShopSelect.Value, lCount, JobVisibility.Value, OutputOption.Value, LabelsOptions.ItemIndex);
end;

procedure TRequestDonorId.PlaceOrder(const ARef: string; const ACount: Integer;
    const AVisibility: String; const AOutputOption: String; const ALabelOption: Integer);
var
  lRetval: TXDataClientResponse;
  lJobId: Integer;
begin
  lRetval := await(TXDataClientResponse,
      MainData.WebClient.RawInvokeAsync(IJOBSSVC_RESERVE_IDS,
            [ARef, ACount, AVisibility, AOutputOption, ALabelOption]));

  lJobId := JS.toInteger(lRetval.ResultAsObject['value']);

  ShowAnAwesomeToast('Request Donor Ids', 'This order has been placed. The Job Number is ' + IntToStr(lJobId), 'far fa-clock');

  shopSelect.ItemIndex := -1;
  CloseButton.Enabled := True;
  ValidateForm;
end;

procedure TRequestDonorId.RequiredNumberExit(Sender: TObject);
begin
  ValidateForm;
end;

procedure TRequestDonorId.ShopInfo(const ARef, AName: string);
var
  lRetval: TXDataClientResponse;
  lCount: Integer;
  lMsg: string;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IDONORSVC_UNUSED_IDS, [ARef]));
  lCount := JS.toInteger(lRetval.ResultAsObject['value']);
  if lCount = 1 then
    lMsg := 'Id'
  else
    lMsg := 'Ids';

  ShopMessage.HTML.Text := Format('%s (%s) currently has %d Donor %s unused', [AName, ARef, lCount, lMsg]);

end;

procedure TRequestDonorId.ShopSelectChange(Sender: TObject);
begin
  if ShopSelect.Value = '' then
    ShopMessage.HTML.Clear
  else
    ShopInfo(ShopSelect.Value, ShopSelect.DisplayText);
  ValidateForm;
end;

procedure TRequestDonorId.ValidateForm;
begin
  if (ShopSelect.Value <> '') and (StrToIntDef(RequiredNumber.Text, 0) > 0)  then
  begin
    ButtonValid(OrderButton);
  end
  else
    ButtonInvalid(OrderButton);
end;

procedure TRequestDonorId.WebFormCreate(Sender: TObject);
begin
  JobVisibility.ItemIndex := 2;
  OutputOption.ItemIndex := 0;
  LabelsOptions.ItemIndex := 1;
  FetchShopList;
end;

procedure TRequestDonorId.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopSelect := TLookupComboBox.Create('ShopSelect');
  RequiredNumber := TEdit.Create('RequiredCount');
  OrderButton := TButton.Create('OrderButton');
  CloseButton := TButton.Create('CloseButton');
  ShopMessage := THTMLSpan.Create('ShopMessage');
  JobVisibility := TLookupComboBox.Create('JobVisibility');
  OutputOption := TLookupComboBox.Create('OutputOption');
  OutcomeMessage := THTMLSpan.Create('OutcomeMessage');
  LabelsOptions := TComboBox.Create('LabelsOptions');
  ShopsLookUp := TXDataWebDataSet.Create(Self);
  ShopsLookUpRef := TStringField.Create(Self);
  ShopsLookUpName := TStringField.Create(Self);
  ShopsLookUpStatus := TStringField.Create(Self);
  ShopsLookUpLocationType := TStringField.Create(Self);
  WebClient := TXDataWebClient.Create(Self);

  CloseThisButton.BeforeLoadDFMValues;
  ShopSelect.BeforeLoadDFMValues;
  RequiredNumber.BeforeLoadDFMValues;
  OrderButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ShopMessage.BeforeLoadDFMValues;
  JobVisibility.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  OutcomeMessage.BeforeLoadDFMValues;
  LabelsOptions.BeforeLoadDFMValues;
  ShopsLookUp.BeforeLoadDFMValues;
  ShopsLookUpRef.BeforeLoadDFMValues;
  ShopsLookUpName.BeforeLoadDFMValues;
  ShopsLookUpStatus.BeforeLoadDFMValues;
  ShopsLookUpLocationType.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    CloseThisButton.Visible := False;
    ShopSelect.SetParentComponent(Self);
    ShopSelect.Name := 'ShopSelect';
    ShopSelect.Left := 80;
    ShopSelect.Top := 104;
    ShopSelect.Width := 241;
    ShopSelect.Height := 22;
    ShopSelect.ElementClassName := 'form-select';
    ShopSelect.ElementPosition := epIgnore;
    ShopSelect.HeightStyle := ssAuto;
    ShopSelect.HeightPercent := 100.000000000000000000;
    ShopSelect.WidthStyle := ssAuto;
    ShopSelect.WidthPercent := 100.000000000000000000;
    SetEvent(ShopSelect, Self, 'OnChange', 'ShopSelectChange');
    ShopSelect.ItemIndex := -1;
    RequiredNumber.SetParentComponent(Self);
    RequiredNumber.Name := 'RequiredNumber';
    RequiredNumber.Left := 80;
    RequiredNumber.Top := 132;
    RequiredNumber.Width := 121;
    RequiredNumber.Height := 22;
    RequiredNumber.ChildOrder := 2;
    RequiredNumber.EditType := weNumeric;
    RequiredNumber.ElementClassName := 'form-control';
    RequiredNumber.ElementPosition := epIgnore;
    RequiredNumber.HeightStyle := ssAuto;
    RequiredNumber.HeightPercent := 100.000000000000000000;
    RequiredNumber.Required := True;
    RequiredNumber.TabOrder := 1;
    RequiredNumber.Text := '100';
    RequiredNumber.WidthStyle := ssAuto;
    RequiredNumber.WidthPercent := 100.000000000000000000;
    SetEvent(RequiredNumber, Self, 'OnExit', 'RequiredNumberExit');
    OrderButton.SetParentComponent(Self);
    OrderButton.Name := 'OrderButton';
    OrderButton.Left := 80;
    OrderButton.Top := 216;
    OrderButton.Width := 96;
    OrderButton.Height := 25;
    OrderButton.Caption := 'Order';
    OrderButton.ChildOrder := 3;
    OrderButton.ElementClassName := 'btn btn-sceondary';
    OrderButton.ElementFont := efCSS;
    OrderButton.ElementPosition := epIgnore;
    OrderButton.HeightStyle := ssAuto;
    OrderButton.HeightPercent := 100.000000000000000000;
    OrderButton.TabOrder := 4;
    OrderButton.WidthPercent := 100.000000000000000000;
    SetEvent(OrderButton, Self, 'OnClick', 'OrderButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 208;
    CloseButton.Top := 216;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 4;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementFont := efCSS;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 5;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    ShopMessage.SetParentComponent(Self);
    ShopMessage.Name := 'ShopMessage';
    ShopMessage.Left := 336;
    ShopMessage.Top := 104;
    ShopMessage.Width := 100;
    ShopMessage.Height := 41;
    ShopMessage.HeightStyle := ssAuto;
    ShopMessage.WidthStyle := ssAuto;
    ShopMessage.ChildOrder := 4;
    ShopMessage.ElementPosition := epIgnore;
    ShopMessage.ElementFont := efCSS;
    ShopMessage.Role := '';
    JobVisibility.SetParentComponent(Self);
    JobVisibility.Name := 'JobVisibility';
    JobVisibility.Left := 80;
    JobVisibility.Top := 168;
    JobVisibility.Width := 145;
    JobVisibility.Height := 22;
    JobVisibility.ElementClassName := 'form-select';
    JobVisibility.ElementFont := efCSS;
    JobVisibility.HeightPercent := 100.000000000000000000;
    JobVisibility.TabOrder := 2;
    JobVisibility.WidthPercent := 100.000000000000000000;
    JobVisibility.ItemIndex := -1;
    JobVisibility.LookupValues.Clear;
    with JobVisibility.LookupValues.Add do
    begin
      Value := 'OwnerOnly';
      DisplayText := 'Only me';
    end;
    with JobVisibility.LookupValues.Add do
    begin
      Value := 'AnyAdministrator';
      DisplayText := 'Any administrator';
    end;
    with JobVisibility.LookupValues.Add do
    begin
      Value := 'AnyAdminUser';
      DisplayText := 'Any admin user';
    end;
    OutputOption.SetParentComponent(Self);
    OutputOption.Name := 'OutputOption';
    OutputOption.Left := 264;
    OutputOption.Top := 168;
    OutputOption.Width := 145;
    OutputOption.Height := 22;
    OutputOption.ElementClassName := 'form-select';
    OutputOption.ElementFont := efCSS;
    OutputOption.ElementPosition := epIgnore;
    OutputOption.HeightStyle := ssAuto;
    OutputOption.HeightPercent := 100.000000000000000000;
    OutputOption.TabOrder := 3;
    OutputOption.WidthStyle := ssAuto;
    OutputOption.WidthPercent := 100.000000000000000000;
    OutputOption.ItemIndex := -1;
    OutputOption.LookupValues.Clear;
    with OutputOption.LookupValues.Add do
    begin
      Value := 'Undecided';
      DisplayText := 'I'#39'll decide later';
    end;
    OutcomeMessage.SetParentComponent(Self);
    OutcomeMessage.Name := 'OutcomeMessage';
    OutcomeMessage.Left := 328;
    OutcomeMessage.Top := 216;
    OutcomeMessage.Width := 100;
    OutcomeMessage.Height := 41;
    OutcomeMessage.HeightStyle := ssAuto;
    OutcomeMessage.WidthStyle := ssAuto;
    OutcomeMessage.ChildOrder := 7;
    OutcomeMessage.ElementPosition := epIgnore;
    OutcomeMessage.ElementFont := efCSS;
    OutcomeMessage.Role := '';
    LabelsOptions.SetParentComponent(Self);
    LabelsOptions.Name := 'LabelsOptions';
    LabelsOptions.Left := 448;
    LabelsOptions.Top := 168;
    LabelsOptions.Width := 145;
    LabelsOptions.Height := 23;
    LabelsOptions.ElementClassName := 'form-select';
    LabelsOptions.ElementFont := efCSS;
    LabelsOptions.HeightStyle := ssAuto;
    LabelsOptions.HeightPercent := 100.000000000000000000;
    LabelsOptions.Text := 'Use Template';
    LabelsOptions.WidthStyle := ssAuto;
    LabelsOptions.WidthPercent := 100.000000000000000000;
    LabelsOptions.ItemIndex := -1;
    LabelsOptions.Items.BeginUpdate;
    try
      LabelsOptions.Items.Clear;
      LabelsOptions.Items.Add('Use full template');
      LabelsOptions.Items.Add('Use pre printed template');
      LabelsOptions.Items.Add('Do not produce labels');
    finally
      LabelsOptions.Items.EndUpdate;
    end;
    ShopsLookUp.SetParentComponent(Self);
    ShopsLookUp.Name := 'ShopsLookUp';
    ShopsLookUp.EntitySetName := 'Shop';
    ShopsLookUp.Connection := MainData.DataConnection;
    ShopsLookUp.Left := 360;
    ShopsLookUp.Top := 16;
    ShopsLookUpRef.SetParentComponent(ShopsLookUp);
    ShopsLookUpRef.Name := 'ShopsLookUpRef';
    ShopsLookUpRef.FieldName := 'Ref';
    ShopsLookUpRef.Size := 5;
    ShopsLookUpName.SetParentComponent(ShopsLookUp);
    ShopsLookUpName.Name := 'ShopsLookUpName';
    ShopsLookUpName.FieldName := 'Name';
    ShopsLookUpName.Size := 250;
    ShopsLookUpStatus.SetParentComponent(ShopsLookUp);
    ShopsLookUpStatus.Name := 'ShopsLookUpStatus';
    ShopsLookUpStatus.FieldName := 'Status';
    ShopsLookUpStatus.Size := 6;
    ShopsLookUpLocationType.SetParentComponent(ShopsLookUp);
    ShopsLookUpLocationType.Name := 'ShopsLookUpLocationType';
    ShopsLookUpLocationType.FieldName := 'LocationType';
    ShopsLookUpLocationType.Size := 10;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := MainData.DataConnection;
    WebClient.Left := 128;
    WebClient.Top := 296;
  finally
    CloseThisButton.AfterLoadDFMValues;
    ShopSelect.AfterLoadDFMValues;
    RequiredNumber.AfterLoadDFMValues;
    OrderButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ShopMessage.AfterLoadDFMValues;
    JobVisibility.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    OutcomeMessage.AfterLoadDFMValues;
    LabelsOptions.AfterLoadDFMValues;
    ShopsLookUp.AfterLoadDFMValues;
    ShopsLookUpRef.AfterLoadDFMValues;
    ShopsLookUpName.AfterLoadDFMValues;
    ShopsLookUpStatus.AfterLoadDFMValues;
    ShopsLookUpLocationType.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
  end;
end;

end.

