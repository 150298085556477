unit App.Helper;

interface

uses
  System.Classes,
  System.SysUtils;

type

  THTMLHelper = class
  public

    class function stringsToUL(AStrings: TStrings; const AListClass: string = ''; AItemClass: string = ''): string;

    class procedure addClass(const aElementId: string; const AClassName: string);
    class procedure removeClass(const aElementId: string; const AClassName: string);
    class procedure hideElement(const aElementId: string);
    class function elementHeight(const aElementId: string): integer;
    class function elementWidth(const aElementId: string): integer;
    class procedure writeHTML(const aElementId: string; const Value: string);
    class procedure loadHTML(const aElementId: string; const URL: string);
    class procedure appendHTML(const aElementId: string; const Value: string);
    class procedure emptyDiv(const aElementId: string);

    class procedure enterAsTab(const aElementId: string);
    class function elementIdExists(const aElementId: string): Boolean;

  end;

implementation

{ THTMLHelper }

{$HINTS OFF}
class procedure THTMLHelper.addClass(const aElementId, AClassName: string);
begin
  asm
    $("#" + aElementId).addClass(AClassName);
  end;
end;

class procedure THTMLHelper.appendHTML(const aElementId, Value: string);
begin
  asm
    var Doc = document.getElementById(aElementId);
    if (Doc !== null) {
       Doc.innerHTML += Value;
    }
  end;
end;

class function THTMLHelper.elementHeight(const aElementId: string): integer;
begin
  asm
    Result = parseInt($("#" + aElementId).height());
  end;
end;

class function THTMLHelper.elementIdExists(const aElementId: string): Boolean;
begin
  asm
   return (document.getElementById("#" + aElementId) !== null);
  end;
end;

class function THTMLHelper.elementWidth(const aElementId: string): integer;
begin
  asm
    Result = parseInt($("#" + aElementId).width());
  end;
end;

class procedure THTMLHelper.emptyDiv(const aElementId: string);
begin
  asm
    var Doc = document.getElementById(aElementId);
    if (Doc !== null) {
       Doc.innerHTML = "";
    }
  end;
end;

class procedure THTMLHelper.enterAsTab(const aElementId: string);
begin
  asm
    var n = $("input:text").length;
    var nextIndex = $('#' + aElementId).index() + 1;
    if(nextIndex < n) {
    $('input:text')[nextIndex].focus(); };
  end;
end;

class procedure THTMLHelper.hideElement(const aElementId: string);
begin
  asm
    $("#" + aElementId).hide();
  end;
end;

class procedure THTMLHelper.loadHTML(const aElementId, URL: string);
begin
  asm
    $("#" + aElementId).load(URL);
  end;
end;

class procedure THTMLHelper.removeClass(const aElementId, AClassName: string);
begin
  asm
    $("#" + aElementId).removeClass(AClassName);
  end;
end;

class function THTMLHelper.stringsToUL(AStrings: TStrings; const AListClass: string = '';
  AItemClass: string = ''): string;
var
  I: integer;
  lClass: string;
begin
  result := '';

  if AItemClass <> '' then
    lClass := format(' class="%s">', [AItemClass])
  else
    lClass := '>';

  for I := 0 to AStrings.Count - 1 do
    result := result + '<li' + lClass + AStrings[I] + '</li>';

  if AListClass <> '' then
    lClass := format(' class="%s">', [AListClass])
  else
    lClass := '>';

  result := '<ul' + lClass + result + '</ul>';
end;

class procedure THTMLHelper.writeHTML(const aElementId, Value: string);
begin
  asm
    $("#" + aElementId).html(Value);
  end;
end;


{$HINTS ON}
end.
