unit ExportSalesForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg;

type
  TSalesExportJob = class(TJobBase)
    GiftAidClaims: TXDataWebDataSet;
    GiftAidClaimsId: TIntegerField;
    GiftAidClaimsTransactionId: TStringField;
    GiftAidClaimsClaimRef: TStringField;
    GiftAidClaimsSubmissionDate: TDateTimeField;
    GiftAidClaimsEarliestSubmissionDate: TDateField;
    GiftAidClaimsStatus: TStringField;
    GiftAidClaimsLastStatusChange: TDateTimeField;
    GiftAidClaimsExportedForRE: TDateTimeField;
    ClaimSelect: TLookupComboBox;
    procedure ClaimSelectChange(Sender: TObject);
    procedure GiftAidClaimsAfterOpen(Dataset: TDataSet);
    procedure JobsPlacedAfterOpen(Dataset: TDataSet);
  private
    { Private declarations }
  protected
    function CanPlaceJob: boolean; override;
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SalesExportJob: TSalesExportJob;

implementation

uses
  AppUtils,
  MainDataModule;

{$R *.dfm}
{ TSalesExportJob }

function TSalesExportJob.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    while not JobsPlaced.eof do
    begin
      if TSysHelper.JobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new export while there is still one in progress');
        Exit(False);
      end;
      JobsPlaced.Next;
    end;
  end;

  result := result and GiftAidClaims.Active;

  if result then
  begin
    if GiftAidClaims.IsEmpty then
    begin
      SetJobMessage
        ('There are no claims that need processing at the moment, but you can reprocess a previous one by entering the Claim Ref');
      result := ControlValidity(ClaimSelect.ElementId, ClaimSelect.ItemIndex > -1);
    end
    else
    begin
      result := ControlValidity(ClaimSelect.ElementId, ClaimSelect.ItemIndex > -1);
      if not result then
        SetJobMessage('Please select the claim to process')
    end;

  end;

end;

procedure TSalesExportJob.ClaimSelectChange(Sender: TObject);
begin
  ValidateJob;
end;

procedure TSalesExportJob.GiftAidClaimsAfterOpen(Dataset: TDataSet);
begin
  if not GiftAidClaims.IsEmpty then
  begin
    while not GiftAidClaims.eof do
    begin
      ClaimSelect.LookupValues.AddPair(GiftAidClaimsId.AsString, GiftAidClaimsClaimRef.Value + '  |  ' +
        GiftAidClaimsStatus.Value + '  |  ' + FormatDateTime('dd/mm/yyyy', GiftAidClaimsSubmissionDate.Value));
      GiftAidClaims.Next;
    end;
  end;

  ValidateJob;
end;

function TSalesExportJob.JobClass: string;
begin
  result := 'TRESalesExport';
end;

function TSalesExportJob.JobDescription: string;
begin
  result := 'Export Sales for Rasier''s Edge';
end;

function TSalesExportJob.JobDisplayName: string;
begin
  result := 'Sales Export';
end;

procedure TSalesExportJob.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['ClaimId'] := ClaimSelect.Value;
  AJobParams.Properties['Method'] := 'select';
end;

procedure TSalesExportJob.JobsPlacedAfterOpen(Dataset: TDataSet);
begin
  inherited;
  GiftAidClaims.Load;
end;

procedure TSalesExportJob.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ClaimSelect := TLookupComboBox.Create('ClaimSelect');
  GiftAidClaims := TXDataWebDataSet.Create(Self);
  GiftAidClaimsId := TIntegerField.Create(Self);
  GiftAidClaimsTransactionId := TStringField.Create(Self);
  GiftAidClaimsClaimRef := TStringField.Create(Self);
  GiftAidClaimsSubmissionDate := TDateTimeField.Create(Self);
  GiftAidClaimsEarliestSubmissionDate := TDateField.Create(Self);
  GiftAidClaimsStatus := TStringField.Create(Self);
  GiftAidClaimsLastStatusChange := TDateTimeField.Create(Self);
  GiftAidClaimsExportedForRE := TDateTimeField.Create(Self);

  JobMessage.BeforeLoadDFMValues;
  ClaimSelect.BeforeLoadDFMValues;
  GiftAidClaims.BeforeLoadDFMValues;
  GiftAidClaimsId.BeforeLoadDFMValues;
  GiftAidClaimsTransactionId.BeforeLoadDFMValues;
  GiftAidClaimsClaimRef.BeforeLoadDFMValues;
  GiftAidClaimsSubmissionDate.BeforeLoadDFMValues;
  GiftAidClaimsEarliestSubmissionDate.BeforeLoadDFMValues;
  GiftAidClaimsStatus.BeforeLoadDFMValues;
  GiftAidClaimsLastStatusChange.BeforeLoadDFMValues;
  GiftAidClaimsExportedForRE.BeforeLoadDFMValues;
  try
    JobMessage.Top := 328;
    ClaimSelect.SetParentComponent(Self);
    ClaimSelect.Name := 'ClaimSelect';
    ClaimSelect.Left := 424;
    ClaimSelect.Top := 264;
    ClaimSelect.Width := 145;
    ClaimSelect.Height := 22;
    ClaimSelect.ElementClassName := 'form-select';
    ClaimSelect.ElementFont := efCSS;
    ClaimSelect.HeightStyle := ssAuto;
    ClaimSelect.HeightPercent := 100.000000000000000000;
    ClaimSelect.WidthStyle := ssAuto;
    ClaimSelect.WidthPercent := 100.000000000000000000;
    SetEvent(ClaimSelect, Self, 'OnChange', 'ClaimSelectChange');
    ClaimSelect.ItemIndex := -1;
    GiftAidClaims.SetParentComponent(Self);
    GiftAidClaims.Name := 'GiftAidClaims';
    GiftAidClaims.AfterOpen := GiftAidClaimsAfterOpen;
    GiftAidClaims.EntitySetName := 'GiftAidClaim';
    GiftAidClaims.Connection := MainData.DataConnection;
    GiftAidClaims.QueryString := '$filter=(ExportedForRE eq null) and ((ClaimStatus eq '#39'Confirmed'#39+') or (ClaimStatus eq '#39'PaymentReceived'#39'))&$orderby=SubmissionDate desc';
    GiftAidClaims.Left := 368;
    GiftAidClaims.Top := 72;
    GiftAidClaimsId.SetParentComponent(GiftAidClaims);
    GiftAidClaimsId.Name := 'GiftAidClaimsId';
    GiftAidClaimsId.FieldName := 'Id';
    GiftAidClaimsTransactionId.SetParentComponent(GiftAidClaims);
    GiftAidClaimsTransactionId.Name := 'GiftAidClaimsTransactionId';
    GiftAidClaimsTransactionId.FieldName := 'TransactionId';
    GiftAidClaimsTransactionId.Size := 32;
    GiftAidClaimsClaimRef.SetParentComponent(GiftAidClaims);
    GiftAidClaimsClaimRef.Name := 'GiftAidClaimsClaimRef';
    GiftAidClaimsClaimRef.FieldName := 'ClaimRef';
    GiftAidClaimsClaimRef.Size := 32;
    GiftAidClaimsSubmissionDate.SetParentComponent(GiftAidClaims);
    GiftAidClaimsSubmissionDate.Name := 'GiftAidClaimsSubmissionDate';
    GiftAidClaimsSubmissionDate.FieldName := 'SubmissionDate';
    GiftAidClaimsEarliestSubmissionDate.SetParentComponent(GiftAidClaims);
    GiftAidClaimsEarliestSubmissionDate.Name := 'GiftAidClaimsEarliestSubmissionDate';
    GiftAidClaimsEarliestSubmissionDate.FieldName := 'EarliestSubmissionDate';
    GiftAidClaimsEarliestSubmissionDate.Required := True;
    GiftAidClaimsStatus.SetParentComponent(GiftAidClaims);
    GiftAidClaimsStatus.Name := 'GiftAidClaimsStatus';
    GiftAidClaimsStatus.FieldName := 'Status';
    GiftAidClaimsStatus.Required := True;
    GiftAidClaimsStatus.Size := 15;
    GiftAidClaimsLastStatusChange.SetParentComponent(GiftAidClaims);
    GiftAidClaimsLastStatusChange.Name := 'GiftAidClaimsLastStatusChange';
    GiftAidClaimsLastStatusChange.FieldName := 'LastStatusChange';
    GiftAidClaimsLastStatusChange.Required := True;
    GiftAidClaimsExportedForRE.SetParentComponent(GiftAidClaims);
    GiftAidClaimsExportedForRE.Name := 'GiftAidClaimsExportedForRE';
    GiftAidClaimsExportedForRE.FieldName := 'ExportedForRE';
  finally
    JobMessage.AfterLoadDFMValues;
    ClaimSelect.AfterLoadDFMValues;
    GiftAidClaims.AfterLoadDFMValues;
    GiftAidClaimsId.AfterLoadDFMValues;
    GiftAidClaimsTransactionId.AfterLoadDFMValues;
    GiftAidClaimsClaimRef.AfterLoadDFMValues;
    GiftAidClaimsSubmissionDate.AfterLoadDFMValues;
    GiftAidClaimsEarliestSubmissionDate.AfterLoadDFMValues;
    GiftAidClaimsStatus.AfterLoadDFMValues;
    GiftAidClaimsLastStatusChange.AfterLoadDFMValues;
    GiftAidClaimsExportedForRE.AfterLoadDFMValues;
  end;
end;

end.
