unit DonorRefundForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  DonorBaseViewForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.ExtCtrls;

type
  TDonorRefundFinal = class(TDonorBaseView)
    RefundDataset: TXDataWebDataSet;
    RefundDatasetId: TIntegerField;
    RefundDatasetRefundDate: TDateField;
    RefundDatasetRefundStatus: TStringField;
    RefundDatasetSales: TFloatField;
    RefundDatasetDonorId: TIntegerField;
    RefundDatasetFee: TFloatField;
    RefundDatasetAddedBy: TIntegerField;
    RefundDatasetDateAdded: TDateTimeField;
    RefundDatasetLastUpdatedBy: TIntegerField;
    RefundDatasetLastUpdated: TDateTimeField;
    RefundDatasetRefundAmount: TFloatField;
    RefundDatasetNotificationId: TIntegerField;
    RefundDatasource: TDataSource;
    DonorDataset: TXDataWebDataSet;
    SentButton: TButton;
    DonorDatasetId: TIntegerField;
    DonorDatasetConsId: TIntegerField;
    DonorDatasetOldRef: TStringField;
    DonorDatasetShopRef: TStringField;
    DonorDatasetTitle: TStringField;
    DonorDatasetFirstName: TStringField;
    DonorDatasetLastName: TStringField;
    DonorDatasetAdd1: TStringField;
    DonorDatasetAdd2: TStringField;
    DonorDatasetAdd3: TStringField;
    DonorDatasetCity: TStringField;
    DonorDatasetPostCode: TStringField;
    DonorDatasetEmail: TStringField;
    DonorDatasetMobile: TStringField;
    DonorDatasetTelephone: TStringField;
    DonorDatasetGiftAidScheme: TStringField;
    DonorDatasetTaxReportRequested: TBooleanField;
    DonorDatasetClaimStatus: TStringField;
    DonorDatasetScannedDocsExist: TBooleanField;
    DonorDatasetAddedBy: TIntegerField;
    DonorDatasetDateAdded: TDateTimeField;
    DonorDatasetLastUpdatedBy: TIntegerField;
    DonorDatasetLastUpdated: TDateTimeField;
    DonorDatasetFurtherContact: TBooleanField;
    DonorDatasetComments: TStringField;
    DonorDatasetDateSigned: TDateField;
    DonorDatasetContactByPhone: TBooleanField;
    DonorDatasetContactByPost: TBooleanField;
    DonorDatasetContactByText: TBooleanField;
    DonorDatasetContactByEmail: TBooleanField;
    DonorDatasetREExported: TDateTimeField;
    DonorDatasetREImported: TDateTimeField;
    DonorDatasetMatchHash: TStringField;
    DonorDatasetMasterId: TIntegerField;
    WebDBLabel1: TDBLabel;
    RefundStatus: TDBLabel;
    TotalSales: TDBLabel;
    Commission: TDBLabel;
    HandlingFee: TDBLabel;
    RefundDatasetCommission: TFloatField;
    RefundAmount: TDBLabel;
    DownloadLetterLink: TLinkLabel;
    DonorDatasetClaimStatusText: TStringField;
    DonorDatasetFullName: TStringField;
    DonorDatasetFullAddress: TStringField;
    procedure DonorDatasetAfterOpen(Dataset: TDataSet);
    procedure DonorDatasetCalcFields(Dataset: TDataSet);
    procedure DownloadLetterLinkClick(Sender: TObject);
    procedure RefundDatasetAfterOpen(Dataset: TDataSet);
    procedure RefundDatasetRefundStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure SentButtonClick(Sender: TObject);
  private
    FRefundId: Integer;
    FDonorId: Integer;
    [async]
    procedure FinaliseRefund; async;
    [async]
    procedure DownloadLetter; async;
    procedure SetRefundId(const Value: string);
    { Private declarations }
  protected
  procedure AfterSetDataset; Override;
  public
    { Public declarations }
    property RefundId: string write SetRefundId;
  protected procedure LoadDFMValues; override; end;

var
  DonorRefundFinal: TDonorRefundFinal;

implementation

{$R *.dfm}

uses
  MainDataModule,
  AppUtils,
  XData.Web.Client;

procedure TDonorRefundFinal.AfterSetDataset;
begin
  inherited;
end;

procedure TDonorRefundFinal.DonorDatasetAfterOpen(Dataset: TDataSet);
begin
  DonorDataset.First;
  SetDataset(DonorDataset);
end;

procedure TDonorRefundFinal.DonorDatasetCalcFields(Dataset: TDataSet);
begin
  DonorDatasetClaimStatusText.Value := TSysHelper.SplitOnCaps(DonorDatasetClaimStatus.Value);
  DonorDatasetFullName.Value := TSysHelper.FullName(DonorDataset);
  DonorDatasetFullAddress.Value := TSysHelper.FormattedAddress(DonorDataset);
end;

procedure TDonorRefundFinal.DownloadLetter;
begin

end;

procedure TDonorRefundFinal.DownloadLetterLinkClick(Sender: TObject);
var lURL: string;
begin
//  DownloadLetter;
  lURL := Format('store/Refund_%d.pdf', [FRefundId]);
  Application.Navigate(lURL);
end;

procedure TDonorRefundFinal.FinaliseRefund;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_REFUND_FINALISE, [FRefundId]));
  ShowAnAwesomeToast('Refund', 'Refund finalised for ' + TSysHelper.ShortName(Datasource.Dataset), 'fad fa-coins');

  RefundDataset.DisableControls;
  RefundDataset.Close;
  RefundDataset.Load;
  SentButton.Visible := False;
end;

procedure TDonorRefundFinal.RefundDatasetAfterOpen(Dataset: TDataSet);
begin
  RefundDataset.EnableControls;
  SentButton.Visible := RefundDatasetRefundStatus.Value = 'RefundRequested';
  if DonorDataset.Active then
    Exit;
  FDonorId := RefundDatasetDonorId.Value;
  if not FDatasetApplied then
  begin
    DonorDataset.QueryString := Format('$filter=Id eq %d', [FDonorId]);
    DonorDataset.Load;
  end;
end;

procedure TDonorRefundFinal.RefundDatasetRefundStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := TSysHelper.SplitOnCaps(Text);
end;

procedure TDonorRefundFinal.SentButtonClick(Sender: TObject);
begin
  if RefundDatasetRefundStatus.Value = 'RefundRequested' then
    FinaliseRefund;
end;

{ TDonorRefundFinal }

procedure TDonorRefundFinal.SetRefundId(const Value: string);
begin
  FRefundId := Value.ToInteger;
  RefundDataset.QueryString := Format('$filter=Id eq %d', [FRefundId]);
  RefundDataset.Load;
end;

procedure TDonorRefundFinal.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebDBLabel1 := TDBLabel.Create('RefundId');
  RefundStatus := TDBLabel.Create('RefundStatus');
  TotalSales := TDBLabel.Create('TotalSales');
  Commission := TDBLabel.Create('Commission');
  HandlingFee := TDBLabel.Create('HandlingFee');
  RefundAmount := TDBLabel.Create('RefundAmount');
  DownloadLetterLink := TLinkLabel.Create('DownloadRefundLetter');
  SentButton := TButton.Create('SentButton');
  RefundDataset := TXDataWebDataSet.Create(Self);
  RefundDatasetId := TIntegerField.Create(Self);
  RefundDatasetRefundDate := TDateField.Create(Self);
  RefundDatasetRefundStatus := TStringField.Create(Self);
  RefundDatasetSales := TFloatField.Create(Self);
  RefundDatasetCommission := TFloatField.Create(Self);
  RefundDatasetFee := TFloatField.Create(Self);
  RefundDatasetRefundAmount := TFloatField.Create(Self);
  RefundDatasetDonorId := TIntegerField.Create(Self);
  RefundDatasetAddedBy := TIntegerField.Create(Self);
  RefundDatasetDateAdded := TDateTimeField.Create(Self);
  RefundDatasetLastUpdatedBy := TIntegerField.Create(Self);
  RefundDatasetLastUpdated := TDateTimeField.Create(Self);
  RefundDatasetNotificationId := TIntegerField.Create(Self);
  RefundDatasource := TDataSource.Create(Self);
  DonorDataset := TXDataWebDataSet.Create(Self);
  DonorDatasetId := TIntegerField.Create(Self);
  DonorDatasetConsId := TIntegerField.Create(Self);
  DonorDatasetOldRef := TStringField.Create(Self);
  DonorDatasetShopRef := TStringField.Create(Self);
  DonorDatasetTitle := TStringField.Create(Self);
  DonorDatasetFirstName := TStringField.Create(Self);
  DonorDatasetLastName := TStringField.Create(Self);
  DonorDatasetAdd1 := TStringField.Create(Self);
  DonorDatasetAdd2 := TStringField.Create(Self);
  DonorDatasetAdd3 := TStringField.Create(Self);
  DonorDatasetCity := TStringField.Create(Self);
  DonorDatasetPostCode := TStringField.Create(Self);
  DonorDatasetEmail := TStringField.Create(Self);
  DonorDatasetMobile := TStringField.Create(Self);
  DonorDatasetTelephone := TStringField.Create(Self);
  DonorDatasetGiftAidScheme := TStringField.Create(Self);
  DonorDatasetTaxReportRequested := TBooleanField.Create(Self);
  DonorDatasetClaimStatus := TStringField.Create(Self);
  DonorDatasetScannedDocsExist := TBooleanField.Create(Self);
  DonorDatasetAddedBy := TIntegerField.Create(Self);
  DonorDatasetDateAdded := TDateTimeField.Create(Self);
  DonorDatasetLastUpdatedBy := TIntegerField.Create(Self);
  DonorDatasetLastUpdated := TDateTimeField.Create(Self);
  DonorDatasetFurtherContact := TBooleanField.Create(Self);
  DonorDatasetComments := TStringField.Create(Self);
  DonorDatasetDateSigned := TDateField.Create(Self);
  DonorDatasetContactByPhone := TBooleanField.Create(Self);
  DonorDatasetContactByPost := TBooleanField.Create(Self);
  DonorDatasetContactByText := TBooleanField.Create(Self);
  DonorDatasetContactByEmail := TBooleanField.Create(Self);
  DonorDatasetREExported := TDateTimeField.Create(Self);
  DonorDatasetREImported := TDateTimeField.Create(Self);
  DonorDatasetMatchHash := TStringField.Create(Self);
  DonorDatasetMasterId := TIntegerField.Create(Self);
  DonorDatasetClaimStatusText := TStringField.Create(Self);
  DonorDatasetFullName := TStringField.Create(Self);
  DonorDatasetFullAddress := TStringField.Create(Self);

  WebDBLabel1.BeforeLoadDFMValues;
  RefundStatus.BeforeLoadDFMValues;
  TotalSales.BeforeLoadDFMValues;
  Commission.BeforeLoadDFMValues;
  HandlingFee.BeforeLoadDFMValues;
  RefundAmount.BeforeLoadDFMValues;
  DownloadLetterLink.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SentButton.BeforeLoadDFMValues;
  RefundDataset.BeforeLoadDFMValues;
  RefundDatasetId.BeforeLoadDFMValues;
  RefundDatasetRefundDate.BeforeLoadDFMValues;
  RefundDatasetRefundStatus.BeforeLoadDFMValues;
  RefundDatasetSales.BeforeLoadDFMValues;
  RefundDatasetCommission.BeforeLoadDFMValues;
  RefundDatasetFee.BeforeLoadDFMValues;
  RefundDatasetRefundAmount.BeforeLoadDFMValues;
  RefundDatasetDonorId.BeforeLoadDFMValues;
  RefundDatasetAddedBy.BeforeLoadDFMValues;
  RefundDatasetDateAdded.BeforeLoadDFMValues;
  RefundDatasetLastUpdatedBy.BeforeLoadDFMValues;
  RefundDatasetLastUpdated.BeforeLoadDFMValues;
  RefundDatasetNotificationId.BeforeLoadDFMValues;
  RefundDatasource.BeforeLoadDFMValues;
  DonorDataset.BeforeLoadDFMValues;
  DonorDatasetId.BeforeLoadDFMValues;
  DonorDatasetConsId.BeforeLoadDFMValues;
  DonorDatasetOldRef.BeforeLoadDFMValues;
  DonorDatasetShopRef.BeforeLoadDFMValues;
  DonorDatasetTitle.BeforeLoadDFMValues;
  DonorDatasetFirstName.BeforeLoadDFMValues;
  DonorDatasetLastName.BeforeLoadDFMValues;
  DonorDatasetAdd1.BeforeLoadDFMValues;
  DonorDatasetAdd2.BeforeLoadDFMValues;
  DonorDatasetAdd3.BeforeLoadDFMValues;
  DonorDatasetCity.BeforeLoadDFMValues;
  DonorDatasetPostCode.BeforeLoadDFMValues;
  DonorDatasetEmail.BeforeLoadDFMValues;
  DonorDatasetMobile.BeforeLoadDFMValues;
  DonorDatasetTelephone.BeforeLoadDFMValues;
  DonorDatasetGiftAidScheme.BeforeLoadDFMValues;
  DonorDatasetTaxReportRequested.BeforeLoadDFMValues;
  DonorDatasetClaimStatus.BeforeLoadDFMValues;
  DonorDatasetScannedDocsExist.BeforeLoadDFMValues;
  DonorDatasetAddedBy.BeforeLoadDFMValues;
  DonorDatasetDateAdded.BeforeLoadDFMValues;
  DonorDatasetLastUpdatedBy.BeforeLoadDFMValues;
  DonorDatasetLastUpdated.BeforeLoadDFMValues;
  DonorDatasetFurtherContact.BeforeLoadDFMValues;
  DonorDatasetComments.BeforeLoadDFMValues;
  DonorDatasetDateSigned.BeforeLoadDFMValues;
  DonorDatasetContactByPhone.BeforeLoadDFMValues;
  DonorDatasetContactByPost.BeforeLoadDFMValues;
  DonorDatasetContactByText.BeforeLoadDFMValues;
  DonorDatasetContactByEmail.BeforeLoadDFMValues;
  DonorDatasetREExported.BeforeLoadDFMValues;
  DonorDatasetREImported.BeforeLoadDFMValues;
  DonorDatasetMatchHash.BeforeLoadDFMValues;
  DonorDatasetMasterId.BeforeLoadDFMValues;
  DonorDatasetClaimStatusText.BeforeLoadDFMValues;
  DonorDatasetFullName.BeforeLoadDFMValues;
  DonorDatasetFullAddress.BeforeLoadDFMValues;
  try
    Height := 556;
    WebDBLabel1.SetParentComponent(Self);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 248;
    WebDBLabel1.Top := 160;
    WebDBLabel1.Width := 48;
    WebDBLabel1.Height := 15;
    WebDBLabel1.Caption := 'Refundid';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.ElementPosition := epIgnore;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.WidthStyle := ssAuto;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Id';
    WebDBLabel1.DataSource := RefundDatasource;
    RefundStatus.SetParentComponent(Self);
    RefundStatus.Name := 'RefundStatus';
    RefundStatus.Left := 248;
    RefundStatus.Top := 181;
    RefundStatus.Width := 70;
    RefundStatus.Height := 15;
    RefundStatus.Caption := 'RefundStatus';
    RefundStatus.ElementFont := efCSS;
    RefundStatus.ElementPosition := epIgnore;
    RefundStatus.HeightStyle := ssAuto;
    RefundStatus.HeightPercent := 100.000000000000000000;
    RefundStatus.WidthStyle := ssAuto;
    RefundStatus.WidthPercent := 100.000000000000000000;
    RefundStatus.DataField := 'RefundStatus';
    RefundStatus.DataSource := RefundDatasource;
    TotalSales.SetParentComponent(Self);
    TotalSales.Name := 'TotalSales';
    TotalSales.Left := 248;
    TotalSales.Top := 221;
    TotalSales.Width := 51;
    TotalSales.Height := 15;
    TotalSales.Caption := 'TotalSales';
    TotalSales.ElementFont := efCSS;
    TotalSales.ElementPosition := epIgnore;
    TotalSales.HeightStyle := ssAuto;
    TotalSales.HeightPercent := 100.000000000000000000;
    TotalSales.WidthStyle := ssAuto;
    TotalSales.WidthPercent := 100.000000000000000000;
    TotalSales.DataField := 'Sales';
    TotalSales.DataSource := RefundDatasource;
    Commission.SetParentComponent(Self);
    Commission.Name := 'Commission';
    Commission.Left := 248;
    Commission.Top := 252;
    Commission.Width := 67;
    Commission.Height := 15;
    Commission.Caption := 'Commission';
    Commission.ElementFont := efCSS;
    Commission.ElementPosition := epIgnore;
    Commission.HeightStyle := ssAuto;
    Commission.HeightPercent := 100.000000000000000000;
    Commission.WidthStyle := ssAuto;
    Commission.WidthPercent := 100.000000000000000000;
    Commission.DataField := 'Commission';
    Commission.DataSource := RefundDatasource;
    HandlingFee.SetParentComponent(Self);
    HandlingFee.Name := 'HandlingFee';
    HandlingFee.Left := 248;
    HandlingFee.Top := 283;
    HandlingFee.Width := 67;
    HandlingFee.Height := 15;
    HandlingFee.Caption := 'HandlingFee';
    HandlingFee.ElementFont := efCSS;
    HandlingFee.ElementPosition := epIgnore;
    HandlingFee.HeightStyle := ssAuto;
    HandlingFee.HeightPercent := 100.000000000000000000;
    HandlingFee.WidthStyle := ssAuto;
    HandlingFee.WidthPercent := 100.000000000000000000;
    HandlingFee.DataField := 'Fee';
    HandlingFee.DataSource := RefundDatasource;
    RefundAmount.SetParentComponent(Self);
    RefundAmount.Name := 'RefundAmount';
    RefundAmount.Left := 251;
    RefundAmount.Top := 323;
    RefundAmount.Width := 67;
    RefundAmount.Height := 15;
    RefundAmount.Caption := 'HandlingFee';
    RefundAmount.ElementFont := efCSS;
    RefundAmount.ElementPosition := epIgnore;
    RefundAmount.HeightStyle := ssAuto;
    RefundAmount.HeightPercent := 100.000000000000000000;
    RefundAmount.WidthStyle := ssAuto;
    RefundAmount.WidthPercent := 100.000000000000000000;
    RefundAmount.DataField := 'RefundAmount';
    RefundAmount.DataSource := RefundDatasource;
    DownloadLetterLink.SetParentComponent(Self);
    DownloadLetterLink.Name := 'DownloadLetterLink';
    DownloadLetterLink.Left := 251;
    DownloadLetterLink.Top := 354;
    DownloadLetterLink.Width := 87;
    DownloadLetterLink.Height := 15;
    DownloadLetterLink.ElementFont := efCSS;
    DownloadLetterLink.HeightPercent := 100.000000000000000000;
    DownloadLetterLink.WidthPercent := 100.000000000000000000;
    SetEvent(DownloadLetterLink, Self, 'OnClick', 'DownloadLetterLinkClick');
    DownloadLetterLink.Caption := 'Download Letter';
    CloseButton.Left := 720;
    CloseButton.Top := 504;
    SentButton.SetParentComponent(Self);
    SentButton.Name := 'SentButton';
    SentButton.Left := 560;
    SentButton.Top := 504;
    SentButton.Width := 129;
    SentButton.Height := 25;
    SentButton.Caption := 'Mark Refund as Sent';
    SentButton.ChildOrder := 15;
    SentButton.ElementClassName := 'btn btn-primary';
    SentButton.ElementFont := efCSS;
    SentButton.ElementPosition := epIgnore;
    SentButton.HeightStyle := ssAuto;
    SentButton.HeightPercent := 100.000000000000000000;
    SentButton.WidthStyle := ssAuto;
    SentButton.WidthPercent := 100.000000000000000000;
    SetEvent(SentButton, Self, 'OnClick', 'SentButtonClick');
    RefundDataset.SetParentComponent(Self);
    RefundDataset.Name := 'RefundDataset';
    RefundDataset.AfterOpen := RefundDatasetAfterOpen;
    RefundDataset.EntitySetName := 'Refund';
    RefundDataset.Connection := MainData.DataConnection;
    RefundDataset.Left := 384;
    RefundDataset.Top := 32;
    RefundDatasetId.SetParentComponent(RefundDataset);
    RefundDatasetId.Name := 'RefundDatasetId';
    RefundDatasetId.FieldName := 'Id';
    RefundDatasetRefundDate.SetParentComponent(RefundDataset);
    RefundDatasetRefundDate.Name := 'RefundDatasetRefundDate';
    RefundDatasetRefundDate.FieldName := 'RefundDate';
    RefundDatasetRefundStatus.SetParentComponent(RefundDataset);
    RefundDatasetRefundStatus.Name := 'RefundDatasetRefundStatus';
    RefundDatasetRefundStatus.FieldName := 'RefundStatus';
    RefundDatasetRefundStatus.Required := True;
    SetEvent(RefundDatasetRefundStatus, Self, 'OnGetText', 'RefundDatasetRefundStatusGetText');
    RefundDatasetRefundStatus.Size := 12;
    RefundDatasetSales.SetParentComponent(RefundDataset);
    RefundDatasetSales.Name := 'RefundDatasetSales';
    RefundDatasetSales.FieldName := 'Sales';
    RefundDatasetSales.Required := True;
    RefundDatasetCommission.SetParentComponent(RefundDataset);
    RefundDatasetCommission.Name := 'RefundDatasetCommission';
    RefundDatasetCommission.FieldName := 'Commission';
    RefundDatasetCommission.Required := True;
    RefundDatasetFee.SetParentComponent(RefundDataset);
    RefundDatasetFee.Name := 'RefundDatasetFee';
    RefundDatasetFee.FieldName := 'Fee';
    RefundDatasetFee.Required := True;
    RefundDatasetRefundAmount.SetParentComponent(RefundDataset);
    RefundDatasetRefundAmount.Name := 'RefundDatasetRefundAmount';
    RefundDatasetRefundAmount.FieldName := 'RefundAmount';
    RefundDatasetRefundAmount.Required := True;
    RefundDatasetDonorId.SetParentComponent(RefundDataset);
    RefundDatasetDonorId.Name := 'RefundDatasetDonorId';
    RefundDatasetDonorId.FieldName := 'DonorId';
    RefundDatasetDonorId.Required := True;
    RefundDatasetAddedBy.SetParentComponent(RefundDataset);
    RefundDatasetAddedBy.Name := 'RefundDatasetAddedBy';
    RefundDatasetAddedBy.FieldName := 'AddedBy';
    RefundDatasetDateAdded.SetParentComponent(RefundDataset);
    RefundDatasetDateAdded.Name := 'RefundDatasetDateAdded';
    RefundDatasetDateAdded.FieldName := 'DateAdded';
    RefundDatasetLastUpdatedBy.SetParentComponent(RefundDataset);
    RefundDatasetLastUpdatedBy.Name := 'RefundDatasetLastUpdatedBy';
    RefundDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    RefundDatasetLastUpdated.SetParentComponent(RefundDataset);
    RefundDatasetLastUpdated.Name := 'RefundDatasetLastUpdated';
    RefundDatasetLastUpdated.FieldName := 'LastUpdated';
    RefundDatasetNotificationId.SetParentComponent(RefundDataset);
    RefundDatasetNotificationId.Name := 'RefundDatasetNotificationId';
    RefundDatasetNotificationId.FieldName := 'NotificationId';
    RefundDatasource.SetParentComponent(Self);
    RefundDatasource.Name := 'RefundDatasource';
    RefundDatasource.DataSet := RefundDataset;
    RefundDatasource.Left := 480;
    RefundDatasource.Top := 32;
    DonorDataset.SetParentComponent(Self);
    DonorDataset.Name := 'DonorDataset';
    DonorDataset.AfterOpen := DonorDatasetAfterOpen;
    SetEvent(DonorDataset, Self, 'OnCalcFields', 'DonorDatasetCalcFields');
    DonorDataset.EntitySetName := 'Donor';
    DonorDataset.Connection := MainData.DataConnection;
    DonorDataset.Left := 208;
    DonorDataset.Top := 80;
    DonorDatasetId.SetParentComponent(DonorDataset);
    DonorDatasetId.Name := 'DonorDatasetId';
    DonorDatasetId.FieldName := 'Id';
    DonorDatasetConsId.SetParentComponent(DonorDataset);
    DonorDatasetConsId.Name := 'DonorDatasetConsId';
    DonorDatasetConsId.FieldName := 'ConsId';
    DonorDatasetOldRef.SetParentComponent(DonorDataset);
    DonorDatasetOldRef.Name := 'DonorDatasetOldRef';
    DonorDatasetOldRef.FieldName := 'OldRef';
    DonorDatasetOldRef.Size := 10;
    DonorDatasetShopRef.SetParentComponent(DonorDataset);
    DonorDatasetShopRef.Name := 'DonorDatasetShopRef';
    DonorDatasetShopRef.FieldName := 'ShopRef';
    DonorDatasetShopRef.Size := 10;
    DonorDatasetTitle.SetParentComponent(DonorDataset);
    DonorDatasetTitle.Name := 'DonorDatasetTitle';
    DonorDatasetTitle.FieldName := 'Title';
    DonorDatasetTitle.Size := 25;
    DonorDatasetFirstName.SetParentComponent(DonorDataset);
    DonorDatasetFirstName.Name := 'DonorDatasetFirstName';
    DonorDatasetFirstName.FieldName := 'FirstName';
    DonorDatasetFirstName.Size := 50;
    DonorDatasetLastName.SetParentComponent(DonorDataset);
    DonorDatasetLastName.Name := 'DonorDatasetLastName';
    DonorDatasetLastName.FieldName := 'LastName';
    DonorDatasetLastName.Size := 50;
    DonorDatasetAdd1.SetParentComponent(DonorDataset);
    DonorDatasetAdd1.Name := 'DonorDatasetAdd1';
    DonorDatasetAdd1.FieldName := 'Add1';
    DonorDatasetAdd1.Size := 75;
    DonorDatasetAdd2.SetParentComponent(DonorDataset);
    DonorDatasetAdd2.Name := 'DonorDatasetAdd2';
    DonorDatasetAdd2.FieldName := 'Add2';
    DonorDatasetAdd2.Size := 75;
    DonorDatasetAdd3.SetParentComponent(DonorDataset);
    DonorDatasetAdd3.Name := 'DonorDatasetAdd3';
    DonorDatasetAdd3.FieldName := 'Add3';
    DonorDatasetAdd3.Size := 75;
    DonorDatasetCity.SetParentComponent(DonorDataset);
    DonorDatasetCity.Name := 'DonorDatasetCity';
    DonorDatasetCity.FieldName := 'City';
    DonorDatasetCity.Size := 75;
    DonorDatasetPostCode.SetParentComponent(DonorDataset);
    DonorDatasetPostCode.Name := 'DonorDatasetPostCode';
    DonorDatasetPostCode.FieldName := 'PostCode';
    DonorDatasetPostCode.Size := 15;
    DonorDatasetEmail.SetParentComponent(DonorDataset);
    DonorDatasetEmail.Name := 'DonorDatasetEmail';
    DonorDatasetEmail.FieldName := 'Email';
    DonorDatasetEmail.Size := 75;
    DonorDatasetMobile.SetParentComponent(DonorDataset);
    DonorDatasetMobile.Name := 'DonorDatasetMobile';
    DonorDatasetMobile.FieldName := 'Mobile';
    DonorDatasetMobile.Size := 25;
    DonorDatasetTelephone.SetParentComponent(DonorDataset);
    DonorDatasetTelephone.Name := 'DonorDatasetTelephone';
    DonorDatasetTelephone.FieldName := 'Telephone';
    DonorDatasetTelephone.Size := 25;
    DonorDatasetGiftAidScheme.SetParentComponent(DonorDataset);
    DonorDatasetGiftAidScheme.Name := 'DonorDatasetGiftAidScheme';
    DonorDatasetGiftAidScheme.FieldName := 'GiftAidScheme';
    DonorDatasetGiftAidScheme.Size := 8;
    DonorDatasetTaxReportRequested.SetParentComponent(DonorDataset);
    DonorDatasetTaxReportRequested.Name := 'DonorDatasetTaxReportRequested';
    DonorDatasetTaxReportRequested.FieldName := 'TaxReportRequested';
    DonorDatasetClaimStatus.SetParentComponent(DonorDataset);
    DonorDatasetClaimStatus.Name := 'DonorDatasetClaimStatus';
    DonorDatasetClaimStatus.FieldName := 'ClaimStatus';
    DonorDatasetClaimStatus.Size := 22;
    DonorDatasetScannedDocsExist.SetParentComponent(DonorDataset);
    DonorDatasetScannedDocsExist.Name := 'DonorDatasetScannedDocsExist';
    DonorDatasetScannedDocsExist.FieldName := 'ScannedDocsExist';
    DonorDatasetAddedBy.SetParentComponent(DonorDataset);
    DonorDatasetAddedBy.Name := 'DonorDatasetAddedBy';
    DonorDatasetAddedBy.FieldName := 'AddedBy';
    DonorDatasetDateAdded.SetParentComponent(DonorDataset);
    DonorDatasetDateAdded.Name := 'DonorDatasetDateAdded';
    DonorDatasetDateAdded.FieldName := 'DateAdded';
    DonorDatasetLastUpdatedBy.SetParentComponent(DonorDataset);
    DonorDatasetLastUpdatedBy.Name := 'DonorDatasetLastUpdatedBy';
    DonorDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DonorDatasetLastUpdated.SetParentComponent(DonorDataset);
    DonorDatasetLastUpdated.Name := 'DonorDatasetLastUpdated';
    DonorDatasetLastUpdated.FieldName := 'LastUpdated';
    DonorDatasetFurtherContact.SetParentComponent(DonorDataset);
    DonorDatasetFurtherContact.Name := 'DonorDatasetFurtherContact';
    DonorDatasetFurtherContact.FieldName := 'FurtherContact';
    DonorDatasetComments.SetParentComponent(DonorDataset);
    DonorDatasetComments.Name := 'DonorDatasetComments';
    DonorDatasetComments.FieldName := 'Comments';
    DonorDatasetComments.Size := 255;
    DonorDatasetDateSigned.SetParentComponent(DonorDataset);
    DonorDatasetDateSigned.Name := 'DonorDatasetDateSigned';
    DonorDatasetDateSigned.FieldName := 'DateSigned';
    DonorDatasetContactByPhone.SetParentComponent(DonorDataset);
    DonorDatasetContactByPhone.Name := 'DonorDatasetContactByPhone';
    DonorDatasetContactByPhone.FieldName := 'ContactByPhone';
    DonorDatasetContactByPost.SetParentComponent(DonorDataset);
    DonorDatasetContactByPost.Name := 'DonorDatasetContactByPost';
    DonorDatasetContactByPost.FieldName := 'ContactByPost';
    DonorDatasetContactByText.SetParentComponent(DonorDataset);
    DonorDatasetContactByText.Name := 'DonorDatasetContactByText';
    DonorDatasetContactByText.FieldName := 'ContactByText';
    DonorDatasetContactByEmail.SetParentComponent(DonorDataset);
    DonorDatasetContactByEmail.Name := 'DonorDatasetContactByEmail';
    DonorDatasetContactByEmail.FieldName := 'ContactByEmail';
    DonorDatasetREExported.SetParentComponent(DonorDataset);
    DonorDatasetREExported.Name := 'DonorDatasetREExported';
    DonorDatasetREExported.FieldName := 'REExported';
    DonorDatasetREImported.SetParentComponent(DonorDataset);
    DonorDatasetREImported.Name := 'DonorDatasetREImported';
    DonorDatasetREImported.FieldName := 'REImported';
    DonorDatasetMatchHash.SetParentComponent(DonorDataset);
    DonorDatasetMatchHash.Name := 'DonorDatasetMatchHash';
    DonorDatasetMatchHash.FieldName := 'MatchHash';
    DonorDatasetMatchHash.Size := 64;
    DonorDatasetMasterId.SetParentComponent(DonorDataset);
    DonorDatasetMasterId.Name := 'DonorDatasetMasterId';
    DonorDatasetMasterId.FieldName := 'MasterId';
    DonorDatasetClaimStatusText.SetParentComponent(DonorDataset);
    DonorDatasetClaimStatusText.Name := 'DonorDatasetClaimStatusText';
    DonorDatasetClaimStatusText.FieldKind := fkCalculated;
    DonorDatasetClaimStatusText.FieldName := 'ClaimStatusText';
    DonorDatasetClaimStatusText.Size := 25;
    DonorDatasetClaimStatusText.Calculated := True;
    DonorDatasetFullName.SetParentComponent(DonorDataset);
    DonorDatasetFullName.Name := 'DonorDatasetFullName';
    DonorDatasetFullName.FieldKind := fkCalculated;
    DonorDatasetFullName.FieldName := 'FullName';
    DonorDatasetFullName.Size := 50;
    DonorDatasetFullName.Calculated := True;
    DonorDatasetFullAddress.SetParentComponent(DonorDataset);
    DonorDatasetFullAddress.Name := 'DonorDatasetFullAddress';
    DonorDatasetFullAddress.FieldKind := fkCalculated;
    DonorDatasetFullAddress.FieldName := 'FullAddress';
    DonorDatasetFullAddress.Size := 250;
    DonorDatasetFullAddress.Calculated := True;
  finally
    WebDBLabel1.AfterLoadDFMValues;
    RefundStatus.AfterLoadDFMValues;
    TotalSales.AfterLoadDFMValues;
    Commission.AfterLoadDFMValues;
    HandlingFee.AfterLoadDFMValues;
    RefundAmount.AfterLoadDFMValues;
    DownloadLetterLink.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SentButton.AfterLoadDFMValues;
    RefundDataset.AfterLoadDFMValues;
    RefundDatasetId.AfterLoadDFMValues;
    RefundDatasetRefundDate.AfterLoadDFMValues;
    RefundDatasetRefundStatus.AfterLoadDFMValues;
    RefundDatasetSales.AfterLoadDFMValues;
    RefundDatasetCommission.AfterLoadDFMValues;
    RefundDatasetFee.AfterLoadDFMValues;
    RefundDatasetRefundAmount.AfterLoadDFMValues;
    RefundDatasetDonorId.AfterLoadDFMValues;
    RefundDatasetAddedBy.AfterLoadDFMValues;
    RefundDatasetDateAdded.AfterLoadDFMValues;
    RefundDatasetLastUpdatedBy.AfterLoadDFMValues;
    RefundDatasetLastUpdated.AfterLoadDFMValues;
    RefundDatasetNotificationId.AfterLoadDFMValues;
    RefundDatasource.AfterLoadDFMValues;
    DonorDataset.AfterLoadDFMValues;
    DonorDatasetId.AfterLoadDFMValues;
    DonorDatasetConsId.AfterLoadDFMValues;
    DonorDatasetOldRef.AfterLoadDFMValues;
    DonorDatasetShopRef.AfterLoadDFMValues;
    DonorDatasetTitle.AfterLoadDFMValues;
    DonorDatasetFirstName.AfterLoadDFMValues;
    DonorDatasetLastName.AfterLoadDFMValues;
    DonorDatasetAdd1.AfterLoadDFMValues;
    DonorDatasetAdd2.AfterLoadDFMValues;
    DonorDatasetAdd3.AfterLoadDFMValues;
    DonorDatasetCity.AfterLoadDFMValues;
    DonorDatasetPostCode.AfterLoadDFMValues;
    DonorDatasetEmail.AfterLoadDFMValues;
    DonorDatasetMobile.AfterLoadDFMValues;
    DonorDatasetTelephone.AfterLoadDFMValues;
    DonorDatasetGiftAidScheme.AfterLoadDFMValues;
    DonorDatasetTaxReportRequested.AfterLoadDFMValues;
    DonorDatasetClaimStatus.AfterLoadDFMValues;
    DonorDatasetScannedDocsExist.AfterLoadDFMValues;
    DonorDatasetAddedBy.AfterLoadDFMValues;
    DonorDatasetDateAdded.AfterLoadDFMValues;
    DonorDatasetLastUpdatedBy.AfterLoadDFMValues;
    DonorDatasetLastUpdated.AfterLoadDFMValues;
    DonorDatasetFurtherContact.AfterLoadDFMValues;
    DonorDatasetComments.AfterLoadDFMValues;
    DonorDatasetDateSigned.AfterLoadDFMValues;
    DonorDatasetContactByPhone.AfterLoadDFMValues;
    DonorDatasetContactByPost.AfterLoadDFMValues;
    DonorDatasetContactByText.AfterLoadDFMValues;
    DonorDatasetContactByEmail.AfterLoadDFMValues;
    DonorDatasetREExported.AfterLoadDFMValues;
    DonorDatasetREImported.AfterLoadDFMValues;
    DonorDatasetMatchHash.AfterLoadDFMValues;
    DonorDatasetMasterId.AfterLoadDFMValues;
    DonorDatasetClaimStatusText.AfterLoadDFMValues;
    DonorDatasetFullName.AfterLoadDFMValues;
    DonorDatasetFullAddress.AfterLoadDFMValues;
  end;
end;

end.
