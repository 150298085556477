unit NewDonorsForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  WEBLib.WebCtrls,
  WEBLib.Actions,
  XData.Web.Client,
  WEBLib.Grids,
  BaseCoreForm,
  WEBLib.EditAutocomplete,
  WEBLib.Toast;

type
  TAddDonorsForm = class(TCoreWebForm)
    ShopsLookup: TXDataWebDataSet;
    ShopsLookupRef: TStringField;
    ShopsLookupRegionRef: TStringField;
    ShopsLookupName: TStringField;
    ShopsLookupStatus: TStringField;
    ShopsLookupLocationType: TStringField;
    DataSource: TDataSource;
    MainDataset: TXDataWebDataSet;
    MainDatasetId: TIntegerField;
    MainDatasetConsId: TIntegerField;
    MainDatasetOldRef: TStringField;
    MainDatasetShopRef: TStringField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetTelephone: TStringField;
    MainDatasetGiftAidScheme: TStringField;
    MainDatasetTaxReportRequested: TBooleanField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetScannedDocsExist: TBooleanField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetShopName: TStringField;
    MainDatasetFurtherContact: TBooleanField;
    MainDatasetComments: TStringField;
    MainDatasetDateSigned: TDateField;
    MainDatasetContactByPhone: TBooleanField;
    MainDatasetContactByPost: TBooleanField;
    MainDatasetContactByText: TBooleanField;
    MainDatasetContactByEmail: TBooleanField;
    SaveAndNew: TButton;
    CancelAndNew: TButton;
    Comments: TDBEdit;
    DateSigned: TDBDateTimePicker;
    ContactByPost: TDBCheckBox;
    ContactByText: TDBCheckBox;
    ContactByEmail: TDBCheckBox;
    ContactByPhone: TDBCheckBox;
    NotifyAllSales: TCheckBox;
    ClaimStatus: TDBLookupComboBox;
    edTelephone: TDBEdit;
    edMobile: TDBEdit;
    edEmail: TDBEdit;
    edPostCode: TDBEdit;
    edCity: TDBEdit;
    edAdd3: TDBEdit;
    edAdd2: TDBEdit;
    edAdd1: TDBEdit;
    edLastName: TDBEdit;
    edFirstName: TDBEdit;
    GiftAidScheme: TDBLabel;
    OldRefLabel: TDBLabel;
    DonorIdLabel: TDBLabel;
    NewDonorId: TEdit;
    DonorIdMessage: THTMLSpan;
    ShopRef: TDBLookupComboBox;
    EventList: TElementActionList;
    WebClient: TXDataWebClient;
    MessageSpan: THTMLSpan;
    SaveAndFinish: TButton;
    CancelAndFinish: TButton;
    MainDatasetFullName: TStringField;
    Dupes: TXDataWebDataSet;
    DupesMessage: THTMLSpan;
    DupesId: TIntegerField;
    DupesConsId: TIntegerField;
    DupesOldRef: TStringField;
    DupesShopRef: TStringField;
    DupesTitle: TStringField;
    DupesFirstName: TStringField;
    DupesLastName: TStringField;
    DupesAdd1: TStringField;
    DupesAdd2: TStringField;
    DupesAdd3: TStringField;
    DupesCity: TStringField;
    DupesPostCode: TStringField;
    DupesEmail: TStringField;
    DupesMobile: TStringField;
    DupesTelephone: TStringField;
    DupesGiftAidScheme: TStringField;
    DupesTaxReportRequested: TBooleanField;
    DupesClaimStatus: TStringField;
    DupesScannedDocsExist: TBooleanField;
    DupesAddedBy: TIntegerField;
    DupesDateAdded: TDateTimeField;
    DupesLastUpdatedBy: TIntegerField;
    DupesLastUpdated: TDateTimeField;
    DupesFurtherContact: TBooleanField;
    DupesComments: TStringField;
    DupesDateSigned: TDateField;
    DupesContactByPhone: TBooleanField;
    DupesContactByPost: TBooleanField;
    DupesContactByText: TBooleanField;
    DupesContactByEmail: TBooleanField;
    DupesREExported: TDateTimeField;
    DupesREImported: TDateTimeField;
    DupesMatchHash: TStringField;
    MessageDlg: TMessageDlg;
    MainDatasetREExported: TDateTimeField;
    MainDatasetREImported: TDateTimeField;
    MainDatasetMatchHash: TStringField;
    MainDatasetMasterId: TIntegerField;
    DupesMasterId: TIntegerField;
    DupesFullName: TStringField;
    DupesFullAddress: TStringField;
    Title: TDBComboBox;
    procedure CancelAndFinishClick(Sender: TObject);
    procedure CancelAndNewClick(Sender: TObject);
    procedure ClaimStatusChange(Sender: TObject);
    procedure ContactByEmailKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure ContactByPhoneKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure ContactByPostKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure ContactByTextKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure DataSourceStateChange(Sender: TObject);
    procedure DupesAfterOpen(Dataset: TDataSet);
    procedure DupesAfterPost(Dataset: TDataSet);
    procedure DupesCalcFields(Dataset: TDataSet);
    procedure DupesMessageClick(Sender: TObject);
    procedure edAdd1Exit(Sender: TObject);
    procedure edFirstNameExit(Sender: TObject);
    procedure EditButtonClick(Sender: TObject);
    procedure edLastNameExit(Sender: TObject);
    procedure EventListActions0Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure EventListActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterOpen(Dataset: TDataSet);
    procedure MainDatasetAfterPost(Dataset: TDataSet);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetNewRecord(Dataset: TDataSet);
    procedure NewButtonClick(Sender: TObject);
    procedure NewDonorIdKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure NotifyAllSalesClick(Sender: TObject);
    procedure NotifyAllSalesKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure SaveAndFinishClick(Sender: TObject);
    procedure SaveAndNewClick(Sender: TObject);
    procedure ShopRefChange(Sender: TObject);
    procedure ShopsLookupAfterOpen(Dataset: TDataSet);
    procedure TitleExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    FResetting: Boolean;
    FAddNewRecord: Boolean;
    FLastValidatedId: string;
    FMinDateSigned: TDate;
    FDonorIdValid: Boolean;
    FPostCodeValid: Boolean;
    FFirstNameValid: Boolean;
    FLastNameValid: Boolean;
    FAdd1Valid: Boolean;
    FCityValid: Boolean;
    FDateSignedValid: Boolean;
    FEmailValid: Boolean;
    FSaveOk: Boolean;
    FGiftAidOK: Boolean;
    FClaimStatusManual: Boolean;
    FClaimStatusAutoUpdate: Boolean;
    FMatchMethod: string;
    FShopRefByLookup: Boolean;
    [async]
    procedure Validate(const AElement: string); async;
    /// <summary>
    ///   <para>
    ///     Checks the Id Entered. The valid return status codes are
    ///   </para>
    ///   <para>
    ///     <i><b>NewIssue</b></i> - this would occur if a valid <i>Old Ref</i>
    ///      has been entered or the id <b>ISSUE</b> has been used
    ///   </para>
    ///   <para>
    ///     <i><b>Issued</b></i> - this is for <i>new ids</i> and confirms it
    ///     is in the Donor Manager and is unallocated
    ///   </para>
    /// </summary>
    [async]
    procedure ValidateId; async;
    procedure ValidateEmail;
    function ValidateInput(AInput: TDBEdit): Boolean;
    procedure ValidatePostCode;
    procedure ValidateForGiftAid;
    procedure ValidateDateSigned;
    procedure SaveThis(const ANew: Boolean);
    procedure CancelThis(const ANew: Boolean);
    procedure HandleEnter(const AElement: string; const shiftKey: Boolean);
    procedure SetSaveButtons;
    function AnyContactAllowed: Boolean;
    [async]
    procedure SaveThisNow; async;
    [async]
    procedure ShowDupes; async;
  protected
    function CanClose: Boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  AddDonorsForm: TAddDonorsForm;

implementation

uses
  System.DateUtils,
  System.Rtti,
  MainDataModule,
  Auth.Service,
  AppUtils,
  App.Helper,
  DupesForm;

{$R *.dfm}

function TAddDonorsForm.AnyContactAllowed: Boolean;
begin
  result := ContactByEmail.Checked or ContactByPhone.Checked or ContactByPost.Checked or ContactByText.Checked;
end;

procedure TAddDonorsForm.CancelAndFinishClick(Sender: TObject);
begin
  CancelThis(False);
end;

procedure TAddDonorsForm.CancelAndNewClick(Sender: TObject);
begin
  CancelThis(True);
end;

procedure TAddDonorsForm.CancelThis(const ANew: Boolean);
begin
  MainDataset.DisableControls;
  MainDataset.Cancel;
  if ANew then
  begin
    MainDataset.Append;
    MainDataset.EnableControls;
  end
  else
  begin
    MainDataset.Close;
    SelfClose;
  end;
end;

function TAddDonorsForm.CanClose: Boolean;
begin
  result := True;
end;

procedure TAddDonorsForm.SaveThisNow;
var
  lDateSigned: TDate;
  lTitle: string;
begin

  if FSaveOk then
  begin
    MainDataset.DisableControls;
    lTitle := Title.Text;
    lDateSigned := DateSigned.Date;
    MainDatasetTitle.Value := lTitle;
    MainDatasetGiftAidScheme.AsString := GiftAid_Scheme[NotifyAllSales.Checked];
    MainDatasetDateSigned.Value := lDateSigned;

    MainDatasetContactByPhone.Value := ContactByPhone.Checked;
    MainDatasetContactByPost.Value := ContactByPost.Checked;
    MainDatasetContactByText.Value := ContactByText.Checked;
    MainDatasetContactByEmail.Value := ContactByEmail.Checked;
    MainDatasetFurtherContact.Value := AnyContactAllowed;

    // lVal := MainDatasetFirstName.Value;
    // MainDatasetFirstName.Value := TSysHelper.CapitaliseFirstLetter(lVal);
    // lVal := MainDatasetLastName.Value;
    // MainDatasetLastName.Value := TSysHelper.CapitaliseFirstLetter(lVal);
    // lVal := MainDatasetAdd1.Value;
    // MainDatasetAdd1.Value := TSysHelper.CapitaliseWords(lVal);
    // lVal := MainDatasetAdd2.Value;
    // MainDatasetAdd2.Value := TSysHelper.CapitaliseWords(lVal);
    // lVal := MainDatasetAdd3.Value;
    // MainDatasetAdd3.Value := TSysHelper.CapitaliseWords(lVal);
    // lVal := MainDatasetCity.Value;
    // MainDatasetCity.Value := TSysHelper.CapitaliseWords(lVal);

    MainDataset.Post;
  end;
end;

procedure TAddDonorsForm.SetSaveButtons;
begin
  if FSaveOk then
  begin
    SaveAndNew.Enabled := True;
    THTMLHelper.removeClass(SaveAndNew.ElementID, 'btn-secondary');
    THTMLHelper.addClass(SaveAndNew.ElementID, 'btn-success');
    SaveAndFinish.Enabled := True;
    THTMLHelper.removeClass(SaveAndFinish.ElementID, 'btn-secondary');
    THTMLHelper.addClass(SaveAndFinish.ElementID, 'btn-success');
  end
  else
  begin
    SaveAndNew.Enabled := False;
    THTMLHelper.removeClass(SaveAndNew.ElementID, 'btn-success');
    THTMLHelper.addClass(SaveAndNew.ElementID, 'btn-secondary');
    SaveAndFinish.Enabled := False;
    THTMLHelper.removeClass(SaveAndFinish.ElementID, 'btn-success');
    THTMLHelper.addClass(SaveAndFinish.ElementID, 'btn-econdary');
  end;
end;

procedure TAddDonorsForm.ClaimStatusChange(Sender: TObject);
begin
  Comments.Visible := (ClaimStatus.Value = 'Other');
  if not FClaimStatusAutoUpdate then
  begin
    FClaimStatusManual := True;
    THTMLHelper.removeClass('ClaimStatus', 'is-invalid');
  end
  else
  begin
    ControlValidity('ClaimStatus', ClaimStatus.Value = 'OK');
  end;
end;

procedure TAddDonorsForm.ContactByEmailKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      edEmail.SetFocus
    else
      ContactByPost.SetFocus;
  end;
end;

procedure TAddDonorsForm.ContactByPhoneKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      ContactByPost.SetFocus
    else
      ContactByText.SetFocus;
  end;
end;

procedure TAddDonorsForm.ContactByPostKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      ContactByEmail.SetFocus
    else
      ContactByPhone.SetFocus;
  end;
end;

procedure TAddDonorsForm.ContactByTextKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      ContactByPhone.SetFocus
    else
      NotifyAllSales.SetFocus;
  end;
end;

procedure TAddDonorsForm.DataSourceStateChange(Sender: TObject);
begin
  SetSaveButtons;
  CancelAndNew.Enabled := (DataSource.State in dsEditModes);
  CancelAndFinish.Enabled := CancelAndNew.Enabled;
end;

procedure TAddDonorsForm.DupesAfterOpen(Dataset: TDataSet);
begin
  if Dupes.IsEmpty then
  begin
    Dupes.Close;
    Dupes.QueryString := '';
    DupesMessage.HTML.Text := '';
    DupesMessage.Cursor := crDefault;
    Exit;
  end;

  DupesMessage.Cursor := crHandPoint;
  if Dupes.RecordCount = 1 then
    DupesMessage.HTML.Text := format('There is already a donor that matches this %s. <u>Click for details</ul>.',
      [FMatchMethod])
  else
    DupesMessage.HTML.Text := format('There are %d donors that match this %s. <ul>Click for details</ul>.',
      [Dupes.RecordCount, FMatchMethod]);

end;

procedure TAddDonorsForm.DupesAfterPost(Dataset: TDataSet);
begin
  Dupes.ApplyUpdates;
end;

procedure TAddDonorsForm.DupesCalcFields(Dataset: TDataSet);
begin
  DupesFullName.Value := TSysHelper.FullName(Dupes);
  DupesFullAddress.Value := TSysHelper.FormattedAddress(Dupes);
end;

procedure TAddDonorsForm.DupesMessageClick(Sender: TObject);
begin
  if Dupes.Active and (not Dupes.IsEmpty) then
    ShowDupes;
end;

procedure TAddDonorsForm.edAdd1Exit(Sender: TObject);
begin
  (Sender as TEdit).Text := TSysHelper.CapitaliseWords((Sender as TEdit).Text);
end;

procedure TAddDonorsForm.edFirstNameExit(Sender: TObject);
begin
  edFirstName.Text := TSysHelper.CapitaliseWords(edFirstName.Text);
end;

procedure TAddDonorsForm.EditButtonClick(Sender: TObject);
begin
  MainDataset.Edit;
end;

procedure TAddDonorsForm.edLastNameExit(Sender: TObject);
begin
  edLastName.Text := TSysHelper.CapitaliseWords(edLastName.Text);
end;

procedure TAddDonorsForm.EventListActions0Execute(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  Validate(Element.Element.id);
end;

procedure TAddDonorsForm.EventListActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  if TJSKeyboardEvent(Event.JSEvent).Key = 'Enter' then
  begin
    HandleEnter(Element.Element.id, TJSKeyboardEvent(Event.JSEvent).shiftKey);
  end;
end;

procedure TAddDonorsForm.HandleEnter(const AElement: string; const shiftKey: Boolean);
begin
  if AElement = 'NewDonorId' then
  begin
    if shiftKey then
      // do nothing

    else
    begin
      if NewDonorId.Text = 'ISSUE' then
        ShopRef.SetFocus
      else
        Title.SetFocus;
    end;
  end
  else if AElement = 'Title' then
  begin
    if shiftKey then
      NewDonorId.SetFocus
    else
      edFirstName.SetFocus;
  end
  else if (AElement = 'FirstName') then
  begin
    if shiftKey then
      Title.SetFocus
    else
      edLastName.SetFocus;
  end
  else if (AElement = 'LastName') then
  begin
    if shiftKey then
      edFirstName.SetFocus
    else
      edAdd1.SetFocus;
  end
  else if (AElement = 'Add1') then
  begin
    if shiftKey then
      edLastName.SetFocus
    else
      edAdd2.SetFocus;
  end
  else if (AElement = 'Add2') then
  begin
    if shiftKey then
      edAdd1.SetFocus
    else
      edAdd3.SetFocus;
  end
  else if (AElement = 'Add3') then
  begin
    if shiftKey then
      edAdd2.SetFocus
    else
      edCity.SetFocus;
  end
  else if (AElement = 'City') then
  begin
    if shiftKey then
      edAdd3.SetFocus
    else
      edPostCode.SetFocus;
  end
  else if (AElement = 'PostCode') then
  begin
    if shiftKey then
      edCity.SetFocus
    else
      DateSigned.SetFocus;
  end
  else if (AElement = 'DateSigned') then
  begin
    if shiftKey then
      edPostCode.SetFocus
    else
      edTelephone.SetFocus;
  end
  else if (AElement = 'Telephone') then
  begin
    if shiftKey then
      DateSigned.SetFocus
    else
      edMobile.SetFocus;
  end
  else if (AElement = 'Mobile') then
  begin
    if shiftKey then
      edTelephone.SetFocus
    else
      edEmail.SetFocus;
  end
  else if (AElement = 'Email') then
  begin
    if shiftKey then
      edMobile.SetFocus
    else
      ContactByEmail.SetFocus;
  end;
end;

procedure TAddDonorsForm.MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  if FAddNewRecord then
  begin
    MainDataset.Append;
    ControlNoValidity(Title.ElementID);
    ControlNoValidity(ShopRef.ElementID);
    ControlNoValidity(ClaimStatus.ElementID);
    MainDataset.EnableControls;
  end
  else
    SelfClose;
end;

procedure TAddDonorsForm.MainDatasetAfterOpen(Dataset: TDataSet);
begin
  MainDataset.Append;
end;

procedure TAddDonorsForm.MainDatasetAfterPost(Dataset: TDataSet);
begin
  MainDataset.ApplyUpdates;
end;

procedure TAddDonorsForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetFullName.Value := Trim(MainDatasetFirstName.Value + ' ' + MainDatasetLastName.Value);
end;

procedure TAddDonorsForm.MainDatasetNewRecord(Dataset: TDataSet);
begin
  FResetting := True;
  try
    if Dupes.Active then
      Dupes.Close;
    Dupes.QueryString := '';
    DupesMessage.HTML.Text := '';
    FMatchMethod := '';
    FShopRefByLookup := False;
    NotifyAllSales.Checked := False;
    MainDatasetGiftAidScheme.AsString := GiftAid_Scheme[NotifyAllSales.Checked];

    MessageSpan.HTML.Text := '';
    MessageSpan.ElementClassName := 'text-secondary';
    FLastValidatedId := '';

    NewDonorId.Clear;
    DonorIdMessage.HTML.Text := '';
    DonorIdMessage.ElementClassName := 'text-secondary';
    NewDonorId.Text := '';

    FDonorIdValid := False;
    FPostCodeValid := False;
    FEmailValid := False;
    FFirstNameValid := False;
    FLastNameValid := False;
    FAdd1Valid := False;
    FCityValid := False;
    FDateSignedValid := False;

    FSaveOk := False;
    FGiftAidOK := False;

    FClaimStatusManual := False;
    FClaimStatusAutoUpdate := False;
    ShopRef.Value := '';

    ControlNoValidity('NewDonorId');
    ControlNoValidity('FirstName');
    ControlNoValidity('LastName');
    ControlNoValidity('Add1');
    ControlNoValidity('City');
    ControlNoValidity('PostCode');
    ControlNoValidity('ShopRef');
    ControlNoValidity('ClaimStatus');
    ControlNoValidity('DateSigned');

    SetSaveButtons;

    NewDonorId.SetFocus;
  finally
    FResetting := False;
  end;

end;

procedure TAddDonorsForm.NewButtonClick(Sender: TObject);
begin
  MainDataset.Append;
end;

procedure TAddDonorsForm.NewDonorIdKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
var
  lId: string;
begin
  if Key = key_enter then
  begin

    if (Sender is TCustomEdit) then
      lId := TCustomEdit(Sender).ElementID
    else if (Sender is TDBLookupComboBox) then
      lId := TDBLookupComboBox(Sender).ElementID
    else if (Sender is TCustomControl) then
      lId := TCustomControl(Sender).ElementID
    else if (Sender is TCheckBox) then
      lId := TCheckBox(Sender).ElementID;

    HandleEnter(lId, (ssShift in Shift));
  end;
end;

procedure TAddDonorsForm.NotifyAllSalesClick(Sender: TObject);
begin
  if not FResetting then
    MainDatasetGiftAidScheme.Value := GiftAid_Scheme[NotifyAllSales.Checked];
end;

procedure TAddDonorsForm.NotifyAllSalesKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      ContactByText.SetFocus
    else
    begin
      if SaveAndNew.Enabled then
        SaveAndNew.SetFocus
      else
        CancelAndNew.SetFocus;
    end;
  end;
end;

procedure TAddDonorsForm.SaveAndFinishClick(Sender: TObject);
begin
  SaveThis(False);
end;

procedure TAddDonorsForm.SaveAndNewClick(Sender: TObject);
begin
  SaveThis(True);
end;

procedure TAddDonorsForm.SaveThis(const ANew: Boolean);
begin
  FAddNewRecord := ANew;
  SaveThisNow;
end;

procedure TAddDonorsForm.ShopRefChange(Sender: TObject);
begin
  if not FResetting then
    ControlValidity('ShopRef', ShopRef.Value <> '');
end;

procedure TAddDonorsForm.ShopsLookupAfterOpen(Dataset: TDataSet);
begin
  while not ShopsLookup.eof do
  begin
    ShopRef.LookupValues.AddPair(ShopsLookupRef.Value, ShopsLookupName.Value);
    ShopsLookup.Next;
  end;
  MainDataset.Open;
end;

procedure TAddDonorsForm.ShowDupes;
var
  AForm: TDupesDisplayForm;
  lRetval: TModalResult;
begin
  AForm := TDupesDisplayForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TDupesDisplayForm, AForm.Load());
    AForm.SubCaptionText := 'Possible matches for ' + MainDatasetFullName.Value;
    AForm.MatchingElements := FMatchMethod;
    AForm.Dataset := Dupes;
    lRetval := await(TModalResult, AForm.Execute);
    if lRetval = mrYes then
    begin
      MainDatasetMasterId.Value := AForm.MatchId;
    end;
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TAddDonorsForm.TitleExit(Sender: TObject);
begin
  ControlValidOrNone('Title', Title.Text <> '');
end;

procedure TAddDonorsForm.Validate(const AElement: string);
begin
  if AElement = 'NewDonorId' then
  begin
    ValidateId;
    ValidateForGiftAid;
  end
  else if AElement = 'Email' then
  begin
    ValidateEmail;
    ValidateForGiftAid;
  end
  else if (AElement = 'FirstName') then
  begin
    FFirstNameValid := ValidateInput(edFirstName);
    ValidateForGiftAid;
  end
  else if (AElement = 'LastName') then
  begin
    FLastNameValid := ValidateInput(edLastName);
    ValidateForGiftAid;
  end
  else if (AElement = 'Add1') then
  begin
    FAdd1Valid := ValidateInput(edAdd1);
    ValidateForGiftAid;
  end
  else if (AElement = 'City') then
  begin
    FCityValid := ValidateInput(edCity);
    ValidateForGiftAid;
  end
  else if (AElement = 'PostCode') then
  begin
    ValidatePostCode;
    ValidateForGiftAid;
  end
  else if (AElement = 'DateSigned') then
  begin
    ValidateDateSigned;
    ValidateForGiftAid;
  end
end;

procedure TAddDonorsForm.ValidateDateSigned;
var
  lDate: TDate;
begin
  lDate := DateOf(DateSigned.Date);
  if lDate = 0 then
  begin
    ControlNoValidity('DateSigned');
  end
  else
  begin
    FDateSignedValid := (lDate > FMinDateSigned) and (lDate <= Today);
    ControlValidity('DateSigned', FDateSignedValid, True);
  end;
end;

procedure TAddDonorsForm.ValidateEmail;
begin
  if Trim(edEmail.Text) = '' then
  begin
    FEmailValid := True;
    ControlValid('Email');
    Exit;
  end;

  if not TSysHelper.IsEmailValid(edEmail.Text) then
  begin
    FEmailValid := False;
    ControlInvalid('Email');
    Exit;
  end
  else
  begin
    FEmailValid := True;
    ControlValid('Email');
  end;

  FMatchMethod := 'Email';

  if Dupes.Active then
    Dupes.Close;

  Dupes.QueryString := format('$filter=email eq ''%s''', [lowerCase(edEmail.Text)]);
  Dupes.Load;

end;

procedure TAddDonorsForm.ValidateForGiftAid;
begin
  if ShopRef.Value = '' then
  begin
    ControlInvalid(ShopRef.ElementID);
    FSaveOk := False;
  end
  else
  begin
    ControlValid(ShopRef.ElementID);
    FSaveOk := True;
  end;
  FSaveOk := FSaveOk and FDonorIdValid and FFirstNameValid and FLastNameValid and FPostCodeValid and FAdd1Valid and
    FCityValid;
  FGiftAidOK := FSaveOk and FDateSignedValid;

  if not FGiftAidOK then
  begin
    if not FClaimStatusManual then
    begin
      FClaimStatusAutoUpdate := True;
      ClaimStatus.Value := 'DonorDetailsIncomplete';
      ControlInvalid('ClaimStatus');
      FClaimStatusAutoUpdate := True;
    end;
  end
  else
  begin
    if not FClaimStatusManual then
    begin
      FClaimStatusAutoUpdate := True;
      ClaimStatus.Value := 'OK';
      ControlValid('ClaimStatus', True);
      FClaimStatusAutoUpdate := True;
    end;
  end;

  SetSaveButtons;
end;

procedure TAddDonorsForm.ValidateId;
var
  lDonorId, lStatus: string;
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
begin
  lDonorId := NewDonorId.Text;
  if lDonorId.Chars[0] = 'G' then
    lDonorId := lDonorId.Substring(1);

  if lDonorId = FLastValidatedId then
    Exit;

  lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(IDONORSVC_DONORID_AVAILABILITY, [lDonorId]));
  lResult := lRetval.ResultAsObject;

  lStatus := JS.toString(lResult['Status']);

  { Possible Status Codes: 'OK,
    Exists,
    NotFound,
    NewIssue, -> That's ok here
    Exception,
    Issued, -> That's ok here
    NotIssued,
    Allocated'; }

  if lStatus = 'Exception' then
  begin
    DonorIdMessage.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> ' + JS.toString(lResult['StatusMessage']);
    DonorIdMessage.ElementClassName := 'text-warning';
    FDonorIdValid := False;
    ControlInvalid('NewDonorId');
  end
  else if lStatus = 'NewIssue' then
  begin
    MainDataset.DisableControls;
    try
      MainDatasetId.Value := JS.toInteger(lResult['DonorId']);
      MainDatasetShopRef.Value := JS.toString(lResult['ShopRef']);
      FShopRefByLookup := True;
      MainDatasetOldRef.Value := JS.toString(lResult['OldRef']);
      DonorIdMessage.HTML.Text := '<i class="fad fa-check"></i> OK: ' + JS.toString(lResult['StatusMessage']);
      DonorIdMessage.ElementClassName := 'text-success';
      FDonorIdValid := True;
      ControlValid('NewDonorId', True);
    finally
      MainDataset.EnableControls;
    end;
  end
  else if lStatus = 'Issued' then
  begin
    MainDataset.DisableControls;
    try
      MainDatasetId.Value := JS.toInteger(lResult['DonorId']);
      MainDatasetShopRef.Value := JS.toString(lResult['ShopRef']);
      ControlValidity('ShopRef', MainDatasetShopRef.Value <> '');
      FShopRefByLookup := True;
      DonorIdMessage.HTML.Text := '<i class="fad fa-check"></i> OK: ' + JS.toString(lResult['StatusMessage']);
      DonorIdMessage.ElementClassName := 'text-success';
      FDonorIdValid := True;
      ControlValid('NewDonorId', True);
    finally
      MainDataset.EnableControls;
    end;
  end
  else
  begin
    DonorIdMessage.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> ' + JS.toString(lResult['StatusMessage']);
    DonorIdMessage.ElementClassName := 'text-warning';
    FDonorIdValid := False;
    ControlInvalid('NewDonorId');
  end;

end;

function TAddDonorsForm.ValidateInput(AInput: TDBEdit): Boolean;
begin
  result := not(Trim(AInput.Text) = '');
  if result then
    ControlValid(AInput.ElementID, True)
  else
    ControlInvalid(AInput.ElementID);
end;

procedure TAddDonorsForm.ValidatePostCode;
begin
  FPostCodeValid := False;
  if (Trim(edPostCode.Text) = '') then
  begin
    ControlInvalid('PostCode');
    Exit;
  end;

  edPostCode.Text := TSysHelper.FormatPostCode(edPostCode.Text);
  if TSysHelper.IsPostCode(edPostCode.Text) <> pcFull then
  begin
    ControlInvalid('PostCode');
  end
  else
  begin
    ControlValid('PostCode', True);
    FPostCodeValid := True;
  end;

end;

procedure TAddDonorsForm.WebFormCreate(Sender: TObject);
begin
  ClaimStatus.LookupValues.AddPair('OK', 'OK');
  ClaimStatus.LookupValues.AddPair('AwaitingPaperwork', 'Awaiting Paperwork');
  ClaimStatus.LookupValues.AddPair('Deceased', 'Deceased');
  ClaimStatus.LookupValues.AddPair('DonorDetailsIncomplete', 'Donor Details Incomplete');
  ClaimStatus.LookupValues.AddPair('NoLongerPaysTax', 'No Longer Pays Tax');
  ClaimStatus.LookupValues.AddPair('NoValidAddressGiven', 'No ValidAddress Given');
  ClaimStatus.LookupValues.AddPair('RequestedRefund', 'Requested Refund');
  ClaimStatus.LookupValues.AddPair('Other', 'Other - please add comment');

  FMinDateSigned := IncYear(Today, -1);
  Title.Items.CommaText := NAME_TITLES;
  ShopsLookup.Load;
end;

procedure TAddDonorsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GiftAidScheme := TDBLabel.Create('GiftAidScheme');
  OldRefLabel := TDBLabel.Create('OldRefLabel');
  DonorIdLabel := TDBLabel.Create('DonorIdLabel');
  SaveAndNew := TButton.Create('SaveAndNew');
  CancelAndNew := TButton.Create('CancelAndNew');
  Comments := TDBEdit.Create('Comments');
  DateSigned := TDBDateTimePicker.Create('DateSigned');
  ContactByPost := TDBCheckBox.Create('ContactByPost');
  ContactByText := TDBCheckBox.Create('ContactByText');
  ContactByEmail := TDBCheckBox.Create('ContactByEmail');
  ContactByPhone := TDBCheckBox.Create('ContactByPhone');
  NotifyAllSales := TCheckBox.Create('NotifyAllSales');
  ClaimStatus := TDBLookupComboBox.Create('ClaimStatus');
  edTelephone := TDBEdit.Create('Phone');
  edMobile := TDBEdit.Create('Mobile');
  edEmail := TDBEdit.Create('Email');
  edPostCode := TDBEdit.Create('PostCode');
  edCity := TDBEdit.Create('City');
  edAdd3 := TDBEdit.Create('Add3');
  edAdd2 := TDBEdit.Create('Add2');
  edAdd1 := TDBEdit.Create('Add1');
  edLastName := TDBEdit.Create('LastName');
  edFirstName := TDBEdit.Create('FirstName');
  NewDonorId := TEdit.Create('NewDonorId');
  DonorIdMessage := THTMLSpan.Create('DonorIdMessage');
  ShopRef := TDBLookupComboBox.Create('ShopRef');
  MessageSpan := THTMLSpan.Create('MessageSpan');
  SaveAndFinish := TButton.Create('SaveAndFinish');
  CancelAndFinish := TButton.Create('CancelAndFinish');
  DupesMessage := THTMLSpan.Create('DupesMessage');
  MessageDlg := TMessageDlg.Create(Self);
  Title := TDBComboBox.Create('Title');
  ShopsLookup := TXDataWebDataSet.Create(Self);
  ShopsLookupRef := TStringField.Create(Self);
  ShopsLookupRegionRef := TStringField.Create(Self);
  ShopsLookupName := TStringField.Create(Self);
  ShopsLookupStatus := TStringField.Create(Self);
  ShopsLookupLocationType := TStringField.Create(Self);
  DataSource := TDataSource.Create(Self);
  MainDataset := TXDataWebDataSet.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetConsId := TIntegerField.Create(Self);
  MainDatasetOldRef := TStringField.Create(Self);
  MainDatasetShopRef := TStringField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetTelephone := TStringField.Create(Self);
  MainDatasetGiftAidScheme := TStringField.Create(Self);
  MainDatasetTaxReportRequested := TBooleanField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetScannedDocsExist := TBooleanField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetShopName := TStringField.Create(Self);
  MainDatasetFurtherContact := TBooleanField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetDateSigned := TDateField.Create(Self);
  MainDatasetContactByPhone := TBooleanField.Create(Self);
  MainDatasetContactByPost := TBooleanField.Create(Self);
  MainDatasetContactByText := TBooleanField.Create(Self);
  MainDatasetContactByEmail := TBooleanField.Create(Self);
  MainDatasetFullName := TStringField.Create(Self);
  MainDatasetREExported := TDateTimeField.Create(Self);
  MainDatasetREImported := TDateTimeField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetMasterId := TIntegerField.Create(Self);
  EventList := TElementActionList.Create(Self);
  WebClient := TXDataWebClient.Create(Self);
  Dupes := TXDataWebDataSet.Create(Self);
  DupesId := TIntegerField.Create(Self);
  DupesConsId := TIntegerField.Create(Self);
  DupesOldRef := TStringField.Create(Self);
  DupesShopRef := TStringField.Create(Self);
  DupesTitle := TStringField.Create(Self);
  DupesFirstName := TStringField.Create(Self);
  DupesLastName := TStringField.Create(Self);
  DupesAdd1 := TStringField.Create(Self);
  DupesAdd2 := TStringField.Create(Self);
  DupesAdd3 := TStringField.Create(Self);
  DupesCity := TStringField.Create(Self);
  DupesPostCode := TStringField.Create(Self);
  DupesEmail := TStringField.Create(Self);
  DupesMobile := TStringField.Create(Self);
  DupesTelephone := TStringField.Create(Self);
  DupesGiftAidScheme := TStringField.Create(Self);
  DupesTaxReportRequested := TBooleanField.Create(Self);
  DupesClaimStatus := TStringField.Create(Self);
  DupesScannedDocsExist := TBooleanField.Create(Self);
  DupesAddedBy := TIntegerField.Create(Self);
  DupesDateAdded := TDateTimeField.Create(Self);
  DupesLastUpdatedBy := TIntegerField.Create(Self);
  DupesLastUpdated := TDateTimeField.Create(Self);
  DupesFurtherContact := TBooleanField.Create(Self);
  DupesComments := TStringField.Create(Self);
  DupesDateSigned := TDateField.Create(Self);
  DupesContactByPhone := TBooleanField.Create(Self);
  DupesContactByPost := TBooleanField.Create(Self);
  DupesContactByText := TBooleanField.Create(Self);
  DupesContactByEmail := TBooleanField.Create(Self);
  DupesREExported := TDateTimeField.Create(Self);
  DupesREImported := TDateTimeField.Create(Self);
  DupesMatchHash := TStringField.Create(Self);
  DupesMasterId := TIntegerField.Create(Self);
  DupesFullName := TStringField.Create(Self);
  DupesFullAddress := TStringField.Create(Self);

  GiftAidScheme.BeforeLoadDFMValues;
  OldRefLabel.BeforeLoadDFMValues;
  DonorIdLabel.BeforeLoadDFMValues;
  SaveAndNew.BeforeLoadDFMValues;
  CancelAndNew.BeforeLoadDFMValues;
  Comments.BeforeLoadDFMValues;
  DateSigned.BeforeLoadDFMValues;
  ContactByPost.BeforeLoadDFMValues;
  ContactByText.BeforeLoadDFMValues;
  ContactByEmail.BeforeLoadDFMValues;
  ContactByPhone.BeforeLoadDFMValues;
  NotifyAllSales.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  edTelephone.BeforeLoadDFMValues;
  edMobile.BeforeLoadDFMValues;
  edEmail.BeforeLoadDFMValues;
  edPostCode.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edAdd3.BeforeLoadDFMValues;
  edAdd2.BeforeLoadDFMValues;
  edAdd1.BeforeLoadDFMValues;
  edLastName.BeforeLoadDFMValues;
  edFirstName.BeforeLoadDFMValues;
  NewDonorId.BeforeLoadDFMValues;
  DonorIdMessage.BeforeLoadDFMValues;
  ShopRef.BeforeLoadDFMValues;
  MessageSpan.BeforeLoadDFMValues;
  SaveAndFinish.BeforeLoadDFMValues;
  CancelAndFinish.BeforeLoadDFMValues;
  DupesMessage.BeforeLoadDFMValues;
  MessageDlg.BeforeLoadDFMValues;
  Title.BeforeLoadDFMValues;
  ShopsLookup.BeforeLoadDFMValues;
  ShopsLookupRef.BeforeLoadDFMValues;
  ShopsLookupRegionRef.BeforeLoadDFMValues;
  ShopsLookupName.BeforeLoadDFMValues;
  ShopsLookupStatus.BeforeLoadDFMValues;
  ShopsLookupLocationType.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetConsId.BeforeLoadDFMValues;
  MainDatasetOldRef.BeforeLoadDFMValues;
  MainDatasetShopRef.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetTelephone.BeforeLoadDFMValues;
  MainDatasetGiftAidScheme.BeforeLoadDFMValues;
  MainDatasetTaxReportRequested.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetScannedDocsExist.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetShopName.BeforeLoadDFMValues;
  MainDatasetFurtherContact.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetDateSigned.BeforeLoadDFMValues;
  MainDatasetContactByPhone.BeforeLoadDFMValues;
  MainDatasetContactByPost.BeforeLoadDFMValues;
  MainDatasetContactByText.BeforeLoadDFMValues;
  MainDatasetContactByEmail.BeforeLoadDFMValues;
  MainDatasetFullName.BeforeLoadDFMValues;
  MainDatasetREExported.BeforeLoadDFMValues;
  MainDatasetREImported.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetMasterId.BeforeLoadDFMValues;
  EventList.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  Dupes.BeforeLoadDFMValues;
  DupesId.BeforeLoadDFMValues;
  DupesConsId.BeforeLoadDFMValues;
  DupesOldRef.BeforeLoadDFMValues;
  DupesShopRef.BeforeLoadDFMValues;
  DupesTitle.BeforeLoadDFMValues;
  DupesFirstName.BeforeLoadDFMValues;
  DupesLastName.BeforeLoadDFMValues;
  DupesAdd1.BeforeLoadDFMValues;
  DupesAdd2.BeforeLoadDFMValues;
  DupesAdd3.BeforeLoadDFMValues;
  DupesCity.BeforeLoadDFMValues;
  DupesPostCode.BeforeLoadDFMValues;
  DupesEmail.BeforeLoadDFMValues;
  DupesMobile.BeforeLoadDFMValues;
  DupesTelephone.BeforeLoadDFMValues;
  DupesGiftAidScheme.BeforeLoadDFMValues;
  DupesTaxReportRequested.BeforeLoadDFMValues;
  DupesClaimStatus.BeforeLoadDFMValues;
  DupesScannedDocsExist.BeforeLoadDFMValues;
  DupesAddedBy.BeforeLoadDFMValues;
  DupesDateAdded.BeforeLoadDFMValues;
  DupesLastUpdatedBy.BeforeLoadDFMValues;
  DupesLastUpdated.BeforeLoadDFMValues;
  DupesFurtherContact.BeforeLoadDFMValues;
  DupesComments.BeforeLoadDFMValues;
  DupesDateSigned.BeforeLoadDFMValues;
  DupesContactByPhone.BeforeLoadDFMValues;
  DupesContactByPost.BeforeLoadDFMValues;
  DupesContactByText.BeforeLoadDFMValues;
  DupesContactByEmail.BeforeLoadDFMValues;
  DupesREExported.BeforeLoadDFMValues;
  DupesREImported.BeforeLoadDFMValues;
  DupesMatchHash.BeforeLoadDFMValues;
  DupesMasterId.BeforeLoadDFMValues;
  DupesFullName.BeforeLoadDFMValues;
  DupesFullAddress.BeforeLoadDFMValues;
  try
    Width := 831;
    Height := 553;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    GiftAidScheme.SetParentComponent(Self);
    GiftAidScheme.Name := 'GiftAidScheme';
    GiftAidScheme.Left := 424;
    GiftAidScheme.Top := 408;
    GiftAidScheme.Width := 79;
    GiftAidScheme.Height := 15;
    GiftAidScheme.Caption := 'GiftAidScheme';
    GiftAidScheme.ElementFont := efCSS;
    GiftAidScheme.HeightStyle := ssAuto;
    GiftAidScheme.HeightPercent := 100.000000000000000000;
    GiftAidScheme.WidthPercent := 100.000000000000000000;
    GiftAidScheme.DataField := 'GiftAidScheme';
    GiftAidScheme.DataSource := DataSource;
    OldRefLabel.SetParentComponent(Self);
    OldRefLabel.Name := 'OldRefLabel';
    OldRefLabel.Left := 232;
    OldRefLabel.Top := 48;
    OldRefLabel.Width := 64;
    OldRefLabel.Height := 15;
    OldRefLabel.Caption := 'OldRefLabel';
    OldRefLabel.ElementFont := efCSS;
    OldRefLabel.ElementPosition := epIgnore;
    OldRefLabel.HeightStyle := ssAuto;
    OldRefLabel.HeightPercent := 100.000000000000000000;
    OldRefLabel.WidthPercent := 100.000000000000000000;
    OldRefLabel.DataField := 'OldRef';
    OldRefLabel.DataSource := DataSource;
    DonorIdLabel.SetParentComponent(Self);
    DonorIdLabel.Name := 'DonorIdLabel';
    DonorIdLabel.Left := 232;
    DonorIdLabel.Top := 27;
    DonorIdLabel.Width := 71;
    DonorIdLabel.Height := 15;
    DonorIdLabel.Caption := 'DonorIdLabel';
    DonorIdLabel.ElementFont := efCSS;
    DonorIdLabel.HeightStyle := ssAuto;
    DonorIdLabel.HeightPercent := 100.000000000000000000;
    DonorIdLabel.WidthPercent := 100.000000000000000000;
    DonorIdLabel.DataField := 'Id';
    DonorIdLabel.DataSource := DataSource;
    SaveAndNew.SetParentComponent(Self);
    SaveAndNew.Name := 'SaveAndNew';
    SaveAndNew.Left := 24;
    SaveAndNew.Top := 404;
    SaveAndNew.Width := 96;
    SaveAndNew.Height := 25;
    SaveAndNew.Caption := 'Save && New';
    SaveAndNew.ChildOrder := 2;
    SaveAndNew.ElementClassName := 'btn btn-success';
    SaveAndNew.ElementFont := efCSS;
    SaveAndNew.ElementPosition := epIgnore;
    SaveAndNew.HeightStyle := ssAuto;
    SaveAndNew.HeightPercent := 100.000000000000000000;
    SaveAndNew.TabOrder := 25;
    SaveAndNew.WidthStyle := ssAuto;
    SaveAndNew.WidthPercent := 100.000000000000000000;
    SetEvent(SaveAndNew, Self, 'OnClick', 'SaveAndNewClick');
    CancelAndNew.SetParentComponent(Self);
    CancelAndNew.Name := 'CancelAndNew';
    CancelAndNew.Left := 139;
    CancelAndNew.Top := 404;
    CancelAndNew.Width := 96;
    CancelAndNew.Height := 25;
    CancelAndNew.Caption := 'Cancel && New';
    CancelAndNew.ChildOrder := 3;
    CancelAndNew.ElementClassName := 'btn btn-secondary';
    CancelAndNew.ElementFont := efCSS;
    CancelAndNew.ElementPosition := epIgnore;
    CancelAndNew.HeightStyle := ssAuto;
    CancelAndNew.HeightPercent := 100.000000000000000000;
    CancelAndNew.TabOrder := 27;
    CancelAndNew.WidthStyle := ssAuto;
    CancelAndNew.WidthPercent := 100.000000000000000000;
    SetEvent(CancelAndNew, Self, 'OnClick', 'CancelAndNewClick');
    Comments.SetParentComponent(Self);
    Comments.Name := 'Comments';
    Comments.Left := 528;
    Comments.Top := 476;
    Comments.Width := 121;
    Comments.Height := 22;
    Comments.ChildOrder := 25;
    Comments.ElementClassName := 'form-control';
    Comments.ElementFont := efCSS;
    Comments.ElementPosition := epIgnore;
    Comments.HeightStyle := ssAuto;
    Comments.HeightPercent := 100.000000000000000000;
    Comments.TabOrder := 23;
    Comments.Text := 'Comments';
    Comments.Visible := False;
    Comments.WidthStyle := ssAuto;
    Comments.WidthPercent := 100.000000000000000000;
    Comments.DataField := 'Comments';
    Comments.DataSource := DataSource;
    DateSigned.SetParentComponent(Self);
    DateSigned.Name := 'DateSigned';
    DateSigned.Left := 253;
    DateSigned.Top := 225;
    DateSigned.Width := 129;
    DateSigned.Height := 22;
    DateSigned.ElementClassName := 'form-control';
    DateSigned.HeightStyle := ssAuto;
    DateSigned.WidthStyle := ssAuto;
    DateSigned.BorderStyle := bsSingle;
    DateSigned.ChildOrder := 26;
    DateSigned.Color := clWhite;
    DateSigned.Date := 44461.755385451390000000;
    DateSigned.ElementFont := efCSS;
    DateSigned.ElementPosition := epIgnore;
    DateSigned.Role := '';
    DateSigned.TabOrder := 10;
    DateSigned.TabStop := True;
    DateSigned.Text := '';
    SetEvent(DateSigned, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    DateSigned.DataField := 'DateSigned';
    DateSigned.DataSource := DataSource;
    ContactByPost.SetParentComponent(Self);
    ContactByPost.Name := 'ContactByPost';
    ContactByPost.Left := 166;
    ContactByPost.Top := 362;
    ContactByPost.Width := 113;
    ContactByPost.Height := 22;
    ContactByPost.ChildOrder := 27;
    ContactByPost.ElementClassName := 'form-check-input';
    ContactByPost.ElementFont := efCSS;
    ContactByPost.ElementPosition := epIgnore;
    ContactByPost.HeightStyle := ssAuto;
    ContactByPost.HeightPercent := 100.000000000000000000;
    ContactByPost.TabOrder := 15;
    ContactByPost.WidthStyle := ssAuto;
    ContactByPost.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByPost, Self, 'OnKeyDown', 'ContactByPostKeyDown');
    ContactByPost.DataField := 'ContactByPost';
    ContactByPost.DataSource := DataSource;
    ContactByText.SetParentComponent(Self);
    ContactByText.Name := 'ContactByText';
    ContactByText.Left := 397;
    ContactByText.Top := 360;
    ContactByText.Width := 113;
    ContactByText.Height := 22;
    ContactByText.ChildOrder := 27;
    ContactByText.ElementClassName := 'form-check-input';
    ContactByText.ElementFont := efCSS;
    ContactByText.ElementPosition := epIgnore;
    ContactByText.HeightStyle := ssAuto;
    ContactByText.HeightPercent := 100.000000000000000000;
    ContactByText.TabOrder := 17;
    ContactByText.WidthStyle := ssAuto;
    ContactByText.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByText, Self, 'OnKeyDown', 'ContactByTextKeyDown');
    ContactByText.DataField := 'ContactByText';
    ContactByText.DataSource := DataSource;
    ContactByEmail.SetParentComponent(Self);
    ContactByEmail.Name := 'ContactByEmail';
    ContactByEmail.Left := 32;
    ContactByEmail.Top := 368;
    ContactByEmail.Width := 113;
    ContactByEmail.Height := 22;
    ContactByEmail.ChildOrder := 27;
    ContactByEmail.ElementClassName := 'form-check-input';
    ContactByEmail.ElementFont := efCSS;
    ContactByEmail.ElementPosition := epIgnore;
    ContactByEmail.HeightStyle := ssAuto;
    ContactByEmail.HeightPercent := 100.000000000000000000;
    ContactByEmail.TabOrder := 14;
    ContactByEmail.WidthStyle := ssAuto;
    ContactByEmail.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByEmail, Self, 'OnKeyDown', 'ContactByEmailKeyDown');
    ContactByEmail.DataField := 'ContactByEmail';
    ContactByEmail.DataSource := DataSource;
    ContactByPhone.SetParentComponent(Self);
    ContactByPhone.Name := 'ContactByPhone';
    ContactByPhone.Left := 278;
    ContactByPhone.Top := 360;
    ContactByPhone.Width := 113;
    ContactByPhone.Height := 22;
    ContactByPhone.ChildOrder := 27;
    ContactByPhone.ElementClassName := 'form-check-input';
    ContactByPhone.ElementFont := efCSS;
    ContactByPhone.ElementPosition := epIgnore;
    ContactByPhone.HeightStyle := ssAuto;
    ContactByPhone.HeightPercent := 100.000000000000000000;
    ContactByPhone.TabOrder := 16;
    ContactByPhone.WidthStyle := ssAuto;
    ContactByPhone.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByPhone, Self, 'OnKeyDown', 'ContactByPhoneKeyDown');
    ContactByPhone.DataField := 'ContactByPhone';
    ContactByPhone.DataSource := DataSource;
    NotifyAllSales.SetParentComponent(Self);
    NotifyAllSales.Name := 'NotifyAllSales';
    NotifyAllSales.Left := 292;
    NotifyAllSales.Top := 409;
    NotifyAllSales.Width := 113;
    NotifyAllSales.Height := 22;
    NotifyAllSales.ChildOrder := 24;
    NotifyAllSales.ElementClassName := 'form-check-input';
    NotifyAllSales.ElementFont := efCSS;
    NotifyAllSales.ElementPosition := epIgnore;
    NotifyAllSales.HeightStyle := ssAuto;
    NotifyAllSales.HeightPercent := 100.000000000000000000;
    NotifyAllSales.TabOrder := 20;
    NotifyAllSales.WidthStyle := ssAuto;
    NotifyAllSales.WidthPercent := 100.000000000000000000;
    SetEvent(NotifyAllSales, Self, 'OnClick', 'NotifyAllSalesClick');
    SetEvent(NotifyAllSales, Self, 'OnKeyDown', 'NotifyAllSalesKeyDown');
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 528;
    ClaimStatus.Top := 448;
    ClaimStatus.Width := 197;
    ClaimStatus.Height := 22;
    ClaimStatus.ElementClassName := 'form-select form-control';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.TabOrder := 22;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    SetEvent(ClaimStatus, Self, 'OnChange', 'ClaimStatusChange');
    ClaimStatus.DataField := 'ClaimStatus';
    ClaimStatus.DataSource := DataSource;
    edTelephone.SetParentComponent(Self);
    edTelephone.Name := 'edTelephone';
    edTelephone.Left := 253;
    edTelephone.Top := 253;
    edTelephone.Width := 200;
    edTelephone.Height := 21;
    edTelephone.ChildOrder := 18;
    edTelephone.ElementClassName := 'form-control';
    edTelephone.ElementFont := efCSS;
    edTelephone.ElementPosition := epIgnore;
    edTelephone.HeightStyle := ssAuto;
    edTelephone.HeightPercent := 100.000000000000000000;
    edTelephone.TabOrder := 11;
    edTelephone.Text := 'edTelephone';
    edTelephone.WidthStyle := ssAuto;
    edTelephone.WidthPercent := 100.000000000000000000;
    SetEvent(edTelephone, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edTelephone.DataField := 'Telephone';
    edTelephone.DataSource := DataSource;
    edMobile.SetParentComponent(Self);
    edMobile.Name := 'edMobile';
    edMobile.Left := 253;
    edMobile.Top := 280;
    edMobile.Width := 200;
    edMobile.Height := 21;
    edMobile.ChildOrder := 17;
    edMobile.ElementClassName := 'form-control';
    edMobile.ElementFont := efCSS;
    edMobile.ElementPosition := epIgnore;
    edMobile.HeightStyle := ssAuto;
    edMobile.HeightPercent := 100.000000000000000000;
    edMobile.TabOrder := 12;
    edMobile.Text := 'edMobile';
    edMobile.WidthStyle := ssAuto;
    edMobile.WidthPercent := 100.000000000000000000;
    SetEvent(edMobile, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edMobile.DataField := 'Mobile';
    edMobile.DataSource := DataSource;
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 253;
    edEmail.Top := 307;
    edEmail.Width := 200;
    edEmail.Height := 21;
    edEmail.CharCase := wecLowerCase;
    edEmail.ChildOrder := 16;
    edEmail.ElementClassName := 'form-control';
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.TabOrder := 13;
    edEmail.Text := 'edEmail';
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    SetEvent(edEmail, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edEmail.DataField := 'Email';
    edEmail.DataSource := DataSource;
    edPostCode.SetParentComponent(Self);
    edPostCode.Name := 'edPostCode';
    edPostCode.Left := 253;
    edPostCode.Top := 198;
    edPostCode.Width := 200;
    edPostCode.Height := 21;
    edPostCode.CharCase := wecUpperCase;
    edPostCode.ChildOrder := 15;
    edPostCode.ElementClassName := 'form-control';
    edPostCode.ElementFont := efCSS;
    edPostCode.ElementPosition := epIgnore;
    edPostCode.HeightStyle := ssAuto;
    edPostCode.HeightPercent := 100.000000000000000000;
    edPostCode.TabOrder := 10;
    edPostCode.Text := 'edPostCode';
    edPostCode.WidthStyle := ssAuto;
    edPostCode.WidthPercent := 100.000000000000000000;
    SetEvent(edPostCode, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edPostCode.DataField := 'PostCode';
    edPostCode.DataSource := DataSource;
    edCity.SetParentComponent(Self);
    edCity.Name := 'edCity';
    edCity.Left := 253;
    edCity.Top := 171;
    edCity.Width := 200;
    edCity.Height := 21;
    edCity.ChildOrder := 14;
    edCity.ElementClassName := 'form-control';
    edCity.ElementFont := efCSS;
    edCity.ElementPosition := epIgnore;
    edCity.HeightStyle := ssAuto;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 8;
    edCity.Text := 'edCity';
    edCity.WidthStyle := ssAuto;
    edCity.WidthPercent := 100.000000000000000000;
    SetEvent(edCity, Self, 'OnExit', 'edAdd1Exit');
    SetEvent(edCity, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edCity.DataField := 'City';
    edCity.DataSource := DataSource;
    edAdd3.SetParentComponent(Self);
    edAdd3.Name := 'edAdd3';
    edAdd3.Left := 253;
    edAdd3.Top := 145;
    edAdd3.Width := 200;
    edAdd3.Height := 21;
    edAdd3.ChildOrder := 13;
    edAdd3.ElementClassName := 'form-control';
    edAdd3.ElementFont := efCSS;
    edAdd3.ElementPosition := epIgnore;
    edAdd3.HeightStyle := ssAuto;
    edAdd3.HeightPercent := 100.000000000000000000;
    edAdd3.TabOrder := 8;
    edAdd3.Text := 'edAdd3';
    edAdd3.WidthStyle := ssAuto;
    edAdd3.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd3, Self, 'OnExit', 'edAdd1Exit');
    SetEvent(edAdd3, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edAdd3.DataField := 'Add3';
    edAdd3.DataSource := DataSource;
    edAdd2.SetParentComponent(Self);
    edAdd2.Name := 'edAdd2';
    edAdd2.Left := 253;
    edAdd2.Top := 118;
    edAdd2.Width := 200;
    edAdd2.Height := 21;
    edAdd2.ChildOrder := 12;
    edAdd2.ElementClassName := 'form-control';
    edAdd2.ElementFont := efCSS;
    edAdd2.ElementPosition := epIgnore;
    edAdd2.HeightStyle := ssAuto;
    edAdd2.HeightPercent := 100.000000000000000000;
    edAdd2.TabOrder := 6;
    edAdd2.Text := 'edAdd2';
    edAdd2.WidthStyle := ssAuto;
    edAdd2.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd2, Self, 'OnExit', 'edAdd1Exit');
    SetEvent(edAdd2, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edAdd2.DataField := 'Add2';
    edAdd2.DataSource := DataSource;
    edAdd1.SetParentComponent(Self);
    edAdd1.Name := 'edAdd1';
    edAdd1.Left := 253;
    edAdd1.Top := 91;
    edAdd1.Width := 200;
    edAdd1.Height := 21;
    edAdd1.ChildOrder := 11;
    edAdd1.ElementClassName := 'form-control';
    edAdd1.ElementFont := efCSS;
    edAdd1.ElementPosition := epIgnore;
    edAdd1.HeightStyle := ssAuto;
    edAdd1.HeightPercent := 100.000000000000000000;
    edAdd1.TabOrder := 6;
    edAdd1.Text := 'edAdd1';
    edAdd1.WidthStyle := ssAuto;
    edAdd1.WidthPercent := 100.000000000000000000;
    SetEvent(edAdd1, Self, 'OnExit', 'edAdd1Exit');
    SetEvent(edAdd1, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edAdd1.DataField := 'Add1';
    edAdd1.DataSource := DataSource;
    edLastName.SetParentComponent(Self);
    edLastName.Name := 'edLastName';
    edLastName.Left := 24;
    edLastName.Top := 188;
    edLastName.Width := 200;
    edLastName.Height := 21;
    edLastName.ChildOrder := 10;
    edLastName.ElementClassName := 'form-control';
    edLastName.ElementFont := efCSS;
    edLastName.ElementPosition := epIgnore;
    edLastName.HeightStyle := ssAuto;
    edLastName.HeightPercent := 100.000000000000000000;
    edLastName.TabOrder := 5;
    edLastName.Text := 'edLastName';
    edLastName.WidthStyle := ssAuto;
    edLastName.WidthPercent := 100.000000000000000000;
    SetEvent(edLastName, Self, 'OnExit', 'edLastNameExit');
    SetEvent(edLastName, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edLastName.DataField := 'LastName';
    edLastName.DataSource := DataSource;
    edFirstName.SetParentComponent(Self);
    edFirstName.Name := 'edFirstName';
    edFirstName.Left := 24;
    edFirstName.Top := 161;
    edFirstName.Width := 200;
    edFirstName.Height := 21;
    edFirstName.ChildOrder := 9;
    edFirstName.ElementClassName := 'form-control';
    edFirstName.ElementFont := efCSS;
    edFirstName.ElementPosition := epIgnore;
    edFirstName.HeightStyle := ssAuto;
    edFirstName.HeightPercent := 100.000000000000000000;
    edFirstName.TabOrder := 4;
    edFirstName.Text := 'edFirstName';
    edFirstName.WidthStyle := ssAuto;
    edFirstName.WidthPercent := 100.000000000000000000;
    SetEvent(edFirstName, Self, 'OnExit', 'edFirstNameExit');
    SetEvent(edFirstName, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    edFirstName.DataField := 'FirstName';
    edFirstName.DataSource := DataSource;
    NewDonorId.SetParentComponent(Self);
    NewDonorId.Name := 'NewDonorId';
    NewDonorId.Left := 32;
    NewDonorId.Top := 106;
    NewDonorId.Width := 153;
    NewDonorId.Height := 22;
    NewDonorId.CharCase := wecUpperCase;
    NewDonorId.ChildOrder := 30;
    NewDonorId.ElementFont := efCSS;
    NewDonorId.ElementPosition := epIgnore;
    NewDonorId.HeightStyle := ssAuto;
    NewDonorId.HeightPercent := 100.000000000000000000;
    NewDonorId.TextHint := '1234567/S681234';
    NewDonorId.WidthStyle := ssAuto;
    NewDonorId.WidthPercent := 100.000000000000000000;
    SetEvent(NewDonorId, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    DonorIdMessage.SetParentComponent(Self);
    DonorIdMessage.Name := 'DonorIdMessage';
    DonorIdMessage.Left := 600;
    DonorIdMessage.Top := 104;
    DonorIdMessage.Width := 100;
    DonorIdMessage.Height := 41;
    DonorIdMessage.HeightStyle := ssAuto;
    DonorIdMessage.WidthStyle := ssAuto;
    DonorIdMessage.ChildOrder := 31;
    DonorIdMessage.ElementPosition := epIgnore;
    DonorIdMessage.ElementFont := efCSS;
    DonorIdMessage.Role := '';
    ShopRef.SetParentComponent(Self);
    ShopRef.Name := 'ShopRef';
    ShopRef.Left := 525;
    ShopRef.Top := 171;
    ShopRef.Width := 200;
    ShopRef.Height := 22;
    ShopRef.ElementClassName := 'form-select';
    ShopRef.ElementFont := efCSS;
    ShopRef.ElementPosition := epIgnore;
    ShopRef.HeightStyle := ssAuto;
    ShopRef.HeightPercent := 100.000000000000000000;
    ShopRef.TabOrder := 2;
    ShopRef.WidthStyle := ssAuto;
    ShopRef.WidthPercent := 100.000000000000000000;
    SetEvent(ShopRef, Self, 'OnChange', 'ShopRefChange');
    SetEvent(ShopRef, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    ShopRef.DataField := 'ShopRef';
    ShopRef.DataSource := DataSource;
    ShopRef.ListField := 'Name';
    MessageSpan.SetParentComponent(Self);
    MessageSpan.Name := 'MessageSpan';
    MessageSpan.Left := 48;
    MessageSpan.Top := 8;
    MessageSpan.Width := 100;
    MessageSpan.Height := 41;
    MessageSpan.HeightStyle := ssAuto;
    MessageSpan.WidthStyle := ssAuto;
    MessageSpan.ChildOrder := 33;
    MessageSpan.ElementPosition := epIgnore;
    MessageSpan.ElementFont := efCSS;
    MessageSpan.Role := '';
    SaveAndFinish.SetParentComponent(Self);
    SaveAndFinish.Name := 'SaveAndFinish';
    SaveAndFinish.Left := 24;
    SaveAndFinish.Top := 435;
    SaveAndFinish.Width := 96;
    SaveAndFinish.Height := 25;
    SaveAndFinish.Caption := 'Save && Finish';
    SaveAndFinish.ChildOrder := 2;
    SaveAndFinish.ElementClassName := 'btn btn-success';
    SaveAndFinish.ElementFont := efCSS;
    SaveAndFinish.ElementPosition := epIgnore;
    SaveAndFinish.HeightStyle := ssAuto;
    SaveAndFinish.HeightPercent := 100.000000000000000000;
    SaveAndFinish.TabOrder := 26;
    SaveAndFinish.WidthStyle := ssAuto;
    SaveAndFinish.WidthPercent := 100.000000000000000000;
    SetEvent(SaveAndFinish, Self, 'OnClick', 'SaveAndFinishClick');
    CancelAndFinish.SetParentComponent(Self);
    CancelAndFinish.Name := 'CancelAndFinish';
    CancelAndFinish.Left := 139;
    CancelAndFinish.Top := 435;
    CancelAndFinish.Width := 96;
    CancelAndFinish.Height := 25;
    CancelAndFinish.Caption := 'Cancel && Finish';
    CancelAndFinish.ChildOrder := 3;
    CancelAndFinish.ElementClassName := 'btn btn-secondary';
    CancelAndFinish.ElementFont := efCSS;
    CancelAndFinish.ElementPosition := epIgnore;
    CancelAndFinish.HeightStyle := ssAuto;
    CancelAndFinish.HeightPercent := 100.000000000000000000;
    CancelAndFinish.TabOrder := 28;
    CancelAndFinish.WidthStyle := ssAuto;
    CancelAndFinish.WidthPercent := 100.000000000000000000;
    SetEvent(CancelAndFinish, Self, 'OnClick', 'CancelAndFinishClick');
    DupesMessage.SetParentComponent(Self);
    DupesMessage.Name := 'DupesMessage';
    DupesMessage.Left := 648;
    DupesMessage.Top := 224;
    DupesMessage.Width := 100;
    DupesMessage.Height := 41;
    DupesMessage.HeightStyle := ssAuto;
    DupesMessage.WidthStyle := ssAuto;
    DupesMessage.ChildOrder := 37;
    DupesMessage.ElementPosition := epIgnore;
    DupesMessage.ElementFont := efCSS;
    DupesMessage.Role := '';
    SetEvent(DupesMessage, Self, 'OnClick', 'DupesMessageClick');
    MessageDlg.SetParentComponent(Self);
    MessageDlg.Name := 'MessageDlg';
    MessageDlg.Left := 760;
    MessageDlg.Top := 91;
    MessageDlg.Width := 24;
    MessageDlg.Height := 24;
    MessageDlg.HeightStyle := ssAuto;
    MessageDlg.WidthStyle := ssAuto;
    MessageDlg.Buttons := [];
    MessageDlg.Opacity := 0.200000000000000000;
    Title.SetParentComponent(Self);
    Title.Name := 'Title';
    Title.Left := 24;
    Title.Top := 136;
    Title.Width := 145;
    Title.Height := 23;
    Title.ElementClassName := 'form-select';
    Title.ElementFont := efCSS;
    Title.ElementPosition := epIgnore;
    Title.HeightStyle := ssAuto;
    Title.HeightPercent := 100.000000000000000000;
    Title.TabOrder := 3;
    Title.Text := 'Title';
    Title.WidthStyle := ssAuto;
    Title.WidthPercent := 100.000000000000000000;
    SetEvent(Title, Self, 'OnExit', 'TitleExit');
    SetEvent(Title, Self, 'OnKeyDown', 'NewDonorIdKeyDown');
    Title.ItemIndex := -1;
    Title.DataField := 'Title';
    Title.DataSource := DataSource;
    ShopsLookup.SetParentComponent(Self);
    ShopsLookup.Name := 'ShopsLookup';
    ShopsLookup.AfterOpen := ShopsLookupAfterOpen;
    ShopsLookup.EntitySetName := 'Shop';
    ShopsLookup.Connection := MainData.DataConnection;
    ShopsLookup.QueryString := '$orderby=Name';
    ShopsLookup.Left := 544;
    ShopsLookup.Top := 24;
    ShopsLookupRef.SetParentComponent(ShopsLookup);
    ShopsLookupRef.Name := 'ShopsLookupRef';
    ShopsLookupRef.FieldName := 'Ref';
    ShopsLookupRef.Size := 5;
    ShopsLookupRegionRef.SetParentComponent(ShopsLookup);
    ShopsLookupRegionRef.Name := 'ShopsLookupRegionRef';
    ShopsLookupRegionRef.FieldName := 'RegionRef';
    ShopsLookupRegionRef.Size := 5;
    ShopsLookupName.SetParentComponent(ShopsLookup);
    ShopsLookupName.Name := 'ShopsLookupName';
    ShopsLookupName.FieldName := 'Name';
    ShopsLookupName.Size := 250;
    ShopsLookupStatus.SetParentComponent(ShopsLookup);
    ShopsLookupStatus.Name := 'ShopsLookupStatus';
    ShopsLookupStatus.FieldName := 'Status';
    ShopsLookupStatus.Size := 6;
    ShopsLookupLocationType.SetParentComponent(ShopsLookup);
    ShopsLookupLocationType.Name := 'ShopsLookupLocationType';
    ShopsLookupLocationType.FieldName := 'LocationType';
    ShopsLookupLocationType.Size := 10;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.AutoEdit := False;
    DataSource.DataSet := MainDataset;
    SetEvent(DataSource, Self, 'OnStateChange', 'DataSourceStateChange');
    DataSource.Left := 472;
    DataSource.Top := 24;
    MainDataset.SetParentComponent(Self);
    MainDataset.Name := 'MainDataset';
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    MainDataset.AfterOpen := MainDatasetAfterOpen;
    MainDataset.AfterPost := MainDatasetAfterPost;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    SetEvent(MainDataset, Self, 'OnNewRecord', 'MainDatasetNewRecord');
    MainDataset.EntitySetName := 'Donor';
    MainDataset.Connection := MainData.DataConnection;
    MainDataset.Left := 416;
    MainDataset.Top := 24;
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetConsId.SetParentComponent(MainDataset);
    MainDatasetConsId.Name := 'MainDatasetConsId';
    MainDatasetConsId.FieldName := 'ConsId';
    MainDatasetOldRef.SetParentComponent(MainDataset);
    MainDatasetOldRef.Name := 'MainDatasetOldRef';
    MainDatasetOldRef.FieldName := 'OldRef';
    MainDatasetOldRef.Size := 10;
    MainDatasetShopRef.SetParentComponent(MainDataset);
    MainDatasetShopRef.Name := 'MainDatasetShopRef';
    MainDatasetShopRef.FieldName := 'ShopRef';
    MainDatasetShopRef.Size := 10;
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 75;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 75;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 75;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 75;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 75;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetTelephone.SetParentComponent(MainDataset);
    MainDatasetTelephone.Name := 'MainDatasetTelephone';
    MainDatasetTelephone.FieldName := 'Telephone';
    MainDatasetTelephone.Size := 25;
    MainDatasetGiftAidScheme.SetParentComponent(MainDataset);
    MainDatasetGiftAidScheme.Name := 'MainDatasetGiftAidScheme';
    MainDatasetGiftAidScheme.FieldName := 'GiftAidScheme';
    MainDatasetGiftAidScheme.Size := 8;
    MainDatasetTaxReportRequested.SetParentComponent(MainDataset);
    MainDatasetTaxReportRequested.Name := 'MainDatasetTaxReportRequested';
    MainDatasetTaxReportRequested.FieldName := 'TaxReportRequested';
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Size := 22;
    MainDatasetScannedDocsExist.SetParentComponent(MainDataset);
    MainDatasetScannedDocsExist.Name := 'MainDatasetScannedDocsExist';
    MainDatasetScannedDocsExist.FieldName := 'ScannedDocsExist';
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetShopName.SetParentComponent(MainDataset);
    MainDatasetShopName.Name := 'MainDatasetShopName';
    MainDatasetShopName.FieldKind := fkLookup;
    MainDatasetShopName.FieldName := 'ShopName';
    MainDatasetShopName.LookupDataSet := ShopsLookup;
    MainDatasetShopName.LookupKeyFields := 'Ref';
    MainDatasetShopName.LookupResultField := 'Name';
    MainDatasetShopName.KeyFields := 'ShopRef';
    MainDatasetShopName.Size := 50;
    MainDatasetShopName.Lookup := True;
    MainDatasetFurtherContact.SetParentComponent(MainDataset);
    MainDatasetFurtherContact.Name := 'MainDatasetFurtherContact';
    MainDatasetFurtherContact.FieldName := 'FurtherContact';
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 255;
    MainDatasetDateSigned.SetParentComponent(MainDataset);
    MainDatasetDateSigned.Name := 'MainDatasetDateSigned';
    MainDatasetDateSigned.FieldName := 'DateSigned';
    MainDatasetContactByPhone.SetParentComponent(MainDataset);
    MainDatasetContactByPhone.Name := 'MainDatasetContactByPhone';
    MainDatasetContactByPhone.FieldName := 'ContactByPhone';
    MainDatasetContactByPost.SetParentComponent(MainDataset);
    MainDatasetContactByPost.Name := 'MainDatasetContactByPost';
    MainDatasetContactByPost.FieldName := 'ContactByPost';
    MainDatasetContactByText.SetParentComponent(MainDataset);
    MainDatasetContactByText.Name := 'MainDatasetContactByText';
    MainDatasetContactByText.FieldName := 'ContactByText';
    MainDatasetContactByEmail.SetParentComponent(MainDataset);
    MainDatasetContactByEmail.Name := 'MainDatasetContactByEmail';
    MainDatasetContactByEmail.FieldName := 'ContactByEmail';
    MainDatasetFullName.SetParentComponent(MainDataset);
    MainDatasetFullName.Name := 'MainDatasetFullName';
    MainDatasetFullName.FieldKind := fkCalculated;
    MainDatasetFullName.FieldName := 'FullName';
    MainDatasetFullName.Size := 75;
    MainDatasetFullName.Calculated := True;
    MainDatasetREExported.SetParentComponent(MainDataset);
    MainDatasetREExported.Name := 'MainDatasetREExported';
    MainDatasetREExported.FieldName := 'REExported';
    MainDatasetREImported.SetParentComponent(MainDataset);
    MainDatasetREImported.Name := 'MainDatasetREImported';
    MainDatasetREImported.FieldName := 'REImported';
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetMasterId.SetParentComponent(MainDataset);
    MainDatasetMasterId.Name := 'MainDatasetMasterId';
    MainDatasetMasterId.FieldName := 'MasterId';
    EventList.SetParentComponent(Self);
    EventList.Name := 'EventList';
    EventList.Actions.Clear;
    with EventList.Actions.Add do
    begin
      Event := heBlur;
      ID := '';
      Name := 'Validate';
      PreventDefault := False;
      Selector := 'input, select';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'EventListActions0Execute');
    end;
    with EventList.Actions.Add do
    begin
      Event := heKeydown;
      ID := '';
      Name := 'EnterKeyTrap';
      SetEvent(Self, 'OnExecute', 'EventListActions1Execute');
    end;
    EventList.Left := 656;
    EventList.Top := 24;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := MainData.DataConnection;
    WebClient.Left := 712;
    WebClient.Top := 24;
    Dupes.SetParentComponent(Self);
    Dupes.Name := 'Dupes';
    Dupes.AfterOpen := DupesAfterOpen;
    Dupes.AfterPost := DupesAfterPost;
    SetEvent(Dupes, Self, 'OnCalcFields', 'DupesCalcFields');
    Dupes.EntitySetName := 'Donor';
    Dupes.Connection := MainData.DataConnection;
    Dupes.Left := 768;
    Dupes.Top := 32;
    DupesId.SetParentComponent(Dupes);
    DupesId.Name := 'DupesId';
    DupesId.FieldName := 'Id';
    DupesConsId.SetParentComponent(Dupes);
    DupesConsId.Name := 'DupesConsId';
    DupesConsId.FieldName := 'ConsId';
    DupesOldRef.SetParentComponent(Dupes);
    DupesOldRef.Name := 'DupesOldRef';
    DupesOldRef.FieldName := 'OldRef';
    DupesOldRef.Size := 10;
    DupesShopRef.SetParentComponent(Dupes);
    DupesShopRef.Name := 'DupesShopRef';
    DupesShopRef.FieldName := 'ShopRef';
    DupesShopRef.Size := 10;
    DupesTitle.SetParentComponent(Dupes);
    DupesTitle.Name := 'DupesTitle';
    DupesTitle.FieldName := 'Title';
    DupesTitle.Size := 25;
    DupesFirstName.SetParentComponent(Dupes);
    DupesFirstName.Name := 'DupesFirstName';
    DupesFirstName.FieldName := 'FirstName';
    DupesFirstName.Size := 50;
    DupesLastName.SetParentComponent(Dupes);
    DupesLastName.Name := 'DupesLastName';
    DupesLastName.FieldName := 'LastName';
    DupesLastName.Size := 50;
    DupesAdd1.SetParentComponent(Dupes);
    DupesAdd1.Name := 'DupesAdd1';
    DupesAdd1.FieldName := 'Add1';
    DupesAdd1.Size := 75;
    DupesAdd2.SetParentComponent(Dupes);
    DupesAdd2.Name := 'DupesAdd2';
    DupesAdd2.FieldName := 'Add2';
    DupesAdd2.Size := 75;
    DupesAdd3.SetParentComponent(Dupes);
    DupesAdd3.Name := 'DupesAdd3';
    DupesAdd3.FieldName := 'Add3';
    DupesAdd3.Size := 75;
    DupesCity.SetParentComponent(Dupes);
    DupesCity.Name := 'DupesCity';
    DupesCity.FieldName := 'City';
    DupesCity.Size := 75;
    DupesPostCode.SetParentComponent(Dupes);
    DupesPostCode.Name := 'DupesPostCode';
    DupesPostCode.FieldName := 'PostCode';
    DupesPostCode.Size := 15;
    DupesEmail.SetParentComponent(Dupes);
    DupesEmail.Name := 'DupesEmail';
    DupesEmail.FieldName := 'Email';
    DupesEmail.Size := 75;
    DupesMobile.SetParentComponent(Dupes);
    DupesMobile.Name := 'DupesMobile';
    DupesMobile.FieldName := 'Mobile';
    DupesMobile.Size := 25;
    DupesTelephone.SetParentComponent(Dupes);
    DupesTelephone.Name := 'DupesTelephone';
    DupesTelephone.FieldName := 'Telephone';
    DupesTelephone.Size := 25;
    DupesGiftAidScheme.SetParentComponent(Dupes);
    DupesGiftAidScheme.Name := 'DupesGiftAidScheme';
    DupesGiftAidScheme.FieldName := 'GiftAidScheme';
    DupesGiftAidScheme.Size := 8;
    DupesTaxReportRequested.SetParentComponent(Dupes);
    DupesTaxReportRequested.Name := 'DupesTaxReportRequested';
    DupesTaxReportRequested.FieldName := 'TaxReportRequested';
    DupesClaimStatus.SetParentComponent(Dupes);
    DupesClaimStatus.Name := 'DupesClaimStatus';
    DupesClaimStatus.FieldName := 'ClaimStatus';
    DupesClaimStatus.Size := 22;
    DupesScannedDocsExist.SetParentComponent(Dupes);
    DupesScannedDocsExist.Name := 'DupesScannedDocsExist';
    DupesScannedDocsExist.FieldName := 'ScannedDocsExist';
    DupesAddedBy.SetParentComponent(Dupes);
    DupesAddedBy.Name := 'DupesAddedBy';
    DupesAddedBy.FieldName := 'AddedBy';
    DupesDateAdded.SetParentComponent(Dupes);
    DupesDateAdded.Name := 'DupesDateAdded';
    DupesDateAdded.FieldName := 'DateAdded';
    DupesLastUpdatedBy.SetParentComponent(Dupes);
    DupesLastUpdatedBy.Name := 'DupesLastUpdatedBy';
    DupesLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DupesLastUpdated.SetParentComponent(Dupes);
    DupesLastUpdated.Name := 'DupesLastUpdated';
    DupesLastUpdated.FieldName := 'LastUpdated';
    DupesFurtherContact.SetParentComponent(Dupes);
    DupesFurtherContact.Name := 'DupesFurtherContact';
    DupesFurtherContact.FieldName := 'FurtherContact';
    DupesComments.SetParentComponent(Dupes);
    DupesComments.Name := 'DupesComments';
    DupesComments.FieldName := 'Comments';
    DupesComments.Size := 255;
    DupesDateSigned.SetParentComponent(Dupes);
    DupesDateSigned.Name := 'DupesDateSigned';
    DupesDateSigned.FieldName := 'DateSigned';
    DupesContactByPhone.SetParentComponent(Dupes);
    DupesContactByPhone.Name := 'DupesContactByPhone';
    DupesContactByPhone.FieldName := 'ContactByPhone';
    DupesContactByPost.SetParentComponent(Dupes);
    DupesContactByPost.Name := 'DupesContactByPost';
    DupesContactByPost.FieldName := 'ContactByPost';
    DupesContactByText.SetParentComponent(Dupes);
    DupesContactByText.Name := 'DupesContactByText';
    DupesContactByText.FieldName := 'ContactByText';
    DupesContactByEmail.SetParentComponent(Dupes);
    DupesContactByEmail.Name := 'DupesContactByEmail';
    DupesContactByEmail.FieldName := 'ContactByEmail';
    DupesREExported.SetParentComponent(Dupes);
    DupesREExported.Name := 'DupesREExported';
    DupesREExported.FieldName := 'REExported';
    DupesREImported.SetParentComponent(Dupes);
    DupesREImported.Name := 'DupesREImported';
    DupesREImported.FieldName := 'REImported';
    DupesMatchHash.SetParentComponent(Dupes);
    DupesMatchHash.Name := 'DupesMatchHash';
    DupesMatchHash.FieldName := 'MatchHash';
    DupesMatchHash.Size := 64;
    DupesMasterId.SetParentComponent(Dupes);
    DupesMasterId.Name := 'DupesMasterId';
    DupesMasterId.FieldName := 'MasterId';
    DupesFullName.SetParentComponent(Dupes);
    DupesFullName.Name := 'DupesFullName';
    DupesFullName.FieldKind := fkCalculated;
    DupesFullName.FieldName := 'FullName';
    DupesFullName.Size := 50;
    DupesFullName.Calculated := True;
    DupesFullAddress.SetParentComponent(Dupes);
    DupesFullAddress.Name := 'DupesFullAddress';
    DupesFullAddress.FieldKind := fkCalculated;
    DupesFullAddress.FieldName := 'FullAddress';
    DupesFullAddress.Size := 100;
    DupesFullAddress.Calculated := True;
  finally
    GiftAidScheme.AfterLoadDFMValues;
    OldRefLabel.AfterLoadDFMValues;
    DonorIdLabel.AfterLoadDFMValues;
    SaveAndNew.AfterLoadDFMValues;
    CancelAndNew.AfterLoadDFMValues;
    Comments.AfterLoadDFMValues;
    DateSigned.AfterLoadDFMValues;
    ContactByPost.AfterLoadDFMValues;
    ContactByText.AfterLoadDFMValues;
    ContactByEmail.AfterLoadDFMValues;
    ContactByPhone.AfterLoadDFMValues;
    NotifyAllSales.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    edTelephone.AfterLoadDFMValues;
    edMobile.AfterLoadDFMValues;
    edEmail.AfterLoadDFMValues;
    edPostCode.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edAdd3.AfterLoadDFMValues;
    edAdd2.AfterLoadDFMValues;
    edAdd1.AfterLoadDFMValues;
    edLastName.AfterLoadDFMValues;
    edFirstName.AfterLoadDFMValues;
    NewDonorId.AfterLoadDFMValues;
    DonorIdMessage.AfterLoadDFMValues;
    ShopRef.AfterLoadDFMValues;
    MessageSpan.AfterLoadDFMValues;
    SaveAndFinish.AfterLoadDFMValues;
    CancelAndFinish.AfterLoadDFMValues;
    DupesMessage.AfterLoadDFMValues;
    MessageDlg.AfterLoadDFMValues;
    Title.AfterLoadDFMValues;
    ShopsLookup.AfterLoadDFMValues;
    ShopsLookupRef.AfterLoadDFMValues;
    ShopsLookupRegionRef.AfterLoadDFMValues;
    ShopsLookupName.AfterLoadDFMValues;
    ShopsLookupStatus.AfterLoadDFMValues;
    ShopsLookupLocationType.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetConsId.AfterLoadDFMValues;
    MainDatasetOldRef.AfterLoadDFMValues;
    MainDatasetShopRef.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetTelephone.AfterLoadDFMValues;
    MainDatasetGiftAidScheme.AfterLoadDFMValues;
    MainDatasetTaxReportRequested.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetScannedDocsExist.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetShopName.AfterLoadDFMValues;
    MainDatasetFurtherContact.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetDateSigned.AfterLoadDFMValues;
    MainDatasetContactByPhone.AfterLoadDFMValues;
    MainDatasetContactByPost.AfterLoadDFMValues;
    MainDatasetContactByText.AfterLoadDFMValues;
    MainDatasetContactByEmail.AfterLoadDFMValues;
    MainDatasetFullName.AfterLoadDFMValues;
    MainDatasetREExported.AfterLoadDFMValues;
    MainDatasetREImported.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetMasterId.AfterLoadDFMValues;
    EventList.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
    Dupes.AfterLoadDFMValues;
    DupesId.AfterLoadDFMValues;
    DupesConsId.AfterLoadDFMValues;
    DupesOldRef.AfterLoadDFMValues;
    DupesShopRef.AfterLoadDFMValues;
    DupesTitle.AfterLoadDFMValues;
    DupesFirstName.AfterLoadDFMValues;
    DupesLastName.AfterLoadDFMValues;
    DupesAdd1.AfterLoadDFMValues;
    DupesAdd2.AfterLoadDFMValues;
    DupesAdd3.AfterLoadDFMValues;
    DupesCity.AfterLoadDFMValues;
    DupesPostCode.AfterLoadDFMValues;
    DupesEmail.AfterLoadDFMValues;
    DupesMobile.AfterLoadDFMValues;
    DupesTelephone.AfterLoadDFMValues;
    DupesGiftAidScheme.AfterLoadDFMValues;
    DupesTaxReportRequested.AfterLoadDFMValues;
    DupesClaimStatus.AfterLoadDFMValues;
    DupesScannedDocsExist.AfterLoadDFMValues;
    DupesAddedBy.AfterLoadDFMValues;
    DupesDateAdded.AfterLoadDFMValues;
    DupesLastUpdatedBy.AfterLoadDFMValues;
    DupesLastUpdated.AfterLoadDFMValues;
    DupesFurtherContact.AfterLoadDFMValues;
    DupesComments.AfterLoadDFMValues;
    DupesDateSigned.AfterLoadDFMValues;
    DupesContactByPhone.AfterLoadDFMValues;
    DupesContactByPost.AfterLoadDFMValues;
    DupesContactByText.AfterLoadDFMValues;
    DupesContactByEmail.AfterLoadDFMValues;
    DupesREExported.AfterLoadDFMValues;
    DupesREImported.AfterLoadDFMValues;
    DupesMatchHash.AfterLoadDFMValues;
    DupesMasterId.AfterLoadDFMValues;
    DupesFullName.AfterLoadDFMValues;
    DupesFullAddress.AfterLoadDFMValues;
  end;
end;

end.

