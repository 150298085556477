unit MainForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  App.Types,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  BaseCoreForm,
  SupportForm;

type
  TMainPage = class(TForm)
    DashboardLabel: TLabel;
    RegionAdminLink: TLabel;
    ShopsAdminLink: TLabel;
    UserAdminLabel: TLabel;
    DonorAdminLabel: TLabel;
    NewDonorsLabel: TLabel;
    RequestIdsLabel: TLabel;
    SearchSalesLink: TLabel;
    NewSalesLink: TLabel;
    ExportDonorsLink: TLabel;
    ImportDonorsLink: TLabel;
    ExportSalesLink: TLabel;
    LoggedInUserLabel: TLabel;
    LogoutLabel: TLabel;
    UserScopeLabel: TLabel;
    UserShopLabel: TLabel;
    LocationTypeLabel: TLabel;
    JobsDataset: TXDataWebDataSet;
    JobsDatasetId: TIntegerField;
    JobsDatasetJobType: TStringField;
    JobsDatasetSubmittedBy: TIntegerField;
    JobsDatasetDateSubmitted: TDateTimeField;
    JobsDatasetStatus: TStringField;
    JobsDatasetTimeTaken: TFloatField;
    JobsDatasetDateRun: TDateTimeField;
    JobsDatasetVisibility: TStringField;
    JobsDatasetData: TStringField;
    JobsDatasetOutputOption: TStringField;
    JobsDatasetCompletedBy: TIntegerField;
    JobsDatasetOwnedBy: TIntegerField;
    JobsDatasetTitle: TStringField;
    JobsDatasetNotifyOnCompletion: TBooleanField;
    JobsDatasetDateCompleted: TDateTimeField;
    WebTimer1: TTimer;
    MainToast: TToast;
    UpdateMe: TLabel;
    MainMessageDlg: TMessageDlg;
    JobsDatasetHasErrors: TBooleanField;
    NotifcationsLink: TLabel;
    GiftAidLink: TLabel;
    JobsDatasetStats: TStringField;
    JobsDatasetDownloads: TStringField;
    NotificationsHistoryLink: TLabel;
    JobsDatasetProgress: TFloatField;
    JobsDatasetRunLevel: TStringField;
    ExceptionTestLink: TLabel;
    SysConfigLink: TLabel;
    GAConfigLink: TLabel;
    TTL: TLabel;
    TTLTimer: TTimer;
    SupportLink: TLabel;
    StopTimersCheckbox: TCheckBox;
    JobsDatasetJobCategory: TStringField;
    JobsDatasetLastPolled: TDateTimeField;
    JobsDatasetPollInterval: TLargeintField;
    JobsDatasetPollCount: TIntegerField;
    DonorDirectroriesLink: TLabel;
    StatementsLink: TLabel;
    PricingLabelsLink: TLabel;
    ReportsLink: TLabel;
    ExportDonorStatusesLink: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure DashboardLabelClick(Sender: TObject);
    procedure DonorAdminLabelClick(Sender: TObject);
    procedure DonorDirectroriesLinkClick(Sender: TObject);
    procedure ExceptionTestLinkClick(Sender: TObject);
    procedure ExportDonorsLinkClick(Sender: TObject);
    procedure ExportSalesLinkClick(Sender: TObject);
    procedure GAConfigLinkClick(Sender: TObject);
    procedure GiftAidLinkClick(Sender: TObject);
    procedure ImportDonorsLinkClick(Sender: TObject);
    procedure JobsDatasetAfterOpen(Dataset: TDataSet);
    procedure JobsDatasetAfterPost(Dataset: TDataSet);
    procedure LogoutLabelClick(Sender: TObject);
    procedure NewDonorsLabelClick(Sender: TObject);
    procedure NewSalesLinkClick(Sender: TObject);
    procedure NotifcationsLinkClick(Sender: TObject);
    procedure NotificationsHistoryLinkClick(Sender: TObject);
    procedure PricingLabelsLinkClick(Sender: TObject);
    procedure RegionAdminLinkClick(Sender: TObject);
    procedure ReportsLinkClick(Sender: TObject);
    procedure RequestIdsLabelClick(Sender: TObject);
    procedure SearchSalesLinkClick(Sender: TObject);
    procedure ShopsAdminLinkClick(Sender: TObject);
    procedure StatementsLinkClick(Sender: TObject);
    procedure SupportLinkClick(Sender: TObject);
    procedure SysConfigLinkClick(Sender: TObject);
    procedure TTLClick(Sender: TObject);
    procedure TTLTimerTimer(Sender: TObject);
    procedure UpdateMeClick(Sender: TObject);
    procedure UserAdminLabelClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure ExportDonorStatusesLinkClick(Sender: TObject);
    procedure ImportDonorStatusesLinkClick(Sender: TObject);
  private
    { Private declarations }
    FLogoutProc: TLogoutProc;
    FDisplayedForm: TForm;
    FJobsLocked: Boolean;
    FJobsUpdating: Boolean;
//    FLastCompletedDate: TDateTime;
    FTTLShow: Int64;
    FTTLHighlight: Int64;
//    FErrorSupportForm: TSupportPage;
    procedure OnFormSelfClose(Sender: TObject);
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil);
    function CloseDisplayedForm: Boolean;
    [async]
    procedure ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil); async;
    procedure ShowDashboard;
    procedure LoadJobs;
    procedure SetLinksAuth;
    procedure OnAuthUpdated(Sendr: TObject);
    procedure OnUserChanged(Sendr: TObject);

    procedure UpdateTTL;
    procedure ToggleTimers(const AEnabled: Boolean);
  public
    { Public declarations }
    class procedure Display(const AElementId: string; LogoutProc: TLogoutProc);
    procedure SetLabels;
    procedure LockJobsData;
    procedure UnlockJobsData(const ARefresh: Boolean = False);
    procedure RefreshJobs;
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    [async]
    procedure ErrorHandler(AMessage: String); async;
    procedure ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
    [async]
    procedure ReLogin(AMessage: string); async;
  protected procedure LoadDFMValues; override; end;

var
  MainPage: TMainPage;

implementation

{$R *.dfm}

uses
  RegionList,
  DashboardForm,
  ShopList,
  Auth.Service,
  GA.Shop.Shared,
  UserList,
  DonorList,
  NewDonorsForm,
  DonorIdRequest,
  MainDataModule,
  System.DateUtils,
  App.Helper,
  SelfUpdateForm,
  DailySalesForm,
  SalesList,
  ExportDonorsForm,
  ExportSalesForm,
  ImportDonorsForm,
  GiftAidClaimsList,
  NotificationsList,
  NotificationsJobForm,
  SysConfigForm,
  GAConfigForm,
  AppUtils,
  LoginForm,
  DirectoryRequest, EndOfYearForm, SalesLabelJob, ReportMaster,
  ExportDonorStatusesForm,
  ImportDonorStatusesForm;

procedure TMainPage.WebFormCreate(Sender: TObject);
begin
  FTTLShow := 15 * 60;
  FTTLHighlight := 3 * 60;
//  FLastCompletedDate := Now;
  MainData.ShowMessageProc := DisplayMessage;
  MainData.OnAuthUpdatedProc := OnAuthUpdated;
  MainData.OnUserChanged := OnUserChanged;
  MainData.ErrorProc := ErrorHandler;

  MainData.PrepareDashboard;

  JobsDataset.DisableControls;

  LoadJobs;

  ShowDashboard;
end;

function TMainPage.CloseDisplayedForm: Boolean;
begin
  Result := True;
  if Assigned(FDisplayedForm) then
  begin
    if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
    begin
      Result := TCoreWebForm(FDisplayedForm).CanClose;
      if Result then
         TCoreWebForm(FDisplayedForm).TearDown;
    end;
    if not Result then
      Exit;
    FDisplayedForm.Free;
    FDisplayedForm := nil;
  end;
end;

procedure TMainPage.DashboardLabelClick(Sender: TObject);
begin
  ShowDashboard;
end;

class procedure TMainPage.Display(const AElementId: string; LogoutProc: TLogoutProc);

  procedure AfterCreate(AForm: TObject);
  begin
    TMainPage(AForm).FLogoutProc := LogoutProc;
    TMainPage(AForm).SetLabels;
    TMainPage(AForm).SetLinksAuth;
  end;

begin
  if not Assigned(MainPage) then
  begin
    MainPage := TMainPage.CreateNew(AElementId, @AfterCreate)
  end;
end;

procedure TMainPage.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

procedure TMainPage.DonorAdminLabelClick(Sender: TObject);
begin
  ShowForm(TDonorListForm);
end;

procedure TMainPage.DonorDirectroriesLinkClick(Sender: TObject);
begin
  ShowPopUp(TDonorDirectoryJob, @RefreshJobs);
end;

procedure TMainPage.ErrorHandler(AMessage: String);
begin

  ShowAnAwesomeToast('An Error Occurred', AMessage, 'fad fa-bug');

//  if Assigned(FErrorSupportForm) then
//    Exit;

//  ToggleTimers(False);
//
//  FErrorSupportForm := TSupportPage.Create(Self);
//  try
//    FErrorSupportForm.Popup := True;
//    FErrorSupportForm.PopupOpacity := 1;
//    await(TWebForm, FErrorSupportForm.Load());
//    FErrorSupportForm.ErrorData := Value;
//    lRetval := await(TModalResult, FErrorSupportForm.Execute);
//    AHandled := (lRetval = mrOK);
//    if AHandled then
//      DisplayMessage('Thank you. This has been sent to the developers')
//    else
//      DisplayMessage('The error has been sent to the developers');
//  finally
//    FErrorSupportForm.Free;
//    FErrorSupportForm := nil;
//  end;
//  ToggleTimers(True);
end;

procedure TMainPage.ExceptionTestLinkClick(Sender: TObject);
begin
  raise Exception.Create('This is a test exception');
end;

procedure TMainPage.ExportDonorsLinkClick(Sender: TObject);
begin
  ShowPopUp(TExportDonors, @RefreshJobs);
end;

procedure TMainPage.ExportDonorStatusesLinkClick(Sender: TObject);
begin
  ShowPopUp(TExportDonorStatuses, @RefreshJobs);

end;

procedure TMainPage.ExportSalesLinkClick(Sender: TObject);
begin
  ShowPopUp(TSalesExportJob, @RefreshJobs);
end;

procedure TMainPage.GAConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TGAConfig);
end;

procedure TMainPage.GiftAidLinkClick(Sender: TObject);
begin
  ShowForm(TGiftAidClaims);
end;

procedure TMainPage.ImportDonorsLinkClick(Sender: TObject);
begin
  ShowPopUp(TImportDonorsJob, @RefreshJobs);
end;

procedure TMainPage.ImportDonorStatusesLinkClick(Sender: TObject);
begin
  ShowPopUp(TImportDonorStatuses, @RefreshJobs);

end;

procedure TMainPage.JobsDatasetAfterOpen(Dataset: TDataSet);
begin
  JobsDataset.EnableControls;
  if (FDisplayedForm is TDashboard) then
    TDashboard(FDisplayedForm).RefreshJobsList;
  FJobsLocked := False;
  WebTimer1.Enabled := True;
end;

procedure TMainPage.JobsDatasetAfterPost(Dataset: TDataSet);
begin
  if not FJobsUpdating then
    JobsDataset.ApplyUpdates;
end;

procedure TMainPage.LoadJobs;
begin
  FJobsLocked := True;
  JobsDataset.DisableControls;
  JobsDataset.Close;
  JobsDataset.Load;
end;

procedure TMainPage.LockJobsData;
begin
  WebTimer1.Enabled := False;
  FJobsLocked := True;
end;

procedure TMainPage.LogoutLabelClick(Sender: TObject);
begin
  FLogoutProc('', True);
end;

procedure TMainPage.NewDonorsLabelClick(Sender: TObject);
begin
  ShowForm(TAddDonorsForm);
end;

procedure TMainPage.NewSalesLinkClick(Sender: TObject);
begin
  ShowForm(TDailySales);
end;

procedure TMainPage.NotifcationsLinkClick(Sender: TObject);
begin
  ShowPopUp(TNotificationsJob, @RefreshJobs);
end;

procedure TMainPage.NotificationsHistoryLinkClick(Sender: TObject);
begin
  ShowForm(TNotificationsListForm);
end;

procedure TMainPage.OnAuthUpdated(Sendr: TObject);
begin
  UpdateTTL;
end;

procedure TMainPage.OnFormSelfClose(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainPage.OnUserChanged(Sendr: TObject);
begin
  SetLabels;
  SetLinksAuth;
  LoadJobs;
end;

procedure TMainPage.PricingLabelsLinkClick(Sender: TObject);
begin
  ShowPopup(TAdditionalSalesLabels,  @RefreshJobs);
end;

procedure TMainPage.RefreshJobs;
begin
  LoadJobs;
end;

procedure TMainPage.RegionAdminLinkClick(Sender: TObject);
begin
  ShowForm(TRegionListForm);
end;

procedure TMainPage.ReLogin(AMessage: string);
var
  lRetval: TModalResult;
//  lW, lH: Integer;
begin
  if Assigned(LoginPage) then
    Exit;
  ToggleTimers(False);

  AuthService.Logout(False);
  MainData.DataConnection.Connected := False;
  // LoginPage := TLoginPage.CreateNew('LoginAgainForm.html');
  LoginPage := TLoginPage.Create(Self);
  try
    LoginPage.Popup := True;
    LoginPage.PopupOpacity := 1;
    LoginPage.IsModalPopUp := True;
    await(TForm, LoginPage.Load());

    LoginPage.AMessage := AMessage;
    lRetval := await(TModalResult, LoginPage.Execute);
    if lRetval = mrOK then
    begin
      ToggleTimers(True);
    end;

  finally
    LoginPage.Free;
    LoginPage := nil;
  end;
end;

procedure TMainPage.ReportsLinkClick(Sender: TObject);
begin
  ShowForm(TMainReportForm);
end;

procedure TMainPage.RequestIdsLabelClick(Sender: TObject);
begin
  ShowPopUp(TRequestDonorId, @RefreshJobs);
end;

procedure TMainPage.SearchSalesLinkClick(Sender: TObject);
begin
  ShowForm(TSalesListForm);
end;

procedure TMainPage.SetLabels;
begin
  LoggedInUserLabel.Caption := AuthService.ClaimValue(CLAIM_FULLNAME);
  UserScopeLabel.Caption := AuthService.UserScope;
  UserShopLabel.Caption := AuthService.ClaimValue(CLAIM_SHOP);
  LocationTypeLabel.Caption := AuthService.LocationType;
end;

procedure TMainPage.SetLinksAuth;

  procedure SetLabelVisibility(const ALabel: TLabel; AVisible: Boolean);
  begin
    ALabel.Visible := AVisible;
    ALabel.Enabled := AVisible;
  end;

var
  lIsAdministrator: Boolean;
  lIsAdminUserOrBetter: Boolean;
begin
  lIsAdministrator := AuthService.IsAdministrator;
  lIsAdminUserOrBetter := AuthService.IsAdminUserOrBetter;

  SetLabelVisibility(ReportsLink, lIsAdministrator);
  // SetLabelVisibility(DashboardLabel, lIsAdminUserOrBetter);
  SetLabelVisibility(RegionAdminLink, lIsAdminUserOrBetter);
  SetLabelVisibility(ShopsAdminLink, lIsAdminUserOrBetter);
  SetLabelVisibility(UserAdminLabel, lIsAdminUserOrBetter);

  SetLabelVisibility(DonorAdminLabel, lIsAdminUserOrBetter);
  SetLabelVisibility(NewDonorsLabel, lIsAdminUserOrBetter);

  SetLabelVisibility(SearchSalesLink, lIsAdminUserOrBetter);
  SetLabelVisibility(NewSalesLink, lIsAdminUserOrBetter);
  SetLabelVisibility(RequestIdsLabel, lIsAdministrator);

  SetLabelVisibility(ExportDonorsLink, lIsAdministrator);
  SetLabelVisibility(ImportDonorsLink, lIsAdministrator);
  SetLabelVisibility(ExportDonorStatusesLink, lIsAdministrator);

  { TODO -oAFP : Need to create the link for the donor statuses again, it was only removed to get a build out }
//  SetLabelVisibility(ImportDonorStatusesLink, lIsAdministrator);

  SetLabelVisibility(ExportSalesLink, lIsAdministrator);

  SetLabelVisibility(NotifcationsLink, lIsAdministrator);
  SetLabelVisibility(NotificationsHistoryLink, lIsAdministrator);
  SetLabelVisibility(GiftAidLink, lIsAdministrator);

  SetLabelVisibility(GAConfigLink, lIsAdministrator);
  SetLabelVisibility(SysConfigLink, False); // lIsAdministrator);
  SetLabelVisibility(DonorDirectroriesLink, lIsAdministrator);
  SetLabelVisibility(PricingLabelsLink, lIsAdministrator);

  if not lIsAdministrator then
  begin
    THTMLHelper.hideElement('RaisersEdgePanel');
    THTMLHelper.hideElement('GiftAidPanel');
  end;

  SetLabelVisibility(ExceptionTestLink, AuthService.LocationType = 'System');
{$IFDEF RELEASE}
  StopTimersCheckbox.Visible := False;
{$ELSE}
  StopTimersCheckbox.Visible := (AuthService.LocationType = 'System');
{$ENDIF}
end;

procedure TMainPage.ShopsAdminLinkClick(Sender: TObject);
begin
  ShowForm(TShopListForm);
end;

procedure TMainPage.ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
  MESSAGE_TITLE = '<i class="%s fa-fw fa-lg"></i> %s';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(Format(MESSAGE_TITLE, [AIcon, ATitle]), Format(MESSAGE_TEMPLATE, [AMessage]));
  lToast.Show;
end;

procedure TMainPage.ShowDashboard;

  procedure DashboardCreated(AForm: TObject);
  begin
    TDashboard(AForm).JobsDataset := JobsDataset;
  end;

begin
  ShowForm(TDashboard, @DashboardCreated);
end;

procedure TMainPage.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc);

  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if Assigned(AProc) then
      AProc(AForm);

  end;

begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) then
    Exit;

  if not CloseDisplayedForm then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated)

end;

procedure TMainPage.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil);
var
  AForm: TForm;
  lRetval: TModalResult;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TMainPage.StatementsLinkClick(Sender: TObject);
begin
 ShowPopUp(TEOYForm, @RefreshJobs);
end;

procedure TMainPage.SupportLinkClick(Sender: TObject);
begin
  ShowPopUp(TSupportPage);
end;

procedure TMainPage.SysConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TSysConfig);
end;

procedure TMainPage.ToggleTimers(const AEnabled: Boolean);
begin
  WebTimer1.Enabled := AEnabled;
  TTLTimer.Enabled := AEnabled;
end;

procedure TMainPage.TTLClick(Sender: TObject);
begin
  ReLogin('');
end;

procedure TMainPage.TTLTimerTimer(Sender: TObject);
begin
  if StopTimersCheckbox.Checked then
    Exit;
  UpdateTTL;
end;

procedure TMainPage.UnlockJobsData(const ARefresh: Boolean);
begin
  if ARefresh then
    RefreshJobs
  else
  begin
    FJobsLocked := False;
    WebTimer1.Enabled := True;
  end;
end;

procedure TMainPage.UpdateMeClick(Sender: TObject);
begin
  ShowForm(TSelfUpdate);
end;

procedure TMainPage.UpdateTTL;

  procedure SetTTLTimer(const AInterval: Int64);
  begin
    if TTLTimer.Interval = AInterval then
      Exit;
    TTLTimer.Enabled := False;
    TTLTimer.Interval := AInterval;
    TTLTimer.Enabled := True;
  end;

var
  lExpiry: TDateTime;
  lTTL: Int64;
begin
  lExpiry := AuthService.TokenExpirationDate;
  if lExpiry <= Now then
  begin
    ReLogin('Token expired');
    Exit;
  end;

  lTTL := SecondsBetween(Now, lExpiry);

  if lTTL < FTTLShow then
  begin
    TTL.Caption := TSysHelper.SecondsAsTime(lTTL);
    if lTTL < FTTLHighlight then
      TTL.Font.Color := clRed
    else
      TTL.Font.Color := clWindowText;

    TTL.Visible := True;
    SetTTLTimer(1000);
  end
  else
  begin
    TTL.Visible := True;
    TTL.Caption := 'Lock Application';
    SetTTLTimer(60000);
  end;

  if not TTLTimer.Enabled then
    TTLTimer.Enabled := True;

end;

procedure TMainPage.UserAdminLabelClick(Sender: TObject);
begin
  ShowForm(TUserListForm);
end;

procedure TMainPage.WebFormShow(Sender: TObject);
begin
  UpdateTTL;
end;

procedure TMainPage.WebTimer1Timer(Sender: TObject);
begin
  if StopTimersCheckbox.Checked then
    Exit;
  { TODO : This should work both ways - prevent a jobs detail form opening until this is is complete }
  if FJobsLocked then
    Exit;
  LoadJobs;
end;

procedure TMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ShopsAdminLink := TLabel.Create('ShopsAdminLink');
  RegionAdminLink := TLabel.Create('RegionsAdminLink');
  UserAdminLabel := TLabel.Create('UsersAdminLink');
  DashboardLabel := TLabel.Create('DashboardLink');
  LoggedInUserLabel := TLabel.Create('UserNameLabel');
  LogoutLabel := TLabel.Create('LogoutLink');
  UserScopeLabel := TLabel.Create('UserScopeLabel');
  UserShopLabel := TLabel.Create('UserShopLabel');
  LocationTypeLabel := TLabel.Create('LocationTypeLabel');
  DonorAdminLabel := TLabel.Create('DonorAdminLink');
  NewDonorsLabel := TLabel.Create('NewDonorsLink');
  RequestIdsLabel := TLabel.Create('RequestIdsLink');
  UpdateMe := TLabel.Create('UpdateMe');
  NewSalesLink := TLabel.Create('DailySalesLink');
  SearchSalesLink := TLabel.Create('SearchSalesLink');
  ExportDonorsLink := TLabel.Create('ExportDonorsLink');
  ImportDonorsLink := TLabel.Create('ImportDonorsLink');
  ExportSalesLink := TLabel.Create('ExportSalesLink');
  NotifcationsLink := TLabel.Create('NotifcationsLink');
  GiftAidLink := TLabel.Create('GiftAidLink');
  NotificationsHistoryLink := TLabel.Create('NotifcationsHistoryLink');
  ExceptionTestLink := TLabel.Create('ExceptionTestLink');
  SysConfigLink := TLabel.Create('SysConfigLink');
  GAConfigLink := TLabel.Create('GAConfigLink');
  TTL := TLabel.Create('TTL');
  SupportLink := TLabel.Create('SupportLink');
  DonorDirectroriesLink := TLabel.Create('DonorDirectroriesLink');
  StatementsLink := TLabel.Create('StatementsLink');
  PricingLabelsLink := TLabel.Create('PricingLabelsLink');
  ReportsLink := TLabel.Create('ReportsLink');
  ExportDonorStatusesLink := TLabel.Create('ExportDonorStatusesLink');
  MainMessageDlg := TMessageDlg.Create(Self);
  StopTimersCheckbox := TCheckBox.Create('StopTimersCheckbox');
  JobsDataset := TXDataWebDataSet.Create(Self);
  JobsDatasetId := TIntegerField.Create(Self);
  JobsDatasetJobType := TStringField.Create(Self);
  JobsDatasetSubmittedBy := TIntegerField.Create(Self);
  JobsDatasetDateSubmitted := TDateTimeField.Create(Self);
  JobsDatasetStatus := TStringField.Create(Self);
  JobsDatasetTimeTaken := TFloatField.Create(Self);
  JobsDatasetDateRun := TDateTimeField.Create(Self);
  JobsDatasetVisibility := TStringField.Create(Self);
  JobsDatasetData := TStringField.Create(Self);
  JobsDatasetOutputOption := TStringField.Create(Self);
  JobsDatasetCompletedBy := TIntegerField.Create(Self);
  JobsDatasetOwnedBy := TIntegerField.Create(Self);
  JobsDatasetTitle := TStringField.Create(Self);
  JobsDatasetNotifyOnCompletion := TBooleanField.Create(Self);
  JobsDatasetDateCompleted := TDateTimeField.Create(Self);
  JobsDatasetProgress := TFloatField.Create(Self);
  JobsDatasetRunLevel := TStringField.Create(Self);
  JobsDatasetHasErrors := TBooleanField.Create(Self);
  JobsDatasetStats := TStringField.Create(Self);
  JobsDatasetDownloads := TStringField.Create(Self);
  JobsDatasetJobCategory := TStringField.Create(Self);
  JobsDatasetLastPolled := TDateTimeField.Create(Self);
  JobsDatasetPollInterval := TLargeintField.Create(Self);
  JobsDatasetPollCount := TIntegerField.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  MainToast := TToast.Create(Self);
  TTLTimer := TTimer.Create(Self);

  ShopsAdminLink.BeforeLoadDFMValues;
  RegionAdminLink.BeforeLoadDFMValues;
  UserAdminLabel.BeforeLoadDFMValues;
  DashboardLabel.BeforeLoadDFMValues;
  LoggedInUserLabel.BeforeLoadDFMValues;
  LogoutLabel.BeforeLoadDFMValues;
  UserScopeLabel.BeforeLoadDFMValues;
  UserShopLabel.BeforeLoadDFMValues;
  LocationTypeLabel.BeforeLoadDFMValues;
  DonorAdminLabel.BeforeLoadDFMValues;
  NewDonorsLabel.BeforeLoadDFMValues;
  RequestIdsLabel.BeforeLoadDFMValues;
  UpdateMe.BeforeLoadDFMValues;
  NewSalesLink.BeforeLoadDFMValues;
  SearchSalesLink.BeforeLoadDFMValues;
  ExportDonorsLink.BeforeLoadDFMValues;
  ImportDonorsLink.BeforeLoadDFMValues;
  ExportSalesLink.BeforeLoadDFMValues;
  NotifcationsLink.BeforeLoadDFMValues;
  GiftAidLink.BeforeLoadDFMValues;
  NotificationsHistoryLink.BeforeLoadDFMValues;
  ExceptionTestLink.BeforeLoadDFMValues;
  SysConfigLink.BeforeLoadDFMValues;
  GAConfigLink.BeforeLoadDFMValues;
  TTL.BeforeLoadDFMValues;
  SupportLink.BeforeLoadDFMValues;
  DonorDirectroriesLink.BeforeLoadDFMValues;
  StatementsLink.BeforeLoadDFMValues;
  PricingLabelsLink.BeforeLoadDFMValues;
  ReportsLink.BeforeLoadDFMValues;
  ExportDonorStatusesLink.BeforeLoadDFMValues;
  MainMessageDlg.BeforeLoadDFMValues;
  StopTimersCheckbox.BeforeLoadDFMValues;
  JobsDataset.BeforeLoadDFMValues;
  JobsDatasetId.BeforeLoadDFMValues;
  JobsDatasetJobType.BeforeLoadDFMValues;
  JobsDatasetSubmittedBy.BeforeLoadDFMValues;
  JobsDatasetDateSubmitted.BeforeLoadDFMValues;
  JobsDatasetStatus.BeforeLoadDFMValues;
  JobsDatasetTimeTaken.BeforeLoadDFMValues;
  JobsDatasetDateRun.BeforeLoadDFMValues;
  JobsDatasetVisibility.BeforeLoadDFMValues;
  JobsDatasetData.BeforeLoadDFMValues;
  JobsDatasetOutputOption.BeforeLoadDFMValues;
  JobsDatasetCompletedBy.BeforeLoadDFMValues;
  JobsDatasetOwnedBy.BeforeLoadDFMValues;
  JobsDatasetTitle.BeforeLoadDFMValues;
  JobsDatasetNotifyOnCompletion.BeforeLoadDFMValues;
  JobsDatasetDateCompleted.BeforeLoadDFMValues;
  JobsDatasetProgress.BeforeLoadDFMValues;
  JobsDatasetRunLevel.BeforeLoadDFMValues;
  JobsDatasetHasErrors.BeforeLoadDFMValues;
  JobsDatasetStats.BeforeLoadDFMValues;
  JobsDatasetDownloads.BeforeLoadDFMValues;
  JobsDatasetJobCategory.BeforeLoadDFMValues;
  JobsDatasetLastPolled.BeforeLoadDFMValues;
  JobsDatasetPollInterval.BeforeLoadDFMValues;
  JobsDatasetPollCount.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  TTLTimer.BeforeLoadDFMValues;
  try
    Name := 'MainPage';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ShopsAdminLink.SetParentComponent(Self);
    ShopsAdminLink.Name := 'ShopsAdminLink';
    ShopsAdminLink.Left := 28;
    ShopsAdminLink.Top := 80;
    ShopsAdminLink.Width := 32;
    ShopsAdminLink.Height := 15;
    ShopsAdminLink.Cursor := crHandPoint;
    ShopsAdminLink.Caption := 'Shops';
    ShopsAdminLink.ElementPosition := epIgnore;
    ShopsAdminLink.HeightStyle := ssAuto;
    ShopsAdminLink.HeightPercent := 100.000000000000000000;
    ShopsAdminLink.WidthStyle := ssAuto;
    ShopsAdminLink.WidthPercent := 100.000000000000000000;
    SetEvent(ShopsAdminLink, Self, 'OnClick', 'ShopsAdminLinkClick');
    RegionAdminLink.SetParentComponent(Self);
    RegionAdminLink.Name := 'RegionAdminLink';
    RegionAdminLink.Left := 28;
    RegionAdminLink.Top := 45;
    RegionAdminLink.Width := 42;
    RegionAdminLink.Height := 15;
    RegionAdminLink.Cursor := crHandPoint;
    RegionAdminLink.Caption := 'Regions';
    RegionAdminLink.ElementPosition := epIgnore;
    RegionAdminLink.HeightStyle := ssAuto;
    RegionAdminLink.HeightPercent := 100.000000000000000000;
    RegionAdminLink.WidthStyle := ssAuto;
    RegionAdminLink.WidthPercent := 100.000000000000000000;
    SetEvent(RegionAdminLink, Self, 'OnClick', 'RegionAdminLinkClick');
    UserAdminLabel.SetParentComponent(Self);
    UserAdminLabel.Name := 'UserAdminLabel';
    UserAdminLabel.Left := 28;
    UserAdminLabel.Top := 115;
    UserAdminLabel.Width := 28;
    UserAdminLabel.Height := 15;
    UserAdminLabel.Cursor := crHandPoint;
    UserAdminLabel.Caption := 'Users';
    UserAdminLabel.ElementPosition := epIgnore;
    UserAdminLabel.HeightStyle := ssAuto;
    UserAdminLabel.HeightPercent := 100.000000000000000000;
    UserAdminLabel.WidthStyle := ssAuto;
    UserAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(UserAdminLabel, Self, 'OnClick', 'UserAdminLabelClick');
    DashboardLabel.SetParentComponent(Self);
    DashboardLabel.Name := 'DashboardLabel';
    DashboardLabel.Left := 28;
    DashboardLabel.Top := 8;
    DashboardLabel.Width := 57;
    DashboardLabel.Height := 15;
    DashboardLabel.Cursor := crHandPoint;
    DashboardLabel.Caption := 'Dashboard';
    DashboardLabel.ElementPosition := epIgnore;
    DashboardLabel.HeightStyle := ssAuto;
    DashboardLabel.HeightPercent := 100.000000000000000000;
    DashboardLabel.WidthStyle := ssAuto;
    DashboardLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DashboardLabel, Self, 'OnClick', 'DashboardLabelClick');
    LoggedInUserLabel.SetParentComponent(Self);
    LoggedInUserLabel.Name := 'LoggedInUserLabel';
    LoggedInUserLabel.Left := 16;
    LoggedInUserLabel.Top := 360;
    LoggedInUserLabel.Width := 101;
    LoggedInUserLabel.Height := 15;
    LoggedInUserLabel.Caption := 'LoggedInUserLabel';
    LoggedInUserLabel.ElementPosition := epIgnore;
    LoggedInUserLabel.HeightStyle := ssAuto;
    LoggedInUserLabel.HeightPercent := 100.000000000000000000;
    LoggedInUserLabel.HTMLType := tDIV;
    LoggedInUserLabel.WidthStyle := ssAuto;
    LoggedInUserLabel.WidthPercent := 100.000000000000000000;
    LogoutLabel.SetParentComponent(Self);
    LogoutLabel.Name := 'LogoutLabel';
    LogoutLabel.Left := 8;
    LogoutLabel.Top := 436;
    LogoutLabel.Width := 38;
    LogoutLabel.Height := 15;
    LogoutLabel.Cursor := crHandPoint;
    LogoutLabel.Caption := 'Logout';
    LogoutLabel.ElementPosition := epIgnore;
    LogoutLabel.HeightStyle := ssAuto;
    LogoutLabel.HeightPercent := 100.000000000000000000;
    LogoutLabel.HTMLType := tDIV;
    LogoutLabel.WidthStyle := ssAuto;
    LogoutLabel.WidthPercent := 100.000000000000000000;
    SetEvent(LogoutLabel, Self, 'OnClick', 'LogoutLabelClick');
    UserScopeLabel.SetParentComponent(Self);
    UserScopeLabel.Name := 'UserScopeLabel';
    UserScopeLabel.Left := 16;
    UserScopeLabel.Top := 376;
    UserScopeLabel.Width := 83;
    UserScopeLabel.Height := 15;
    UserScopeLabel.Caption := 'UserScopeLabel';
    UserScopeLabel.ElementPosition := epIgnore;
    UserScopeLabel.HeightStyle := ssAuto;
    UserScopeLabel.HeightPercent := 100.000000000000000000;
    UserScopeLabel.HTMLType := tDIV;
    UserScopeLabel.Visible := False;
    UserScopeLabel.WidthStyle := ssAuto;
    UserScopeLabel.WidthPercent := 100.000000000000000000;
    UserShopLabel.SetParentComponent(Self);
    UserShopLabel.Name := 'UserShopLabel';
    UserShopLabel.Left := 16;
    UserShopLabel.Top := 395;
    UserShopLabel.Width := 78;
    UserShopLabel.Height := 15;
    UserShopLabel.Caption := 'UserShopLabel';
    UserShopLabel.ElementPosition := epIgnore;
    UserShopLabel.HeightStyle := ssAuto;
    UserShopLabel.HeightPercent := 100.000000000000000000;
    UserShopLabel.HTMLType := tDIV;
    UserShopLabel.WidthStyle := ssAuto;
    UserShopLabel.WidthPercent := 100.000000000000000000;
    LocationTypeLabel.SetParentComponent(Self);
    LocationTypeLabel.Name := 'LocationTypeLabel';
    LocationTypeLabel.Left := 16;
    LocationTypeLabel.Top := 414;
    LocationTypeLabel.Width := 98;
    LocationTypeLabel.Height := 15;
    LocationTypeLabel.Caption := 'LocationTypeLabel';
    LocationTypeLabel.ElementPosition := epIgnore;
    LocationTypeLabel.HeightStyle := ssAuto;
    LocationTypeLabel.HeightPercent := 100.000000000000000000;
    LocationTypeLabel.HTMLType := tDIV;
    LocationTypeLabel.Visible := False;
    LocationTypeLabel.WidthStyle := ssAuto;
    LocationTypeLabel.WidthPercent := 100.000000000000000000;
    DonorAdminLabel.SetParentComponent(Self);
    DonorAdminLabel.Name := 'DonorAdminLabel';
    DonorAdminLabel.Left := 26;
    DonorAdminLabel.Top := 171;
    DonorAdminLabel.Width := 38;
    DonorAdminLabel.Height := 15;
    DonorAdminLabel.Cursor := crHandPoint;
    DonorAdminLabel.Caption := 'Donors';
    DonorAdminLabel.ElementFont := efCSS;
    DonorAdminLabel.ElementPosition := epIgnore;
    DonorAdminLabel.HeightStyle := ssAuto;
    DonorAdminLabel.HeightPercent := 100.000000000000000000;
    DonorAdminLabel.WidthStyle := ssAuto;
    DonorAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DonorAdminLabel, Self, 'OnClick', 'DonorAdminLabelClick');
    NewDonorsLabel.SetParentComponent(Self);
    NewDonorsLabel.Name := 'NewDonorsLabel';
    NewDonorsLabel.Left := 24;
    NewDonorsLabel.Top := 208;
    NewDonorsLabel.Width := 65;
    NewDonorsLabel.Height := 15;
    NewDonorsLabel.Cursor := crHandPoint;
    NewDonorsLabel.Caption := 'New Donors';
    NewDonorsLabel.ElementFont := efCSS;
    NewDonorsLabel.HeightStyle := ssAuto;
    NewDonorsLabel.HeightPercent := 100.000000000000000000;
    NewDonorsLabel.WidthPercent := 100.000000000000000000;
    SetEvent(NewDonorsLabel, Self, 'OnClick', 'NewDonorsLabelClick');
    RequestIdsLabel.SetParentComponent(Self);
    RequestIdsLabel.Name := 'RequestIdsLabel';
    RequestIdsLabel.Left := 24;
    RequestIdsLabel.Top := 240;
    RequestIdsLabel.Width := 96;
    RequestIdsLabel.Height := 15;
    RequestIdsLabel.Cursor := crHandPoint;
    RequestIdsLabel.Caption := 'Request Donor Ids';
    RequestIdsLabel.ElementFont := efCSS;
    RequestIdsLabel.ElementPosition := epIgnore;
    RequestIdsLabel.HeightStyle := ssAuto;
    RequestIdsLabel.HeightPercent := 100.000000000000000000;
    RequestIdsLabel.WidthPercent := 100.000000000000000000;
    SetEvent(RequestIdsLabel, Self, 'OnClick', 'RequestIdsLabelClick');
    UpdateMe.SetParentComponent(Self);
    UpdateMe.Name := 'UpdateMe';
    UpdateMe.Left := 192;
    UpdateMe.Top := 8;
    UpdateMe.Width := 55;
    UpdateMe.Height := 15;
    UpdateMe.Caption := 'UpdateMe';
    UpdateMe.ElementFont := efCSS;
    UpdateMe.ElementPosition := epIgnore;
    UpdateMe.HeightStyle := ssAuto;
    UpdateMe.HeightPercent := 100.000000000000000000;
    UpdateMe.WidthStyle := ssAuto;
    UpdateMe.WidthPercent := 100.000000000000000000;
    SetEvent(UpdateMe, Self, 'OnClick', 'UpdateMeClick');
    NewSalesLink.SetParentComponent(Self);
    NewSalesLink.Name := 'NewSalesLink';
    NewSalesLink.Left := 24;
    NewSalesLink.Top := 275;
    NewSalesLink.Width := 53;
    NewSalesLink.Height := 15;
    NewSalesLink.Cursor := crHandPoint;
    NewSalesLink.Caption := 'New Sales';
    NewSalesLink.ElementFont := efCSS;
    NewSalesLink.ElementPosition := epIgnore;
    NewSalesLink.HeightStyle := ssAuto;
    NewSalesLink.HeightPercent := 100.000000000000000000;
    NewSalesLink.WidthStyle := ssAuto;
    NewSalesLink.WidthPercent := 100.000000000000000000;
    SetEvent(NewSalesLink, Self, 'OnClick', 'NewSalesLinkClick');
    SearchSalesLink.SetParentComponent(Self);
    SearchSalesLink.Name := 'SearchSalesLink';
    SearchSalesLink.Left := 24;
    SearchSalesLink.Top := 294;
    SearchSalesLink.Width := 64;
    SearchSalesLink.Height := 15;
    SearchSalesLink.Cursor := crHandPoint;
    SearchSalesLink.Caption := 'Search Sales';
    SearchSalesLink.ElementFont := efCSS;
    SearchSalesLink.ElementPosition := epIgnore;
    SearchSalesLink.HeightStyle := ssAuto;
    SearchSalesLink.HeightPercent := 100.000000000000000000;
    SearchSalesLink.WidthStyle := ssAuto;
    SearchSalesLink.WidthPercent := 100.000000000000000000;
    SetEvent(SearchSalesLink, Self, 'OnClick', 'SearchSalesLinkClick');
    ExportDonorsLink.SetParentComponent(Self);
    ExportDonorsLink.Name := 'ExportDonorsLink';
    ExportDonorsLink.Left := 208;
    ExportDonorsLink.Top := 275;
    ExportDonorsLink.Width := 75;
    ExportDonorsLink.Height := 15;
    ExportDonorsLink.Caption := 'Export Donors';
    ExportDonorsLink.ElementFont := efCSS;
    ExportDonorsLink.HeightStyle := ssAuto;
    ExportDonorsLink.HeightPercent := 100.000000000000000000;
    ExportDonorsLink.WidthStyle := ssAuto;
    ExportDonorsLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExportDonorsLink, Self, 'OnClick', 'ExportDonorsLinkClick');
    ImportDonorsLink.SetParentComponent(Self);
    ImportDonorsLink.Name := 'ImportDonorsLink';
    ImportDonorsLink.Left := 208;
    ImportDonorsLink.Top := 294;
    ImportDonorsLink.Width := 77;
    ImportDonorsLink.Height := 15;
    ImportDonorsLink.Caption := 'Import Donors';
    ImportDonorsLink.ElementFont := efCSS;
    ImportDonorsLink.HeightStyle := ssAuto;
    ImportDonorsLink.HeightPercent := 100.000000000000000000;
    ImportDonorsLink.WidthStyle := ssAuto;
    ImportDonorsLink.WidthPercent := 100.000000000000000000;
    SetEvent(ImportDonorsLink, Self, 'OnClick', 'ImportDonorsLinkClick');
    ExportSalesLink.SetParentComponent(Self);
    ExportSalesLink.Name := 'ExportSalesLink';
    ExportSalesLink.Left := 208;
    ExportSalesLink.Top := 313;
    ExportSalesLink.Width := 63;
    ExportSalesLink.Height := 15;
    ExportSalesLink.Caption := 'Export Sales';
    ExportSalesLink.ElementFont := efCSS;
    ExportSalesLink.HeightStyle := ssAuto;
    ExportSalesLink.HeightPercent := 100.000000000000000000;
    ExportSalesLink.WidthStyle := ssAuto;
    ExportSalesLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExportSalesLink, Self, 'OnClick', 'ExportSalesLinkClick');
    NotifcationsLink.SetParentComponent(Self);
    NotifcationsLink.Name := 'NotifcationsLink';
    NotifcationsLink.Left := 208;
    NotifcationsLink.Top := 395;
    NotifcationsLink.Width := 92;
    NotifcationsLink.Height := 15;
    NotifcationsLink.Caption := 'Run Notifications';
    NotifcationsLink.ElementFont := efCSS;
    NotifcationsLink.HeightStyle := ssAuto;
    NotifcationsLink.HeightPercent := 100.000000000000000000;
    NotifcationsLink.WidthStyle := ssAuto;
    NotifcationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(NotifcationsLink, Self, 'OnClick', 'NotifcationsLinkClick');
    GiftAidLink.SetParentComponent(Self);
    GiftAidLink.Name := 'GiftAidLink';
    GiftAidLink.Left := 208;
    GiftAidLink.Top := 433;
    GiftAidLink.Width := 117;
    GiftAidLink.Height := 15;
    GiftAidLink.Caption := 'GiftAid Claims History';
    GiftAidLink.ElementFont := efCSS;
    GiftAidLink.HeightStyle := ssAuto;
    GiftAidLink.HeightPercent := 100.000000000000000000;
    GiftAidLink.WidthStyle := ssAuto;
    GiftAidLink.WidthPercent := 100.000000000000000000;
    SetEvent(GiftAidLink, Self, 'OnClick', 'GiftAidLinkClick');
    NotificationsHistoryLink.SetParentComponent(Self);
    NotificationsHistoryLink.Name := 'NotificationsHistoryLink';
    NotificationsHistoryLink.Left := 208;
    NotificationsHistoryLink.Top := 412;
    NotificationsHistoryLink.Width := 109;
    NotificationsHistoryLink.Height := 15;
    NotificationsHistoryLink.Caption := 'Notifications History';
    NotificationsHistoryLink.ElementFont := efCSS;
    NotificationsHistoryLink.HeightStyle := ssAuto;
    NotificationsHistoryLink.HeightPercent := 100.000000000000000000;
    NotificationsHistoryLink.WidthPercent := 100.000000000000000000;
    SetEvent(NotificationsHistoryLink, Self, 'OnClick', 'NotificationsHistoryLinkClick');
    ExceptionTestLink.SetParentComponent(Self);
    ExceptionTestLink.Name := 'ExceptionTestLink';
    ExceptionTestLink.Left := 189;
    ExceptionTestLink.Top := 80;
    ExceptionTestLink.Width := 75;
    ExceptionTestLink.Height := 15;
    ExceptionTestLink.Cursor := crHandPoint;
    ExceptionTestLink.Caption := 'Exception Test';
    ExceptionTestLink.ElementFont := efCSS;
    ExceptionTestLink.HeightStyle := ssAuto;
    ExceptionTestLink.HeightPercent := 100.000000000000000000;
    ExceptionTestLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExceptionTestLink, Self, 'OnClick', 'ExceptionTestLinkClick');
    SysConfigLink.SetParentComponent(Self);
    SysConfigLink.Name := 'SysConfigLink';
    SysConfigLink.Left := 189;
    SysConfigLink.Top := 38;
    SysConfigLink.Width := 77;
    SysConfigLink.Height := 15;
    SysConfigLink.Cursor := crHandPoint;
    SysConfigLink.Caption := 'System Config';
    SysConfigLink.ElementFont := efCSS;
    SysConfigLink.HeightStyle := ssAuto;
    SysConfigLink.HeightPercent := 100.000000000000000000;
    SysConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(SysConfigLink, Self, 'OnClick', 'SysConfigLinkClick');
    GAConfigLink.SetParentComponent(Self);
    GAConfigLink.Name := 'GAConfigLink';
    GAConfigLink.Left := 189;
    GAConfigLink.Top := 59;
    GAConfigLink.Width := 76;
    GAConfigLink.Height := 15;
    GAConfigLink.Cursor := crHandPoint;
    GAConfigLink.Caption := 'GiftAid Config';
    GAConfigLink.ElementFont := efCSS;
    GAConfigLink.HeightStyle := ssAuto;
    GAConfigLink.HeightPercent := 100.000000000000000000;
    GAConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(GAConfigLink, Self, 'OnClick', 'GAConfigLinkClick');
    TTL.SetParentComponent(Self);
    TTL.Name := 'TTL';
    TTL.Left := 488;
    TTL.Top := 456;
    TTL.Width := 89;
    TTL.Height := 15;
    TTL.Cursor := crHandPoint;
    TTL.Caption := 'Lock Application';
    TTL.ElementFont := efCSS;
    TTL.HeightStyle := ssAuto;
    TTL.HeightPercent := 100.000000000000000000;
    TTL.HTMLType := tSPAN;
    TTL.WidthPercent := 100.000000000000000000;
    SetEvent(TTL, Self, 'OnClick', 'TTLClick');
    SupportLink.SetParentComponent(Self);
    SupportLink.Name := 'SupportLink';
    SupportLink.Left := 192;
    SupportLink.Top := 120;
    SupportLink.Width := 42;
    SupportLink.Height := 15;
    SupportLink.Caption := 'Support';
    SupportLink.ElementFont := efCSS;
    SupportLink.ElementPosition := epIgnore;
    SupportLink.HeightStyle := ssAuto;
    SupportLink.HeightPercent := 100.000000000000000000;
    SupportLink.WidthStyle := ssAuto;
    SupportLink.WidthPercent := 100.000000000000000000;
    SetEvent(SupportLink, Self, 'OnClick', 'SupportLinkClick');
    DonorDirectroriesLink.SetParentComponent(Self);
    DonorDirectroriesLink.Name := 'DonorDirectroriesLink';
    DonorDirectroriesLink.Left := 24;
    DonorDirectroriesLink.Top := 261;
    DonorDirectroriesLink.Width := 129;
    DonorDirectroriesLink.Height := 15;
    DonorDirectroriesLink.Cursor := crHandPoint;
    DonorDirectroriesLink.Caption := 'Create Donor Directories';
    DonorDirectroriesLink.ElementFont := efCSS;
    DonorDirectroriesLink.ElementPosition := epIgnore;
    DonorDirectroriesLink.HeightStyle := ssAuto;
    DonorDirectroriesLink.HeightPercent := 100.000000000000000000;
    DonorDirectroriesLink.WidthPercent := 100.000000000000000000;
    SetEvent(DonorDirectroriesLink, Self, 'OnClick', 'DonorDirectroriesLinkClick');
    StatementsLink.SetParentComponent(Self);
    StatementsLink.Name := 'StatementsLink';
    StatementsLink.Left := 208;
    StatementsLink.Top := 454;
    StatementsLink.Width := 108;
    StatementsLink.Height := 15;
    StatementsLink.Caption := 'Run EOY Statements';
    StatementsLink.ElementFont := efCSS;
    StatementsLink.HeightStyle := ssAuto;
    StatementsLink.HeightPercent := 100.000000000000000000;
    StatementsLink.WidthStyle := ssAuto;
    StatementsLink.WidthPercent := 100.000000000000000000;
    SetEvent(StatementsLink, Self, 'OnClick', 'StatementsLinkClick');
    PricingLabelsLink.SetParentComponent(Self);
    PricingLabelsLink.Name := 'PricingLabelsLink';
    PricingLabelsLink.Left := 159;
    PricingLabelsLink.Top := 240;
    PricingLabelsLink.Width := 118;
    PricingLabelsLink.Height := 15;
    PricingLabelsLink.Cursor := crHandPoint;
    PricingLabelsLink.Caption := 'Request Pricing Labels';
    PricingLabelsLink.ElementFont := efCSS;
    PricingLabelsLink.ElementPosition := epIgnore;
    PricingLabelsLink.HeightStyle := ssAuto;
    PricingLabelsLink.HeightPercent := 100.000000000000000000;
    PricingLabelsLink.WidthPercent := 100.000000000000000000;
    SetEvent(PricingLabelsLink, Self, 'OnClick', 'PricingLabelsLinkClick');
    ReportsLink.SetParentComponent(Self);
    ReportsLink.Name := 'ReportsLink';
    ReportsLink.Left := 153;
    ReportsLink.Top := 208;
    ReportsLink.Width := 40;
    ReportsLink.Height := 15;
    ReportsLink.Cursor := crHandPoint;
    ReportsLink.Caption := 'Reports';
    ReportsLink.ElementFont := efCSS;
    ReportsLink.ElementPosition := epIgnore;
    ReportsLink.HeightStyle := ssAuto;
    ReportsLink.HeightPercent := 100.000000000000000000;
    ReportsLink.WidthPercent := 100.000000000000000000;
    SetEvent(ReportsLink, Self, 'OnClick', 'ReportsLinkClick');
    ExportDonorStatusesLink.SetParentComponent(Self);
    ExportDonorStatusesLink.Name := 'ExportDonorStatusesLink';
    ExportDonorStatusesLink.Left := 208;
    ExportDonorStatusesLink.Top := 334;
    ExportDonorStatusesLink.Width := 116;
    ExportDonorStatusesLink.Height := 15;
    ExportDonorStatusesLink.Caption := 'Export Donor Statuses';
    ExportDonorStatusesLink.ElementFont := efCSS;
    ExportDonorStatusesLink.HeightStyle := ssAuto;
    ExportDonorStatusesLink.HeightPercent := 100.000000000000000000;
    ExportDonorStatusesLink.WidthStyle := ssAuto;
    ExportDonorStatusesLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExportDonorStatusesLink, Self, 'OnClick', 'ExportDonorStatusesLinkClick');
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 472;
    MainMessageDlg.Top := 336;
    MainMessageDlg.Width := 24;
    MainMessageDlg.Height := 24;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn';
    MainMessageDlg.ElementDialogClassName := 'shadow-lg mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    StopTimersCheckbox.SetParentComponent(Self);
    StopTimersCheckbox.Name := 'StopTimersCheckbox';
    StopTimersCheckbox.Left := 296;
    StopTimersCheckbox.Top := 8;
    StopTimersCheckbox.Width := 113;
    StopTimersCheckbox.Height := 22;
    StopTimersCheckbox.Caption := 'Stop timers';
    StopTimersCheckbox.ChildOrder := 27;
    StopTimersCheckbox.ElementClassName := 'form-control';
    StopTimersCheckbox.ElementLabelClassName := 'custom-control-label';
    StopTimersCheckbox.ElementFont := efCSS;
    StopTimersCheckbox.HeightStyle := ssAuto;
    StopTimersCheckbox.HeightPercent := 100.000000000000000000;
    StopTimersCheckbox.WidthPercent := 100.000000000000000000;
    JobsDataset.SetParentComponent(Self);
    JobsDataset.Name := 'JobsDataset';
    JobsDataset.AfterOpen := JobsDatasetAfterOpen;
    JobsDataset.AfterPost := JobsDatasetAfterPost;
    JobsDataset.EntitySetName := 'Job';
    JobsDataset.Connection := MainData.DataConnection;
    JobsDataset.Left := 480;
    JobsDataset.Top := 104;
    JobsDatasetId.SetParentComponent(JobsDataset);
    JobsDatasetId.Name := 'JobsDatasetId';
    JobsDatasetId.FieldName := 'Id';
    JobsDatasetJobType.SetParentComponent(JobsDataset);
    JobsDatasetJobType.Name := 'JobsDatasetJobType';
    JobsDatasetJobType.FieldName := 'JobType';
    JobsDatasetJobType.Size := 25;
    JobsDatasetSubmittedBy.SetParentComponent(JobsDataset);
    JobsDatasetSubmittedBy.Name := 'JobsDatasetSubmittedBy';
    JobsDatasetSubmittedBy.FieldName := 'SubmittedBy';
    JobsDatasetDateSubmitted.SetParentComponent(JobsDataset);
    JobsDatasetDateSubmitted.Name := 'JobsDatasetDateSubmitted';
    JobsDatasetDateSubmitted.FieldName := 'DateSubmitted';
    JobsDatasetDateSubmitted.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetStatus.SetParentComponent(JobsDataset);
    JobsDatasetStatus.Name := 'JobsDatasetStatus';
    JobsDatasetStatus.FieldName := 'Status';
    JobsDatasetStatus.Size := 10;
    JobsDatasetTimeTaken.SetParentComponent(JobsDataset);
    JobsDatasetTimeTaken.Name := 'JobsDatasetTimeTaken';
    JobsDatasetTimeTaken.FieldName := 'TimeTaken';
    JobsDatasetDateRun.SetParentComponent(JobsDataset);
    JobsDatasetDateRun.Name := 'JobsDatasetDateRun';
    JobsDatasetDateRun.FieldName := 'DateRun';
    JobsDatasetDateRun.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetVisibility.SetParentComponent(JobsDataset);
    JobsDatasetVisibility.Name := 'JobsDatasetVisibility';
    JobsDatasetVisibility.FieldName := 'Visibility';
    JobsDatasetVisibility.Size := 9;
    JobsDatasetData.SetParentComponent(JobsDataset);
    JobsDatasetData.Name := 'JobsDatasetData';
    JobsDatasetData.FieldName := 'Data';
    JobsDatasetData.Size := 4096;
    JobsDatasetOutputOption.SetParentComponent(JobsDataset);
    JobsDatasetOutputOption.Name := 'JobsDatasetOutputOption';
    JobsDatasetOutputOption.FieldName := 'OutputOption';
    JobsDatasetOutputOption.Size := 50;
    JobsDatasetCompletedBy.SetParentComponent(JobsDataset);
    JobsDatasetCompletedBy.Name := 'JobsDatasetCompletedBy';
    JobsDatasetCompletedBy.FieldName := 'CompletedBy';
    JobsDatasetOwnedBy.SetParentComponent(JobsDataset);
    JobsDatasetOwnedBy.Name := 'JobsDatasetOwnedBy';
    JobsDatasetOwnedBy.FieldName := 'OwnedBy';
    JobsDatasetTitle.SetParentComponent(JobsDataset);
    JobsDatasetTitle.Name := 'JobsDatasetTitle';
    JobsDatasetTitle.FieldName := 'Title';
    JobsDatasetTitle.Size := 255;
    JobsDatasetNotifyOnCompletion.SetParentComponent(JobsDataset);
    JobsDatasetNotifyOnCompletion.Name := 'JobsDatasetNotifyOnCompletion';
    JobsDatasetNotifyOnCompletion.FieldName := 'NotifyOnCompletion';
    JobsDatasetDateCompleted.SetParentComponent(JobsDataset);
    JobsDatasetDateCompleted.Name := 'JobsDatasetDateCompleted';
    JobsDatasetDateCompleted.FieldName := 'DateCompleted';
    JobsDatasetDateCompleted.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetProgress.SetParentComponent(JobsDataset);
    JobsDatasetProgress.Name := 'JobsDatasetProgress';
    JobsDatasetProgress.FieldName := 'Progress';
    JobsDatasetRunLevel.SetParentComponent(JobsDataset);
    JobsDatasetRunLevel.Name := 'JobsDatasetRunLevel';
    JobsDatasetRunLevel.FieldName := 'RunLevel';
    JobsDatasetRunLevel.Size := 10;
    JobsDatasetHasErrors.SetParentComponent(JobsDataset);
    JobsDatasetHasErrors.Name := 'JobsDatasetHasErrors';
    JobsDatasetHasErrors.FieldName := 'HasErrors';
    JobsDatasetStats.SetParentComponent(JobsDataset);
    JobsDatasetStats.Name := 'JobsDatasetStats';
    JobsDatasetStats.FieldName := 'Stats';
    JobsDatasetStats.Size := 4096;
    JobsDatasetDownloads.SetParentComponent(JobsDataset);
    JobsDatasetDownloads.Name := 'JobsDatasetDownloads';
    JobsDatasetDownloads.FieldName := 'Downloads';
    JobsDatasetDownloads.Size := 4096;
    JobsDatasetJobCategory.SetParentComponent(JobsDataset);
    JobsDatasetJobCategory.Name := 'JobsDatasetJobCategory';
    JobsDatasetJobCategory.FieldName := 'JobCategory';
    JobsDatasetJobCategory.Size := 8;
    JobsDatasetLastPolled.SetParentComponent(JobsDataset);
    JobsDatasetLastPolled.Name := 'JobsDatasetLastPolled';
    JobsDatasetLastPolled.FieldName := 'LastPolled';
    JobsDatasetPollInterval.SetParentComponent(JobsDataset);
    JobsDatasetPollInterval.Name := 'JobsDatasetPollInterval';
    JobsDatasetPollInterval.FieldName := 'PollInterval';
    JobsDatasetPollCount.SetParentComponent(JobsDataset);
    JobsDatasetPollCount.Name := 'JobsDatasetPollCount';
    JobsDatasetPollCount.FieldName := 'PollCount';
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 10000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 472;
    WebTimer1.Top := 176;
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.AutoHideDelay := 3000;
    MainToast.Position := tpBottomRight;
    MainToast.Left := 472;
    MainToast.Top := 264;
    TTLTimer.SetParentComponent(Self);
    TTLTimer.Name := 'TTLTimer';
    TTLTimer.Enabled := False;
    SetEvent(TTLTimer, Self, 'OnTimer', 'TTLTimerTimer');
    TTLTimer.Left := 560;
    TTLTimer.Top := 176;
  finally
    ShopsAdminLink.AfterLoadDFMValues;
    RegionAdminLink.AfterLoadDFMValues;
    UserAdminLabel.AfterLoadDFMValues;
    DashboardLabel.AfterLoadDFMValues;
    LoggedInUserLabel.AfterLoadDFMValues;
    LogoutLabel.AfterLoadDFMValues;
    UserScopeLabel.AfterLoadDFMValues;
    UserShopLabel.AfterLoadDFMValues;
    LocationTypeLabel.AfterLoadDFMValues;
    DonorAdminLabel.AfterLoadDFMValues;
    NewDonorsLabel.AfterLoadDFMValues;
    RequestIdsLabel.AfterLoadDFMValues;
    UpdateMe.AfterLoadDFMValues;
    NewSalesLink.AfterLoadDFMValues;
    SearchSalesLink.AfterLoadDFMValues;
    ExportDonorsLink.AfterLoadDFMValues;
    ImportDonorsLink.AfterLoadDFMValues;
    ExportSalesLink.AfterLoadDFMValues;
    NotifcationsLink.AfterLoadDFMValues;
    GiftAidLink.AfterLoadDFMValues;
    NotificationsHistoryLink.AfterLoadDFMValues;
    ExceptionTestLink.AfterLoadDFMValues;
    SysConfigLink.AfterLoadDFMValues;
    GAConfigLink.AfterLoadDFMValues;
    TTL.AfterLoadDFMValues;
    SupportLink.AfterLoadDFMValues;
    DonorDirectroriesLink.AfterLoadDFMValues;
    StatementsLink.AfterLoadDFMValues;
    PricingLabelsLink.AfterLoadDFMValues;
    ReportsLink.AfterLoadDFMValues;
    ExportDonorStatusesLink.AfterLoadDFMValues;
    MainMessageDlg.AfterLoadDFMValues;
    StopTimersCheckbox.AfterLoadDFMValues;
    JobsDataset.AfterLoadDFMValues;
    JobsDatasetId.AfterLoadDFMValues;
    JobsDatasetJobType.AfterLoadDFMValues;
    JobsDatasetSubmittedBy.AfterLoadDFMValues;
    JobsDatasetDateSubmitted.AfterLoadDFMValues;
    JobsDatasetStatus.AfterLoadDFMValues;
    JobsDatasetTimeTaken.AfterLoadDFMValues;
    JobsDatasetDateRun.AfterLoadDFMValues;
    JobsDatasetVisibility.AfterLoadDFMValues;
    JobsDatasetData.AfterLoadDFMValues;
    JobsDatasetOutputOption.AfterLoadDFMValues;
    JobsDatasetCompletedBy.AfterLoadDFMValues;
    JobsDatasetOwnedBy.AfterLoadDFMValues;
    JobsDatasetTitle.AfterLoadDFMValues;
    JobsDatasetNotifyOnCompletion.AfterLoadDFMValues;
    JobsDatasetDateCompleted.AfterLoadDFMValues;
    JobsDatasetProgress.AfterLoadDFMValues;
    JobsDatasetRunLevel.AfterLoadDFMValues;
    JobsDatasetHasErrors.AfterLoadDFMValues;
    JobsDatasetStats.AfterLoadDFMValues;
    JobsDatasetDownloads.AfterLoadDFMValues;
    JobsDatasetJobCategory.AfterLoadDFMValues;
    JobsDatasetLastPolled.AfterLoadDFMValues;
    JobsDatasetPollInterval.AfterLoadDFMValues;
    JobsDatasetPollCount.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
    TTLTimer.AfterLoadDFMValues;
  end;
end;

end.
