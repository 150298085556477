unit smx.Dashboard.store;

interface

uses
  Generics.Collections,
  JS,
  smx.Reports.Types,
  Dashboard.ReturnTypes;

type

  TDashboardStore = class
  private
    FItems: TList<TDashboardBase>;
    function GetItemById(const AId: Integer): TDashboardBase;
    function GetItems(const index: Integer): TDashboardBase;
  public
    constructor Create;
    destructor Destroy; override;
    function AddItem(Value: TDashboardBase): Integer;
    function AddFromJSON(Value: JS.TJSObject): TDashboardBase;
    function Count: Integer;
    procedure Clear;
    procedure UpdateFromJSON(AItem: TDashboardBase; Value: JS.TJSObject);
    property Items[const index: Integer]: TDashboardBase read GetItems;
    property ItemById[const AId: Integer]: TDashboardBase read GetItemById;
  end;

implementation

uses System.Rtti, System.DateUtils;

{ TDashboardStore }

function TDashboardStore.AddFromJSON(Value: JS.TJSObject): TDashboardBase;

begin
  Result := TDashboardBase.Create;
  UpdateFromJSON(Result, Value);
  FItems.Add(Result);
end;

function TDashboardStore.AddItem(Value: TDashboardBase): Integer;
begin
  Result := FItems.Add(Value);
end;

procedure TDashboardStore.Clear;
begin
  FItems.Clear;
end;

function TDashboardStore.Count: Integer;
begin
  Result := FItems.Count;
end;

constructor TDashboardStore.Create;
begin
  FItems := TList<TDashboardBase>.Create;
end;

destructor TDashboardStore.Destroy;
begin
  FItems.Free;
  inherited;
end;

function TDashboardStore.GetItemById(const AId: Integer): TDashboardBase;
var I: Integer;
begin
  Result := Nil;
  for I := 0 to FItems.Count - 1 do
  begin
    if FItems[I].MyId = AId then
       Exit(FItems[I]);
  end;
end;

function TDashboardStore.GetItems(const index: Integer): TDashboardBase;
begin
  Result := FItems[index];
end;

procedure TDashboardStore.UpdateFromJSON(AItem: TDashboardBase; Value: JS.TJSObject);
begin
  AItem.ReportId := JS.ToInteger(Value['ReportId']);
  AItem.MyId := JS.ToInteger(Value['MiId']);

  AItem.DisplayType := TRttiEnumerationType.GetValue<TDashboardDisplay>(JS.ToString(Value['DisplayType']));

  AItem.Title := JS.ToString(Value['Title']);
  AItem.SubTitle := JS.ToString(Value['SubTitle']);
  AItem.Data := JS.ToString(Value['Data']);
  AItem.Config := JS.ToString(Value['Config']);

  AItem.Refresh := TRttiEnumerationType.GetValue<TRefreshFreq>(JS.ToString(Value['Refresh']));

  AItem.LastRefresh := RFC3339ToDateTime(JS.ToString(Value['LastRefresh']));
  AItem.Status := TRttiEnumerationType.GetValue<TDashboardItemStatus>(JS.ToString(Value['Status']));

end;

end.
