unit NotificationsJobForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TNotificationsJob = class(TJobBase)
    LetterThreshold: TComboBox;
    EmailThreshold: TComboBox;
    LetterDate: TDateTimePicker;
    SalesCutOffDate: TDateTimePicker;
    NotificationsDataset: TXDataWebDataSet;
    NotificationsDatasetId: TIntegerField;
    NotificationsDatasetClaimId: TIntegerField;
    NotificationsDatasetClaimRef: TStringField;
    NotificationsDatasetLetterThreshold: TFloatField;
    NotificationsDatasetLastSalesDate: TDateField;
    NotificationsDatasetEmailThreshold: TFloatField;
    NotificationsDatasetEmailCount: TIntegerField;
    NotificationsDatasetNotNeededCount: TIntegerField;
    NotificationsDatasetLetterCount: TIntegerField;
    NotificationsDatasetLetterDate: TDateField;
    NotificationsDatasetUnderThresholdCount: TIntegerField;
    NotificationsDatasetStatus: TStringField;
    NotificationsDatasetEarliestSalesDate: TDateField;
    NotificationsDatasetGiftAidId: TIntegerField;
    LetterThresholdOther: TEdit;
    EmailThresholdOther: TEdit;
    procedure EmailThresholdChange(Sender: TObject);
    procedure LetterThresholdChange(Sender: TObject);
    procedure NotificationsDatasetAfterOpen(Dataset: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FLastNotificationStatus: string;
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: String; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  NotificationsJob: TNotificationsJob;

implementation

{$R *.dfm}

uses
  MainDataModule,
  DateUtils, AppUtils;

// GiftAid Status Codes 'Open,Cancelled,Submitting,Submitted,Confirmed,PaymentReceived,ClaimRejected,Error'
// Notification Status Codes 'Open,ClaimActive,Complete,Cancelled'
const
  ACTIVE_CLAIM_STATII = 'Open, ClaimActive'; //

procedure TNotificationsJob.WebFormCreate(Sender: TObject);
begin
  FLastNotificationStatus := 'NotLoaded';
  inherited;
  NotificationsDataset.Load;

  OutputOption.Visible := False;

  SalesCutOffDate.Date := EndOfTheMonth(IncMonth(Today, -1));
  LetterDate.Date := IncDay(Today, 7);
  JobRunType.Visible := True;
  JobRunType.ItemIndex := 1;
end;

{ TNotificationsJob }

function TNotificationsJob.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;

  if result then
  begin
    while not JobsPlaced.eof do
    begin
      if TSysHelper.JobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new notification while there is still one in progress');
        Exit(False);
      end;
      JobsPlaced.Next;
    end;
  end;

  if result then
  begin
    result := FLastNotificationStatus = 'OK';
    if not result then
    begin
      if FLastNotificationStatus = 'NotLoaded' then
        JobMessage.HTML.Text :=
          'Please wait while the status of the latest notification/claim is checked'
      else
        JobMessage.HTML.Text :=
          'You can start another claim process while the latest notifications/claim is still active';
      Exit;
    end;

    // Other Validation
  end;

end;

procedure TNotificationsJob.EmailThresholdChange(Sender: TObject);
begin
  EmailThresholdOther.Visible := (EmailThreshold.Text = 'Other');
  if EmailThresholdOther.Visible then
    EmailThresholdOther.SetFocus;
end;

function TNotificationsJob.JobClass: string;
begin
  result := 'TGiftAidNotifications';
end;

function TNotificationsJob.JobDescription: string;
begin
  result := 'GiftAid notifications process'
end;

function TNotificationsJob.JobDisplayName: string;
begin
  result := 'GiftAid Notification';
end;

procedure TNotificationsJob.JobParams(AJobParams: JS.TJSObject);
var
  lThreshold: string;
begin
  inherited;
  lThreshold := LetterThreshold.Items[LetterThreshold.ItemIndex];
  if lThreshold = 'Other' then
    lThreshold := LetterThresholdOther.Text
  else if lThreshold = 'no minimum' then
    lThreshold := '0';

  AJobParams.Properties['LetterThreshold'] := lThreshold.ToDouble;

  lThreshold := EmailThreshold.Items[EmailThreshold.ItemIndex];
  if lThreshold = 'Other' then
    lThreshold := EmailThresholdOther.Text
  else if lThreshold = 'no minimum' then
    lThreshold := '0';

  AJobParams.Properties['EmailThreshold'] := lThreshold.ToDouble;

  AJobParams.Properties['CutOffDate'] := SalesCutOffDate.Date;
  AJobParams.Properties['LetterDate'] := LetterDate.Date;
end;

procedure TNotificationsJob.LetterThresholdChange(Sender: TObject);
begin
  LetterThresholdOther.Visible := (LetterThreshold.Text = 'Other');
  if LetterThresholdOther.Visible then
    LetterThresholdOther.SetFocus;
end;

function TNotificationsJob.MinimumProcessLevel: String;
begin
  Result := 'AnyAdministrator';
end;

procedure TNotificationsJob.NotificationsDatasetAfterOpen(Dataset: TDataSet);
var
  lThreshold: Double;
  idx: Integer;
begin

  // Open, ClaimActive, Complete, Cancelled
  if not NotificationsDataset.IsEmpty then
    FLastNotificationStatus := 'OK'
  else if ACTIVE_CLAIM_STATII.Contains(NotificationsDatasetStatus.Value) then
    FLastNotificationStatus := 'Active'
  else
    FLastNotificationStatus := 'OK';

  LetterThreshold.Enabled := (FLastNotificationStatus = 'OK');
  EmailThreshold.Enabled := (FLastNotificationStatus = 'OK');
  LetterDate.Enabled := (FLastNotificationStatus = 'OK');
  SalesCutOffDate.Enabled := (FLastNotificationStatus = 'OK');
  LetterDate.Enabled := (FLastNotificationStatus = 'OK');
  SalesCutOffDate.Enabled := (FLastNotificationStatus = 'OK');

  if (FLastNotificationStatus = 'OK') and (not NotificationsDataset.IsEmpty) then
  begin

    lThreshold := NotificationsDatasetLetterThreshold.Value;
    if lThreshold = 0 then
      LetterThreshold.ItemIndex := 0
    else
    begin
      idx := LetterThreshold.Items.IndexOf(Trunc(lThreshold).ToString);
      if idx > -1 then
        LetterThreshold.ItemIndex := idx
      else
      begin
        LetterThreshold.ItemIndex := LetterThreshold.Items.IndexOf('Other');
        LetterThresholdOther.Text := lThreshold.ToString;
        LetterThresholdOther.Visible := True;
      end;
    end;

    lThreshold := NotificationsDatasetEmailThreshold.Value;
    if lThreshold = 0 then
      EmailThreshold.ItemIndex := 0
    else
    begin
      idx := EmailThreshold.Items.IndexOf(Trunc(lThreshold).ToString);
      if idx > -1 then
        EmailThreshold.ItemIndex := idx
      else
      begin
        EmailThreshold.ItemIndex := EmailThreshold.Items.IndexOf('Other');
        EmailThresholdOther.Text := lThreshold.ToString;
        EmailThresholdOther.Visible := True;
      end;
    end;

  end
  else
  begin
    LetterThreshold.ItemIndex := 1;
    EmailThreshold.ItemIndex := 0;
  end;

  JobMessage.HTML.Text := '';
  ValidateJob;
end;


procedure TNotificationsJob.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LetterThreshold := TComboBox.Create('LetterThreshold');
  EmailThreshold := TComboBox.Create('EmailThreshold');
  LetterDate := TDateTimePicker.Create('LetterDate');
  SalesCutOffDate := TDateTimePicker.Create('SalesCutOffDate');
  LetterThresholdOther := TEdit.Create('LetterThresholdOther');
  EmailThresholdOther := TEdit.Create('EmailThresholdOther');
  NotificationsDataset := TXDataWebDataSet.Create(Self);
  NotificationsDatasetId := TIntegerField.Create(Self);
  NotificationsDatasetClaimId := TIntegerField.Create(Self);
  NotificationsDatasetClaimRef := TStringField.Create(Self);
  NotificationsDatasetLetterThreshold := TFloatField.Create(Self);
  NotificationsDatasetLastSalesDate := TDateField.Create(Self);
  NotificationsDatasetEmailThreshold := TFloatField.Create(Self);
  NotificationsDatasetEmailCount := TIntegerField.Create(Self);
  NotificationsDatasetNotNeededCount := TIntegerField.Create(Self);
  NotificationsDatasetLetterCount := TIntegerField.Create(Self);
  NotificationsDatasetLetterDate := TDateField.Create(Self);
  NotificationsDatasetUnderThresholdCount := TIntegerField.Create(Self);
  NotificationsDatasetStatus := TStringField.Create(Self);
  NotificationsDatasetEarliestSalesDate := TDateField.Create(Self);
  NotificationsDatasetGiftAidId := TIntegerField.Create(Self);

  CancelButton.BeforeLoadDFMValues;
  PlaceJobButton.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  LetterThreshold.BeforeLoadDFMValues;
  EmailThreshold.BeforeLoadDFMValues;
  LetterDate.BeforeLoadDFMValues;
  SalesCutOffDate.BeforeLoadDFMValues;
  LetterThresholdOther.BeforeLoadDFMValues;
  EmailThresholdOther.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  NotificationsDataset.BeforeLoadDFMValues;
  NotificationsDatasetId.BeforeLoadDFMValues;
  NotificationsDatasetClaimId.BeforeLoadDFMValues;
  NotificationsDatasetClaimRef.BeforeLoadDFMValues;
  NotificationsDatasetLetterThreshold.BeforeLoadDFMValues;
  NotificationsDatasetLastSalesDate.BeforeLoadDFMValues;
  NotificationsDatasetEmailThreshold.BeforeLoadDFMValues;
  NotificationsDatasetEmailCount.BeforeLoadDFMValues;
  NotificationsDatasetNotNeededCount.BeforeLoadDFMValues;
  NotificationsDatasetLetterCount.BeforeLoadDFMValues;
  NotificationsDatasetLetterDate.BeforeLoadDFMValues;
  NotificationsDatasetUnderThresholdCount.BeforeLoadDFMValues;
  NotificationsDatasetStatus.BeforeLoadDFMValues;
  NotificationsDatasetEarliestSalesDate.BeforeLoadDFMValues;
  NotificationsDatasetGiftAidId.BeforeLoadDFMValues;
  try
    Height := 577;
    CancelButton.Top := 368;
    PlaceJobButton.Top := 368;
    OutputOption.Visible := False;
    WaitMessage.Left := 528;
    WaitMessage.Top := 32;
    LetterThreshold.SetParentComponent(Self);
    LetterThreshold.Name := 'LetterThreshold';
    LetterThreshold.Left := 88;
    LetterThreshold.Top := 424;
    LetterThreshold.Width := 145;
    LetterThreshold.Height := 23;
    LetterThreshold.ElementClassName := 'form-select';
    LetterThreshold.ElementFont := efCSS;
    LetterThreshold.HeightStyle := ssAuto;
    LetterThreshold.HeightPercent := 100.000000000000000000;
    LetterThreshold.Text := 'LetterThreshold';
    LetterThreshold.WidthPercent := 100.000000000000000000;
    SetEvent(LetterThreshold, Self, 'OnChange', 'LetterThresholdChange');
    LetterThreshold.ItemIndex := -1;
    LetterThreshold.Items.BeginUpdate;
    try
      LetterThreshold.Items.Clear;
      LetterThreshold.Items.Add('no minimum');
      LetterThreshold.Items.Add('10');
      LetterThreshold.Items.Add('20');
      LetterThreshold.Items.Add('30');
      LetterThreshold.Items.Add('Other');
    finally
      LetterThreshold.Items.EndUpdate;
    end;
    EmailThreshold.SetParentComponent(Self);
    EmailThreshold.Name := 'EmailThreshold';
    EmailThreshold.Left := 88;
    EmailThreshold.Top := 453;
    EmailThreshold.Width := 145;
    EmailThreshold.Height := 23;
    EmailThreshold.ElementClassName := 'form-select';
    EmailThreshold.ElementFont := efCSS;
    EmailThreshold.HeightStyle := ssAuto;
    EmailThreshold.HeightPercent := 100.000000000000000000;
    EmailThreshold.Text := 'EmailThreshold';
    EmailThreshold.WidthStyle := ssAuto;
    EmailThreshold.WidthPercent := 100.000000000000000000;
    SetEvent(EmailThreshold, Self, 'OnChange', 'EmailThresholdChange');
    EmailThreshold.ItemIndex := -1;
    EmailThreshold.Items.BeginUpdate;
    try
      EmailThreshold.Items.Clear;
      EmailThreshold.Items.Add('no minimum');
      EmailThreshold.Items.Add('5');
      EmailThreshold.Items.Add('10');
      EmailThreshold.Items.Add('20');
      EmailThreshold.Items.Add('Other');
    finally
      EmailThreshold.Items.EndUpdate;
    end;
    LetterDate.SetParentComponent(Self);
    LetterDate.Name := 'LetterDate';
    LetterDate.Left := 88;
    LetterDate.Top := 488;
    LetterDate.Width := 145;
    LetterDate.Height := 22;
    LetterDate.ElementClassName := 'form-control';
    LetterDate.HeightStyle := ssAuto;
    LetterDate.WidthStyle := ssAuto;
    LetterDate.HeightPercent := 100.000000000000000000;
    LetterDate.WidthPercent := 100.000000000000000000;
    LetterDate.BorderStyle := bsSingle;
    LetterDate.ChildOrder := 11;
    LetterDate.Color := clWhite;
    LetterDate.Date := 44525.765948321760000000;
    LetterDate.ElementFont := efCSS;
    LetterDate.ElementPosition := epIgnore;
    LetterDate.Role := '';
    LetterDate.Text := '';
    SalesCutOffDate.SetParentComponent(Self);
    SalesCutOffDate.Name := 'SalesCutOffDate';
    SalesCutOffDate.Left := 88;
    SalesCutOffDate.Top := 516;
    SalesCutOffDate.Width := 145;
    SalesCutOffDate.Height := 22;
    SalesCutOffDate.ElementClassName := 'form-control';
    SalesCutOffDate.HeightStyle := ssAuto;
    SalesCutOffDate.WidthStyle := ssAuto;
    SalesCutOffDate.HeightPercent := 100.000000000000000000;
    SalesCutOffDate.WidthPercent := 100.000000000000000000;
    SalesCutOffDate.BorderStyle := bsSingle;
    SalesCutOffDate.ChildOrder := 11;
    SalesCutOffDate.Color := clWhite;
    SalesCutOffDate.Date := 44525.765948321760000000;
    SalesCutOffDate.ElementFont := efCSS;
    SalesCutOffDate.ElementPosition := epIgnore;
    SalesCutOffDate.Role := '';
    SalesCutOffDate.Text := '';
    LetterThresholdOther.SetParentComponent(Self);
    LetterThresholdOther.Name := 'LetterThresholdOther';
    LetterThresholdOther.Left := 248;
    LetterThresholdOther.Top := 424;
    LetterThresholdOther.Width := 121;
    LetterThresholdOther.Height := 22;
    LetterThresholdOther.Alignment := taRightJustify;
    LetterThresholdOther.ChildOrder := 13;
    LetterThresholdOther.ElementClassName := 'form-control';
    LetterThresholdOther.ElementFont := efCSS;
    LetterThresholdOther.ElementPosition := epIgnore;
    LetterThresholdOther.HeightStyle := ssAuto;
    LetterThresholdOther.HeightPercent := 100.000000000000000000;
    LetterThresholdOther.Text := '0.00';
    LetterThresholdOther.Visible := False;
    LetterThresholdOther.WidthStyle := ssAuto;
    LetterThresholdOther.WidthPercent := 100.000000000000000000;
    EmailThresholdOther.SetParentComponent(Self);
    EmailThresholdOther.Name := 'EmailThresholdOther';
    EmailThresholdOther.Left := 248;
    EmailThresholdOther.Top := 452;
    EmailThresholdOther.Width := 121;
    EmailThresholdOther.Height := 22;
    EmailThresholdOther.Alignment := taRightJustify;
    EmailThresholdOther.ChildOrder := 13;
    EmailThresholdOther.ElementClassName := 'form-control';
    EmailThresholdOther.ElementFont := efCSS;
    EmailThresholdOther.ElementPosition := epIgnore;
    EmailThresholdOther.HeightStyle := ssAuto;
    EmailThresholdOther.HeightPercent := 100.000000000000000000;
    EmailThresholdOther.Text := '0.00';
    EmailThresholdOther.Visible := False;
    EmailThresholdOther.WidthStyle := ssAuto;
    EmailThresholdOther.WidthPercent := 100.000000000000000000;
    JobRunType.Visible := False;
    CloseTimer.Left := 464;
    CloseTimer.Top := 32;
    NotificationsDataset.SetParentComponent(Self);
    NotificationsDataset.Name := 'NotificationsDataset';
    NotificationsDataset.AfterOpen := NotificationsDatasetAfterOpen;
    NotificationsDataset.EntitySetName := 'GiftaidNotification';
    NotificationsDataset.Connection := MainData.DataConnection;
    NotificationsDataset.Left := 560;
    NotificationsDataset.Top := 64;
    NotificationsDatasetId.SetParentComponent(NotificationsDataset);
    NotificationsDatasetId.Name := 'NotificationsDatasetId';
    NotificationsDatasetId.FieldName := 'Id';
    NotificationsDatasetClaimId.SetParentComponent(NotificationsDataset);
    NotificationsDatasetClaimId.Name := 'NotificationsDatasetClaimId';
    NotificationsDatasetClaimId.FieldName := 'ClaimId';
    NotificationsDatasetClaimRef.SetParentComponent(NotificationsDataset);
    NotificationsDatasetClaimRef.Name := 'NotificationsDatasetClaimRef';
    NotificationsDatasetClaimRef.FieldName := 'ClaimRef';
    NotificationsDatasetClaimRef.Size := 32;
    NotificationsDatasetLetterThreshold.SetParentComponent(NotificationsDataset);
    NotificationsDatasetLetterThreshold.Name := 'NotificationsDatasetLetterThreshold';
    NotificationsDatasetLetterThreshold.FieldName := 'LetterThreshold';
    NotificationsDatasetLetterThreshold.Required := True;
    NotificationsDatasetLastSalesDate.SetParentComponent(NotificationsDataset);
    NotificationsDatasetLastSalesDate.Name := 'NotificationsDatasetLastSalesDate';
    NotificationsDatasetLastSalesDate.FieldName := 'LastSalesDate';
    NotificationsDatasetLastSalesDate.Required := True;
    NotificationsDatasetEmailThreshold.SetParentComponent(NotificationsDataset);
    NotificationsDatasetEmailThreshold.Name := 'NotificationsDatasetEmailThreshold';
    NotificationsDatasetEmailThreshold.FieldName := 'EmailThreshold';
    NotificationsDatasetEmailThreshold.Required := True;
    NotificationsDatasetEmailCount.SetParentComponent(NotificationsDataset);
    NotificationsDatasetEmailCount.Name := 'NotificationsDatasetEmailCount';
    NotificationsDatasetEmailCount.FieldName := 'EmailCount';
    NotificationsDatasetEmailCount.Required := True;
    NotificationsDatasetNotNeededCount.SetParentComponent(NotificationsDataset);
    NotificationsDatasetNotNeededCount.Name := 'NotificationsDatasetNotNeededCount';
    NotificationsDatasetNotNeededCount.FieldName := 'NotNeededCount';
    NotificationsDatasetNotNeededCount.Required := True;
    NotificationsDatasetLetterCount.SetParentComponent(NotificationsDataset);
    NotificationsDatasetLetterCount.Name := 'NotificationsDatasetLetterCount';
    NotificationsDatasetLetterCount.FieldName := 'LetterCount';
    NotificationsDatasetLetterCount.Required := True;
    NotificationsDatasetLetterDate.SetParentComponent(NotificationsDataset);
    NotificationsDatasetLetterDate.Name := 'NotificationsDatasetLetterDate';
    NotificationsDatasetLetterDate.FieldName := 'LetterDate';
    NotificationsDatasetLetterDate.Required := True;
    NotificationsDatasetUnderThresholdCount.SetParentComponent(NotificationsDataset);
    NotificationsDatasetUnderThresholdCount.Name := 'NotificationsDatasetUnderThresholdCount';
    NotificationsDatasetUnderThresholdCount.FieldName := 'UnderThresholdCount';
    NotificationsDatasetUnderThresholdCount.Required := True;
    NotificationsDatasetStatus.SetParentComponent(NotificationsDataset);
    NotificationsDatasetStatus.Name := 'NotificationsDatasetStatus';
    NotificationsDatasetStatus.FieldName := 'Status';
    NotificationsDatasetStatus.Required := True;
    NotificationsDatasetStatus.Size := 9;
    NotificationsDatasetEarliestSalesDate.SetParentComponent(NotificationsDataset);
    NotificationsDatasetEarliestSalesDate.Name := 'NotificationsDatasetEarliestSalesDate';
    NotificationsDatasetEarliestSalesDate.FieldName := 'EarliestSalesDate';
    NotificationsDatasetEarliestSalesDate.Required := True;
    NotificationsDatasetGiftAidId.SetParentComponent(NotificationsDataset);
    NotificationsDatasetGiftAidId.Name := 'NotificationsDatasetGiftAidId';
    NotificationsDatasetGiftAidId.FieldName := 'GiftAidId';
  finally
    CancelButton.AfterLoadDFMValues;
    PlaceJobButton.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    LetterThreshold.AfterLoadDFMValues;
    EmailThreshold.AfterLoadDFMValues;
    LetterDate.AfterLoadDFMValues;
    SalesCutOffDate.AfterLoadDFMValues;
    LetterThresholdOther.AfterLoadDFMValues;
    EmailThresholdOther.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
    NotificationsDataset.AfterLoadDFMValues;
    NotificationsDatasetId.AfterLoadDFMValues;
    NotificationsDatasetClaimId.AfterLoadDFMValues;
    NotificationsDatasetClaimRef.AfterLoadDFMValues;
    NotificationsDatasetLetterThreshold.AfterLoadDFMValues;
    NotificationsDatasetLastSalesDate.AfterLoadDFMValues;
    NotificationsDatasetEmailThreshold.AfterLoadDFMValues;
    NotificationsDatasetEmailCount.AfterLoadDFMValues;
    NotificationsDatasetNotNeededCount.AfterLoadDFMValues;
    NotificationsDatasetLetterCount.AfterLoadDFMValues;
    NotificationsDatasetLetterDate.AfterLoadDFMValues;
    NotificationsDatasetUnderThresholdCount.AfterLoadDFMValues;
    NotificationsDatasetStatus.AfterLoadDFMValues;
    NotificationsDatasetEarliestSalesDate.AfterLoadDFMValues;
    NotificationsDatasetGiftAidId.AfterLoadDFMValues;
  end;
end;

end.

