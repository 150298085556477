unit NotificationsRedoJobForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TRedoNotificationsJob = class(TJobBase)
    RerunEmails: TCheckBox;
    RerunLetters: TCheckBox;
    procedure RerunEmailsClick(Sender: TObject);
    procedure RerunLettersClick(Sender: TObject);
  private
    FNotificationDataset: TXDataWebDataSet;
    procedure SetNotificationDataset(const Value: TXDataWebDataSet);
    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;
  public
    { Public declarations }
    property NotificationDataset: TXDataWebDataSet read FNotificationDataset write SetNotificationDataset;

  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}
{ TRedoNotificationsJob }

function TRedoNotificationsJob.CanPlaceJob: Boolean;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    Result := RerunLetters.Checked or RerunEmails.Checked;
  end;
end;

function TRedoNotificationsJob.JobClass: string;
begin
  Result := 'TRedoNotifications';
end;

function TRedoNotificationsJob.JobDescription: string;
begin
  Result := 'Rerun notification mail merge';
end;

function TRedoNotificationsJob.JobDisplayName: string;
begin
  Result := 'Reruns notification output';
end;

procedure TRedoNotificationsJob.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['NotificationId'] := NotificationDataset.FieldByName('Id').AsInteger;
  AJobParams.Properties['Emails'] := RerunEmails.Checked;
  AJobParams.Properties['Letters'] := RerunLetters.Checked;
end;

function TRedoNotificationsJob.MinimumProcessLevel: string;
begin
  result := 'AnyAdministrator';
end;

procedure TRedoNotificationsJob.RerunEmailsClick(Sender: TObject);
begin
  ValidateJob;
end;

procedure TRedoNotificationsJob.RerunLettersClick(Sender: TObject);
begin
  ValidateJob;
end;

procedure TRedoNotificationsJob.SetNotificationDataset(const Value: TXDataWebDataSet);
begin
  FNotificationDataset := Value;
end;

procedure TRedoNotificationsJob.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RerunEmails := TCheckBox.Create('RerunEmails');
  RerunLetters := TCheckBox.Create('RerunLetters');

  RerunEmails.BeforeLoadDFMValues;
  RerunLetters.BeforeLoadDFMValues;
  try
    RerunEmails.SetParentComponent(Self);
    RerunEmails.Name := 'RerunEmails';
    RerunEmails.Left := 182;
    RerunEmails.Top := 360;
    RerunEmails.Width := 113;
    RerunEmails.Height := 22;
    RerunEmails.Caption := 'Rerun Emails';
    RerunEmails.ChildOrder := 10;
    RerunEmails.Color := clNone;
    RerunEmails.ElementClassName := 'form-check-input';
    RerunEmails.ElementFont := efCSS;
    RerunEmails.ElementPosition := epIgnore;
    RerunEmails.HeightStyle := ssAuto;
    RerunEmails.HeightPercent := 100.000000000000000000;
    RerunEmails.State := cbUnchecked;
    RerunEmails.WidthStyle := ssAuto;
    RerunEmails.WidthPercent := 100.000000000000000000;
    SetEvent(RerunEmails, Self, 'OnClick', 'RerunEmailsClick');
    RerunLetters.SetParentComponent(Self);
    RerunLetters.Name := 'RerunLetters';
    RerunLetters.Left := 326;
    RerunLetters.Top := 360;
    RerunLetters.Width := 113;
    RerunLetters.Height := 22;
    RerunLetters.Caption := 'Rerun Letters';
    RerunLetters.ChildOrder := 10;
    RerunLetters.Color := clNone;
    RerunLetters.ElementClassName := 'form-check-input';
    RerunLetters.ElementLabelClassName := 'form-control-label';
    RerunLetters.ElementFont := efCSS;
    RerunLetters.ElementPosition := epIgnore;
    RerunLetters.HeightStyle := ssAuto;
    RerunLetters.HeightPercent := 100.000000000000000000;
    RerunLetters.State := cbUnchecked;
    RerunLetters.WidthStyle := ssAuto;
    RerunLetters.WidthPercent := 100.000000000000000000;
    SetEvent(RerunLetters, Self, 'OnClick', 'RerunLettersClick');
  finally
    RerunEmails.AfterLoadDFMValues;
    RerunLetters.AfterLoadDFMValues;
  end;
end;

end.
