unit RegionList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  WEBLib.Actions,
  WEBLib.Toast,
  AppUtils, WEBLib.ExtCtrls;

type
  TRegionListForm = class(TBaseListForm)
    MainDatasetRef: TStringField;
    MainDatasetName: TStringField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetStatus: TStringField;
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    { Private declarations }
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  RegionListForm: TRegionListForm;

implementation

uses
  RegionForm,
  Auth.Service;

{$R *.dfm}

{ TRegionListForm }

procedure TRegionListForm.cbPageSizeChange(Sender: TObject);
begin
  inherited;
//
end;

procedure TRegionListForm.edSearchChange(Sender: TObject);
begin
  inherited;
//
end;

function TRegionListForm.GetEditFormClass: TFormClass;
begin
  Result := TRegionEditForm;
end;

procedure TRegionListForm.LoadData;
begin
  FGridPlugin.Load;
end;

procedure TRegionListForm.PrepareForm;
begin
  FIdField := 'Ref';
end;

function TRegionListForm.RecordViewOption: TViewOption;
begin
  if AuthService.IsAdministrator then
    Result := TViewOption.voCreateAndEdit
  else
    Result := TViewOption.voReadOnly;
end;

procedure TRegionListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetRef := TStringField.Create(Self);
  MainDatasetName := TStringField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetStatus := TStringField.Create(Self);

  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetRef.BeforeLoadDFMValues;
  MainDatasetName.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetStatus.BeforeLoadDFMValues;
  try
    cbPageSize.Visible := False;
    edSearch.Visible := False;
    lcPaginator.Visible := False;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Ref';
      Title := 'Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Name';
      Title := 'Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Status';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SearchButton.Visible := False;
    MainDataset.EntitySetName := 'Region';
    MainDatasetRef.SetParentComponent(MainDataset);
    MainDatasetRef.Name := 'MainDatasetRef';
    MainDatasetRef.FieldName := 'Ref';
    MainDatasetRef.Size := 5;
    MainDatasetName.SetParentComponent(MainDataset);
    MainDatasetName.Name := 'MainDatasetName';
    MainDatasetName.FieldName := 'Name';
    MainDatasetName.Size := 250;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetStatus.SetParentComponent(MainDataset);
    MainDatasetStatus.Name := 'MainDatasetStatus';
    MainDatasetStatus.FieldName := 'Status';
    MainDatasetStatus.Size := 7;
  finally
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetRef.AfterLoadDFMValues;
    MainDatasetName.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetStatus.AfterLoadDFMValues;
  end;
end;

end.


